<div class="profile_wrap">
    <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png " />
                    </figure>
                </div>
            </div>
        </div>
    </div>
    <div class="prfile">
        <div class="conta_iner">
          
            <div class="profile_main">
                <div class="toggle2">
                    <svg class="ham hamRotate ham8" viewBox="0 0 100 100" width="80" onclick="this.classList.toggle('active')">
                        <path
                              class="line top"
                              d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                        <path
                              class="line middle"
                              d="m 30,50 h 40" />
                        <path
                              class="line bottom"
                              d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                      </svg>
                      
                </div>
                <mat-horizontal-stepper [linear]="isLinear" #stepper >
                    <mat-step [stepControl]="personalFormGroup" [editable]="editable">
                        <form [formGroup]="personalFormGroup">
                            <ng-template matStepLabel>Personal Details</ng-template>
                            <div class="deatil_form_outer">
                                <h3 class="driver_title">Personal detail</h3>

                                <div class="detail_form">
                                    <div class="driver_profile_img">
                                        <div class="pr_man">
                                            <figure *ngIf="!imageSrc">
                                                <img (click)="fileUpload.click()"
                                                    src="assets/images/user.png " />
                                            </figure>
                                            <figure *ngIf="imageSrc">
                                                <img (click)="fileUpload.click()" [src]="imageSrc" />
                                            </figure>
                                        </div>
                                        <div class="profile_camera">
                                            <figure>
                                                <img (click)="fileUpload.click()" src="assets/uimages/edit.svg " />
                                            </figure>
                                            <input class="upload_file" id="file-input" #fileUpload type="file"
                                                (change)="onProfileUpload($event,'proImage')" />
                                        </div>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>First Name<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>First Name</mat-label>
                                            <input matInput type="text" class="form-control" formControlName="firstName"
                                                name="name " />
                                            <mat-error *ngIf="
                                            personalFormGroup.controls['firstName'].hasError('required')">
                                                First name is required!
                                            </mat-error>
                                            <mat-error *ngIf="
                                            personalFormGroup.controls['firstName'].hasError('pattern')">
                                                Please enter valid first name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Last Name<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Last Name</mat-label>
                                            <input matInput type="text" class="form-control" formControlName="lastName"
                                                name="name " />
                                            <mat-error
                                                *ngIf="personalFormGroup.controls['lastName'].hasError('required')">
                                                Last name is required!
                                            </mat-error>
                                            <mat-error *ngIf="
                                            personalFormGroup.controls['lastName'].hasError('pattern')">
                                                Please enter valid last name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Email Address<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Email Address</mat-label>
                                            <input matInput type="text" class="form-control" formControlName="email"
                                                name="name " />
                                            <mat-error *ngIf="personalFormGroup.controls['email'].hasError('required')">
                                                Email address is required.
                                            </mat-error>
                                            <mat-error *ngIf="personalFormGroup.controls['email'].hasError('pattern')">
                                                Sorry, only letters (a-z), number (0-9), and periods (.) are allowed.
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-error *ngIf="inValidEmail">
                                            Email already exist.
                                        </mat-error>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Phone Number<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Phone Number</mat-label>
                                            <input matInput type="text" class="form-control"
                                                formControlName="phoneNumber" name="name" readonly/>
                                            <mat-error
                                                *ngIf="personalFormGroup.controls['phoneNumber'].hasError('required')">
                                                Phone number is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>DL Number<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>DL Number</mat-label>
                                            <input matInput type="text" class="form-control"
                                                formControlName="dlNumber" name="name"/>
                                            <mat-error
                                                *ngIf="personalFormGroup.controls['dlNumber'].hasError('required')">
                                                DL Number is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="btn_outer">
                                <button mat-button class="btn btn-primary" (click)="goToStepTwo()">Next</button>
                            </div>

                            <!-----start fileds-->

                            <!-----End fileds-->
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="addressFormGroup" [editable]="editable">
                        <form [formGroup]="addressFormGroup">
                            <ng-template matStepLabel>Address</ng-template>

                            <div class="deatil_form_outer">
                                <h3 class="driver_title">Address</h3>

                                <div class="detail_form">
                                    <div class="detail_form_items">
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Street Name/Street No.</mat-label>
                                            <input class="form-control" matInput type="text "
                                                formControlName="streetName" name="name " />
                                            <mat-error
                                                *ngIf="addressFormGroup.controls['streetName'].hasError('required')">
                                                Street name is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>search for your address</mat-label>
                                            <input matInput placeholder="search for your address" autocorrect="off"
                                                autocapitalize="off" spellcheck="off" type="text" class="form-control"
                                                ngx-google-places-autocomplete #placesRef="ngx-places"
                                                (onAddressChange)="handleLocationChnage($event)"
                                                formControlName="searchControl" />
                                            <mat-error
                                                *ngIf="addressFormGroup.controls['searchControl'].hasError('required')">
                                                Location is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <mat-form-field [floatLabel]="'never'">
                                            <!-- <mat-label>Postal/Zip Code</mat-label> -->
                                            <input class="form-control" matInput type="text"
                                                placeholder="Postal/Zip Code" formControlName="zipCode" name="name "
                                                />
                                            <mat-error
                                                *ngIf="addressFormGroup.controls['zipCode'].hasError('required')">
                                                Postal/Zip code is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Password</mat-label>
                                            <input class="form-control" matInput type="password"
                                                formControlName="password" name="name " />
                                            <mat-error
                                                *ngIf="addressFormGroup.controls['password'].hasError('required')">
                                                Password is required.</mat-error>
                                                <mat-error
                                                *ngIf="addressFormGroup.controls['password'].hasError('minlength')">
                                                Password should be 6 character long.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Confirm Password</mat-label>
                                            <input class="form-control" type="password" matInput type="password "
                                                formControlName="confirmPass" name="name " />
                                            <mat-error
                                                *ngIf="addressFormGroup.controls['confirmPass'].hasError('required')">
                                                Confirm password is required.</mat-error>
                                            <mat-error
                                                *ngIf="addressFormGroup.controls['confirmPass'].hasError('mustMatch')">
                                                Passwords must match</mat-error>
                                                <mat-error
                                                *ngIf="addressFormGroup.controls['confirmPass'].hasError('minlength')">
                                                Password should be 6 character long</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="checkbox_outer">
                                    <mat-checkbox formControlName="TandC">
                                        <p>By clicking here you agree to the <a>Apptunix<sup>TM</sup> Contract</a> and
                                            terms, conditions
                                            and policies as stated in the <a>Apptunix<sup>TM</sup> Policy Manual</a>
                                            without reservations.
                                        </p>
                                    </mat-checkbox>
                                    <mat-error
                                        *ngIf="submitted && addressFormGroup.controls['TandC'].hasError('required')">
                                        Terms and conditions are required.</mat-error>
                                </div>
                            </div>
                            <div class="btn_divide">
                                <div class="btn_outer inactive">
                                    <button mat-button (click)="goToPersonalTab()" class="btn btn-primary">
                                        Back
                                    </button>
                                </div>
                                <div class="btn_outer">
                                    <button mat-button (click)="register(2,'addressFormGroup','next')"
                                        class="btn btn-primary">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="bankFormGroup" [editable]="editable">
                        <form [formGroup]="bankFormGroup">
                            <ng-template matStepLabel>Bank Details</ng-template>
                            <!-- <mat-form-field>
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="secondCtrl " placeholder="Ex. 1 Main St, New York, NY " required />
                    </mat-form-field> -->
                            <div class="deatil_form_outer">
                                <h3 class="driver_title">Bank Details</h3>

                                <div class="detail_form">
                                    <div class="detail_form_items">
                                        <label>Account Number<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <input class="form-control" placeholder="Account number" matInput
                                                type="text" formControlName="accNumber" name="name " />
                                            <mat-error *ngIf="bankFormGroup.controls['accNumber'].hasError('required')">
                                                Account number is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Transit<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Transit</mat-label>
                                            <input class="form-control" matInput type="text" formControlName="transit"
                                                name="name " />
                                            <mat-error *ngIf="bankFormGroup.controls['transit'].hasError('required')">
                                                Transit is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Bank Name<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Bank Name</mat-label>
                                            <input class="form-control" matInput type="text" formControlName="bankName"
                                                name="name " />
                                            <mat-error *ngIf="bankFormGroup.controls['bankName'].hasError('required')">
                                                Bank name is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Branch Address<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Branch Address</mat-label>
                                            <input class="form-control" matInput type="text" formControlName="branchAdd"
                                                name="name " />
                                            <mat-error *ngIf="bankFormGroup.controls['branchAdd'].hasError('required')">
                                                Branch address is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Attached a copy of Void Cheque<span class="red">*</span></label>
                                        <input class="form-control" type="file" formControlName="voidCheque"
                                            (change)="onFileChange($event,'voidCheque')" name=" " id=" " />
                                        <mat-error *ngIf="bankFormGroup.controls['voidCheque'].hasError('required')">
                                            This field is required.
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="btn_divide">
                                <div class="btn_outer inactive">
                                    <button mat-button (click)="goToBack()" class="btn btn-primary">Back</button>
                                </div>
                                <div class="btn_outer">
                                    <button mat-button (click)="bankFormSubmit()" class="btn btn-primary">Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <!------start driver info-->
                    <mat-step [stepControl]="drivingFormGroup" [editable]="editable">
                        <form [formGroup]="drivingFormGroup">
                            <ng-template matStepLabel>Driving Info</ng-template>

                            <div class="deatil_form_outer">
                                <h3 class="driver_title">Driving Info</h3>

                                <div class="detail_form">
                                    <div class="detail_form_items">
                                        <label>DL Number<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>DL Number</mat-label>
                                            <input class="form-control" matInput type="text" formControlName="dlNumber"
                                                name="name " />
                                            <mat-error
                                                *ngIf="drivingFormGroup.controls['dlNumber'].hasError('required')">Dl
                                                number is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Expiry<span class="red">*</span></label>
                                        <mat-form-field appearance="fill" class="date-picker-outer">
                                            <mat-label>Select Expiry Date</mat-label>
                                            <input matInput formcontrolName="dlExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getDlExpiryDate($event)"
                                                [matDatepicker]="dp3" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="dp3" class="date_picker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #dp3 disabled="false" class="date_picker1"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Country<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Country</mat-label>
                                            <input class="form-control" matInput type="text" formControlName="dlCountry"
                                                name="name " />
                                            <mat-error
                                                *ngIf="drivingFormGroup.controls['dlCountry'].hasError('required')">
                                                Country is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Province/ State<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>search for location</mat-label>
                                            <input matInput placeholder="search for location" autocorrect="off"
                                                autocapitalize="off" spellcheck="off" type="text" class="form-control"
                                                ngx-google-places-autocomplete #placesRef="ngx-places"
                                                (onAddressChange)="handleStateChnage($event)"
                                                formControlName="dlState" />
                                            <mat-error
                                                *ngIf="drivingFormGroup.controls['dlState'].hasError('required')"> Province/ State
                                                name is required.</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="detail_form_items">
                                        <label>Attached a copy of Your driving license<span class="red">*</span></label>
                                        <input class="form-control" type="file" name=" " formControlName="dlImage"
                                            (change)="onFileChange($event,'dlImage')" id=" " />
                                        <mat-error *ngIf="drivingFormGroup.controls['dlImage'].hasError('required')">
                                            Driving license is required.</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="btn_divide">
                                <div class="btn_outer inactive">
                                    <button mat-button (click)="goToBack()" class="btn btn-primary">Back</button>
                                </div>
                                <div class="btn_outer">
                                    <button mat-button (click)="drivingFormSubmit()"
                                        class="btn btn-primary">Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <!------end driver info-->
                    <!-----start vehicle detail-->
                    <mat-step [stepControl]="vehicleFormGroup" [editable]="editable">
                        <form [formGroup]="vehicleFormGroup">
                            <ng-template matStepLabel>Vehicle Info</ng-template>

                            <div class="deatil_form_outer">
                                <h3 class="driver_title">Vehicle Info</h3>

                                <div class="detail_form">
                                    <div class="detail_form_items">
                                        <label>Select Vehicle Make<span class="red">*</span></label>
                                        <mat-form-field class="select_bx">
                                            <mat-label>Select Vehicle Make</mat-label>
                                            <!-- <input type="text" matInput class="form-control"
                                                formControlName="vehicleMake" /> -->
                                                <mat-select class="form-control" formControlName="vehicleMake">
                                                    <mat-option *ngFor="let vehicle of getVehicleCategoryData" [value]="vehicle?.id">
                                                        {{ vehicle?.vehicleCategory }}
                                                    </mat-option>
                                                </mat-select>
                                            <mat-error
                                                *ngIf="vehicleFormGroup.controls['vehicleMake'].hasError('required')">
                                                Select vehicle make is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Select Vehicle Model<span class="red">*</span></label>
                                        <mat-form-field class="select_bx">
                                            <mat-label>Select Vehicle Model</mat-label>
                                            <!-- <input type="text" matInput class="form-control"
                                                formControlName="vehicleModel" /> -->
                                                <mat-select class="form-control" formControlName="vehicleModel">
                                                    <mat-option value="E-250">E-250</mat-option>
                                                    <mat-option value="Ferrari-One">Ferrari-One</mat-option>
                                                    <mat-option value="Octavia">Octavia</mat-option>
                                                    <mat-option value="Maserati">Maserati</mat-option>
                                                    <mat-option value="BMW">BMW</mat-option>
                                                </mat-select>
                                            <mat-error
                                                *ngIf="vehicleFormGroup.controls['vehicleModel'].hasError('required')">
                                                Select vehicle model is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- //vehicle name added -->
                                    <div class="detail_form_items">
                                        <label>Vehicle image<span class="red">*</span></label>
                                        <input type="file" class="form-control" formControlName="vehicleImage"
                                            (change)="onFileChange($event,'vehicleImage')">
                                        <mat-error
                                            *ngIf="isVehicleSubmit && vehicleFormGroup.controls['vehicleImage'].hasError('required')">
                                            Vehicle image is required.</mat-error>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>License plate number<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>License plate number</mat-label>
                                            <input class="form-control" matInput type="text "
                                                formControlName="licensePlateNo" name="name" maxlength="10" />
                                            <mat-error
                                                *ngIf="vehicleFormGroup.controls['licensePlateNo'].hasError('required')">
                                                License plate number is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>License Plate<span class="red">*</span></label>
                                        <input type="file" class="form-control" formControlName="licensePlateImage"
                                            (change)="onFileChange($event,'licensePlateImage')">
                                        <mat-error
                                            *ngIf="isVehicleSubmit && vehicleFormGroup.controls['licensePlateImage'].hasError('required')">
                                            License plate image is required.</mat-error>
                                    </div>

                                    <div class="detail_form_items">
                                        <label>Select Vehicle Year<span class="red">*</span></label>
                                        <mat-form-field class="select_bx">
                                            <mat-label>Select Vehicle Year</mat-label>
                                            <mat-select class="form-control" formControlName="vehicleYear">
                                                <mat-option *ngFor="let year of vehicleYears" [value]="year">
                                                    {{ year }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="vehicleFormGroup.controls['vehicleYear'].hasError('required')">
                                                Select vehicle year is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Insurance policy number<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Insurance policy number</mat-label>
                                            <input class="form-control" matInput type="text "
                                                formControlName="policyNumber" name="name " />
                                            <mat-error
                                                *ngIf="vehicleFormGroup.controls['policyNumber'].hasError('required')">
                                                Insurance policy number is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Insurance name<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>Insurance name</mat-label>
                                            <input class="form-control" matInput type="text "
                                                formControlName="insuranceName" name="name " />
                                            <mat-error
                                                *ngIf="vehicleFormGroup.controls['insuranceName'].hasError('required')">
                                                Insurance name is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Insurance Expiry date<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'" class="date-picker-outer">
                                            <mat-label>Select Expiry Date</mat-label>
                                            <input matInput formControlName="insuranceExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getInsuranceExpiryDate($event)"
                                                [matDatepicker]="dp4" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                            <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                                            <mat-error
                                                *ngIf="isVehicleSubmit && vehicleFormGroup.controls['insuranceExpiry'].hasError('required')">
                                                Expiry date is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Inspection Expiry date<span class="red">*</span></label>
                                        <mat-form-field [floatLabel]="'never'" class="date-picker-outer">
                                            <mat-label>Select Expiry Date</mat-label>
                                            <input matInput formControlName="inspectionExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getInspectionExpiryDate($event)"
                                                [matDatepicker]="dp5" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="dp5"></mat-datepicker-toggle>
                                            <mat-datepicker #dp5 disabled="false"></mat-datepicker>
                                            <mat-error
                                                *ngIf="isVehicleSubmit && vehicleFormGroup.controls['inspectionExpiry'].hasError('required')">
                                                Expiry date is required.</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="detail_form_items">
                                        <label>Share drive coverage<span class="red">*</span></label>
                                        <mat-form-field class="select_bx">
                                            <mat-label>Share drive coverage</mat-label>
                                            <!-- <input type="text" class="form-control" matInput
                                                formControlName="driveCoverage" > -->
                                            <!-- <mat-radio-group aria-label="Select an option " class="form-control drive-coverage"
                                                formControlName="driveCoverage">
                                                <mat-radio-button [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button [value]="false">No</mat-radio-button>
                                            </mat-radio-group> -->
                                            <mat-select class="form-control" formControlName="driveCoverage">
                                                <mat-option [value]="true">Yes</mat-option>
                                                <mat-option [value]="false">No</mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="isVehicleSubmit && vehicleFormGroup.controls['driveCoverage'].hasError('required')">
                                                Share drive coverage is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Attach A Copy Of Your vehicle insurance<span class="red">*</span></label>
                                        <input class="form-control" type="file"
                                            (change)="onFileChange($event,'insuranceImage')"
                                            formControlName="insuranceImage">
                                        <mat-error
                                            *ngIf="isVehicleSubmit && vehicleFormGroup.controls['insuranceImage'].hasError('required')">
                                            Vehicle insurance image is required.</mat-error>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Attach A Copy Of Your Registration/Ownership<span
                                                class="red">*</span></label>
                                        <input class="form-control" type="file" formControlName="registrationImage"
                                            (change)="onFileChange($event,'registrationImage')">
                                        <mat-error
                                            *ngIf="isVehicleSubmit && vehicleFormGroup.controls['registrationImage'].hasError('required')">
                                            VRegistration/Ownership image is required.</mat-error>
                                    </div>
                                    <div class="detail_form_items">
                                        <label>Attach A Copy Of vehicle Inspection<span class="red">*</span></label>
                                        <input class="form-control" type="file" formControlName="inspectionImage"
                                            (change)="onFileChange($event,'inspectionImage')">
                                        <mat-error
                                            *ngIf="isVehicleSubmit && vehicleFormGroup.controls['inspectionImage'].hasError('required')">
                                            Vehicle inspection image is required.</mat-error>
                                    </div>

                                    <div class="detail_form_items full_widthdetail">
                                        <label>Does your vehicle have A/C and it is working?</label>
                                        <div class="radio_component">
                                            <mat-radio-group aria-label="Select an option "
                                                formControlName="isAcWorking">
                                                <mat-radio-button [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button [value]="false">No</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isAcWorking'].hasError('required')">
                                                This field is is required.</mat-error>
                                        </div>
                                    </div>
                                    <div class="detail_form_items full_widthdetail">
                                        <label>Does your vehicle have heating and it is working?</label>
                                        <div class="radio_component">
                                            <mat-radio-group aria-label="Select an option "
                                                formControlName="isHeatingWorking">
                                                <mat-radio-button [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button [value]="false">No</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isHeatingWorking'].hasError('required')">
                                                This field is is required.</mat-error>
                                        </div>
                                    </div>
                                    <div class="detail_form_items full_widthdetail">
                                        <label>Are there any dents or rust in the body of the vehicle? </label>
                                        <div class="radio_component">
                                            <mat-radio-group aria-label="Select an option "
                                                formControlName="isAnyDents">
                                                <mat-radio-button [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button [value]="false">No</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isAnyDents'].hasError('required')">
                                                This field is is required.</mat-error>
                                        </div>
                                    </div>
                                    <div class="detail_form_items full_widthdetail">
                                        <label>Is the interior clean and without rips/burns/smells? </label>
                                        <div class="radio_component">
                                            <mat-radio-group aria-label="Select an option "
                                                formControlName="isInteriorClean">
                                                <mat-radio-button [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button [value]="false">No</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isInteriorClean'].hasError('required')">
                                                This field is is required.</mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn_divide">
                                <div class="btn_outer inactive">
                                    <button mat-button (click)="goToBack()" class="btn btn-primary">Back</button>
                                </div>
                                <div class="btn_outer">
                                    <button mat-button (click)="vehicleFormSubmit()"
                                        class="btn btn-primary">Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <!----end vehicle detail-->
                    <mat-step [stepControl]="regulatoryFormGroup" [editable]="editable">
                        <form [formGroup]="regulatoryFormGroup">
                            <ng-template matStepLabel>Regulatory Compliance</ng-template>
                            <div class="deatil_form_outer">
                                <h3 class="driver_title">Regulatory Compliance</h3>

                                <div class="detail_form">
                                    <div class="detail_form_items ">
                                        <label>Attach A Copy Of Your Criminal Background Record Check<span
                                                class="red">*</span></label>
                                        <input class="form-control" id="myfile " name="myfile"
                                            (change)="onFileChange($event, 'criminalBg')" formControlName="criminalBg"
                                            type="file" />
                                        <mat-error
                                            *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['criminalBg'].hasError('required')">
                                            Image is required.</mat-error>
                                    </div>
                                    <div class="detail_form_items ">
                                        <label>Expiry date<span class="red">*</span></label>
                                        <mat-form-field class="select_bx date-picker-outer">
                                            <mat-label>Select expiry date</mat-label>
                                            <input matInput formControlName="criminalBgExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getInspectionExpiryDate($event)"
                                                [matDatepicker]="dp6" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="dp6"></mat-datepicker-toggle>
                                            <mat-datepicker #dp6 disabled="false"></mat-datepicker>
                                            <mat-error
                                                *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['criminalBgExpiry'].hasError('required')">
                                                Expiry is required.</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="detail_form_items ">
                                        <label>Attach A Copy Of Your Child Abuse Register Check<span
                                                class="red">*</span></label>
                                        <input class="form-control" id="myfile "
                                            (change)="onFileChange($event, 'abuseRegisterCheck')" name="myfile "
                                            formControlName="abuseRegisterCheck" type="file" />
                                        <mat-error
                                            *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['abuseRegisterCheck'].hasError('required')">
                                            Image is required.</mat-error>
                                    </div>
                                    <div class="detail_form_items ">
                                        <label>Expiry date<span class="red">*</span></label>
                                        <mat-form-field class="select_bx date-picker-outer">
                                            <mat-label>Select expiry date</mat-label>
                                            <input matInput formControlName="childAbuseExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getInspectionExpiryDate($event)"
                                                [matDatepicker]="dp7" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="dp7"></mat-datepicker-toggle>
                                            <mat-datepicker #dp7 disabled="false"></mat-datepicker>
                                            <mat-error
                                                *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['childAbuseExpiry'].hasError('required')">
                                                Expiry is required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="checkbox_outer">
                                <mat-checkbox formControlName="TandC">
                                    <p>By clicking here you agree to the<a>Apptunix<sup>TM</sup> Contract</a> and terms, conditions and
                                        policies as stated in the <a>Apptunix<sup>TM</sup> Policy Manual</a> without reservations.</p>
                                </mat-checkbox>
                                <mat-error *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['TandC'].hasError('required')">
                                    Terms and Conditions are required.</mat-error>
                            </div>
                            <div class="btn_divide">
                                <div class="btn_outer inactive">
                                    <button mat-button (click)="goToBack()" class="btn btn-primary">Back</button>
                                </div>
                                <div class="btn_outer">
                                    <button mat-button (click)="regularityFormSubmit()"
                                        class="btn btn-primary">Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="documentFormGroup" [editable]="editable">
                        <form [formGroup]="documentFormGroup">
                            <ng-template matStepLabel>Documents</ng-template>
                            <div class="deatil_form_outer">
                                <h3 class="driver_title">Documents</h3>

                                <div class="detail_form">
                                    <div class="driver_doc_img" *ngFor="let document of requiredDocumentsData">
                                        <h3>{{document?.documentName}}<span *ngIf="document?.isMandate">*</span></h3>
                                        <div class="driver_doc_outer">
                                            <div class="detail_form_items" *ngIf="document?.isFrontSideImage">
                                                <label>Front Side<span class="red"
                                                        *ngIf="document?.isMandate">*</span></label>
                                                <input class="form-control" type="file"
                                                    [formControlName]="document?.documentName+'FrontSide'"
                                                    (change)="onDocumentUpload($event,document?.documentName+'FrontSide')" />
                                                <mat-error
                                                    *ngIf="document?.isMandate && documentFormGroup.controls[document?.documentName+'FrontSide'].hasError('required')">
                                                    Front side image of {{document?.documentName}} is required.
                                                </mat-error>
                                            </div>
                                            <div class="detail_form_items" *ngIf="document?.isBackSideImage">
                                                <label>Back Side<span class="red"
                                                        *ngIf="document?.isMandate">*</span></label>
                                                <input class="form-control" type="file"
                                                    [formControlName]="document?.documentName+'BackSide'"
                                                    (change)="onDocumentUpload($event,document?.documentName+'BackSide')" />
                                                <mat-error
                                                    *ngIf="document?.isMandate && documentFormGroup.controls[document?.documentName+'BackSide'].hasError('required')">
                                                    Back side image of {{document?.documentName}} is required.
                                                </mat-error>
                                            </div>
                                            <div class="detail_form_items" *ngIf="document?.isExpirydate">
                                                <label>Expiry date<span class="red"
                                                        *ngIf="document?.isMandate">*</span></label>
                                                <mat-form-field class="select_bx date-picker-outer">
                                                    <mat-label>Expiry</mat-label>
                                                    <input matInput [min]="minDate"
                                                        [formControlName]="document?.documentName+'Expiry'"
                                                        class="form-control"
                                                        (dateChange)="getExpiryDateOfDocuments($event,document?.documentName+'Expiry')"
                                                        [matDatepicker]="dp8" disabled>
                                                    <mat-datepicker-toggle matSuffix [for]="dp8">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #dp8 disabled="false"></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="document?.isMandate && documentFormGroup.controls[document?.documentName+'Expiry'].hasError('required')">
                                                        Expiry date of {{document?.documentName}} is required.
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn_divide">
                                <div class="btn_outer inactive">
                                    <button mat-button (click)="goToBack()" class="btn btn-primary">Back</button>
                                </div>
                                <div class="btn_outer">
                                    <button mat-button (click)="documentFormSubmit()"
                                        class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>




                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
</div>