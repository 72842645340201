import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../services/common/common.service";
import { HttpsService } from "../services/https/https.service";
@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.scss"],
})
export class VerificationComponent implements OnInit, AfterViewInit {
  otp: string;
  nextPopup = 0;
  verificationData: any = {
    countryCode: '',
    phoneNo: '',
    otpCode: ''
  }
  phoneNo: any;
  countryCode: any;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;

  constructor(
    private router: Router,
    private commonservice: CommonService,
    private changeDetect: ChangeDetectorRef,
    private httpService: HttpsService
  ) { }

  ngOnInit(): void {
    if(localStorage.phoneNo !== undefined) {
      this.verificationData.phoneNo = localStorage.getItem('phoneNo');
    }
    if(localStorage.countryCode !== undefined) {
      this.verificationData.countryCode = localStorage.getItem('countryCode');
    }
  }

  ngAfterViewInit() {
    this.changeDetect.detectChanges();
    this.commonservice.getVerificationData().subscribe((res: any) => {
      if (res) {
        this.verificationData = res;
        this.phoneNo = res.phoneNo;
        this.countryCode = res.countryCode;
        // this.ngOtpInput.setValue(res.otpCode);
      }
    });
  }

  resendCode() {
    this.httpService
      .httpPost("sendOtp", { phoneNo: this.verificationData.phoneNo, countryCode: this.verificationData.countryCode })
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonservice.OpenNotificationModal("success", "top-end", res.message);
          this.commonservice.setVerificationData({
            countryCode: res.data.countryCode,
            phoneNo: res.data.phoneNo,
            otpCode: res.data.otpCode,
          });
        }
      });
  }

  verify() {
    var param = {
      otpCode: this.verificationData.otpCode,
      countryCode: this.verificationData.countryCode,
      phoneNo: this.verificationData.phoneNo,
    };
    this.httpService.httpPost("verifyOtp", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonservice.OpenNotificationModal("success", "top-end", res.message);
        var detail = {
          isDriverInfo: false,
          isFilledBankDetail: false,
          isRegularCompliance: false,
          isVehicleInfo: false,
          isNewUser: true
        }
        this.commonservice.setUserProfileAccessDetail(detail);
        localStorage.setItem('verifiedPhone',JSON.stringify({phoneNo: res.data.phoneNo, countryCode: res.data.countryCode, driverType: this.commonservice.driverType}));
        // this.commonservice.openCongratsModal();
        this.router.navigate(["urider"]);
      } else {
        this.commonservice.OpenNotificationModal("error", "top-end", res.message);
      }
    });
  }

  onOtpChange(otp) {
    this.verificationData.otpCode = otp;
    // var param = {
    //   "otpCode": this.verificationData.otpCode,
    //   "countryCode": "+91",
    //   "phoneNo": this.verificationData.phoneNo
    // }
    // this.httpService.httpPost('verifyOtp', param).subscribe((res: any) => {
    //   if(res.statusCode == 200) {
    //     this.commonservice.OpenNotificationModal('success','top-end',res.message);
    //     this.router.navigate(["profile"]);
    //   } else {
    //     this.commonservice.OpenNotificationModal('error','top-end',res.message);
    //   }
    // });
  }

  goTouhomepage() {
    this.router.navigate(["/uhomepage"]);
  }
  goTosignup() {
    this.router.navigate(["/signup"]);
  }
}
