import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-download-application',
  templateUrl: './download-application.component.html',
  styleUrls: ['./download-application.component.scss']
})
export class DownloadApplicationComponent implements OnInit, OnDestroy {
  id: NodeJS.Timeout;

  constructor(
    public dialogRef: MatDialogRef<DownloadApplicationComponent>
  ) { }
  

  ngOnInit(): void {
    this.id = setInterval(() => {
      this.close(); 
    }, 2000);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

}
