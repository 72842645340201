<div class="profile_wrap">
 <div class="congrutions_page">
       
            <div class="congrutions_img">
                <figure>
                    <img src="assets/uimages/Congratulations.svg" alt="" />
                </figure>
                <h3>Congratulations</h3>
                <p>Now that you're all signed up, Let's explore the world.</p>
                <div class="btn_common_cls">
                    <button class="btn btn-primary" (click)="goTouhomepage()">
            Continue
          </button>
                </div>
            </div>
        </div>

</div>