import { AfterViewInit, Component, OnInit, ViewChild, NgZone, AfterViewChecked, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
// import * as $ from "jquery";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { MatStepper } from '@angular/material/stepper';
import { MapsAPILoader } from '@agm/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { fConstant } from '../core/constant';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
interface Ready {
  value: string;
  viewValue: string;
}
interface driver {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-riderdetail",
  templateUrl: "./riderdetail.component.html",
  styleUrls: ["./riderdetail.component.scss"],
})
export class RiderdetailFComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  @ViewChild('stepper', { static: false }) public stepper: MatStepper;
  @ViewChild('step1', { static: false }) public step1: any;
  @ViewChild('step2', { static: false }) public step2: any;
  isLinear: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  threeFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  pick: Ready[] = [
    { value: "5 Days", viewValue: "lorem" },
    { value: "1 Days", viewValue: "lorem" },
  ];
  pick2: driver[] = [
    { value: "DELIVERY", viewValue: "Delivery Driver " },
    { value: "RIDE", viewValue: "Ride Driver" },
    { value: "BOTH", viewValue: "Both " },
  ];
  pick4: driver[] = [
    { value: "male", viewValue: "Male" },
    { value: "female", viewValue: "Female" },
    { value: "others", viewValue: "Others" },
  ];

  modalYear = [];
  addDriverForm: FormGroup;
  submitted: boolean = false;
  pickup: any;
  pickupAddress: any;
  geoCoder: google.maps.Geocoder;
  public onStepsChange: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public goToBack: BehaviorSubject<boolean> = new BehaviorSubject(null);
  fileDataUrl: any;
  baseUrl: any;
  userNameAlreadyExist: boolean = false;
  editable: boolean = false;
  notifier = new Subject();
  processing: boolean = true;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  isPhoneExist: boolean = false;
  userData: any;
  isEmailExist: boolean = false;
  fullAddress = { address: '', lat: 0, lng: 0 };
  latitude: number = 0;
  longitude: number = 0;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private http: HttpsService,
    private commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private constant: fConstant
  ) {
    this.baseUrl = environment.imageUrl;
  }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      firstName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      lastName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      userName: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      phone: ["", Validators.required],
      companyName: [""],
      image: [""],
    });
    this.firstFormGroup.get('phone').valueChanges.subscribe((value: any) => {
      if (value) {
        this.isPhoneExist = false;
      }
    });
    this.firstFormGroup.get('email').valueChanges.subscribe((value: any) => {
      if (value) {
        this.isEmailExist = false;
      }
    });
    this.secondFormGroup = this._formBuilder.group({
      street: ["", Validators.required],
      location: ["", Validators.required],
      zipCode: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      country: ["", Validators.required],
      localAddress: [''],
      agree: ["", Validators.requiredTrue],
    });
    this.threeFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });

    this.fourFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.getProfileData();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
    this.onStepsChange.pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res) {
        this.cdr.detectChanges();
        this.stepper.selectedIndex++;
        this.isLinear = true;
      }
    });
    this.goToBack.pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res) {
        this.cdr.detectChanges();
        this.stepper.selectedIndex--;
        this.isLinear = true;
        this.getProfileData();
      }
    });
    this.validateUserName();
  }

  validateUserName() {
    this.firstFormGroup.get('userName').valueChanges.pipe(takeUntil(this.notifier)).subscribe((value: any) => {
      if (value) {
        this.http.httpPostUser('checkUserName', { userName: value }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.userNameAlreadyExist = res.data.isUserExist;
            this.http.httpGetWithHeaderUser('userProfile', "").pipe(takeUntil(this.notifier)).subscribe((res1: any) => {
              if (res1.data.userName != "") {
                this.userNameAlreadyExist = false;
              }
            });
          }
        });
      }
    });
  }

  ngAfterViewInit() {
    this.http.httpGetWithHeaderUser('userProfile', "").pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      console.log(res)
      this.processing = false;
      if (res.data.userName === "") {
        this.isLinear = false
        this.stepper.selectedIndex = 0;
        this.isLinear = true;
        this.editable = false;
      } else if (res.data.address === "") {
        this.isLinear = false
        this.stepper.selectedIndex = 1;
        this.isLinear = true;
        this.editable = false;
      } else if (res.data.address != "" && !res.data.isAccountCompleted) {
        this.isLinear = false
        this.stepper.selectedIndex = 2;
        this.isLinear = true;
        this.editable = false;
      } else if (res.data.isAccountCompleted && !this.commonService.isUserRecovered) {
        this.router.navigate(['/riderequest']);
      }
    })
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address)
      let lat = (address.geometry.location.lat())
      let lng = (address.geometry.location.lng())
      this.fullAddress.lat = lat;
      this.fullAddress.lng = lng;
      if (this.userData.latitude === '' && this.userData.longitude === '') {
        this.latitude = lat;
        this.longitude = lng;
      }
      this.getPickupAddress(lat, lng, address.formatted_address);
    }
  }

  onKeyUp(value) {
    if (value === "") {
      this.fullAddress.lat = 0;
      this.fullAddress.lng = 0;
      this.secondFormGroup.controls['city'].patchValue(null);
    }
  }

  onAddChanged(value) {
    if (this.fullAddress.lat === 0 && this.fullAddress.lng === 0) {
      this.secondFormGroup.controls['city'].patchValue(null);
      this.submitted = true;
    } else if (this.fullAddress.address !== value) {
      this.secondFormGroup.controls['city'].patchValue(null);
      this.submitted = true;
    }
  }

  getPickupAddress(lat, lng, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          setTimeout(() => {
            if(!this.secondFormGroup.controls['zipCode'].value) {
              if(results[0].address_components[length-1].types.includes("postal_code")) {
                this.secondFormGroup.controls['zipCode'].patchValue(results[0].address_components[length-1].long_name);
              }
            }
          }, 1000);
          results.forEach(addresses => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log('exist');
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            console.log('not-exist');
            this.fetchAddress(results[0].address_components);
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach(element => {
      if (element.types.includes("locality") || element.types.includes("postal_town") || element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") || element.types.includes("sublocality_level_2") || element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") || element.types.includes("sublocality_level_5")) {
        city = element.long_name;
      }
      if (element.types.includes("administrative_area_level_1") || element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") || element.types.includes("administrative_area_level_4") || element.types.includes("administrative_area_level_5")) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.fullAddress.address = city+', '+state+', '+country;
    this.secondFormGroup.controls['location'].patchValue(city+', '+state+', '+country);
    this.secondFormGroup.controls['city'].patchValue(city);
    this.secondFormGroup.controls['state'].patchValue(state);
    this.secondFormGroup.controls['country'].patchValue(country);
    this.secondFormGroup.controls['zipCode'].patchValue(zipCode);
  }

  getProfileData() {

    this.http.httpGetWithHeaderUser('userProfile', "").pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      this.userData = res.data;
      this.firstFormGroup.controls['firstName'].patchValue(res.data.firstName);
      this.firstFormGroup.controls['lastName'].patchValue(res.data.lastName);
      this.firstFormGroup.controls['email'].patchValue(res.data.email);
      // this.firstFormGroup.controls['phone'].patchValue(res.data.phoneNo);
      this.firstFormGroup.controls['userName'].patchValue(res.data.userName);
      this.firstFormGroup.controls['companyName'].patchValue('N/A');
      this.secondFormGroup.controls['location'].patchValue(res.data.address);
      this.secondFormGroup.controls['street'].patchValue(res.data.streetName);
      this.secondFormGroup.controls['zipCode'].patchValue(res.data.zipCode);
      this.secondFormGroup.controls['localAddress'].patchValue(res.data.localAddress);
      if (res.data.phoneNo !== '') {
        this.firstFormGroup.get('phone').patchValue(res.data.countryCode + ' ' + res.data.phoneNo);
      }
      if (res.data.city !== '') {
        this.secondFormGroup.get('city').patchValue(res.data.city);
      }
      if (res.data.country !== '') {
        this.secondFormGroup.get('country').patchValue(res.data.country);
      }
      if (res.data.state !== '') {
        this.secondFormGroup.get('state').patchValue(res.data.state);
      }
      if (this.commonService.isUserRecovered && res.data.address !== "") {
        this.secondFormGroup.controls['agree'].patchValue(true);
      }
      if (res.data.image != "") {
        this.fileDataUrl = res.data.image;
      }
    });
  }

  goToAddressTab(index) {
    this.editable = true;
    this.http.httpGetWithHeaderUser('userProfile', "").pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      this.secondFormGroup.controls['location'].patchValue(res.data.address);
      this.secondFormGroup.controls['street'].patchValue(res.data.streetName);
      this.secondFormGroup.controls['zipCode'].patchValue(res.data.zipCode);
      this.secondFormGroup.controls['agree'].patchValue(true);
      this.editable = true;
      this.isLinear = false;
      this.stepper.selectedIndex = index;
    });
  }

  backToPrevious() {
    this.editable = true;
    this.submitted = false;
    this.isLinear = false;
    this.goToBack.next(true);
  }

  updateProfile(step, formName, stepC) {
    this.submitted = true;
    var formData = new FormData();
    if (this[formName].valid) {
      if (step === '1') {
        if (this.userNameAlreadyExist === true) {
          return
        }
        if (this.userData.phoneNo === "") {
          var codeLength = this.firstFormGroup.value.phone.dialCode.length;
          this.http.httpPostUser('checkIfPhoneNumberExist', { phoneNo: this.firstFormGroup.value.phone.e164Number.slice(codeLength) }).subscribe((val: any) => {
            if (val.data.isValid) {
              this.isPhoneExist = false;
              formData.append('phoneNo', this.firstFormGroup.value.phone.e164Number.slice(codeLength));
              formData.append('countryCode', this.firstFormGroup.value.phone.dialCode);
              if (this.fileDataUrl != undefined) {
                formData.append('image', this.fileDataUrl);
              }
              formData.append('firstName', this.firstFormGroup.value.firstName);
              formData.append('lastName', this.firstFormGroup.value.lastName);
              if (this.firstFormGroup.value.companyName != "") {
                formData.append('companyName', this.firstFormGroup.value.companyName);
              }
              formData.append('userName', this.firstFormGroup.value.userName);
              this.update(formData);
            } else {
              this.isPhoneExist = true;
              return;
            }
          })
        } else if (this.userData.email === "") {
          this.http.httpPostUser('checkIfEmailExist', { email: this.firstFormGroup.value.email.toLowerCase() }).subscribe((val: any) => {
            if (val.data.isValid) {
              this.isEmailExist = false;
              formData.append('email', this.firstFormGroup.value.email.toLowerCase());
              if (this.fileDataUrl != undefined) {
                formData.append('image', this.fileDataUrl);
              }
              formData.append('firstName', this.firstFormGroup.value.firstName);
              formData.append('lastName', this.firstFormGroup.value.lastName);
              if (this.firstFormGroup.value.companyName != "") {
                formData.append('companyName', this.firstFormGroup.value.companyName);
              }
              formData.append('userName', this.firstFormGroup.value.userName);
              this.update(formData);
            } else {
              this.isEmailExist = true;
              return;
            }
          })
        } else {
          if (this.fileDataUrl != undefined) {
            formData.append('image', this.fileDataUrl);
          }
          formData.append('firstName', this.firstFormGroup.value.firstName);
          formData.append('lastName', this.firstFormGroup.value.lastName);
          if (this.firstFormGroup.value.companyName != "") {
            formData.append('companyName', this.firstFormGroup.value.companyName);
          }
          formData.append('userName', this.firstFormGroup.value.userName);
          this.update(formData);
        }

      } else if (step === '2') {
        if (this.secondFormGroup.value.agree === true) {
          formData.append('streetName', this.secondFormGroup.value.street);
          formData.append('address', this.secondFormGroup.value.location);
          if (this.secondFormGroup.value.city) {
            formData.append('city', this.secondFormGroup.value.city);
          }
          if (this.secondFormGroup.value.state) {
            formData.append('state', this.secondFormGroup.value.state);
          }
          if (this.secondFormGroup.value.country) {
            formData.append('country', this.secondFormGroup.value.country);
          }
          formData.append('zipCode', this.secondFormGroup.value.zipCode);
          this.secondFormGroup.value.localAddress && formData.append('localAddress', this.secondFormGroup.value.localAddress);
          if (this.userData.latitude == "" && this.userData.longitude == "") {
            formData.append("latitude", this.latitude.toString());
            formData.append("longitude", this.longitude.toString());
          }
        }
        this.update(formData);
      }
    }
  }

  update(formData) {
    this.http.httpPostWithFormDataHeader('updateUserProfile', formData).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.editable = true;
        this.submitted = false;
        this.isLinear = false;
        this.onStepsChange.next(true);
      }
    });
  }

  onFileUpload(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    this.http.userFileUpload(fileData).pipe(takeUntil(this.notifier)).subscribe(res => {
      this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
    });
  }

  goTovechicle() {
    this.router.navigate(["vechicle"]);
  }

  goTouhomepage() {
    this.router.navigate(["uhomepage"]);
  }
  goToriderlogin() {
    this.router.navigate(["riderlogin"]);
  }

  goToridercongratulations() {
    var formData = new FormData();
    formData.append('isAccountCompleted', 'true');
    this.http.httpPostWithFormDataHeader('updateUserProfile', formData).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.submitted = false;
        this.isLinear = false;
        this.onStepsChange.next(true);
        this.commonService.openCongratsModal(this.constant.MSGS.CONGRATS);
        this.router.navigate(["/riderequest"]);
      }
    });
    // this.router.navigate(["/ridercongratulations"]);
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
