<div class="main">
    <i class="fa fa-times close" (click)="close()" aria-hidden="true"></i>
    <div class="succes-msg">
        <form [formGroup]="numberForm">
        <h2>Enter your phone number</h2>
        <div class="form-group number">
            <!-- <mat-form-field [floatLabel]="'never'"> -->
            <ngx-intl-tel-input [inputId]="'nmbr'" matInput [cssClass]="'custom nbr'"
                [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true" [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
            </ngx-intl-tel-input>
            <!-- </mat-form-field> -->
            <mat-error
                *ngIf="isSubmitted && numberForm.controls['phoneNo'].hasError('required')">Phone
                number is required.</mat-error>
            <mat-error
                *ngIf="isSubmitted && numberForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                Enter valid number.</mat-error>
                <mat-error *ngIf="isSubmitted && isPhoneExist && !numberForm.controls['phoneNo'].hasError('validatePhoneNumber') && !numberForm.controls['phoneNo'].hasError('required')">
                Phone number already exist.</mat-error>
        </div>
        <div class="submit_btn">
            <button class="btn-primary btn" (click)="submit()">
              Submit
            </button>
          </div>
        </form>
        <!-- <p>jbvfb fb ib fb ifbifbivbfvf fi if ifif vif</p> -->
    </div>
</div>


