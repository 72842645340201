import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../prelaunch/services/common/common.service';
import { HttpsService } from '../../prelaunch/services/https/https.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: "app-riderlogin",
  templateUrl: "./riderlogin.component.html",
  styleUrls: ["./riderlogin.component.scss"],
})
export class RiderloginComponent implements OnInit {
  loginForm: FormGroup;
  forgotPassForm: FormGroup;
  passResetSubmit: boolean = false;
  loginSubmit: boolean = false;
  userNameRequired: boolean = false;
  userNameErrorMsg: string;
  verificationTypeForm: FormGroup;
  redirectTo: string = '/riderotppage';
  email: any;
  phoneNo: string;
  display: string = 'none';
  display2: string = 'none';
  verificationBy: any;
  accRecoverParam: { providerId: string; provider: string; deviceType: string; deviceToken: string; isPreLaunch: boolean; role: string; isSocialEmail: boolean };
  display3: string = 'none';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpsService,
    public commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private authService: SocialAuthService,
    private location: LocationStrategy,
  ) { }

  ngOnInit(): void {
    if (this.router.url === '/delivery-partner-login') {
      this.commonService.isThisDeliveryPartner = true;
    } else {
      this.commonService.isThisDeliveryPartner = false;
    }
    this.definition();
    this.forgotPassDefinition();
    this.preventBackButton();
    this.verificationTypeForm = this.fb.group({
      verifyBy: ['phone', Validators.required]
    });
    this.verificationTypeForm.get('verifyBy').valueChanges.subscribe((res: any) => {
      if (res) {
        if (res === 'email') {
          this.redirectTo = '/verifyemail'
        } else {
          this.redirectTo = '/riderotppage';
        }
      }
    })
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  definition() {
    this.loginForm = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  forgotPassDefinition() {
    this.forgotPassForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]]
    });
  }

  passwordResetOTP() {
    this.passResetSubmit = true;
    if (this.forgotPassForm.valid) {
      this.http.httpPostUser('forgotPassword', { email: this.forgotPassForm.value.email }).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          this.display3 = 'none';
          localStorage.setItem('email', this.forgotPassForm.value.email);
          this.forgotPassForm.reset();
          this.router.navigateByUrl('/verifyemail?forget=true');
        }
      });
    }
  }

  login() {
    this.loginSubmit = true
    if (this.loginForm.valid) {
      let data = {
        userName: this.loginForm.value.userName,
        password: this.loginForm.value.password,
        deviceType: 'WEB',
        deviceToken: new Date()
      }
      this.http.httpPostWithHeaderUser('userLogin', data).subscribe((res: any) => {
        if (res.statusCode === 200 && !res.data.isBlocked) {
          localStorage.clear();
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('userId', res.data.id);
          if (res.data.isEamilVerified === true || res.data.isPhoneVerified === true) {
            if (res.data.isAccountCompleted) {
              if (res.data.role === 'DELIVERY_PARTNER') {
                localStorage.setItem('userType', 'DELIVERY_PARTNER');
                // this.router.navigate(["/riderdeliverybooking"]);
                this.router.navigate(["/riderequest"]);
              } else if (res.data.role === 'RIDER') {
                localStorage.setItem('userType', 'RIDER');
                this.router.navigate(["/riderequest"]);
              }
            } else {
              var detail = {
                isAddressInfo: false,
                isNewUser: false,
                isAccountCompleted: false
              }
              this.commonService.setRiderProfileAccessDetail(detail);
              this.router.navigate(["/riderdetail"]);
            }
          } else {
            this.email = res.data.email;
            this.commonService.emailForVerification = res.data.email;
            this.commonService.phoneNoForVerification = res.data.phoneNo
            this.phoneNo = res.data.countryCode + ' ' + res.data.phoneNo;
            localStorage.setItem('phoneNo', res.data.phoneNo);
            localStorage.setItem('countryCode', res.data.countryCode);
            localStorage.setItem('email', res.data.email);
            this.display = 'block';
          }
        } else if (res.data.isBlocked) {
          this.commonService.OpenNotificationModal('error', 'top-end', 'You have been suspended. Please contact support.');
        }
      })
    }
  }

  goTohome() {
    this.router.navigate(["home"]);
  }
  // goToriderequest() {
  //   this.router.navigate(["/riderequest"]);
  // }
  goToforgetpassword() {
    this.router.navigate(["/forgetpassword"]);
  }
  goToridersignup() {
    if (this.commonService.isThisDeliveryPartner) {
      this.router.navigate(["/delivery-partner-signup"]);
    } else {
      this.router.navigate(["/ridersignup"]);
    }
  }
  goTouhomepage() {
    this.router.navigate(["/uhomepage"]);
  }

  goToriderotppage(email, phoneNo) {
    if (this.redirectTo === '/verifyemail') {
      this.sendOTP({ email: email });
    } else {
      this.sendOTP({ phoneNo: this.commonService.phoneNoForVerification });
    }
    // this.router.navigate([this.redirectTo]);
  }

  sendOTP(param) {
    this.http.httpPostWithHeaderUser('sendOtp', param).subscribe((item: any) => {
      if (item.statusCode == 200) {
        localStorage.setItem('phone', item.data.phoneNo);
        this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
        this.display = 'none';
        this.ngxService.stop();
        this.router.navigate([this.redirectTo]);
      }
    });
  }

  fbLogin() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
      console.log(res);
      this.checkUserByProviderId(res.id).then((ress: any) => {
        if (ress.isAccountExist) {
          localStorage.setItem('userType', ress.userData.role);
          if (ress.userData.isAccountCompleted && ress.userData.isDeleted) {
            if (res.email === undefined || res.email === null || res.email === '') {
              this.accRecoverParam = {
                providerId: res.id,
                "provider": 'FACEBOOK',
                "deviceType": 'WEB',
                "isSocialEmail": false,
                'deviceToken': (new Date()).getTime().toString(),
                "isPreLaunch": true,
                "role": this.commonService.isThisDeliveryPartner ? 'DELIVERY_PARTNER' : "RIDER"
              };
              this.accRecover();
            } else {
              this.accRecoverParam = {
                providerId: res.id,
                "provider": 'FACEBOOK',
                "deviceType": 'WEB',
                "isSocialEmail": true,
                'deviceToken': (new Date()).getTime().toString(),
                "isPreLaunch": true,
                "role": this.commonService.isThisDeliveryPartner ? 'DELIVERY_PARTNER' : "RIDER"
              };
              this.accRecover();
            }
            
          } else {
            if (res.email === undefined || res.email === null || res.email === '') {
              var param = {
                providerId: res.id,
                "provider": 'FACEBOOK',
                "isSocialEmail": false,
                "deviceType": 'WEB',
                'deviceToken': (new Date()).getTime().toString(),
                "isPreLaunch": true,
                "role": this.commonService.isThisDeliveryPartner ? 'DELIVERY_PARTNER' : "RIDER"
              }
              this.hitSocialLoginApi(param, 'phone', false);
            } else {
              var param = {
                providerId: res.id,
                "provider": 'FACEBOOK',
                "isSocialEmail": true,
                "deviceType": 'WEB',
                'deviceToken': (new Date()).getTime().toString(),
                "isPreLaunch": true,
                "role": this.commonService.isThisDeliveryPartner ? 'DELIVERY_PARTNER' : "RIDER"
              }
              this.hitSocialLoginApi(param, 'phone', false);
            }

          }
        } else {
          if (res.email === undefined || res.email === null || res.email === '') {
            this.commonService.OpenNumberModal();
            this.commonService.getSocialLoginNumber.subscribe((value: any) => {
              if (value.phoneNo !== "") {
                var param = {
                  providerId: res.id,
                  "provider": res.provider,
                  "firstName": res.firstName,
                  "lastName": res.lastName,
                  "isSocialEmail": false,
                  "phoneNo": value.phoneNo,
                  "countryCode": value.countryCode,
                  "deviceType": 'WEB',
                  'deviceToken': res.authToken,
                  "isPreLaunch": true,
                  "role": this.commonService.isThisDeliveryPartner ? 'DELIVERY_PARTNER' : "RIDER"
                }
                this.hitSocialLoginApi(param, 'phone', false);
              }
            })
          } else {
            var param2 = {
              providerId: res.id,
              "provider": 'FACEBOOK',
              "firstName": res.firstName,
              "lastName": res.lastName,
              "email": res.email,
              "isSocialEmail": true,
              "deviceType": 'WEB',
              'deviceToken': res.authToken,
              "isPreLaunch": true,
              "role": this.commonService.isThisDeliveryPartner ? 'DELIVERY_PARTNER' : "RIDER"
            }
            this.hitSocialLoginApi(param2, 'email', false);
          }
        }
      });
    });
  }

  hitSocialLoginApi(param, type, accRecoveringType) {
    this.http.httpPostUser('socialLogin', param).subscribe((response: any) => {
      console.log(response);
      if (response.statusCode == 200) {
        if (response.statusCode === 200 && !response.data.isBlocked) {
          localStorage.clear();
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('userId', response.data.id);
          localStorage.setItem('userType', response.data.role);
          localStorage.setItem('phone', response.data.phoneNo);
          localStorage.setItem('phoneNo', response.data.phoneNo);
          localStorage.setItem('countryCode', response.data.countryCode);
          if (type === 'email') {
            if (response.data.isAccountCompleted) {
              localStorage.setItem('userType', response.data.role);
              this.router.navigate(["/riderequest"]);
            } else {
              var detail = {
                isAddressInfo: false,
                isNewUser: false,
                isAccountCompleted: false
              }
              this.commonService.setRiderProfileAccessDetail(detail);
              this.router.navigate(["/riderdetail"]);
            }
          } else if (type === 'phone') {
            if (accRecoveringType === 'RECOVER') {
              this.router.navigate(["/riderequest"]);
            } else if (accRecoveringType === 'NEW') {
              this.commonService.isUserRecovered = true;
              if (localStorage.userType === "RIDER") {
                this.router.navigate(['riderdetail']);
              } else if (localStorage.userType === "DELIVERY_PARTNER") {
                this.router.navigate(['riderequest']);
              }
            } else {
              if (response.data.isAccountCompleted && (response.data.isPhoneVerified || response.data.isEamilVerified)) {
                localStorage.setItem('userType', response.data.role);
                this.router.navigate(["/riderequest"]);
              } else if (response.data.phoneNo !== "" && !response.data.isPhoneVerified) {
                this.verificationBy = response.data.phoneNo;
                this.display2 = 'block';
                // this.sendOtp({ 'phoneNo': response.data.phoneNo });
              } else if (!response.data.isAccountCompleted) {
                this.router.navigate(['riderdetail']);
              }
            }
          }
        } else if (response.data.isBlocked) {
          this.commonService.OpenNotificationModal('error', 'top-end', 'Your account is blocked from admin side.');
        }
      }
    });

  }

  sendOtp(param) {
    this.http.httpPostWithHeaderUser('sendOtp', param).subscribe((item: any) => {
      if (item.statusCode == 200) {
        this.display2 = 'none';
        localStorage.setItem('phone', item.data.phoneNo);
        this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
        // this.commonService.emailForVerification = response.data.email;
        // $("#verification").modal('show');
        // this.display = 'block';
        this.ngxService.stop();
        this.router.navigate([this.redirectTo]);
      }
    });
  }

  checkUserByProviderId(id) {
    return new Promise((resolve, reject) => {
      this.http.httpPostUser('checkUserByProviderId', { providerId: id }).subscribe((response: any) => {
        if (response.statusCode == 200) {
          return resolve(response.data);
        }
      })
    })
  }

  accRecover() {
    Swal.fire({
      title: 'Do you want to restore your previous account?',
      // text: "You won't be able to revert this!",
      icon: 'info',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Restore',
      cancelButtonText: 'Close',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      this.commonService.isUserRecovered = true;

      if (result.value) {
        this.hitSocialLoginApi(this.accRecoverParam, 'phone', 'NEW');
        // this.router.navigate(['riderequest']);
      } else {
        // this.hitSocialLoginApi(this.accRecoverParam, 'phone', 'NEW');

      }
    });
  }

  openForgotModal() {
    this.display3 = 'block';
  }

  closeForgotModal() {
    this.display3 = 'none';
    this.passResetSubmit = false;
    this.forgotPassForm.reset();
  }
}
