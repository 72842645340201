<div class="riderequest">
    <div class="map">
        <agm-map [latitude]="lat" [longitude]="lng"></agm-map>
    </div>
    <div class="login_map_toper flex" (click)="goToprofilesetup()">
        <div class="login_map_toper_left">
            <figure><img src="../../assets/images/user.png" /></figure>
        </div>
        <div class="login_map_toper_right" (click)="goToprofilesetup()">
            <h4>Amit Sharma</h4>
        </div>
    </div>
    <div class="driver_info_bx">
        <p class="arrving_time">Your Driver is arrving in 2:30 min.</p>
        <div class="about_driver">
            <figure class="driver_img">
                <img src="../../assets/uimages/profile_image.png" alt="" />
            </figure>
            <div class="driver_name_sec">
                <p class="driver_name">Jhon</p>
                <span>4.8</span>
                <span>2:30 min</span>
            </div>
            <div class="car_img">
                <figure>
                    <img src="../../assets/uimages/ride_car.png" alt="" />
                </figure>
                <p>Toyota Corolla: <span>8CF4829</span></p>
            </div>
        </div>
        <!----start cancel call btn-->
        <div class="ride_can_btn">
            <button class="ride_cancle_btn">Cancel</button>
            <button class="call_btn">Call</button>
        </div>
    </div>
</div>