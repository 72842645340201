<div class="login_wrap top">
    <!-- <div class="back_home">
        <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
    </div> -->
    <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" style="cursor: pointer;" routerLink="/uhomepage">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" style="cursor: pointer;" routerLink="/uhomepage">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="login">
        <div class="conta_iner">
            <div class="login_main">
                <div class="login_form center">
                    <!-- <div class="login_img_sec">
                        <h4>Customer Service</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
                    </div> -->

                    <figure class="login_logo">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                    <h4>Let's Get Started <span>Customer service form</span></h4>
                    <!-- <h4>Let's Get Started <span>For customer service, please send email to <a (click)="openGmail()">info@u2go.ca</a></span></h4> -->

                    <form class="form_control_form" [formGroup]="contactForm">
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>First name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeFirst($event.target.value)" (keypress)="onKeyPress($event)" formControlName="firstName" matInput type="text"
                                    placeholder="First name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && contactForm.controls['firstName'].hasError('required')">First name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateFirstArr.includes(true)">
                                Please enter valid first name.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Last name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeSecond($event.target.value)" (keypress)="onKeyPress($event)" formControlName="lastName" matInput type="text"
                                    placeholder="Last name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && contactForm.controls['lastName'].hasError('required')">Last
                                name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateSecondArr.includes(true)">
                                Please enter valid last
                                name.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Email address</label>
                                <input class="form-control" formControlName="email" matInput type="email"
                                    placeholder="Email address" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && contactForm.controls['email'].hasError('required')">Email is
                                required.</mat-error>
                            <mat-error *ngIf="submitted && contactForm.controls['email'].hasError('pattern')">
                                Sorry, only letters (a-z), number (0-9), and periods (.) are allowed.</mat-error>
                        </div>
                        <div class="form-group">
                            <label class="algn-lft">Phone number</label>
                            <!-- <mat-form-field [floatLabel]="'never'"> -->
                            <ngx-intl-tel-input [inputId]="'adsasd'" matInput [cssClass]="'custom'"
                                [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                                [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                            </ngx-intl-tel-input>
                            <!-- </mat-form-field> -->
                            <mat-error *ngIf="submitted && contactForm.controls['phoneNo'].hasError('required')">Phone
                                number is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && contactForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                                Enter valid number.</mat-error>
                        </div>

                        <div class="form-group">
                            <label class="algn-lft">Comment</label>
                            <div class="fgi sec">
                                <textarea id="w3review" class="from-control" placeholder="Enter your comment here"
                                    formControlName="desc"></textarea>
                                <mat-error
                                    *ngIf="submitted && contactForm.controls['desc'].hasError('required')">
                                    Comment is required.</mat-error>
                            </div>
                        </div>

                        <div class="flex submit_btn">
                            <button class="btn btn-primary" (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="login_images"></div> -->
</div>