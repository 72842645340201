<div class="riderequest">
    <div class="map">
        <agm-map [latitude]="pickup.lat" [longitude]="pickup.lng" [zoom]="zoom" (mapReady)="onMapReady($event)">
            <div *ngIf="!isDriveingStart">
                <agm-overlay *ngFor="let driver of nearByDriversData" [latitude]="driver?.latitude"
                    [longitude]="driver?.longitude"
                    [bounds]="{x:{latitude:-0.003,longitude:-0.0052},y:{latitude:0.003,longitude:0.0052}}">
                    <div>
                        <img style="cursor: pointer;width: 50px; height: 50px;" [src]="ubericon">
                    </div>
                </agm-overlay>
            </div>
            <div *ngFor="let pick of pickupfieldsArr">
                <agm-marker *ngIf="showPickMarker && pick?.lat !== 0 && pick?.lng !== 0" [iconUrl]="originMarkerIcon"
                    [latitude]="pick?.lat" [longitude]="pick?.lng" [markerDraggable]="true"
                    (dragEnd)="markerDragEndOrigin($event,pick)"></agm-marker>
            </div>
            <div *ngFor="let drop of allPackagesDetail">
                <agm-marker
                    *ngIf="showDropMarker && drop?.addressDetails?.itemDropup?.lat !== 0 && drop?.addressDetails?.itemDropup?.lng !== 0"
                    [iconUrl]="destinationMarkerIcon" [latitude]="drop?.addressDetails?.itemDropup?.lat"
                    [longitude]="drop?.addressDetails?.itemDropup?.lng" [markerDraggable]="true"
                    (dragEnd)="markerDragEndDestination($event,drop)"></agm-marker>
            </div>

            <!-- <div *ngIf="bookingResponseData?.bookingStatus === 'ARRIVED'">
                <agm-marker [iconUrl]="driverIcon" [latitude]="bookingResponseData.pickUplatitude"
                    [longitude]="bookingResponseData.pickUplongitude" [markerDraggable]="false"></agm-marker>
            </div> -->
            <agm-direction *ngIf="showDirections" [origin]="origin" [destination]="destination"
                [renderOptions]="renderOptions" [markerOptions]="markerOptions" [waypoints]="waypoints">
            </agm-direction>


        </agm-map>
    </div>
    <div class="login_map_toper flex" (click)="goToprofilesetup()">
        <div class="login_map_toper_left">
            <figure>
                <img *ngIf="!user?.image" class="proImage" src="assets/images/user.png" />
                <img *ngIf="user?.image" class="proImage" [src]="baseUrl+user?.image" />
            </figure>
        </div>
        <div class="login_map_toper_right">
            <h4>{{user?.firstName ? user?.firstName : '' + " " + user?.lastName ? user?.lastName : ''}}</h4>
        </div>
        <div class="fa_arrow"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
    </div>
    <div class="ride_request_main">
        <div class="ride_request_right">
            <div class="ride_request_right2">
                <figure class="ride_request_logo" (click)="continue(0)">
                    <img src="assets/uimages/logo.png">
                </figure>
            </div>
            <div class="ride_request_right2 fbtn">
                <figure class="ride_request_logo" style="cursor: pointer;" (click)="goToHome()">
                    <img src="assets/uimages/logo.png" />
                </figure>
                <div class="back_home hmbtn">
                    <button class="driverap_btn" style="cursor: pointer;" (click)="goToHome()">
                        Back To Home
                    </button>
                </div>
            </div>
            <div class="request_ride_inner" *ngIf="nextPopup == 0">
                <div class="select_ride_cate">
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1"
                            style="margin-right: 15px" class="choose_ride_radio" (click)="goToriderequest()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="183" height="183" viewBox="0 0 183 183">
                                <defs>
                                    <filter id="Rectangle_3731" x="0" y="0" width="183" height="183"
                                        filterUnits="userSpaceOnUse">
                                        <feOffset dy="2" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="10" result="blur" />
                                        <feFlood flood-opacity="0.078" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                    </filter>
                                </defs>
                                <g id="Group_59548" data-name="Group 59548" transform="translate(-20 -466)">
                                    <g transform="matrix(1, 0, 0, 1, 20, 466)" filter="url(#Rectangle_3731)">
                                        <rect id="Rectangle_3731-2" data-name="Rectangle 3731" width="123" height="123"
                                            rx="10" transform="translate(30 28)" fill="#fff" />
                                    </g>
                                    <text id="Book_a_Ride" data-name="Book a Ride" transform="translate(62 515)"
                                        fill="#0171bd" font-size="13" font-family="SFUIText-Bold, SF UI Text"
                                        font-weight="700">
                                        <tspan x="11.825" y="12">Book a Ride</tspan>
                                    </text>
                                    <g id="Taxi-Car-Cab-Vehicle-Transport" transform="translate(87 553.896)">
                                        <path id="Path_13432" data-name="Path 13432"
                                            d="M10.99,35a2.99,2.99,0,1,0,2.99,2.99A2.99,2.99,0,0,0,10.99,35Zm0,4.486a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,10.99,39.486Z"
                                            transform="translate(-1.515 -7.068)" fill="#0171bd" />
                                        <path id="Path_13433" data-name="Path 13433"
                                            d="M50.99,35a2.99,2.99,0,1,0,2.99,2.99A2.99,2.99,0,0,0,50.99,35Zm0,4.486a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,50.99,39.486Z"
                                            transform="translate(-11.611 -7.068)" fill="#0171bd" />
                                        <path id="Path_13434" data-name="Path 13434" d="M23,40H36.456v1.5H23Z"
                                            transform="translate(-5.301 -8.33)" fill="#0171bd" />
                                        <path id="Path_13435" data-name="Path 13435" d="M23,36H36.456v1.5H23Z"
                                            transform="translate(-5.301 -7.32)" fill="#0171bd" />
                                        <path id="Path_13436" data-name="Path 13436" d="M29,21h4.486v1.5H29Z"
                                            transform="translate(-6.815 -3.534)" fill="#0171bd" />
                                        <path id="Path_13437" data-name="Path 13437"
                                            d="M46.855,24.194V22.7a2.249,2.249,0,0,0-2.243-2.243h-2.96L40.1,15.029l-.464-1.847a2.243,2.243,0,0,0-2.175-1.7h-6.31V8.5a1.5,1.5,0,0,0-1.5-1.5H19.194a1.5,1.5,0,0,0-1.5,1.5v2.99H11.39a2.243,2.243,0,0,0-2.175,1.7l-.456,1.839L7.2,20.456H4.243A2.249,2.249,0,0,0,2,22.7v1.5a2.237,2.237,0,0,0,1.054,1.891A3.72,3.72,0,0,0,2,28.68v8.971a2.232,2.232,0,0,0,.748,1.66v2.826A2.249,2.249,0,0,0,4.99,44.379h5.981a2.249,2.249,0,0,0,2.243-2.243V39.894H35.641v2.243a2.249,2.249,0,0,0,2.243,2.243h5.981a2.249,2.249,0,0,0,2.243-2.243V39.311a2.232,2.232,0,0,0,.748-1.66V28.68A3.72,3.72,0,0,0,45.8,26.086a2.237,2.237,0,0,0,1.054-1.892ZM19.194,8.5H29.661v2.99H19.194Zm-8.53,5.054a.748.748,0,0,1,.725-.568H37.465a.748.748,0,0,1,.725.568l.232.927H10.433Zm-.628,2.422H38.818l2.557,8.971h-21.5a4.48,4.48,0,0,0-8.836,0H7.48Zm8.306,8.971H12.571a2.978,2.978,0,0,1,5.771,0ZM3.5,22.7a.748.748,0,0,1,.748-.748H6.777l-.852,2.99H4.243a.748.748,0,0,1-.748-.748Zm8.223,19.437a.748.748,0,0,1-.748.748H4.99a.748.748,0,0,1-.748-.748V39.894h7.476Zm32.894,0a.748.748,0,0,1-.748.748H37.884a.748.748,0,0,1-.748-.748V39.894h7.476Zm.748-4.485a.748.748,0,0,1-.748.748H4.243a.748.748,0,0,1-.748-.748V36.9H45.36Zm0-2.243H3.5V28.68a2.249,2.249,0,0,1,2.243-2.243H43.117A2.249,2.249,0,0,1,45.36,28.68Zm0-11.214a.748.748,0,0,1-.748.748H42.93l-.852-2.99h2.534a.748.748,0,0,1,.748.748Z"
                                            transform="translate(0 0)" fill="#0171bd" />
                                    </g>
                                </g>
                            </svg>
                        </mat-radio-button>
                        <mat-radio-button value="2" class="choose_ride_radio">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="183" height="183" viewBox="0 0 183 183">
                                <defs>
                                    <filter id="Rectangle_4193" x="0" y="0" width="183" height="183"
                                        filterUnits="userSpaceOnUse">
                                        <feOffset dy="2" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="10" result="blur" />
                                        <feFlood flood-opacity="0.078" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                    </filter>
                                </defs>
                                <g id="Group_59547" data-name="Group 59547" transform="translate(-173 -466)">
                                    <g transform="matrix(1, 0, 0, 1, 173, 466)" filter="url(#Rectangle_4193)">
                                        <rect id="Rectangle_4193-2" data-name="Rectangle 4193" width="123" height="123"
                                            rx="10" transform="translate(30 28)" fill="#fff" />
                                    </g>
                                    <text id="Book_a_Delivery" data-name="Book a Delivery"
                                        transform="translate(211 514)" fill="#0171bd" font-size="13"
                                        font-family="SFUIText-Bold, SF UI Text" font-weight="700">
                                        <tspan x="2.992" y="12">Book a Delivery</tspan>
                                    </text>
                                    <g id="logistics" transform="translate(238 552)">
                                        <path id="Path_13438" data-name="Path 13438"
                                            d="M49.434,49a2.434,2.434,0,1,0,2.434,2.434A2.437,2.437,0,0,0,49.434,49Zm0,3.245a.811.811,0,1,1,.811-.811A.812.812,0,0,1,49.434,52.245Z"
                                            transform="translate(-8.681 -8.303)" fill="#0171bd" />
                                        <path id="Path_13439" data-name="Path 13439"
                                            d="M17.434,49a2.434,2.434,0,1,0,2.434,2.434A2.437,2.437,0,0,0,17.434,49Zm0,3.245a.811.811,0,1,1,.811-.811A.812.812,0,0,1,17.434,52.245Z"
                                            transform="translate(-2.642 -8.303)" fill="#0171bd" />
                                        <path id="Path_13440" data-name="Path 13440"
                                            d="M49.058,30.15l-1.082-.541-1.1-6.615a4.044,4.044,0,0,0-4-3.39H34.263a4.038,4.038,0,0,0-3.224,1.623H28.584V13.113H23.716V5H12.358v8.113H7.49v8.113H2.623v8.113A1.624,1.624,0,0,0,1,30.961v1.623a1.624,1.624,0,0,0,1.623,1.623v7.3a2.437,2.437,0,0,0,2.434,2.434H9.178a5.671,5.671,0,0,0,11.228,0H35.139a5.671,5.671,0,0,0,11.228,0h2.5A2.437,2.437,0,0,0,51.3,41.508v-7.73A4.035,4.035,0,0,0,49.058,30.15Zm.62,7.612-1.623-.811V35.829h1.623ZM17.226,22.848h1.623v1.623H17.226Zm3.245,3.245V22.848h1.623v6.49H13.981v-6.49H15.6v3.245Zm9.735,3.245h-6.49v-6.49h1.623v3.245h4.868Zm0-5.679v.811H26.961V22.848h3.327a4.053,4.053,0,0,0-.082.811Zm-3.245-2.434H18.848v-6.49h1.623v3.245h4.868V14.735h1.623Zm-4.868-6.49h1.623v1.623H22.094ZM17.226,6.623h1.623V8.245H17.226Zm-3.245,0H15.6V9.868h4.868V6.623h1.623v6.49H13.981Zm-1.623,8.113h1.623v1.623H12.358Zm-3.245,0h1.623v3.245H15.6V14.735h1.623v6.49H9.113ZM7.49,22.848H9.113v1.623H7.49Zm-3.245,0H5.868v3.245h4.868V22.848h1.623v6.49H4.245ZM2.623,30.961H30.206v1.623H2.623Zm1.623,6.49H7.262l-.406,1.623H4.245Zm0,4.056V40.7H8.124l1.217-4.868h-5.1V34.206H30.206v8.113h-9.8a5.671,5.671,0,0,0-11.228,0H5.056A.812.812,0,0,1,4.245,41.508Zm10.547,5.679a4.056,4.056,0,1,1,4.056-4.056A4.061,4.061,0,0,1,14.792,47.187Zm25.15-17.848h3.245v1.623H41.565A1.624,1.624,0,0,1,39.942,29.339Zm0-1.623a1.624,1.624,0,0,0-1.623,1.623,3.212,3.212,0,0,0,.451,1.623h-3.7v-6.49h7.339l.608,3.245Zm-4.868,4.868h8.113v5.423a5.658,5.658,0,0,0-8.048,4.312h-.065Zm5.679,14.6a4.056,4.056,0,1,1,4.056-4.056A4.061,4.061,0,0,1,40.753,47.187Zm8.113-4.868h-2.5a5.66,5.66,0,0,0-1.558-3.155V28.527l-.8-4.353a1.623,1.623,0,0,0-1.595-1.326h-7.34a1.624,1.624,0,0,0-1.623,1.623V42.319H31.829V23.66a2.437,2.437,0,0,1,2.434-2.434h8.609a2.426,2.426,0,0,1,2.4,2.034l1.239,7.431,1.821.91a2.422,2.422,0,0,1,1.346,2.177v.428H46.432v3.747l3.245,1.623v1.932A.812.812,0,0,1,48.866,42.319Z"
                                            transform="translate(0 0)" fill="#0171bd" />
                                    </g>
                                </g>
                            </svg>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <!----// start Rider Pikup Location Input bxes sec  //-->
                <!-- <form class="choose_pickup_input" [formGroup]="bookingForm"> -->
                <div class="title_bar">
                    <h2>Request a Delivery now</h2>
                </div>
                <form [formGroup]="pickupForm">
                    <div class="form-group choose_pickup_outer">
                        <div class="form_grp_request_left">

                        </div>
                        <div class="form_grp_request_right">
                            <mat-label class="ride_request">Choose your pick up</mat-label>
                            <div class="multiple_add_outer" *ngFor="let pickup of pickupfieldsArr; let i = index">
                                <mat-form-field class="example-full-width">
                                    <div class="multiple-add">
                                        <div class="blue_circle"></div>
                                        <input matInput placeholder="Search for pick up location" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete #placesRef="ngx-places"
                                            (change)="pickupChange(pickup)" (keyup)="onKeyUp($event.target.value,pickup)"
                                            (onAddressChange)="handlePickUpChnage($event,pickup)"
                                            formControlName="{{pickup?.name}}" />
                                        <div *ngIf="dropupfieldsArr.length === 1">
                                            <!-- <button class="rotate" *ngIf="i !== pickupfieldsArr?.length - 1 || i > 2"
                                                (click)="removePickupField(i)">+</button>
                                            <button [disabled]="pickupForm.controls[pickup?.name].hasError('required')"
                                                class="plusBtn" *ngIf="i === pickupfieldsArr?.length - 1 && i<= 2"
                                                (click)="addPickupField()">+</button> -->
                                        </div>
                                    </div>
                                    <p style="color: red;"
                                        *ngIf="rideSubmited === true && pickupForm.controls[pickup?.name].hasError('required')">
                                        Pickup address is required.</p>
                                    <!-- <p style="color: red;"
                                        *ngIf="rideSubmited === true && !isPickupChanged && !bookingForm.controls['ridePickUpAddress'].hasError('required')">
                                        Please enter valid source.</p> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- <form [formGroup]="dropupForm">
                    <div class="form-group choose_pickup_outer">
                        <div class="form_grp_request_left">
                        </div>
                        <div class="form_grp_request_right sec">
                            <mat-label class="ride_request">Choose your drop off </mat-label>
                            <div class="multiple_add_outer" *ngFor="let drop of dropupfieldsArr; let i = index">
                                <mat-form-field class="example-full-width">
                                    <div class="multiple-add">
                                        <div class="red_circle"></div>
                                        <input matInput placeholder="Search for drop off location" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete #placesRef="ngx-places"
                                            (change)="dropChange()" (onAddressChange)="handleDropChnage($event,drop)"
                                            formControlName="{{drop?.name}}" />
                                        <div *ngIf="pickupfieldsArr.length === 1">
                                            <button class="rotate" *ngIf="i !== dropupfieldsArr?.length - 1 || i > 2"
                                                (click)="removeDropupField(i)">+</button>
                                            <button [disabled]="dropupForm.controls[drop?.name].hasError('required')"
                                                class="plusBtn" *ngIf="i === dropupfieldsArr?.length - 1 && i <= 2"
                                                (click)="addDropupField()">+</button>
                                        </div>
                                    </div>
                                    <p style="color: red;"
                                        *ngIf="rideSubmited === true && dropupForm.controls[drop?.name].hasError('required')">
                                        Drop off address is required.</p>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form> -->
                <!----// End Rider Pikup Location Input bxes sec //-->
                <div class="btn_divde margin_top25">
                    <div class="btn_outer">
                        <button
                            [ngClass]="{'btn-active': activeBtn === 'Now','btn': true, 'btn-outline': activeBtn != 'Now'}"
                            (click)="startNow('10')">
                            Start now
                        </button>
                    </div>
                    <div class="btn_outer">
                        <button
                            [ngClass]="{'btn-active': activeBtn === 'Later','btn': true, 'btn-outline': activeBtn != 'Later'}"
                            (click)="openDateTimeModal()">
                            Schedule a delivery
                        </button>
                    </div>

                </div>
            </div>
            <div class="request_ride_inner part2" *ngIf="nextPopup == 1">
                <div class="car_using_main">
                    <div class="car_using_left">
                        <h4>Package Size</h4>
                        <ul class="car_using_left_inner">
                            <li [ngClass]="{'active_car_bx': selectedSizeIndex === i}"
                                *ngFor="let packageSize of packageSizeData; let i = index"
                                (click)='selectPackageSize(packageSize,i);'>
                                <div class="yellow_bg">
                                    <figure>
                                        <img [src]="baseUrl+packageSize?.blackImage" alt="" />
                                    </figure>
                                </div>
                                <h5>{{packageSize?.packageSizeName}}</h5>
                                <p>{{packageSize?.size}}</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="car_using_main margin_top30">
                    <div class="car_using_left">
                        <h4 class="choose-v">Choose Vehicle</h4>
                        <ul class="car_using_left_inner sec">
                            <li [ngClass]="{'active_car_bx': selectedRideIndex === i}"
                                *ngFor="let category of vehicleCategoryData; let i = index"
                                (click)='selectVehicle(category,i);'>
                                <div class="categoryinner">
                                    <div class="yellow_bg">
                                        <figure>
                                            <img [src]="baseUrl+category?.vehicleImage" />
                                        </figure>
                                    </div>
                                    <h5>{{category?.vehicleCategory}}</h5>
                                    <p>US $<span>{{category?.booKingAmount}}</span></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="add_promo_sec">
                    <div class="visa_card">
                        <figure (click)="continue('2')">
                            <img src="assets/uimages/visa_card.png" alt="" />
                        </figure>
                    </div>
                    <div class="add_promo">
                        <a href="##" data-toggle="modal" data-target="#add_promo"><span><img
                                    src="assets/uimages/promo_icon.png" alt="" /></span>Loyality Points</a>
                    </div>
                </div>
                <div class="btn_divde custom_margin">
                    <div class="btn_outer inactive">
                        <button class="btn btn-secondary" (click)="continue('11')">
                            Back
                        </button>
                    </div>
                    <div class="btn_outer">
                        <button class="btn btn-primary" (click)="continue('12')">
                            <!-- (click)="continue('3')" -->
                            Continue
                        </button>
                    </div>
                </div>
            </div>


            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 2">
                <div class="title_bar">
                    <h2>Payment</h2>
                </div>
                <ul class="payment_last">
                    <li class="borderbtm">
                        <div class="list_inner">
                            <mat-checkbox>Pay via Apptunix Wallet</mat-checkbox>
                        </div>
                    </li>
                    <li class="borderbtm">
                        <div class="list_inner">
                            <mat-checkbox>Pay With Paypal</mat-checkbox>
                        </div>
                    </li>
                    <li>
                        <div class="list_inner">
                            <mat-checkbox>Pay with Credit/Debit Card</mat-checkbox>
                        </div>
                    </li>
                    <li class="half">
                        <div data-toggle="modal" data-target="#add_payment">
                            <a>Add new card</a>
                        </div>
                    </li>
                    <li class="borderbtm">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1">
                                <div class="card_detailcs">
                                    <h3>•••• •••• •••• 4561<span>Mastercard - 06/23</span></h3>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </li>
                    <li>
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1">
                                <div class="card_detailcs">
                                    <h3>•••• •••• •••• 4561<span>Visacard - 06/23</span></h3>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </li>
                </ul>
                <div class="flex reqst driverap_btn">
                    <button (click)="continue('1')">Next</button>
                </div>
            </div>
            <!-----start rider accept -->
            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 3" style="height: 100%;">
                <div class="driver_info_bx sec">
                    <div class="spinners"><i class="fa fa-spinner fa-spin"></i></div>
                    <p class="arrving_time">We are processing your booking request..</p>
                    <p class="arrving_time">A notification will be received soon.</p>

                    <div class="ride_can_btn">
                        <button class="ride_cancle_btn" (click)="cancelBooking(bookingId)">Cancel</button>
                    </div>
                </div>

                <!-- <div class="flex reqst driverap_btn">
                        <button (click)="continue('1')">Next</button>
                    </div> -->

                <!-----end rider accept-->
            </div>
            <!-----start call me reach me screen-->
            <!-----start rider accept -->
            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 4">
                <div class="driver_info_bx">
                    <p class="arrving_time" *ngIf="duration > 1">{{driverMessage}}
                    </p>
                    <p class="arrving_time" *ngIf="duration <= 1">Your driver arrived to pick you up your items.</p>
                    <div class="about_driver">
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
                            <img src="assets/images/user.png" alt=" " />
                        </figure>
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
                            <img [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distance?.toFixed(2)}} km</span>

                        </div>
                        <div class="car_img">
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleModel}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                <div *ngIf="pick?.isPickUp">
                                    <div class="blm">
                                        <div class="bb"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{pick?.address}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                                <div *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant?.BOOKING_STATUS?.NOT_AVAILABLE && drop?.bookingStatus !== constant?.BOOKING_STATUS?.NONE">
                                    <div class="blm">
                                        <div class="blc"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{drop?.address}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <div class="note_call" *ngIf="bookingResponseData?.note != null">
                        <p>Note : {{bookingResponseData?.note}}.</p>
                    </div>
                    <div class="trip_payment">
                        <h4>Payment</h4>
                        <div class="visa_card">
                            <figure (click)="continue('2')">
                                <img src="assets/uimages/visa_card.png " alt=" " />
                            </figure>
                        </div>
                    </div>
                    <!----start cancel call btn-->
                    <div class="ride_can_btn">
                        <button class="ride_cancle_btn" *ngIf="bookingResponseData?.subBookingData[0].bookingStatus !== constant.BOOKING_STATUS.PICKED" (click)="cancelBooking(bookingResponseData._id)">Cancel<div class="duration"
                                *ngIf="cancelCounter > 0">{{cancelCounter}}</div></button>
                        <button class="call_btn" (click)="goToContactDetail()">Call</button>
                    </div>
                    <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
                        <div class="add_honda_left">
                            <div class="file">
                                <figure class="driver-img">
                                    <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image"
                                        src="assets/images/user.png">
                                    <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image"
                                        [src]="baseUrl+bookingResponseData?.driverId?.image">
                                </figure>
                                <h5><span>{{bookingResponseData?.driverId?.countryCode}}
                                        {{bookingResponseData?.driverId?.phoneNo}}</span>
                                    {{bookingResponseData?.driverId?.firstName}}
                                    {{bookingResponseData?.driverId?.lastName}}
                                    <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                                </h5>
                            </div>
                        </div>
                        <div class="add_vehicle_right flex">
                            <div class="add_vehicle_right_icon">
                                <figure>
                                    <img src="assets/images/phone-call.svg">
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="flex reqst driverap_btn ">
                        <button (click)="continue( '1') ">Next</button>
                    </div> -->

                <!-----end rider accept-->
            </div>
            <!-----End  call me reach me screen-->

            <!-----start driver reach -->
            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 6">
                <div class="driver_info_bx">
                    <p class="arrving_time">Your driver has arrived.</p>
                    <div class="about_driver">
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
                            <img src="assets/images/user.png" alt=" " />
                        </figure>
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
                            <img [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distance?.toFixed(2)}} km</span>
                        </div>
                        <div class="car_img">
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleModel}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                <div *ngIf="pick?.isPickUp">
                                    <div class="blm">
                                        <div class="bb"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{pick?.address}}</p>
                                    </div>

                                </div>
                                <div class="stts"
                                    *ngIf="pick?.isPickUp && pick?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                    <p>{{constant.SHOW_STATUS_BOOKING[pick?.bookingStatus]}}</p>
                                </div>
                            </div>

                            <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                                <div *ngIf="!drop?.isPickUp">
                                    <div class="blm">
                                        <div class="blc"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{drop?.address}}</p>
                                    </div>
                                </div>
                                <div class="stts"
                                    *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                    <p>{{constant.SHOW_STATUS_BOOKING[drop?.bookingStatus]}}</p>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <div class="note_call" *ngIf="bookingResponseData?.note != null">
                        <p>Note : {{bookingResponseData?.note}}.</p>
                    </div>
                    <div class="trip_payment">
                        <h4>Payment</h4>
                        <div class="visa_card">
                            <figure (click)="continue('2')">
                                <img src="assets/uimages/visa_card.png " alt=" " />
                            </figure>
                        </div>
                    </div>
                    <!----start cancel call btn-->
                    <div class="ride_can_btn">
                        <button class="call_btn" (click)="goToContactDetail()">Call</button>
                    </div>
                    <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
                        <div class="add_honda_left">
                            <div class="file">
                                <div class="file-left">
                                    <figure class="driver-img">
                                        <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image"
                                            src="assets/images/user.png">
                                        <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image"
                                            [src]="baseUrl+bookingResponseData?.driverId?.image">
                                    </figure>
                                </div>
                                <div class="file-right">
                                    <h5><span>{{bookingResponseData?.driverId?.countryCode}}
                                            {{bookingResponseData?.driverId?.phoneNo}}</span>
                                        {{bookingResponseData?.driverId?.firstName}}
                                        {{bookingResponseData?.driverId?.lastName}}
                                        <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="add_vehicle_right flex">
                            <div class="add_vehicle_right_icon">
                                <figure>
                                    <img class="clr-bl" src="assets/images/phone-call.svg">
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <!-----end rider accept-->
            </div>
            <!-----End  call me driver  me screen-->

            <!-----start rider accept -->
            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 5">
                <div class="driver_info_bx">
                    <p class="arrving_time">Driver is arriving.</p>
                    <div class="about_driver">
                        <figure class="driver_img">
                            <img *ngIf="!bookingResponseData?.driverId?.image" src="assets/uimages/profile_image.png "
                                alt=" " />
                            <img *ngIf="bookingResponseData?.driverId?.image"
                                [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distanceInKM}}</span>

                        </div>
                        <div class="car_img">
                            <figure>
                                <img *ngIf="!bookingResponseData?.vehicleId?.vehicleImage"
                                    src="assets/uimages/ride_car.png " alt=" " />
                                <img *ngIf="bookingResponseData?.vehicleId?.vehicleImage"
                                    [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleMake}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <div class="priceRide">
                        <h5 class="amnt">Price: ${{bookingResponseData?.booKingAmount}}</h5>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm">
                                <div class="blm">
                                    <div class="bb"></div>
                                </div>
                                <div class="mkl">
                                    <p>{{bookingResponseData?.pickUpAddress}}</p>
                                </div>
                            </div>

                            <div class="mm">
                                <div class="blm">
                                    <div class="blc"></div>
                                </div>
                                <div class="mkl">
                                    <p>{{bookingResponseData?.dropUpAddress}}</p>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <!-- /// package detail start -->
                    <div class="card">
                        <div class="card-header p-2" id="review">
                            <div class="fs collapsed" data-toggle="collapse" data-target="#reviewsOne">
                                <div class="fd">
                                    <h5 class="mb-0">
                                        Delivery Details
                                    </h5>
                                </div>
                                <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                            </div>
                        </div>

                        <div id="reviewsOne" class="collapse" aria-labelledby="review" data-parent="#faqExample">
                            <div class="card-body">
                                <div class="delivery_detail_form">
                                    <div class="delivery_view_item">
                                        <label for="">Delivery To</label>
                                        <p>{{bookingResponseData?.addressType}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Street Address</label>
                                        <p>{{bookingResponseData?.streetAddress}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Building/apt/suite</label>
                                        <p>{{bookingResponseData?.suit}}</p>
                                    </div>

                                    <div class="delivery_view_item">
                                        <label for="">City</label>
                                        <p>{{bookingResponseData?.city}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Province</label>
                                        <p>{{bookingResponseData?.province}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Postal/Zip code:</label>
                                        <p>{{bookingResponseData?.zipCode}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Phone Number</label>
                                        <p>{{bookingResponseData?.phoneNo}}
                                        </p>
                                    </div>
                                    <div class="delivery_view_item" *ngIf="bookingResponseData?.additionalInfo">
                                        <label for="">Additional Details</label>
                                        <p>{{bookingResponseData?.additionalInfo}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-2" id="review2">
                            <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#reviewstwo">
                                <div class="fd">
                                    <h5 class="mb-0 ">
                                        Package Details
                                    </h5>
                                </div>
                                <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                            </div>
                        </div>
                        <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample">
                            <div class="card-body">
                                <div class="flex">
                                    <div class="ml">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                            [config]="carouselConfig">
                                            <div ngxSlickItem
                                                *ngFor="let package of bookingResponseData?.packageDetails"
                                                class="slide">
                                                <div class="add_vehicle_honda flex">
                                                    <div class="add_honda_left">
                                                        <div class="file">
                                                            <figure>
                                                                <img [src]="baseUrl+package?.itemImage">
                                                            </figure>
                                                            <h5><span class="capitaAL_SVU">Type:
                                                                    {{package?.packageName}}</span>
                                                                <span class="capitaAL_SVU">Description:
                                                                    {{package?.itemDescription}}</span>
                                                                <span class="capitaAL_SVU">Quantity:
                                                                    {{package?.itemQuantity}}</span>
                                                                <span class="capitaAL_SVU">Status: Not Picked</span>
                                                            </h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- package detail end  -->
                    <div class="moniter_your_ride">
                        <p (click)="openMonitorModal(bookingResponseData?.id)">
                            Monitor your ride
                            <i class="fa fa-angle-right"></i>
                        </p>
                    </div>
                    <div class="ride_cost_sec">
                        <div class="cost_item">
                            <p>Cost <span>US ${{bookingResponseData?.booKingAmount}}</span></p>
                        </div>
                        <div class="cost_item">
                            <p>Duration <span>{{duration}}</span></p>
                        </div>
                    </div>
                </div>
                <div class="trip_payment">
                    <h4>Payment</h4>
                    <div class="visa_card">
                        <figure (click)="continue('2')">
                            <img src="assets/uimages/visa_card.png " alt=" " />
                        </figure>
                    </div>
                </div>
                <!----start cancel call btn-->
                <div class="ride_can_btn">
                    <button class="call_btn" (click)="goToContactDetail()">Call</button>
                </div>
                <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
                    <div class="add_honda_left">
                        <div class="file">
                            <figure class="driver-img">
                                <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image"
                                    src="assets/images/user.png">
                                <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image"
                                    [src]="baseUrl+bookingResponseData?.driverId?.image">
                            </figure>
                            <h5><span>{{bookingResponseData?.driverId?.countryCode}}
                                    {{bookingResponseData?.driverId?.phoneNo}}</span>
                                {{bookingResponseData?.driverId?.firstName}} {{bookingResponseData?.driverId?.lastName}}
                                <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                            </h5>
                        </div>
                    </div>
                    <div class="add_vehicle_right flex">
                        <div class="add_vehicle_right_icon">
                            <figure>
                                <img class="clr-bl" src="assets/images/phone-call.svg">
                            </figure>
                        </div>
                    </div>
                </div>
                <!-----end rider accept-->
            </div>
            <!-----End  call me reach me screen-->
            <!-----you ride started page-->

            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 7">
                <div class="driver_info_bx">
                    <p class="arrving_time">Your packages has been picked up.</p>
                    <div class="about_driver">
                        <figure class="driver_img">
                            <img *ngIf="!bookingResponseData?.driverId?.image" src="assets/uimages/profile_image.png "
                                alt=" " />
                            <img *ngIf="bookingResponseData?.driverId?.image"
                                [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distanceInKM}}</span>

                        </div>
                        <div class="car_img">
                            <figure>
                                <img *ngIf="!bookingResponseData?.vehicleId?.vehicleImage"
                                    src="assets/uimages/ride_car.png " alt=" " />
                                <img *ngIf="bookingResponseData?.vehicleId?.vehicleImage"
                                    [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleMake}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <div class="priceRide">
                        <h5 class="amnt">Price: ${{bookingResponseData?.booKingAmount}}</h5>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm">
                                <div class="blm">
                                    <div class="bb"></div>
                                </div>
                                <div class="mkl">
                                    <p>{{bookingResponseData?.pickUpAddress}}</p>
                                </div>
                            </div>

                            <div class="mm">
                                <div class="blm">
                                    <div class="blc"></div>
                                </div>
                                <div class="mkl">
                                    <p>{{bookingResponseData?.dropUpAddress}}</p>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <!-- /// package detail start -->
                    <div class="card">
                        <div class="card-header p-2" id="review">
                            <div class="fs collapsed" data-toggle="collapse" data-target="#reviewsOne">
                                <div class="fd">
                                    <h5 class="mb-0">
                                        Delivery Details
                                    </h5>
                                </div>
                                <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                            </div>
                        </div>

                        <div id="reviewsOne" class="collapse" aria-labelledby="review" data-parent="#faqExample">
                            <div class="card-body">
                                <div class="delivery_detail_form">
                                    <div class="delivery_view_item">
                                        <label for="">Delivery To</label>
                                        <p>{{bookingResponseData?.addressType}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Street Address</label>
                                        <p>{{bookingResponseData?.streetAddress}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Building/apt/suite</label>
                                        <p>{{bookingResponseData?.suit}}</p>
                                    </div>

                                    <div class="delivery_view_item">
                                        <label for="">City</label>
                                        <p>{{bookingResponseData?.city}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Province</label>
                                        <p>{{bookingResponseData?.province}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Postal/Zip code:</label>
                                        <p>{{bookingResponseData?.zipCode}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Phone Number</label>
                                        <p>{{bookingResponseData?.phoneNo}}
                                        </p>
                                    </div>
                                    <div class="delivery_view_item" *ngIf="bookingResponseData?.additionalInfo">
                                        <label for="">Additional Details</label>
                                        <p>{{bookingResponseData?.additionalInfo}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-2" id="review2">
                            <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#reviewstwo">
                                <div class="fd">
                                    <h5 class="mb-0 ">
                                        Package Details
                                    </h5>
                                </div>
                                <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                            </div>
                        </div>
                        <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample">
                            <div class="card-body">
                                <div class="flex">
                                    <div class="ml">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                            [config]="carouselConfig">
                                            <div ngxSlickItem
                                                *ngFor="let package of bookingResponseData?.packageDetails"
                                                class="slide">
                                                <div class="add_vehicle_honda flex">
                                                    <div class="add_honda_left">
                                                        <div class="file">
                                                            <figure>
                                                                <img [src]="baseUrl+package?.itemImage">
                                                            </figure>
                                                            <h5><span>Type: {{package?.packageName}}</span>
                                                                <span>Description: {{package?.itemDescription}}</span>
                                                                <span class="capitaAL_SVU">Quantity:
                                                                    {{package?.itemQuantity}}</span>
                                                                <span class="capitaAL_SVU">Status:
                                                                    {{package?.status}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- package detail end  -->
                    <div class="trip_payment">
                        <h4>Payment</h4>
                        <div class="visa_card">
                            <figure (click)="continue('2')">
                                <img src="assets/uimages/visa_card.png " alt=" " />
                            </figure>
                        </div>
                    </div>
                    <!----start cancel call btn-->
                    <div class="ride_can_btn">
                        <button class="call_btn" (click)="goToContactDetail()">Call</button>
                    </div>
                    <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
                        <div class="add_honda_left">
                            <div class="file">
                                <figure class="driver-img">
                                    <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image"
                                        src="assets/images/user.png">
                                    <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image"
                                        [src]="baseUrl+bookingResponseData?.driverId?.image">
                                </figure>
                                <h5><span>{{bookingResponseData?.driverId?.countryCode}}
                                        {{bookingResponseData?.driverId?.phoneNo}}</span>
                                    {{bookingResponseData?.driverId?.firstName}}
                                    {{bookingResponseData?.driverId?.lastName}}
                                    <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                                </h5>
                            </div>
                        </div>
                        <div class="add_vehicle_right flex">
                            <div class="add_vehicle_right_icon">
                                <figure>
                                    <img class="clr-bl" src="assets/images/phone-call.svg">
                                </figure>
                            </div>
                        </div>
                    </div>

                </div>

                <!-----end rider accept-->
            </div>

            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 8">
                <div class="driver_info_bx">
                    <p class="arrving_time">Driver is out for delivery.</p>
                    <div class="about_driver">
                        <figure class="driver_img">
                            <img *ngIf="!bookingResponseData?.driverId?.image" src="assets/uimages/profile_image.png "
                                alt=" " />
                            <img *ngIf="bookingResponseData?.driverId?.image"
                                [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distanceInKM}}</span>

                        </div>
                        <div class="car_img">
                            <figure>
                                <img *ngIf="!bookingResponseData?.vehicleId?.vehicleImage"
                                    src="assets/uimages/ride_car.png " alt=" " />
                                <img *ngIf="bookingResponseData?.vehicleId?.vehicleImage"
                                    [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleMake}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <div class="priceRide">
                        <h5 class="amnt">Price: ${{bookingResponseData?.booKingAmount}}</h5>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm">
                                <div class="blm">
                                    <div class="bb"></div>
                                </div>
                                <div class="mkl">
                                    <p>{{bookingResponseData?.pickUpAddress}}</p>
                                </div>
                            </div>

                            <div class="mm">
                                <div class="blm">
                                    <div class="blc"></div>
                                </div>
                                <div class="mkl">
                                    <p>{{bookingResponseData?.dropUpAddress}}</p>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <!-- /// package detail start -->
                    <div class="card">
                        <div class="card-header p-2" id="review">
                            <div class="fs collapsed" data-toggle="collapse" data-target="#reviewsOne">
                                <div class="fd">
                                    <h5 class="mb-0">
                                        Delivery Details
                                    </h5>
                                </div>
                                <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                            </div>
                        </div>

                        <div id="reviewsOne" class="collapse" aria-labelledby="review" data-parent="#faqExample">
                            <div class="card-body">
                                <div class="delivery_detail_form">
                                    <div class="delivery_view_item">
                                        <label for="">Delivery To</label>
                                        <p>{{bookingResponseData?.addressType}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Street Address</label>
                                        <p>{{bookingResponseData?.streetAddress}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Building/apt/suite</label>
                                        <p>{{bookingResponseData?.suit}}</p>
                                    </div>

                                    <div class="delivery_view_item">
                                        <label for="">City</label>
                                        <p>{{bookingResponseData?.city}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Province</label>
                                        <p>{{bookingResponseData?.province}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Postal/Zip code:</label>
                                        <p>{{bookingResponseData?.zipCode}}</p>
                                    </div>
                                    <div class="delivery_view_item">
                                        <label for="">Phone Number</label>
                                        <p>{{bookingResponseData?.phoneNo}}
                                        </p>
                                    </div>
                                    <div class="delivery_view_item" *ngIf="bookingResponseData?.additionalInfo">
                                        <label for="">Additional Details</label>
                                        <p>{{bookingResponseData?.additionalInfo}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-2" id="review2">
                            <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#reviewstwo">
                                <div class="fd">
                                    <h5 class="mb-0 ">
                                        Package Details
                                    </h5>
                                </div>
                                <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                            </div>
                        </div>
                        <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample">
                            <div class="card-body">
                                <div class="flex">
                                    <div class="ml">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                            [config]="carouselConfig">
                                            <div ngxSlickItem
                                                *ngFor="let package of bookingResponseData?.packageDetails"
                                                class="slide">
                                                <div class="add_vehicle_honda flex">
                                                    <div class="add_honda_left">
                                                        <div class="file">
                                                            <figure>
                                                                <img [src]="baseUrl+package?.itemImage">
                                                            </figure>
                                                            <h5><span>Type: {{package?.packageName}}</span>
                                                                <span>Description: {{package?.itemDescription}}</span>
                                                                <span class="capitaAL_SVU">Quantity:
                                                                    {{package?.itemQuantity}}</span>
                                                                <span class="capitaAL_SVU">Status:
                                                                    {{package?.status}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- package detail end  -->
                    <div class="moniter_your_ride">
                        <p class="mntr_title" (click)="openMonitorModal(bookingResponseData?.id)">
                            Monitor your delivery
                            <i class="fa fa-angle-right"></i>
                        </p>
                    </div>
                    <hr>
                    <div class="ftr-dtl">
                        <div class="ride_cost_sec">
                            <div class="cost_item">
                                <p>Cost <span>US ${{bookingResponseData?.booKingAmount}}</span></p>
                            </div>
                            <div class="cost_item">
                                <p>Duration <span>{{duration}}</span></p>
                            </div>
                        </div>
                        <div class="trip_payment">
                            <h4 class="pymnt">Payment</h4>
                            <div class="visa_card">
                                <figure (click)="continue(2)">
                                    <img src="assets/uimages/visa_card.png " alt=" " />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <!----start cancel call btn-->
                    <div class="ride_can_btn">
                        <button class="call_btn" (click)="goToContactDetail()">Call</button>
                    </div>
                    <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
                        <div class="add_honda_left">
                            <div class="file">
                                <figure class="driver-img">
                                    <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image"
                                        src="assets/images/user.png">
                                    <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image"
                                        [src]="baseUrl+bookingResponseData?.driverId?.image">
                                </figure>
                                <h5><span>{{bookingResponseData?.driverId?.countryCode}}
                                        {{bookingResponseData?.driverId?.phoneNo}}</span>
                                    {{bookingResponseData?.driverId?.firstName}}
                                    {{bookingResponseData?.driverId?.lastName}}
                                    <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                                </h5>
                            </div>
                        </div>
                        <div class="add_vehicle_right flex">
                            <div class="add_vehicle_right_icon">
                                <figure>
                                    <img class="clr-bl" src="assets/images/phone-call.svg">
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <!-----end rider accept-->
            </div>

            <!-----End you ride started page-->

            <!----start next 2 screen ride start now-->

            <!----end next 2 screen ride start now-->

            <!-----you ride started page-->

            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 9">
                <div class="flex-m">
                    <div class="driver_info_bx">
                        <p class="arrving_time">Package has delivered.</p>
                        <div class="about_driver">
                            <figure class="driver_img">
                                <img *ngIf="!bookingResponseData?.driverId?.image"
                                    src="assets/uimages/profile_image.png " alt=" " />
                                <img *ngIf="bookingResponseData?.driverId?.image"
                                    [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                            </figure>
                            <div class="driver_name_sec">
                                <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                                <span><i class="fa fa-star"
                                        aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                                <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distance?.toFixed(2)}}</span>


                            </div>
                            <div class="car_img">
                                <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                    <img src="assets/uimages/ride_car.png " alt=" " />
                                </figure>
                                <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                    <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                                </figure>
                                <p>{{bookingResponseData?.vehicleId?.vehicleMake}}:
                                    <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                            </div>

                        </div>
                        <div class="priceRide">
                            <h5 class="amnt">Order Id: {{bookingResponseData?.bookingNo}}</h5>
                        </div>
                        <!-- <Div class="start_trip">

                            <div class="start_trip_text">
                                <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                    <div *ngIf="pick?.isPickUp">
                                        <div class="blm">
                                            <div class="bb"></div>
                                        </div>
                                        <div class="mkl">
                                            <p>{{pick?.address}}</p>
                                        </div>

                                    </div>
                                    <div class="stts"
                                        *ngIf="pick?.isPickUp && pick?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                        <p>{{constant.SHOW_STATUS_BOOKING[pick?.bookingStatus]}}</p>
                                    </div>
                                </div>

                                <div class="mm last" *ngFor="let drop of bookingResponseData?.subBookingData">
                                    <div *ngIf="!drop?.isPickUp">
                                        <div class="blm">
                                            <div class="blc"></div>
                                        </div>
                                        <div class="mkl">
                                            <p>{{drop?.address}}</p>
                                        </div>
                                    </div>
                                    <div class="stts"
                                        *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                        <p>{{constant.SHOW_STATUS_BOOKING[drop?.bookingStatus]}}</p>
                                    </div>
                                </div>
                            </div>
                        </Div> -->
                        <!-- /// package detail start -->
                        <div class="fs">
                            <div class="fd">
                                <h5 class="mb-0">
                                    Pick up Address
                                </h5>
                            </div>
                        </div>
                        <div>
                            <div class="">

                                <div class="pickup_input" *ngFor="let pick of bookingResponseData?.subBookingData">
                                    <div class="form-group border_remove" *ngIf="pick?.isPickUp">
                                        <div class="form_grp_request_left">
                                            <div class="blue_circle">

                                            </div>
                                        </div>
                                        <div class="form_grp_request_right">
                                            <mat-form-field
                                                class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                                <div class="mat-form-field-wrapper ng-tns-c52-89">
                                                    <div class="mat-form-field-flex ng-tns-c52-89">
                                                        <div class="mat-form-field-infix ng-tns-c52-89">
                                                            <input matInput placeholder="Choose your Pickup" disabled=""
                                                                class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                                id="mat-input-57" aria-invalid="false"
                                                                aria-required="false">
                                                            <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                                <label
                                                                    class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                                    id="mat-form-field-label-147" for="mat-input-57"
                                                                    aria-owns="mat-input-57">
                                                                    <mat-label _ngcontent-rbn-c120=""
                                                                        class="ng-tns-c52-89 ng-star-inserted">
                                                                        {{pick?.address}}
                                                                    </mat-label>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                        <span class="mat-form-field-ripple ng-tns-c52-89"></span>
                                                    </div>
                                                    <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                                        <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                            style="opacity: 1; transform: translateY(0%);">
                                                            <div class="mat-form-field-hint-spacer ng-tns-c52-89">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="fs">
                            <div class="fd">
                                <h5 class="mb-0 ">
                                    Package Details
                                </h5>
                            </div>
                        </div>
                        <div>
                            <div class="card-body">
                                <div class="flex">
                                    <div class="ml">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                            [config]="carouselConfig">
                                            <div ngxSlickItem *ngFor="let package of allPackagesDetail" class="slide">
                                                <div class="add_vehicle_honda flex">
                                                    <div class="add_honda_left">
                                                        <div class="file">
                                                            <div class="file-inner">
                                                                <div class="nsf">
                                                                    <figure>
                                                                        <img [src]="baseUrl+package?.image">
                                                                    </figure>
                                                                    <h5><span>{{package?.packageType.split(',')[1]}}</span>{{package?.desc}}<span
                                                                            class="capitaAL_SVU">Quantity:
                                                                            {{package?.quantity}}</span>
                                                                    </h5>
                                                                </div>
                                                                <div class="nsf sec">
                                                                    <div [ngClass]="package?.bookingStatus === constant.BOOKING_STATUS.AVAILABLE ? 'blue_sqr' : 'red_circle'"></div>
                                                                    <h5>
                                                                        <span class="capitaAL_SVU">Address:
                                                                            {{package?.addressDetails?.itemDropup?.address}} {{package?.bookingStatus}}</span>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample"></div>
                        <div class="card-body">
                            <div class="flex">
                                <div class="ml">
                                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                        [config]="carouselConfig">
                                        <div ngxSlickItem *ngFor="let package of bookingResponseData?.packageDetails"
                                            class="slide">
                                            <div class="add_vehicle_honda flex">
                                                <div class="add_honda_left">
                                                    <div class="file">
                                                        <figure>
                                                            <img [src]="baseUrl+package?.itemImage">
                                                        </figure>
                                                        <h5><span>Type: {{package?.packageName}}</span>
                                                            <span>Desc: {{package?.itemDescription}}</span>
                                                            <span class="capitaAL_SVU">Quantity:
                                                                {{package?.itemQuantity}}</span>
                                                            <span class="capitaAL_SVU">Status:
                                                                {{package?.status}}</span>
                                                        </h5>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- package detail end  -->
                    <div class="price-detail">
                        <div class="price-title">Total Price</div>
                        <div class="price-outer">
                            <div>Promo Discount</div>
                            <div>$ 10.00</div>
                        </div>
                        <div class="price-outer">
                            <div>Tax</div>
                            <div>$ 10.00</div>
                        </div>
                        <div class="price-outer">
                            <div>Delivery Charges</div>
                            <div>$ 10.00</div>
                        </div>
                        <div class="price-outer">
                            <div>Service Charges</div>
                            <div>$ 10.00</div>
                        </div>
                    </div>
                    <hr>
                    <div class="complt-price">
                        <p class="amount-d">${{bookingResponseData?.booKingAmount}}</p>
                        <p class="grn-txt">Order is completed.</p>
                    </div>
                </div>
                <div class="share_feebback">
                    <div class="two-btn">
                        <button class="btn btn-primary firstBtn" (click)="continue(14)">Review Driver</button>
                        <button class="btn btn-outline secondBtn">View Bill</button>
                    </div>
                </div>
            </div>
            <!-----end rider accept-->


            <div class="request_ride_inner border_remove" *ngIf="nextPopup == 14">
                <div class="driver_info_bx">
                    <p class="arrving_time">Rate Your Delivery.</p>
                    <div class="rate-screen">
                        <!-- <h6>Your Delivery</h6> -->
                    </div>
                    <div class="rl">
                        <div class="del-main">
                            <div class="del-l">
                                <figure>
                                    <img *ngIf="!driverReviewData?.image" src="assets/images/man_user.jpg">
                                    <img *ngIf="driverReviewData?.image" [src]="baseUrl+driverReviewData?.image">
                                </figure>
                            </div>
                            <div class="main-c">
                                <div class="del-r del-u">
                                    <h5>{{driverReviewData?.firstName +' '+driverReviewData?.lastName}}</h5>
                                </div>
                                <div class="driver_rate_pop del-b">
                                    <p>Rating<span>{{driverReviewData?.avgRating}}/5</span></p>
                                    <p>Review<span>{{driverReviewData?.reviewTotal}}</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="heart">
                            <i (click)="addFavouriteDriver()" *ngIf="!driverReviewData?.isFav"
                                class="fa fa-heart-o redcolor" aria-hidden="true"></i>
                            <i (click)="removeFavouriteDriver()" *ngIf="driverReviewData?.isFav"
                                class="fa fa-heart redcolor" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="rating">
                        <p class="title">Rate</p>
                        <div class="star-rating">
                            <button mat-icon-button *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
                                (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                                <mat-icon>
                                    {{showIcon(i)}}
                                </mat-icon>
                            </button>
                        </div>
                        <p class="rating-err" *ngIf="ratingSubmitted && rating === 0">Please give rating.
                        </p>
                    </div>
                    <ul class="bages" *ngIf="rating > 2">
                        <li [ngClass]="selectedDriverReview === i ? 'badge badge-secondary active' : 'badge badge-secondary'"
                            *ngFor="let data of reviewData; let i = index" (click)="selectReview(i,data)">{{data?.name}}
                        </li>
                        <!-- <li [ngClass]="{'badge badge-secondary': data?.isActive === false,'badge badge-secondary active': data?.isActive === true}" *ngFor="let data of reviewData">{{data?.name}}</li> -->
                    </ul>
                    <div class="fgi sec" *ngIf="rating <= 2 && rating > 0">
                        <textarea id="w3review" class="from-control" placeholder="Enter your feedback here..."
                            [formControl]="review"></textarea>
                    </div>
                    <p class="rating-err" *ngIf="ratingSubmitted && selectedReviewData === '' && rating > 0">Please give
                        feedback.</p>
                </div>
                <div class="tip-title">
                    <p>Add a tip</p>
                </div>
                <div class="bb-hldr">
                    <div [ngClass]="selectedTip === i ? 'bbxx active' : 'bbxx'"
                        *ngFor="let item of dummy; let i = index" (click)="selectTip(i,item?.amount)">
                        {{'$ ' + item?.amount}}</div>
                </div>
                <div class="custom-tip">
                    <mat-form-field class="example-full-width">
                        <input class="tip-input" type="number" [formControl]="customRate" matInput
                            placeholder="Enter custom amount.">
                    </mat-form-field>
                </div>
                <div class="feedback-links">
                    <h5 class="fdd-links">Feedback Links</h5>
                    <h6 class="dd">Driver</h6>
                    <ul>
                        <li>
                            Was the driver on time?
                            <div class="tp">
                                <mat-radio-group aria-label="Select an option" [formControl]="wasTheDriverOnTime">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </li>
                        <li>
                            Did the driver make your ride comfortabel?
                            <div class="tp">
                                <mat-radio-group aria-label="Select an option" [formControl]="driverMakeYouComfortabel">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </li>
                        <li>
                            Was the ride safe?
                            <div class="tp">
                                <mat-radio-group aria-label="Select an option" [formControl]="wasTheRideSafe">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </li>
                        <li class="imp">
                            What can we improve for next time?
                            <div class="tp">
                                <mat-form-field class="example-full-width">

                                    <textarea matInput [formControl]="comment" placeholder="Comment"></textarea>
                                </mat-form-field>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="bk-hm">
                    <button class="btn btn-primary first-btn" (click)="addRating(0)">Submit</button>
                    <button class="btn btn-outline second-btn" (click)="continue(0)">Skip</button>
                </div>
            </div>

            <!-------start delivery page html-->
            <div class="request_ride_inner part2" *ngIf="nextPopup == 10">
                <div class="car_using_main">
                    <form [formGroup]="deliveryDetailForm">
                        <div class="car_using_left">
                            <h4>Delivery Details</h4>
                            <div class="delivery_detail_form">
                                <div class="form-group secl">
                                    <div class="fgi">
                                        <label>Delivery To</label>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Delivery Type</mat-label>
                                            <mat-select formControlName="deliveryTo">
                                                <mat-option *ngFor="let food of foods" [value]="food.value">
                                                    {{food.viewValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['deliveryTo'].hasError('required')">
                                            Delivery type is required.</mat-error>

                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <div class="fgi">
                                        <label>Choose your item drop off location</label>
                                        <input matInput class="from-control"
                                            placeholder="Search for item drop off location" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete #placesRef="ngx-places"
                                            (onAddressChange)="handleItemDropChnage($event)"
                                            formControlName="itemDropup" />
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['itemDropup'].hasError('required')">
                                            Item Drop off location is required.</mat-error>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <div class="fgi">
                                        <label>Street Address</label>
                                        <input type="text" formControlName="streetAdd" class="from-control"
                                            placeholder="Street name/Street no." />
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['streetAdd'].hasError('required')">
                                            Street address is required.</mat-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fgi">
                                        <label>Suite/apt/office</label>
                                        <input type="text" class="from-control" formControlName="suiteAptOffice"
                                            placeholder="Suite/apt/office" />
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['suiteAptOffice'].hasError('required')">
                                            Suite/apt/office is required.</mat-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fgi">
                                        <label>City</label>
                                        <input type="text" class="from-control" formControlName="city"
                                            placeholder="Enter Your City Name" />
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['city'].hasError('required')">
                                            City name is required.</mat-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fgi">
                                        <label>Province</label>
                                        <input type="text" class="from-control" formControlName="province"
                                            placeholder="Enter Province" />
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['province'].hasError('required')">
                                            Province is required.</mat-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fgi">
                                        <label>Postal/Zip code</label>
                                        <input type="text" class="from-control" formControlName="zipCode"
                                            placeholder="Enter Postal/Zip code" />
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['zipCode'].hasError('required')">
                                            Postal/Zip code is required.</mat-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fgi">
                                        <label>Phone Number</label>
                                        <ngx-intl-tel-input [inputId]="'adsasd'" [cssClass]="'custom'"
                                            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada"
                                            [maxLength]="15" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                            [separateDialCode]="separateDialCode" name="phone"
                                            formControlName="phoneNo">
                                        </ngx-intl-tel-input>
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['phoneNo'].hasError('required')">
                                            Phone number is required.</mat-error>
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                                            Please enter valid phone number.</mat-error>
                                    </div>
                                </div>
                                <div class="form-group">

                                    <div class="fgi">
                                        <label>Additional Details</label>
                                        <!-- <input type="text" class="from-control" formControlName="additionalInfo"
                                            placeholder="Additional Details" /> -->
                                        <textarea id="w3review" class="from-control" formControlName="additionalInfo">

                                                </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="two_btn">
                                <!-- <div class="commen_btn_outer">
                                    <button class="btn btn-secondary" (click)="continue('0')">Back</button>
                                </div> -->
                                <div class="commen_btn_outer">
                                    <button class="btn btn-primary" (click)="goToPackageDetail(11)">Next</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-------End delivery page html-->

            <!-----start package deatail page-->
            <div class="request_ride_inner part2" *ngIf="nextPopup == 11">
                <div class="car_using_main">
                    <div class="car_using_left">
                        <h4>Package Details</h4>
                        <div class="ml">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                                <div ngxSlickItem *ngFor="let package of allPackagesDetail" class="slide">
                                    <div class="add_vehicle_honda flex">
                                        <div class="add_honda_left">
                                            <div class="file">
                                                <div class="file-inner">
                                                    <div class="nsf">
                                                        <figure>
                                                            <img [src]="baseUrl+package?.image">
                                                        </figure>
                                                        <h5>
                                                            <span>{{package?.packageType.split(',')[1]}}</span>
                                                            <span class="desc-txt">{{package?.desc}}</span>
                                                            <span class="capitaAL_SVU">Quantity: {{package?.quantity}}</span>
                                                        </h5>
                                                    </div>
                                                    <div class="nsf sec">
                                                        <div class="red_circle"></div>
                                                        <h5>
                                                            <span class="capitaAL_SVU">Address:
                                                                {{package?.addressDetails?.itemDropup?.address}}</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="add_vehicle_right flex">
                                            <div class="add_vehicle_right_icon" (click)="editPackageDetail(package)">
                                                <figure>
                                                    <svg id="pencil" xmlns="http://www.w3.org/2000/svg" width="15.229"
                                                        height="15.23" viewBox="0 0 15.229 15.23">
                                                        <g id="Group_11780" data-name="Group 11780"
                                                            transform="translate(0 0)">
                                                            <g id="Group_11779" data-name="Group 11779"
                                                                transform="translate(0 0)">
                                                                <path id="Path_24244" data-name="Path 24244"
                                                                    d="M11.288,81.128a.356.356,0,0,0-.489,0L4.215,87.717a.346.346,0,0,0,.09.555l.54.271v.91l-1.656.474L1.518,88.254,1.991,86.6H2.9l.27.541a.347.347,0,0,0,.254.187.342.342,0,0,0,.055,0,.345.345,0,0,0,.245-.1l6.583-6.589a.347.347,0,0,0,0-.49L9.036,78.874a.346.346,0,0,0-.245-.1h0a.346.346,0,0,0-.245.1L1.485,86.006s0,.009-.007.012a.339.339,0,0,0-.081.137l-.6,2.1L.013,91.005a.347.347,0,0,0,.333.442.352.352,0,0,0,.095-.013l2.743-.784,2.1-.6c.006,0,.011-.007.017-.009a.641.641,0,0,0,.117-.069s.01-.005.015-.01l7.125-7.067a.347.347,0,0,0,0-.491Z"
                                                                    transform="translate(0 -76.217)" fill="#0171bd" />
                                                                <path id="Path_24245" data-name="Path 24245"
                                                                    d="M295.708,2.474,293.631.4a1.415,1.415,0,0,0-1.959,0L290.4,1.677a.347.347,0,0,0,0,.489L293.939,5.7a.346.346,0,0,0,.488,0l1.28-1.27a1.386,1.386,0,0,0,0-1.959Z"
                                                                    transform="translate(-280.884 -0.002)"
                                                                    fill="#0171bd" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </figure>
                                            </div>
                                            <div class="add_vehicle_right_icon" (click)="deletePackageDetail(package)">
                                                <figure>
                                                    <svg id="delete_2_" xmlns="http://www.w3.org/2000/svg"
                                                        width="14.169" height="16.193" viewBox="0 0 14.169 16.193">
                                                        <path id="Path_24246" data-name="Path 24246"
                                                            d="M64.444,139.01a2.026,2.026,0,0,0,2.024,2.024h8.1a2.026,2.026,0,0,0,2.024-2.024V128.889H64.444Z"
                                                            transform="translate(-63.432 -124.841)" fill="#FF0000" />
                                                        <path id="Path_24247" data-name="Path 24247"
                                                            d="M41.331,1.012V0H37.282V1.012h-5.06V3.036H46.391V1.012Z"
                                                            transform="translate(-32.222)" fill="#FF0000" />
                                                    </svg>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                        <form [formGroup]="packageDetailForm">
                            <div class="delivery_detail_form">
                                <div class="form-group secl">
                                    <div class="fgi">
                                        <label>Package Type</label>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Select package type</mat-label>
                                            <mat-select formControlName="packageType">
                                                <mat-option *ngFor="let pckg of packageType"
                                                    [value]="pckg?._id+','+pckg?.packageName">
                                                    {{pckg?.packageName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error
                                            *ngIf="packageSubmitted && packageDetailForm.controls['packageType'].hasError('required')">
                                            Package type is required.</mat-error>

                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <div class="fgi">
                                        <label>Quantity</label>
                                        <input type="number" min="1" class="from-control" formControlName="quantity"
                                            placeholder="Quantity" />
                                        <mat-error
                                            *ngIf="packageSubmitted && packageDetailForm.controls['quantity'].hasError('required')">
                                            Quantity is required.</mat-error>
                                        <mat-error
                                            *ngIf="packageSubmitted && negativeValidator && !packageDetailForm.controls['quantity'].hasError('required')">
                                            Quantity should be greater then zero.</mat-error>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="fgi">
                                        <label class="ride_request">Choose your item drop off location </label>
                                        <mat-form-field class="example-full-width">
                                            <input class="from-control" matInput
                                                placeholder="Search for drop off location" autocorrect="off"
                                                autocapitalize="off" spellcheck="off" type="text"
                                                ngx-google-places-autocomplete #placesRef="ngx-places"
                                                (change)="dropChange()" (onAddressChange)="handleItemDropChnage($event)"
                                                formControlName="itemDropup" />
                                            <p style="color: red;"
                                                *ngIf="packageSubmitted && packageDetailForm.controls['itemDropup'].hasError('required')">
                                                Item drop off address is required.</p>
                                        </mat-form-field>

                                        <!-- <p style="color: red;"
                                                *ngIf="rideSubmited === true && !isDropChanged && !bookingForm.controls['rideDropAddress'].hasError('required')">
                                                Please enter valid destination.</p> -->
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fgi">
                                        <label class="ride_request">Item image </label>
                                        <div class="fg sec">
                                            <div class="fgi">
                                                <figure (click)="fileUpload.click()"><svg *ngIf="!fileDataUrl"
                                                        height="511pt" fill="#ccc" viewBox="0 -21 511.98744 511"
                                                        width="511pt" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="m133.320312 373.828125c-34.152343 0-64.53125-21.867187-75.5625-54.421875l-.746093-2.453125c-2.601563-8.621094-3.691407-15.871094-3.691407-23.125v-145.453125l-51.753906 172.757812c-6.65625 25.410157 8.511719 51.753907 33.960938 58.773438l329.878906 88.34375c4.117188 1.066406 8.234375 1.578125 12.289062 1.578125 21.246094 0 40.660157-14.101563 46.101563-34.882813l19.21875-61.117187zm0 0" />
                                                        <path
                                                            d="m191.988281 149.828125c23.53125 0 42.664063-19.136719 42.664063-42.667969s-19.132813-42.667968-42.664063-42.667968-42.667969 19.136718-42.667969 42.667968 19.136719 42.667969 42.667969 42.667969zm0 0" />
                                                        <path
                                                            d="m458.652344.492188h-320c-29.394532 0-53.332032 23.9375-53.332032 53.335937v234.664063c0 29.398437 23.9375 53.335937 53.332032 53.335937h320c29.398437 0 53.335937-23.9375 53.335937-53.335937v-234.664063c0-29.398437-23.9375-53.335937-53.335937-53.335937zm-320 42.667968h320c5.890625 0 10.667968 4.777344 10.667968 10.667969v151.445313l-67.390624-78.636719c-7.148438-8.382813-17.496094-12.863281-28.609376-13.117188-11.050781.0625-21.417968 4.96875-28.5 13.460938l-79.234374 95.101562-25.8125-25.75c-14.589844-14.589843-38.335938-14.589843-52.90625 0l-58.878907 58.859375v-201.363281c0-5.890625 4.777344-10.667969 10.664063-10.667969zm0 0" />
                                                    </svg>
                                                    <img *ngIf="fileDataUrl" [src]="baseUrl+fileDataUrl">

                                                </figure>
                                                <p *ngIf="!fileDataUrl">Upload image</p>
                                            </div>

                                            <input type="file" #fileUpload formControlName="file"
                                                (change)="onFileChange($event)" id="myfile" name="myfile">
                                            <mat-error
                                                *ngIf="packageSubmitted && packageDetailForm.controls['file'].hasError('required')">
                                                Package image is required.</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fgi sec">
                                        <label>Item description</label>
                                        <textarea id="w3review" placeholder="Item description.." class="from-control" formControlName="itemDesc">
                                                </textarea>
                                        <mat-error
                                            *ngIf="packageSubmitted && packageDetailForm.controls['itemDesc'].hasError('required')">
                                            Item description is required.</mat-error>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="package_btn">
                            <div class="commen_btn_outer">
                                <button class="btn btn-primary borders" *ngIf="pickupfieldsArr.length === 1"
                                    (click)="addItem()">More Items</button>
                                <button class="btn btn-primary borders" *ngIf="pickupfieldsArr.length > 1"
                                    (click)="nextPopup = 10">Back</button>
                            </div>
                            <div class="commen_btn_outer">
                                <button class="btn btn-primary" (click)="goToSelectPackageSize(1)">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-----End package deatail page-->
            <!----start Review Detail page-->



            <!-----start package deatail page-->
            <div class="request_ride_inner part2" *ngIf="nextPopup == 12">
                <div class="car_using_main review_Detail">

                    <!---=======================================================-->
                    <!---------start Faq Section--------->
                    <!---=======================================================-->
                    <div class="container py-3" style="width: 95%;">
                        <div class="row">
                            <div class="faq car_using_left review-detail">
                                <h4>Review Details</h4>

                                <div class="fs">
                                    <div class="fd">
                                        <h5 class="mb-0">
                                            Pick up Address
                                        </h5>
                                    </div>
                                </div>
                                <div>
                                    <div class="">

                                        <div class="pickup_input" *ngFor="let pick of pickupfieldsArr">
                                            <div class="form-group border_remove">
                                                <div class="form_grp_request_left">
                                                    <div class="blue_circle">

                                                    </div>
                                                </div>
                                                <div class="form_grp_request_right">
                                                    <mat-form-field
                                                        class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                                                            <div class="mat-form-field-flex ng-tns-c52-89">
                                                                <div class="mat-form-field-infix ng-tns-c52-89">
                                                                    <input matInput placeholder="Choose your Pickup"
                                                                        disabled=""
                                                                        class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                                        id="mat-input-57" aria-invalid="false"
                                                                        aria-required="false">
                                                                    <span
                                                                        class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                                        <label
                                                                            class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                                            id="mat-form-field-label-147"
                                                                            for="mat-input-57" aria-owns="mat-input-57">
                                                                            <mat-label _ngcontent-rbn-c120=""
                                                                                class="ng-tns-c52-89 ng-star-inserted">
                                                                                {{pickupForm.controls[pick?.name].value}}
                                                                            </mat-label>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                                <span
                                                                    class="mat-form-field-ripple ng-tns-c52-89"></span>
                                                            </div>
                                                            <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                                                <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                                    style="opacity: 1; transform: translateY(0%);">
                                                                    <div
                                                                        class="mat-form-field-hint-spacer ng-tns-c52-89">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                                <div class="fs">
                                    <div class="fd">
                                        <h5 class="mb-0 ">
                                            Package Details
                                        </h5>
                                    </div>
                                </div>
                                <div>
                                    <div class="card-body">
                                        <div class="flex">
                                            <div class="ml">
                                                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                                    [config]="carouselConfig">
                                                    <div ngxSlickItem *ngFor="let package of allPackagesDetail"
                                                        class="slide">
                                                        <div class="add_vehicle_honda flex">
                                                            <div class="add_honda_left">
                                                                <div class="file">
                                                                    <div class="file-inner">
                                                                        <div class="nsf">
                                                                            <figure>
                                                                                <img [src]="baseUrl+package?.image">
                                                                            </figure>
                                                                            <h5><span>{{package?.packageType.split(',')[1]}}</span>
                                                                                <span class="dewsc-txt">{{package?.desc}}</span>
                                                                                <span
                                                                                    class="capitaAL_SVU">Quantity:
                                                                                    {{package?.quantity}}</span>
                                                                            </h5>
                                                                        </div>
                                                                        <div class="nsf sec">
                                                                            <div class="red_circle"></div>
                                                                            <h5>
                                                                                <span class="capitaAL_SVU">Address:
                                                                                    {{package?.addressDetails?.itemDropup?.address}}</span>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </ngx-slick-carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="fs">
                                    <div class="fd">
                                        <h5 class="mb-0 ">
                                            Choices
                                        </h5>
                                    </div>
                                </div>

                                <div>
                                    <div class="">
                                        <ul>
                                            <li>

                                                <h6>Package Sizes</h6>
                                                <div class="fl">
                                                    <div class="flleft">
                                                        <figure><img [src]="baseUrl+selectedPackageSIze?.blackImage">
                                                        </figure>
                                                    </div>
                                                    <div class="flright">
                                                        <h5>{{selectedPackageSIze?.packageSizeName}}
                                                            <span>{{selectedPackageSIze?.size}}</span></h5>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>

                                                <h6>Vehicle</h6>
                                                <div class="fl">
                                                    <div class="flleft">
                                                        <figure><img [src]="baseUrl+selectedVehicleData?.vehicleImage">
                                                        </figure>
                                                    </div>
                                                    <div class="flright">
                                                        <h5>{{selectedVehicleData?.vehicleCategory}}
                                                            <span>${{selectedVehicleData?.booKingAmount}}</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="estimated_cost">
                                    <h4>Estimated Price</h4>
                                    <h5>${{selectedVehicleData?.booKingAmount}}</h5>
                                </div>
                                <div class="package_btn">
                                    <div class="commen_btn_outer preview_btn order_items_btn">
                                        <button class="btn btn-primary" (click)="requestBooking('3')">
                                            Confirm and request booking
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/row-->
                    </div>
                    <!---=======================================================-->
                    <!---------End Faq Section--------->
                    <!---=======================================================-->
                    <!-- 

                    <div class="car_using_left">
                        <h4>Review Details</h4>
                        <div class="pickup_input">
                            <div class="form-group border_remove">
                                <div class="form_grp_request_left">
                                    <div class="blue_circle">

                                    </div>
                                </div>
                                <div class="form_grp_request_right">
                                    <mat-form-field
                                        class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                                            <div class="mat-form-field-flex ng-tns-c52-89">
                                                <div class="mat-form-field-infix ng-tns-c52-89">
                                                    <input matInput="" placeholder="Choose your Pickup" disabled=""
                                                        class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                        id="mat-input-57" aria-invalid="false" aria-required="false">
                                                    <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                        <label
                                                            class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                            id="mat-form-field-label-147" for="mat-input-57"
                                                            aria-owns="mat-input-57">
                                                           
                                                            <mat-label _ngcontent-rbn-c120=""
                                                                class="ng-tns-c52-89 ng-star-inserted">{{bookingForm.controls['ridePickUpAddress'].value}}</mat-label>
                                                        </label>
                                                        </span>
                                                </div>
                                               
                                            </div>
                                            <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                <span class="mat-form-field-ripple ng-tns-c52-89"></span></div>
                                            
                                            <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                               
                                                <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                    style="opacity: 1; transform: translateY(0%);">
                                                    
                                                    <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                        <div class="pickup_input">
                            <div class="form-group border_remove last">
                                <div class="form_grp_request_left">
                                    <div class="yellow_circle">

                                    </div>
                                </div>
                                <div class="form_grp_request_right">
                                    <mat-form-field
                                        class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                                            <div class="mat-form-field-flex ng-tns-c52-89">
                                                <div class="mat-form-field-infix ng-tns-c52-89">
                                                    <input matInput="" placeholder="Choose your Pickup" disabled=""
                                                        class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                        id="mat-input-57" aria-invalid="false" aria-required="false">
                                                    <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                        <label
                                                            class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                            id="mat-form-field-label-147" for="mat-input-57"
                                                            aria-owns="mat-input-57">
                                                         
                                                            <mat-label _ngcontent-rbn-c120=""
                                                                class="ng-tns-c52-89 ng-star-inserted">{{bookingForm.controls['rideDropAddress'].value}}</mat-label>
                                                        </label>
                                                        </span>
                                                </div>
                                               
                                            </div>
                                            <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                <span class="mat-form-field-ripple ng-tns-c52-89"></span></div>
                                            
                                            <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                                
                                                <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                    style="opacity: 1; transform: translateY(0%);">
                                                  
                                                    <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                        <div class="delivery_detail_form">
                            <div class="delivery_view_item">
                                <label for="">Delivery To</label>
                                <p>{{deliveryDetailForm.controls['province'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Street Address</label>
                                <p>{{deliveryDetailForm.controls['streetAdd'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Building/apt/suite</label>
                                <p>{{deliveryDetailForm.controls['suiteAptOffice'].value}}</p>
                            </div>

                            <div class="delivery_view_item">
                                <label for="">City</label>
                                <p>{{deliveryDetailForm.controls['city'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Province</label>
                                <p>{{deliveryDetailForm.controls['province'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Postal/Zip code:</label>
                                <p>{{deliveryDetailForm.controls['zipCode'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Phone Number</label>
                                <p>{{deliveryDetailForm.controls['phoneNo'].value.e164Number}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Additional Details</label>
                                <p>{{deliveryDetailForm.controls['additionalInfo'].value}}</p>
                            </div>
                        </div>
                      
                        <div class="estimated_cost">
                            <h4>Estimated cost</h4>
                            <h5>${{getFare(selectedVehicleData?.pricePerMile)}}</h5>
                        </div>
                        <div class="package_btn">
                            <div class="commen_btn_outer preview_btn order_items_btn">
                                <button class="btn btn-primary" (click)="requestBooking('3')">
                                    Confirm And Request Booking
                                </button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-----End package deatail page-->
            <!----End Review Detail page-->

            <!-----start package deatail page-->
            <div class="request_ride_inner part2" *ngIf="nextPopup == 13">
                <div class="car_using_main review_using_detail">
                    <div class="car_using_left">
                        <h4>Order Details</h4>
                        <div class="accordion" id="faqExample">
                            <div class="card">
                                <div class="card-header p-2" id="headingTwo">
                                    <div class="fs collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseone1">
                                        <div class="fd">
                                            <h5 class="mb-0 ">
                                                Delivery Details
                                            </h5>
                                        </div>
                                        <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                                    </div>
                                </div>
                                <div id="collapseone1" class="collapse" aria-labelledby="headingTwo"
                                    data-parent="#faqExample">
                                    <div class="card-body">
                                        <div class="pickup_input">
                                            <div class="form-group border_remove">
                                                <div class="form_grp_request_left">
                                                    <div class="blue_circle">

                                                    </div>
                                                </div>
                                                <div class="form_grp_request_right">
                                                    <mat-form-field
                                                        class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                                                            <div class="mat-form-field-flex ng-tns-c52-89">
                                                                <div class="mat-form-field-infix ng-tns-c52-89">
                                                                    <input matInput placeholder="Choose your Pickup"
                                                                        disabled=""
                                                                        class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                                        id="mat-input-57" aria-invalid="false"
                                                                        aria-required="false">
                                                                    <span
                                                                        class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                                        <label
                                                                            class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                                            id="mat-form-field-label-147"
                                                                            for="mat-input-57" aria-owns="mat-input-57">
                                                                            <!---->
                                                                            <mat-label _ngcontent-rbn-c120=""
                                                                                class="ng-tns-c52-89 ng-star-inserted">
                                                                                {{bookingForm.controls['ridePickUpAddress'].value}}
                                                                            </mat-label>
                                                                        </label>
                                                                        <!----></span>
                                                                </div>
                                                                <!---->
                                                            </div>
                                                            <div
                                                                class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                                <span
                                                                    class="mat-form-field-ripple ng-tns-c52-89"></span>
                                                            </div>
                                                            <!---->
                                                            <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                                                <!---->
                                                                <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                                    style="opacity: 1; transform: translateY(0%);">
                                                                    <!---->
                                                                    <div
                                                                        class="mat-form-field-hint-spacer ng-tns-c52-89">
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>


                                        </div>
                                        <!--===========================================-->
                                        <!------Start Yellow Icon input------->
                                        <!--===========================================-->
                                        <div class="pickup_input">
                                            <div class="form-group border_remove last">
                                                <div class="form_grp_request_left">
                                                    <div class="yellow_circle">

                                                    </div>
                                                </div>
                                                <div class="form_grp_request_right">
                                                    <mat-form-field
                                                        class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                                                            <div class="mat-form-field-flex ng-tns-c52-89">
                                                                <div class="mat-form-field-infix ng-tns-c52-89">
                                                                    <input matInput placeholder="Choose your Pickup"
                                                                        disabled=""
                                                                        class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                                        id="mat-input-57" aria-invalid="false"
                                                                        aria-required="false">
                                                                    <span
                                                                        class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                                        <label
                                                                            class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                                            id="mat-form-field-label-147"
                                                                            for="mat-input-57" aria-owns="mat-input-57">
                                                                            <!---->
                                                                            <mat-label _ngcontent-rbn-c120=""
                                                                                class="ng-tns-c52-89 ng-star-inserted">
                                                                                {{bookingForm.controls['rideDropAddress'].value}}
                                                                            </mat-label>
                                                                        </label>
                                                                        <!----></span>
                                                                </div>
                                                                <!---->
                                                            </div>
                                                            <div
                                                                class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                                <span
                                                                    class="mat-form-field-ripple ng-tns-c52-89"></span>
                                                            </div>
                                                            <!---->
                                                            <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                                                <!---->
                                                                <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                                    style="opacity: 1; transform: translateY(0%);">
                                                                    <!---->
                                                                    <div
                                                                        class="mat-form-field-hint-spacer ng-tns-c52-89">
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="order_address">

                                            <div class="delivery_detail_form">
                                                <div class="delivery_view_item">
                                                    <label for="">Delivery To</label>
                                                    <p>{{bookingResponseData?.addressType}}</p>
                                                </div>
                                                <div class="delivery_view_item">
                                                    <label for="">Street Address</label>
                                                    <p>{{bookingResponseData?.streetAddress}}</p>
                                                </div>
                                                <div class="delivery_view_item">
                                                    <label for="">Building/apt/suite</label>
                                                    <p>{{bookingResponseData?.suit}}</p>
                                                </div>

                                                <div class="delivery_view_item">
                                                    <label for="">City</label>
                                                    <p>{{bookingResponseData?.city}}</p>
                                                </div>
                                                <div class="delivery_view_item">
                                                    <label for="">Province</label>
                                                    <p>{{bookingResponseData?.province}}</p>
                                                </div>
                                                <div class="delivery_view_item">
                                                    <label for="">Postal/Zip code:</label>
                                                    <p>{{bookingResponseData?.zipCode}}</p>
                                                </div>
                                                <div class="delivery_view_item">
                                                    <label for="">Phone Number</label>
                                                    <p>{{bookingResponseData?.phoneNo}}</p>
                                                </div>
                                                <div class="delivery_view_item"
                                                    *ngIf="bookingResponseData?.additionalInfo">
                                                    <label for="">Additional Details</label>
                                                    <p>{{bookingResponseData?.additionalInfo}}</p>
                                                </div>
                                            </div>
                                            <div class="order_form_input">
                                                <label> Phone Number </label>
                                                <p>{{bookingResponseData?.phoneNo}}</p>
                                            </div>
                                            <div class="order_form_input" *ngIf="bookingResponseData?.additionalInfo">
                                                <label> Additional Details </label>
                                                <p>{{bookingResponseData?.additionalInfo}}</p>
                                            </div>


                                        </div>
                                        <div class="order_total_price">
                                            <h3>Total Price</h3>
                                            <table>
                                                <tr>
                                                    <td>Promo Discount</td>
                                                    <td>$10.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Tax</td>
                                                    <td>$10.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Delivery Charges</td>
                                                    <td>$10.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Service charge</td>
                                                    <td>$10.00</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div class="package_click">
                                <div class="card">
                                    <div class="card-header p-2" id="headingTwo">
                                        <div class="fs collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapseTwo2">
                                            <div class="fd">
                                                <h5 class="mb-0 ">
                                                    Package Details
                                                </h5>
                                            </div>
                                            <div class="arrow"> <i class="fa fa-angle-down"></i></div>
                                        </div>
                                    </div>
                                    <div id="collapseTwo2" class="collapse" aria-labelledby="headingTwo"
                                        data-parent="#faqExample">
                                        <div class="card-body">
                                            <div class="flex">
                                                <div class="ml">
                                                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                                        [config]="carouselConfig">
                                                        <div ngxSlickItem
                                                            *ngFor="let package of bookingResponseData?.packageDetails"
                                                            class="slide">
                                                            <div class="add_vehicle_honda flex">
                                                                <div class="add_honda_left">
                                                                    <div class="file">
                                                                        <figure>
                                                                            <img [src]="baseUrl+package?.itemImage">
                                                                        </figure>
                                                                        <h5><span>{{package?.packageName}}</span>{{package?.itemDescription}}<span
                                                                                class="capitaAL_SVU">Quantity:
                                                                                {{package?.itemQuantity}}</span>
                                                                        </h5>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </ngx-slick-carousel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-2" id="headingThree">
                                        <div class="fs collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapseThree3">
                                            <div class="fd">
                                                <h5 class="mb-0 ">
                                                    Choices
                                                </h5>
                                            </div>
                                            <div class="arrow"><i class="fa fa-angle-down"></i></div>
                                        </div>
                                    </div>
                                    <div id="collapseThree3" class="collapse" aria-labelledby="headingThree"
                                        data-parent="#faqExample">
                                        <div class="card-body">
                                            <ul>
                                                <li>

                                                    <h6>Package Sizes</h6>
                                                    <div class="fl">
                                                        <div class="flleft">
                                                            <figure><img
                                                                    [src]="baseUrl+selectedPackageSIze?.blackImage">
                                                            </figure>
                                                        </div>
                                                        <div class="flright">
                                                            <h5>{{selectedPackageSIze?.packageSizeName}}
                                                                <span>{{selectedPackageSIze?.size}}</span></h5>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>

                                                    <h6>Vehicle</h6>
                                                    <div class="fl">
                                                        <div class="flleft">
                                                            <figure><img
                                                                    [src]="baseUrl+selectedVehicleData?.vehicleImage">
                                                            </figure>
                                                        </div>
                                                        <div class="flright">
                                                            <h5>{{selectedVehicleData?.vehicleCategory}}
                                                                <span>${{bookingResponseData?.booKingAmount}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="estimated_cost">
                                <p>${{bookingResponseData?.booKingAmount}}</p>
                                <label>Order is completed</label>
                            </div>
                            <div class="package_btn">
                                <div class="commen_btn_outer preview_btn order_items_btn">
                                    <button class="btn btn-primary" data-toggle="modal" data-target="#myModal23_user">
                                        Review Driver
                                    </button>
                                    <button class="btn btn-primary">View Bill</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-----End package deatail page-->

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal " tabindex="-1 " role="dialog " aria-labelledby="exampleModalLabel " aria-hidden="true ">
                    <div class="modal-dialog" role="document ">
                        <div class="modal-content">
                            <div class=" ">
                                <button type="button " class="close" data-dismiss="modal " aria-label="Close ">
                <span aria-hidden="true ">&times;</span>
              </button>
                            </div>
                            <div class="modal-body">
                                <div class="add_vehicle">
                                    <h4>Add vehicle</h4>
                                    <div class="vehicle_form">
                                        <div class="form-group">
                                            <mat-form-field [floatLabel]="'never'">
                                                <input matInput type="text " placeholder="Vehicle make " name="name " />
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group">
                                            <mat-form-field [floatLabel]="'never'">
                                                <input matInput type="text " placeholder="Vehicle Model " name="name " />
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group">
                                            <mat-form-field>
                                                <mat-label>Vehicle type</mat-label>
                                                <mat-select [(value)]="selected">
                                                    <mat-option value="option1 ">SUV</mat-option>
                                                    <mat-option value="option2 ">SUV</mat-option>
                                                    <mat-option value="option3 ">SUV</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-group upload_form_group">
                                            <figure class="ipload_img_vehicle">
                                                <svg xmlns="http://www.w3.org/2000/svg " width="114.195 " height="97.656 " viewBox="0 0 114.195 97.656 ">
                        <g id="surface1 " transform="translate(0) ">
                          <path
                            id="Path_24238 "
                            data-name="Path 24238 "
                            d="M110.78,160.375H3.416a3.39,3.39,0,0,0-2.583,1.164,3.25,3.25,0,0,0-.8,2.7l7.069,50.644a8.6,8.6,0,0,0,8.431,7.381H98.8a8.581,8.581,0,0,0,8.432-7.409l6.926-50.645a3.343,3.343,0,0,0-.795-2.7A3.461,3.461,0,0,0,110.78,160.375ZM75.267,191.744a2.266,2.266,0,0,1-1.533.6,2.418,2.418,0,0,1-1.675-.71l-12.69-13.655v32.448a2.271,2.271,0,0,1-4.543,0V177.976L42.137,191.6a2.269,2.269,0,1,1-3.321-3.094l16.635-17.857a2.272,2.272,0,0,1,1.675-.709,2.318,2.318,0,0,1,1.675.709l16.635,17.857A2.372,2.372,0,0,1,75.267,191.744Zm0,0 "
                            transform="translate(0 -124.605) "
                            fill="#ccc "
                          />
                          <path
                            id="Path_24239 "
                            data-name="Path 24239 "
                            d="M39.057,31.228h96.152V24.045a8.379,8.379,0,0,0-8.432-8.432H91.263A13.123,13.123,0,0,1,80.078,9.2L77.126,4.173A8.569,8.569,0,0,0,69.83,0H46.58a8.468,8.468,0,0,0-6.161,2.7,8.563,8.563,0,0,0-2.357,6.33Zm0,0 "
                            transform="translate(-29.566) "
                            fill="#ccc "
                          />
                        </g>
                      </svg>
                                            </figure>
                                            <h5>Upload insurance document</h5>
                                            <input type="file " id="file-input " class="upload_file" />
                                        </div>
                                    </div>
                                    <div class="driverap_btn">
                                        <button>Add Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
<!-- request_modale -->

<div class="modal fade" id="exampleModall" [ngStyle]="{'display':display,'opacity':1}" tabindex="-1 " role="dialog " aria-labelledby="exampleModalLabel "
    aria-hidden="true ">
    <div class="modal-dialog" role="document ">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button " class="close" (click)="closeDateTimeModal()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true ">&times;</span>
                </button>
            </div>
            <div class="modal-body second">
                <div class="add_vehicle">
                    <h4>Pick a day and time to book a vehicle</h4>
                    <div class="vehicle_form">
                        <form [formGroup]="requestForLaterForm">
                            <div class="form-group">
                                <input [owlDateTimeTrigger]="dt" formControlName="dateTime"
                                    placeholder="Select Date and Time" [min]="mindate" [owlDateTime]="dt">
                                <owl-date-time #dt></owl-date-time>
                                <mat-error
                                    *ngIf="isDateTimeSubmit && requestForLaterForm.controls['dateTime'].hasError('required')">
                                    Please select Date and Time</mat-error>
                            </div>
                        </form>
                    </div>
                    <div class="driverap_btn">
                        <button (click)="getVehicleForLater()">Request For Later</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-----add payment card modal-->
<!-- Modal -->
<div class="modal fade" id="add_payment " role="dialog ">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button " class="close" data-dismiss="modal" aria-label="Close ">
                    +
                </button>
            </div>
            <div class="modal-body">
                <h3 class="add_card_title">Add a card</h3>
                <div class="card_card_form">
                    <div class="add_card_input_itms">
                        <input type="text " placeholder="card holder name " />
                    </div>
                    <div class="add_card_input_itms">
                        <input type="text " placeholder="XXX XXX XXX " />
                    </div>
                    <div class="add_card_input_itms">
                        <select>
                            <option>Month</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                        </select>
                    </div>
                    <div class="add_card_input_itms">
                        <select>
                            <option>year</option>
                            <option>2015</option>
                            <option>2016</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                        </select>
                    </div>
                    <div class="add_card_input_itms">
                        <input type="text " placeholder="CCV " />
                    </div>
                </div>
            </div>
            <button class="add_card">Save</button>
        </div>
    </div>
</div>

<!----start promo card popup-->


<!-- Modal -->
<div class="modal fade promo_page" id="add_promo" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span><i class="fa fa-times" aria-hidden="true"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <h3 class="add_card_title">Add Promo code</h3>
                <div class="enter_promo_code">
                    <input class="from-control" type="text " placeholder="Enter Your Promotion Code " />
                    <div class="commen_btn_outer">
                        <button class="btn btn-primary">Apply</button>
                    </div>
                </div>
                <!----start promo code offer-->
                <div class="promo_offer_main">
                    <div class="promo_offer_bx">
                        <figure>
                            <img src="assets/uimages/promo_code_icon.png " alt=" " />
                        </figure>
                        <h4>
                            10% of base rate on your next rental
                            <span>Valid to 10/10/2020</span>
                        </h4>

                        <mat-checkbox></mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!----end promo card popup-->
<!-- Modal -->
<div class="modal fade promo_page" id="add_moniter" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button " class="close" data-dismiss="modal" aria-label="Close">
                    <span><i class="fa fa-times" aria-hidden="true"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="monitorForm">
                    <h3 class="add_card_title">Monitor your delivery</h3>
                    <p class="moniter_para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et dolore magna aliqua. Ut enim.
                    </p>

                    <input type="text" class="from-control moniter_input" formControlName="name" placeholder="Name" />
                    <mat-error *ngIf="monitorSubmit && monitorForm.controls['name'].hasError('required')">
                        Name is required.
                    </mat-error>
                    <ngx-intl-tel-input [inputId]="'adsasd'" [cssClass]="'custom'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                        [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                        [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                    </ngx-intl-tel-input>
                    <mat-error *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('required')">Phone
                        number is required.</mat-error>
                    <mat-error *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                        Enter valid number.</mat-error>
                    <div class="share_btn">
                        <button (click)="monitorRide()">share</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!----end promo card popup-->

<div class="modal right fade profile_side_popup driver_info_pop" id="myModal23_user" [ngStyle]="{'display':myModal23_user}" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel23">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModalLabel2"></h4>
            </div>

            <div class="modal-body">
                <div class="request_ride_inner sec">
                    <div class="title_bar">
                        <h2>Rate Your Driver</h2>
                    </div>
                    <div class="driver_info">
                        <div class="driver_info_img">
                            <figure>
                                <img src="assets/uimages/driver_img.png" alt="" />
                            </figure>
                        </div>
                        <div class="driver_info_text">
                            <h3>Evan Guzman</h3>
                            <div class="driver_rate_pop">
                                <p>Rated<span>4.2/5</span></p>
                                <p>Review<span>142</span></p>
                            </div>
                        </div>
                    </div>

                    <div class="rate_screen">
                        <h3>Rate</h3>
                        <figure>
                            <img src="assets/uimages/five_star.png" alt="" />
                        </figure>

                        <div class="driver_performance">
                            <p>Excellent</p>
                            <p>Very Good</p>
                            <p>Good</p>
                            <p>Package delivered as picked-up?</p>
                            <p>Picked-up and delivered on time</p>
                        </div>
                    </div>
                    <div class="add_driver_tip">
                        <h3>Add A tip</h3>
                        <div class="add_drive_tipmain">
                            <p>$2</p>
                            <p>$5</p>
                            <p>$8</p>
                            <p>$10</p>
                        </div>
                        <p>Enter Custom Amount</p>
                    </div>
                    <div class="driver_feedback_links">
                        <h3>Feedback Links</h3>
                        <div class="driver_feedback_main">
                            <h4>Driver</h4>
                            <div class="driver_feedback_item">
                                <p>Was the driver on time?</p>
                                <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button value="1">Yes</mat-radio-button>
                                    <mat-radio-button value="2">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="driver_feedback_item">
                                <p>Did the driver make your ride comfortable?</p>
                                <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button value="1">Yes</mat-radio-button>
                                    <mat-radio-button value="2">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="driver_feedback_item">
                                <p>Was the ride safe?</p>
                                <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button value="1">Yes</mat-radio-button>
                                    <mat-radio-button value="2">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="driver_feedback_item">
                                <p>What can we improve for next time?</p>
                                <textarea placeholder="Comment"> </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="rate_sub_btn">
                        <div class="commen_btn_outer review_driver">
                            <button class="btn btn-primary" (click)="continue('0')">
                                Submit
                            </button>
                        </div>
                        <div class="commen_btn_outer review_driver">
                            <button class="btn btn-primary btn_sec" (click)="continue('0')">
                                Skip
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>