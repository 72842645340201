<!-- <div class="login_wrap verification">
    <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" (click)="goTouhomepage()">
            Back To Home
          </button>
                </div>
            </div>
        </div>
    </div>
    <div class="login">
        <div class="conta_iner">
            <div class="login_main">
                <div class="login_form">
                    <h4 class="left">
                        Verification<span>Enter the 4 - Digit code that was sent to you at
              +91-563475643578645</span
            >
          </h4>

          <form class="form_control_form">
            <div class="verfication_group">
              <ng-otp-input
                (onInputChange)="onOtpChange($event)"
                [config]="{ length: 4 }"
              >
              </ng-otp-input>
            </div>
            <div class="driverap_btn">
              <button (click)="goToprofile()">Verify</button>
            </div>
            <div class="driverap_btn inactive">
              <button (click)="goTosignup()">Sign Up With Email</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="login_images"></div>
</div> -->

<div class="login_wrap top">
    <div class="back_home">
        <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
    </div>
    <div class="login_sec">
        <div class="conta_iner">
            <div class="login_outer_cls">
                <div class="otp_img">
                    <figure>
                        <img src="assets/uimages/rider_otp.svg" alt="" />
                    </figure>
                </div>
                <!-----end otp img class-->
                <!----start text -->
                <div class="login_content_sec">
                    <h3 class="verify_number">Verifying Your Phone Number !</h3>
                    <p class="user_num">
                        Please enter the verification code sent to
                        <span>{{verificationData?.countryCode+' '+verificationData?.phoneNo}}</span>
                    </p>
                    <div class="rider_otp">
                        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{ length: 4 }">
                        </ng-otp-input>
                    </div>
                    <!-- <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 5 }"></ng-otp-input> -->
                    <div class="commen_btn_outer">
                        <button class="btn btn-primary" (click)="verify()">
              Verify
            </button>
                    </div>
                    <p class="resend_opt">
                        Didn't Receive SMS? <a style="cursor: pointer;" (click)="resendCode()" >Resend Code</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>