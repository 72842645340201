<!------start homapge page-->
<div class="homepage">
  <!-- <app-unavbar></app-unavbar> -->
  <app-unavbar id="navbar"></app-unavbar>
  <div class="header_padding_class">
    <section class="homepage_first_sec">
      <div class="conta_iner">
        <div class="hompage_fs_main">
          <div class="homepage_fs_left">
            <h1>Apptunix™ Ride and Delivery</h1>
            <p>
              services is a new generation company to serve you with the best customer service, technology and costs in
              the market place where we operate.
            </p>
            <button (click)="goToWhySecton()">Learn More</button>
          </div>
          <div class="homepage_fs_img">
            <figure>
              <img src="assets/uimages/home_first.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-------start homepage secound sec-->
    <section class="homepage_ourstory">
      <div class="conta_iner">
        <div class="homepage_ourstory_main">
          <div class="homepage_ourstory_img">
            <figure>
              <img src="assets/uimages/our_story_new.svg" alt="our_story" />
            </figure>
          </div>
          <div class="homepage_ourstory_content">
            <div class="main_headings">
              <h2>Our Story</h2>
              <h3>
                We started Apptunix™ Enterprises Inc. in 2018 to serve the needs of riders and delivery services.
              </h3>
              <p>
                For the last 3 years we have been developing state-of-the-art GPS based and mobile applications that are
                being deployed on North American servers. We expect a soft launch in the winter of {{currentYear}}. Our management
                team is highly motivated, innovative, growth
                oriented and has a combined experience of more than 60 years delivering mobile solutions in the global
                marketplace.
              </p>
              <button (click)="goToFbPage()">Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <a href="#why_id" id="whyU2go"></a>
    <!------start homepage third section-->
    <section class="all_sec hompage_whysec" id="why_id">
      <div class="conta_iner">
        <div class="homepage_whysec_main">
          <div class="main_headings">
            <h2>Why Apptunix™?</h2>
          </div>
          <!----start our story bx-->
          <div class="home_ourstory_list">
            <div class="homstory_listouter">
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon1.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>Competitive lower costs and strong value propositions</p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon2.svg" alt="why_home_icon2" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>
                    Secure and safe multifactor authentication, data encryption and thorough checking of driver's
                    records
                  </p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon3.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>Reliable and efficient rides and deliveries</p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon4.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>
                    Multiple payment options (credit, debit, digital wallet, etc)
                  </p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon5.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>
                    Excellent customer service and leading innovative technology
                  </p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon6.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>We give you control of your rides or deliveries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!----start our our driver sec-->
    <section class="homepage_ourdriver home_page_items">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img">
            <figure>
              <img src="assets/uimages/our_driver.png" alt="our_driver" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>Our Drivers</h2>
              <h3>
                are customer oriented, professional and thoroughly checked to ensure best service in the industry. They
                benefit from:
              </h3>
              <ul class="ourdriver_list">
                <li>
                  <span>Competitive compensation and flexible hours</span>
                </li>
                <li>
                  <span>Opportunities to earn money from rides or deliveries or
                    both
                  </span>
                </li>
                <li><span>Company ownership possibilities</span></li>
                <li>
                  <span>Tips opportunities for each ride or delivery (at the
                    customer option)
                  </span>
                </li>
                <li>
                  <span>Coupons, loyalty and other company benefits </span>
                </li>
              </ul>
              <div class="two_btn">
                <button routerLink="/pre-register">Go to Pre-Registration</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-----start our Riders section-->
    <section class="all_sec homepage_ourdriver homepage_ourriders">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img" (click)="goToriderdeliverybooking()">
            <figure>
              <img src="assets/uimages/our_riders.png" alt="our_riders" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>Our Riders</h2>
              <h3>Apptunix™ will provide our Riders with:</h3>
              <ul class="ourdriver_list">
                <li><span>Best rates and great drivers </span></li>
                <li>
                  <span>Ride now or schedule a ride and choose your vehicle of
                    choice (subject to availability)
                  </span>
                </li>
                <li><span>Loyalty and coupon programs </span></li>
                <li><span>Electronic commerce </span></li>
                <li>
                  <span>Ability to monitor rides from acceptance to completion
                  </span>
                  <div class="ourdriver_sublist">
                    <ul>
                      <li><span>From rider perspective</span></li>
                      <li><span>From a family or friend perspective</span></li>
                    </ul>
                  </div>
                </li>
              </ul>
              <div class="two_btn">
                <button (click)="goToridersignup()">Sign up as Rider</button>
                <button class="onlyborder_fillbtn" (click)="goToriderlogin()">
                  Login as Rider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--------------deilvery patners sec-->
    <section class="homepage_ourdriver homepage_deliverypat">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img">
            <figure>
              <img src="assets/uimages/delivery_partners.png" alt="" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>Delivery Partners</h2>
              <h3>
                Our Delivery Partners rely on us to deliver their goods to their customer in a cost efficient and
                reliable manner. We offer this service in a variety of industries such as food delivery, auto parts,
                health care, banking, real estate and others. Delivery
                Partners will benefit as follows:
              </h3>
              <ul class="ourdriver_list">
                <li>
                  <span>Very low transaction costs (free for the first 6
                    months)</span>
                </li>
                <li><span>Reliable and efficient service</span></li>
                <li><span>Loyalty and coupon programs </span></li>
                <li><span>Electronic commerce</span></li>
                <li>
                  <span>Ability to track and monitors all aspects of the delivery
                    work</span>
                  <div class="ourdriver_sublist">
                    <ul>
                      <li><span>From a Delivery Partner perspective</span></li>
                      <li>
                        <span>From a Delivery Partner Customer perspective</span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <!-- <div class="two_btn">
                <button class="onlyborder_fillbtn" routerLink="/udeliverypartners">
                  Become a Delivery Partner
                </button>
              </div> -->
              <div class="two_btn">
                <button (click)="goToDeliverySignup()" >Sign up as Delivery Partner</button>
                <button (click)="goToDeliveryLogin()" class="onlyborder_fillbtn" >
                  Login as Delivery Partner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-----start business partners section css-->
  </div>
</div>
<section class="all_sec homepage_ourdriver homepage_ourriders homepage_business">
  <div class="conta_iner">
    <div class="homepage_ourdriver_main">
      <div class="homepage_ourdriver_img">
        <figure>
          <img src="assets/uimages/home_businesspartners.png" alt="" />
        </figure>
      </div>
      <div class="homepage_ourdriver_content">
        <div class="main_headings">
          <h2>Business Partners</h2>
          <h3>
            Our Business Partners can operate the Apptunix™ solution in an
            exclusive jurisdiction (e.g. a City, Province/State/Country). It
            is a turn-key solution adapted to their jurisdiction.
            <span>To learn more please contact us at: gdemarchi@Apptunix.ca</span>
          </h3>

          <div class="two_btn">
            <button class="onlyborder_fillbtn" (click)="goToBP()">
              Become a Business Partner
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-----start our app section-->
<section class="our_app" id="our_app">
  <div class="conta_iner">
    <div class="our_app_main">
      <div class="our_app_screen">
        <figure>
          <img src="assets/uimages/app_screen.png" alt="" />
        </figure>
      </div>
      <div class="our_app_content">
        <h2>
          Download our Apps and start experiencing a new world of
          possibilities
        </h2>
        <div class="app_downloadlink">
          <a style="cursor: pointer;" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DELIVERY)">
            <img src="assets/uimages/google_store.png" alt="" />
          </a>
          <a style="cursor: pointer;" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DELIVERY)">
            <img src="assets/uimages/app_store.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<app-ufooter></app-ufooter>


<!-- Modal -->
<div class="modal fade promo_page" id="add_business" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">Become a business partner</h3>
        <div class="business_partner_input">
          <input type="text" class="form-control" placeholder="Full Name" />
          <input type="text" class="form-control" placeholder="Address" />
          <input type="text" class="form-control" placeholder="Phone" />
          <input type="text" class="form-control" placeholder="City,State,Country,Region of interest" />
          <textarea name="" id="" placeholder="Enter you comments here"></textarea>
        </div>
        <div class="btn_outer">
          <button class="btn btn-primary margin_top20">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>