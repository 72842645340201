
export class apiList {
    sendOtp: string = 'driver/sendOtp';
    verifyOtp: string = 'driver/verifyOtp';
    uploadFile: string = 'driver/uploadFile';
    register: string = 'driver/register';
    login: string = 'driver/login';
    updateProfile: string = 'driver/updateProfile';
    getDefaultDriverDocument: string = 'driver/getDefaultDriverDocument'
    getDriverProfile: string = 'driver/getProfile';
    addDriverDocument: string = 'driver/addDriverDocument';
    forgotPassword: string = 'driver/forgotPassword';
    logout: string = 'driver/logout';
    getVehicleCategory: string = 'driver/getVehicleCategory';
    checkIfPhoneNumberExist: string = 'driver/checkIfPhoneNumberExist'
    checkIfEmailExist: string = 'driver/checkIfEmailExist';
}


export class apiListUser {
    register: string = 'user/register';
    sendOtp: string = 'user/sendOtp';
    reSendOtp: string = 'user/reSendOtp';
    verifyOtp: string = 'user/verifyPhoneOtp';
    uploadFile: string = 'user/uploadFile';
    uploadFileWithOutAuth: string = 'user/uploadFileWithOutAuth';
    userProfile: string = 'user/getProfile';
    updateUserProfile: string = 'user/updateProfile';
    userLogin: string = 'user/login';
    changePassword: string = 'user/changePassword';
    forgotPassword: string = 'user/forgotPassword';
    getVehicleCategoryInGeofence: string = 'user/getVehicleCategoryInGeofence';
    createRideBooking: string = 'user/createRideBooking';
    checkUserName: string = 'user/checkUserName';
    logout: string = 'user/logout';
    getPackageSize: string = 'user/getPackageSize';
    getPackageType: string = 'user/getPackageType';
    createDeliveryBooking: string = 'user/createDeliveryBooking';
    getNearbyDriversWithGeoFenceId: string = 'user/getNearbyDriversWithGeoFenceId';
    cancelBooking: string = 'user/cancelBooking';
    getBookingHistory: string = 'user/getBookingHistory'
    becomeBusinessPartner: string = 'user/becomeBusinessPartner';
    becomeDeliveryPartner: string = 'user/becomeDeliveryPartner';
    addFavouriteDriver: string = 'user/addFavouriteDriver';
    deleteFavouriteDriver: string = 'user/deleteFavouriteDriver';
    getAllFavouriteDriver: string = 'user/getAllFavouriteDriver';
    getDriverRatingsReview: string = 'user/getDriverRatingsReview';
    addRatings: string = 'user/addRatings';
    shareYourRide: string = 'user/shareYourRide';
    getShareYourRide: string = 'user/getShareYourRide';
    deleteUser: string = 'user/deleteUser';
    getRatingAndReview: string = 'user/getRatingAndReview';
    getBookingDetails: string = 'user/getBookingDetails';
    sendOtpWithAuth: string = 'user/sendOtpWithAuth';
    socialLogin: string = 'user/socialLogin';
    userQuery: string = 'user/userQuery';
    checkIfPhoneNumberExist: string = 'user/checkIfPhoneNumberExist'
    checkIfEmailExist: string = 'user/checkIfEmailExist';
    checkUserByProviderId: string = 'user/checkUserByProviderId';
    checkPrelaunchLocation: string = 'user/checkPrelaunchLocation';
    verifyEmail: string = 'user/verifyEmail';
    forgotChangePassword: string = 'user/forgotChangePassword';
    getCms: string = 'admin/getCms';
    getHelpCenter: string = 'user/getHelpCenter';
    getZones: string = 'user/getZones';
    getGeofence: string = 'user/Geofences'
}