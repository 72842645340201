import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../services/common/common.service";

@Component({
  selector: "app-unavbar",
  templateUrl: "./unavbar.component.html",
  styleUrls: ["./unavbar.component.scss"],
})
export class UnavbarComponent implements OnInit {

  navLinkArr = [
    { name: 'Home', url: 'uhomepage', isActiveClass: false },
    { name: 'Drivers', url: 'udriver', isActiveClass: false },
    { name: 'Riders', url: 'urider', isActiveClass: false },
    { name: 'Delivery Partners', url: 'udeliverypartners', isActiveClass: false },
    { name: 'Business Partners', url: 'ubusinesspartners', isActiveClass: false },
  ];

  constructor(private router: Router,
    private commonService: CommonService) {}

  ngOnInit(): void {
    this.activeClass();
  }

  activeClass() {
    this.navLinkArr.forEach((data: any) => {
      if(this.router.url.search(data.url) === 1) {
        data.isActiveClass = true;
      } else {
        data.isActiveClass = false;
      }
    });
    if(this.router.url === '/pre-register') {
      this.navLinkArr[1].isActiveClass = true;
    }
    if((this.router.url === '/riderlogin' || this.router.url === '/ridersignup') && !this.commonService.isThisDeliveryPartner) {
      this.navLinkArr[2].isActiveClass = true;
    }
    if((this.router.url === '/delivery-partner-login' || this.router.url === '/delivery-partner-signup') && this.commonService.isThisDeliveryPartner) {
      this.navLinkArr[3].isActiveClass = true;
    }
  }

  
}
