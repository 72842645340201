<div class="login_wrap top">
  <app-unavbar id="navbar"></app-unavbar>
    <!-- <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="back_home">
        <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
    </div> -->
    <div class="login">
        <div class="conta_iner">
            <div class="login_main">
                <div class="login_form center">

                    <h4>Let's Get Started <span>Create an account</span></h4>

                    <form class="form_control_form" [formGroup]="signupForm"
                        *ngIf="!commonService?.isThisDeliveryPartner">
                        <!-- // -->
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>First name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeFirst($event.target.value)" (keypress)="onKeyPress($event)" formControlName="firstName" matInput type="text"
                                    placeholder="First name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['firstName'].hasError('required')">First name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateFirstArr.includes(true)">
                                Please enter valid first name.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Last name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeSecond($event.target.value)" (keypress)="onKeyPress($event)" formControlName="lastName" matInput type="text"
                                    placeholder="Last name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['lastName'].hasError('required')">Last
                                name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateSecondArr.includes(true)">
                                Please enter valid last
                                name.</mat-error>
                        </div>
                        <!-- // -->
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Email address</label>
                                <input class="form-control" formControlName="email" matInput type="email"
                                    placeholder="Email address" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('required')">Email is
                                required.</mat-error>
                            <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('pattern')">
                                Sorry, only letters (a-z), number (0-9), and periods (.) are allowed.</mat-error>
                        </div>
                        <div class="form-group">
                            <label class="algn-lft">Phone number</label>
                            <!-- <mat-form-field [floatLabel]="'never'"> -->
                            <ngx-intl-tel-input [inputId]="'adsasd'" matInput [cssClass]="'custom'"
                                [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                                [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                            </ngx-intl-tel-input>
                            <!-- </mat-form-field> -->
                            <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('required')">Phone
                                number is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                                Enter valid number.</mat-error>
                        </div>

                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Password</label>
                                <input class="form-control" formControlName="password" matInput type="password"
                                    placeholder="Password" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('required')">
                                Password is required.</mat-error>
                            <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Confirm password</label>
                                <input class="form-control" formControlName="confirmPassword" matInput type="password"
                                    placeholder="Confirm password" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('required')">
                                Confirm password is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('mustMatch')">
                                Password and confirm password must match.</mat-error>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div>

                        <div class="flex submit_btn">
                            <button class="btn btn-primary" (click)="goToVerificationPopup()">
                                Sign Up
                            </button>
                        </div>

                        <p class="dont_account">
                            Already have an account?
                            <a (click)="goToriderlogin()">login</a>
                        </p>
                    </form>

                    <!-- //delivery partner form -->
                    <form class="form_control_form" [formGroup]="deliveryPartnerForm"
                        *ngIf="commonService?.isThisDeliveryPartner">

                        <div class="profile_uolad">
                            <div class="putop">
                                <figure>
                                    <img *ngIf="!fileDataUrl" src="assets/images/user.png">
                                    <img *ngIf="fileDataUrl" [src]="fileDataUrl">
                                </figure>
                            </div>
                            <div class="pubottom">
                                <div class="edit-bg" style="cursor: pointer;" (click)="fileUpload.click()"> <i style="color: white; cursor: pointer;" class="fa fa-pencil"></i></div>
                                <input type="file" #fileUpload (change)="onFileChange($event)" id="myfile"
                                    name="myfile">
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>First name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeFirst($event.target.value)" (keypress)="onKeyPress($event)" formControlName="firstName" matInput type="text"
                                    placeholder="First name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['firstName'].hasError('required')">First name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateFirstArr.includes(true)">
                                Please enter valid first name.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Last name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeSecond($event.target.value)" (keypress)="onKeyPress($event)" formControlName="lastName" matInput type="text"
                                    placeholder="Last name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['lastName'].hasError('required')">Last
                                name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateSecondArr.includes(true)">
                                Please enter valid last
                                name.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Email address</label>
                                <input class="form-control" formControlName="email" matInput type="email"
                                    placeholder="Email address" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && deliveryPartnerForm.controls['email'].hasError('required')">
                                Email is
                                required.</mat-error>
                            <mat-error *ngIf="submitted && deliveryPartnerForm.controls['email'].hasError('pattern')">
                                Sorry, only letters (a-z), number (0-9), and periods (.) are allowed.</mat-error>
                        </div>
                        <div class="form-group">
                            <label class="algn-lft">Phone number</label>
                            <!-- <mat-form-field [floatLabel]="'never'"> -->
                            <ngx-intl-tel-input [inputId]="'adsasd'" matInput [cssClass]="'custom'"
                                [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                                [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                            </ngx-intl-tel-input>
                            <!-- </mat-form-field> -->
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['phoneNo'].hasError('required')">Phone
                                number is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                                Enter valid number.</mat-error>
                        </div>
                        <!-- <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Full address (Street No./Street Name, City, state/Province, Country)</label>
                                <input class="form-control" matInput placeholder="Search for your full address"
                                    autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
                                    ngx-google-places-autocomplete #placesRef="ngx-places"
                                    (keyup)="onKeyUp($event.target.value)" (change)="onAddChanged($event.target.value)"
                                    (onAddressChange)="handleLocationChnage($event)" formControlName="address" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['address'].hasError('required') && addressError === ''">
                                Full address is required.</mat-error>
                        </div> -->
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Company name</label>
                                <input class="form-control" formControlName="companyName" matInput type="text"
                                    placeholder="Company name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['companyName'].hasError('required')">
                                Company name is required.</mat-error>
                        </div>


                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Company street no. and name</label>
                                <input class="form-control" formControlName="companyStreet" matInput type="text"
                                    placeholder="Company street no. and name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['companyStreet'].hasError('required')">
                                Company street no. and name are required.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Additional details (unit #, name of business, etc).</label>
                                <input class="form-control" formControlName="localAddress" matInput type="text"
                                    placeholder="Additional details (unit #, name of business, etc)">
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Company city, state/province and country.</label>
                                <input class="form-control" matInput
                                    placeholder="Search for your Company city, state/province and country"
                                    autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
                                    ngx-google-places-autocomplete #placesRef="ngx-places"
                                    (keyup)="onKeyUp($event.target.value)" (change)="onAddChanged($event.target.value)"
                                    (onAddressChange)="handleLocationChnage($event)" formControlName="address" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['address'].hasError('required')">
                                Company city, state/province and country are required.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Company zip code</label>
                                <input class="form-control" formControlName="companyZipCode" matInput type="text"
                                    placeholder="Company zip code" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['companyZipCode'].hasError('required')">
                                Company zip code is required.</mat-error>
                        </div>

                        <!-- <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <input class="form-control" formControlName="companyCertificateNo" matInput type="text"
                                    placeholder="Company certificate no.">
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['companyCertificateNo'].hasError('required')">
                                Company country is required.</mat-error>
                        </div> -->
                        <div class="form-group img-txt">
                            <label class="algn-lft">Company certificate</label>
                            <div class="data-b">
                                <input type="file" style="cursor: pointer;" (change)="onFileUpload($event)"
                                    #companyCertificate />
                                <div class="paracc">
                                    <p (click)="companyCertificate.click()" *ngIf="!preview"
                                        class="preview-dta mt-0 mb-0 text-left">Company certificate</p>
                                    <p (click)="companyCertificate.click()" *ngIf="preview"
                                        class="preview-dta mt-0 mb-0 text-left">{{preview}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Business incorporation number</label>
                                <input class="form-control" formControlName="bussinessIncorporation" matInput
                                    type="text" placeholder="Business incorporation number" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <label class="algn-lft">Industry name</label>
                            <mat-form-field [floatLabel]="'never'">

                                <mat-label>Industry name</mat-label>
                                <mat-select formControlName="industryName" class="form-control">
                                    <mat-optgroup *ngFor="let group of industries" [label]="group.group">
                                        <mat-option *ngFor="let item of group.values" [value]="item">{{item}}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['industryName'].hasError('required')">
                                Industry name is required.</mat-error>
                        </div>
                        <!-- <div class="form-group datepicker"> 
                            <label class="algn-lft">Credit card expiry date</label>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput (click)="dp1.open()" placeholder="Credit card expiry date" formControlName="creditExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getcreditExpiryDate($event)"
                                                [matDatepicker]="dp1">
                                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                            <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['creditExpiry'].hasError('required')">
                                Credit card expiry date is required.</mat-error>
                        </div> -->
                        <!-- <div class="form-group datepicker">
                            <label class="algn-lft">Bank card expiry date</label>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput (click)="dp5.open()" placeholder="Bank card expiry date" formControlName="bankCardExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getbankCardExpiryDate($event)"
                                                [matDatepicker]="dp5">
                                            <mat-datepicker-toggle matSuffix [for]="dp5"></mat-datepicker-toggle>
                                            <mat-datepicker #dp5 disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['bankCardExpiry'].hasError('required')">
                                Bank card expiry date is required.</mat-error>
                        </div> -->
                        <!-- <div class="form-group datepicker">
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput placeholder="Loyality card expiry date" formControlName="loyalityCardExpiry" [min]="minDate"
                                                class="form-control" (dateChange)="getLoyalityCardExpiryDate($event)"
                                                [matDatepicker]="dp6" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="dp6"></mat-datepicker-toggle>
                                            <mat-datepicker #dp6 disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['bankCardExpiry'].hasError('required')">
                                Loyality card expiry date is required.</mat-error>
                        </div> -->
                        <!-- <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <input class="form-control" formControlName="description" matInput type="text"
                                    placeholder="Description" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['description'].hasError('required')">
                                Description is required.</mat-error>
                        </div> -->

                        <!-- /////// -->
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Password</label>
                                <input class="form-control" formControlName="password" matInput type="password"
                                    placeholder="Password" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['password'].hasError('required')">
                                Password is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['password'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Confirm password</label>
                                <input class="form-control" formControlName="confirmPassword" matInput type="password"
                                    placeholder="Confirm password" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['confirmPassword'].hasError('required')">
                                Confirm password is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['confirmPassword'].hasError('mustMatch')">
                                Password and confirm password must match.</mat-error>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['confirmPassword'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div>

                        <div class="agr agr--rider-detail">
                            <mat-checkbox formControlName="agree">I agree to the Apptunix<a><sup
                                        style="margin-right: 4px;">TM</sup></a>
                            </mat-checkbox>

                            <a (click)="goToCmsPages('/cms/privacy-policy')" class="mr-1 link-new"> Privacy Policy</a> and
                            <a (click)="goToCmsPages('/cms/service-delivery-contracts')" class="ml-1 link-new"> Service Delivery Contract.</a>
                        </div>
                        <mat-error *ngIf="submitted && deliveryPartnerForm.controls['agree'].invalid">Checkbox
                            is required.</mat-error>
                        <div class="flex submit_btn mt-4">
                            <button class="btn btn-primary" (click)="goToVerificationPopup()">
                                Sign Up
                            </button>
                        </div>

                        <p class="dont_account">
                            Already have an account?
                            <a (click)="goToriderlogin()">login</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="login_images"></div> -->
</div>



<div id="verification" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <form [formGroup]="verificationTypeForm">
                <div class="modal-body">
                    <h4>Let's verify your information</h4>
                    <p>Security and safety are very important for us.</p>
                    <div class="rb">
                        <mat-radio-group aria-label="Select an option" formControlName="verifyBy">
                            <mat-radio-button value="email">{{email}}</mat-radio-button>
                            <mat-radio-button value="phone" [checked]="true">{{phoneNo}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="flex signup_btn">
                        <button class="btn btn-primary" (click)="goToriderotppage()">
                            Continue
                        </button>
                    </div>
                </div>
            </form>

        </div>

    </div>
</div>