<div class="profilesetup_wrap">
  <div class="signupinner">
    <div class="conta_iner">
      <div class="flex">
        <div class="haeder_left">
          <figure class="logo_cm" (click)="goTouhomepage()">
            <img src="assets/uimages/logo.png" />
          </figure>
        </div>
        <div class="back_home">
          <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
              Back To Home
          </button>
      </div>
      </div>
    </div>
  </div>
  <div>
    <div class="conta_iner">
      <div class="profile_main">
        <div class="toggleprofile sides" (click)="toggle()" id="bt">
          <svg [class]="'ham hamRotate ham1 ' + (tgshow? '':'active')" viewBox="0 0 100 100" width="80"
            onclick="this.classList.toggle('active')">
            <path class="line top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
            <path class="line middle" d="m 30,50 h 40" />
            <path class="line bottom"
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
          </svg>
        </div>
        <div class="prfile_left bg sides2" [ngClass]="tgshow ? 'hdiv' : 'sdiv'">
          <ul class="nav nav-justified nav-tabs" id="justifiedTab" role="tablist">
            <li class="nav-item first" (click)="continue(0)">
              <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab" href="#profile"
                id="home-tab" role="tab">
                <div class="user_profile">
                  <div class="user_profile_img">
                    <figure>
                      <img *ngIf="commonService?.userUpdateImage" [src]="baseUrl+commonService?.userUpdateImage" />
                      <img *ngIf="!commonService?.userUpdateImage" src="assets/images/user.png" />
                    </figure>
                  </div>
                  <div class="profile_camera1">
                    <h4>{{commonService?.loggedInUser?.firstName + ' ' + commonService?.loggedInUser?.lastName}}</h4>
                    <div class="driver_rating mrgn-l">
                      <figure>
                        <img src="assets/uimages/rate_icon.svg" alt="" />
                      </figure>
                      <p class="avg-rtng">{{commonService?.loggedInUser?.avgRating}}</p>
                    </div>
                  </div>
                </div>
              </a>
          </ul>
          <ul class="ul2">
            <li class="nav-item" *ngFor="let data of commonService?.linkArr; let i = index"
              (click)="data?.name === 'Logout' ? Alert(i,'Are you sure you want to logout?','logout') : toggleClick(i,data)">
              <a [ngClass]="{'nav-link active': selectedItem === i, 'nav-link': selectedItem != i, 'gray': (data.name === 'Payment' || data.name === 'Loyalty Points' || data.name === 'Notification' || data.name === 'Wallet')}"
                [ngStyle]="{'cursor': (data.name !== 'Payment' && data.name !== 'Loyalty Points' && data.name !== 'Notification' && data.name !== 'Wallet') ? 'pointer' : 'not-allowed'}">{{data?.name}}</a>
            </li>
          </ul>
        </div>
        <div class="profile_right">
          <div class="tab-content" id="justifiedTabContent">
            <div *ngIf="nextPopup === 0">
              <div class="profile_content">
                <form [formGroup]="profileForm">
                  <div class="user_profile">
                    <div class="user_profile_img">
                      <figure>
                        <img *ngIf="!commonService?.userUpdateImage" src="assets/images/user.png" />
                        <img *ngIf="commonService?.userUpdateImage" [src]="baseUrl+commonService?.userUpdateImage" />
                      </figure>
                    </div>
                    <div class="profile_camera" *ngIf="editable">
                      <figure>
                        <img src="assets/images/editpencil.svg" />
                      </figure>
                      <input type="file" #fileUpload formControlName="proImage" (change)="onFileUpload($event)"
                        id="file-input" class="upload_file" />
                    </div>
                  </div>
                  <ul class="formn_group_main">
                    <li>
                      <label>First Name</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'" *ngIf="editable">
                          <input matInput class="form-control" maxlength="50" (change)="onChangeFirst($event.target.value)" (keypress)="onKeyPress($event)" formControlName="firstName" type="text"
                            placeholder="First name" name="name" />
                          <mat-error *ngIf="submitted && validateFirstArr.includes(true) && !profileForm.controls['firstName'].hasError('required')">Please
                            enter valid first name.</mat-error>
                          <mat-error *ngIf="submitted && profileForm.controls['firstName'].hasError('required')">First
                            name is required.</mat-error>
                        </mat-form-field>
                        <span *ngIf="!editable" class="form-control">{{commonService?.loggedInUser?.firstName}}</span>
                      </div>
                    </li>
                    <li>
                      <label>Last Name</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'" *ngIf="editable">
                          <input matInput class="form-control" maxlength="50"  (change)="onChangeSecond($event.target.value)" (keypress)="onKeyPress($event)" formControlName="lastName" type="text"
                            placeholder="Last name" name="name" />
                          <mat-error *ngIf="submitted && validateSecondArr.includes(true) && !profileForm.controls['lastName'].hasError('required')">Please
                            enter valid last name.</mat-error>
                          <mat-error *ngIf="submitted && profileForm.controls['lastName'].hasError('required')">Last
                            name is required.</mat-error>
                        </mat-form-field>
                        <span *ngIf="!editable" class="form-control">{{commonService?.loggedInUser?.lastName}}</span>
                      </div>
                    </li>
                    <!-- <li>
                      <label>Gender</label>
                      <div class="form-group">
                        <mat-form-field *ngIf="editable">
                          <mat-label class="grey_color">Gender</mat-label>
                          <mat-select [(value)]="selected" formControlName="gender" class="form-control">
                            <mat-option value="MALE">Male</mat-option>
                            <mat-option value="FEMALE">Female</mat-option>
                            <mat-option value="NO_PREFRENCE">Other</mat-option>
                          </mat-select>
                          <mat-error *ngIf="submitted && profileForm.controls['gender'].hasError('required')">Gender is required.</mat-error>
                        </mat-form-field>
                        <span *ngIf="!editable" class="form-control">{{user?.gender}}</span>
                      </div>
                    </li> -->
                    <li>
                      <label>Email</label>
                      <span class="vrfd second" *ngIf="isEamilVerified === true"><i class="fa fa-check-circle" aria-hidden="true"></i> Verified</span>
                      <span class="unvrfd" *ngIf="isEamilVerified === false && !editable"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Verify Now</span>
                      <span class="unvrfd" style="cursor: pointer;" (click)="verifyEmail()" *ngIf="isEamilVerified === false && editable"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Verify Now</span>
                      <div class="form-group emailWd">
                        <mat-form-field [floatLabel]="'never'" *ngIf="editable">
                          <input class="form-control emailW" matInput formControlName="email" type="email"
                            placeholder="Email id" name="name" [readonly]="commonService.loggedInUser.isSocialEmail"/>
                          <mat-error class="sorry-error" *ngIf="submitted && profileForm.controls['email'].hasError('pattern')">Sorry, only
                            letters (a-z), number (0-9), and periods (.) are allowed.</mat-error>
                          <mat-error *ngIf="submitted && profileForm.controls['email'].hasError('required')">Email.is
                            required.</mat-error>
                          </mat-form-field>
                          <mat-error *ngIf="submitted && isEmailExist && !profileForm.controls['email'].hasError('required') && !profileForm.controls['email'].hasError('pattern')">Email already exist.</mat-error>
                          <mat-error *ngIf="submitted && !isEmailExist && !isEamilVerified && !profileForm.controls['email'].hasError('required') && !profileForm.controls['email'].hasError('pattern')">Please verify your email.</mat-error>
                        <span *ngIf="!editable" class="form-control">{{commonService?.loggedInUser?.email}}</span>
                      </div>
                    </li>
                    <li>
                      <label>Phone Number</label>
                      <span class="vrfd" *ngIf="isPhoneVerified === true"><i class="fa fa-check-circle" aria-hidden="true"></i> Verified</span>
                      <span class="unvrfd" *ngIf="isPhoneVerified === false && !editable"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Verify Now</span>
                      <span class="unvrfd" style="cursor: pointer;" (click)="verifyPhone()" *ngIf="isPhoneVerified === false && editable"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Verify Now</span>
                      <div class="form-group phoneNo">
                        <div *ngIf="editable">
                          <!-- <mat-form-field [floatLabel]="'never'" > -->
                          <ngx-intl-tel-input class="form-control" [inputId]="'adsasd'" [cssClass]="'custom'"
                            [preferredCountries]="preferredCountries"
                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                            [enableAutoCountrySelect]="true" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                            [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                            [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                          </ngx-intl-tel-input>
                          <mat-error *ngIf="submitted && profileForm.controls['phoneNo'].hasError('required')">
                            Phone Number is required.</mat-error>
                          <mat-error
                            *ngIf="submitted && profileForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                            Please Enter Valid Phone Number.</mat-error>
                          <mat-error
                            *ngIf="submitted && isPhoneExist && !profileForm.controls['phoneNo'].hasError('validatePhoneNumber') && !profileForm.controls['phoneNo'].hasError('required')">
                            Phone number already exist.</mat-error>
                            <mat-error *ngIf="submitted && !isPhoneExist && !isPhoneVerified 
                            && !profileForm.controls['phoneNo'].hasError('required')
                            && !profileForm.controls['phoneNo'].hasError('validatePhoneNumber')">Please verify your phone number.</mat-error>
                          <!-- <input class="form-control" matInput formControlName="phoneNo" type="number"
                            placeholder="Phone number" name="name" />
                          <mat-error *ngIf="submitted && profileForm.controls['phoneNo'].hasError('required')">Phone number is required.</mat-error>
                          <mat-error *ngIf="submitted && profileForm.controls['phoneNo'].hasError('minLength')">Please enter valid number.</mat-error> -->
                          <!-- </mat-form-field> -->
                        </div>
                        <span *ngIf="!editable" class="form-control">{{commonService?.loggedInUser?.countryCode+' '+commonService?.loggedInUser?.phoneNo}}</span>
                      </div>
                    </li>
                  </ul>
                  <div class="commen_btn_outer">
                    <button class="btn btn-primary" *ngIf="!editable" (click)="edit()">Edit</button>
                    <button class="btn btn-primary" *ngIf="editable" (click)="verification()">Update</button>
                    <button class="btn btn-default canc-btn" *ngIf="editable" (click)="cancel()">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
            <div *ngIf="nextPopup === 1">
              <div class="orderdetail_content">
                <mat-tab-group (selectedTabChange)="onTabChange('RIDE',$event)">
                  <mat-tab label="Ongoing">
                    <div *ngIf="selectedTabIndex === 0 && bookingDataUpcomingRide.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let upcoming of bookingDataUpcomingRide">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left" *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED || 
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING">
                              <figure *ngIf="upcoming?.bookingStatus != constant?.BOOKING_STATUS?.PENDING">
                                <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl+upcoming?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right">
                              <!-- //abc -->
                              <div *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED || 
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING">
                                <h4>{{upcoming?.driverId?.firstName}} {{upcoming?.driverId?.lastName}}</h4>
                                <!-- <div class="driver_rating">
                                  <figure>
                                    <img src="assets/uimages/rate_icon.svg" alt="" />
                                  </figure>
                                  <p>{{upcoming?.driverId?.avgRating}}</p>
                                </div> -->
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>Final Cost <span>$ {{upcoming?.booKingAmount}}</span></p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>Date/Time<span>{{upcoming?.bookingDate | date: 'd/MM/yy, h:mm a'}}</span></p>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PENDING || upcoming?.bookingStatus === constant?.BOOKING_STATUS?.SCHEDULED
                              || upcoming?.bookingStatus === constant?.BOOKING_STATUS?.UPCOMING">
                                <h4>Driver not assigned.</h4>
                              </div>
                            </div>
                            <!-- <p class="complete" *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED">
                              ONGOING</p> -->
                            <!-- <p class="complete"
                              *ngIf="upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.STARTED && upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.PENDING">
                              {{constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus]}}</p> -->
                            <p class="complete-status">{{constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus]}}</p>
                          </div>
                          <div class="order_detail_conten flex" style="cursor: pointer;">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl+upcoming?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(upcoming,'RIDE')" class="view-booking-dtl">
                              <p>View Booking Details</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onPrevPage('RIDE','totalPagesUpcomingRide','currentPageUpcomingRide', 3)">
                              <i></i>
                              <span>Previous</span>
                            </a>
                          </li>
                          <li *ngFor="let a of [].constructor(totalPagesUpcomingRide);let i = index;"
                            class="{{currentPageUpcomingRide === i+1 ? 'page-item active' : 'page-item' }}">
                            <a class="page-link"
                              *ngIf="currentPageUpcomingRide < (i+1) ? ((i+1 - currentPageUpcomingRide) < 3) : ((currentPageUpcomingRide - (i+1)) < 3)"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i+1,'RIDE','totalPagesUpcomingRide','currentPageUpcomingRide', 3)">{{i+1}}</a>
                          </li>
                          <li class="{{nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onNextPage('RIDE','totalPagesUpcomingRide','currentPageUpcomingRide', 3)">
                              <i></i>
                              <span>Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataUpcomingRide.length === 0" class="del-main">
                      <h3>No data found</h3>
                    </div>
                  </mat-tab>

                  <mat-tab label="Completed">
                    <div *ngIf="selectedTabIndex === 1 && bookingDataCompletedRide.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let completed of bookingDataCompletedRide">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left">
                              <figure>
                                <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="completed?.driverId?.image" [src]="baseUrl+completed?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="">
                                <h4>{{completed?.driverId?.firstName +' '+completed?.driverId?.lastName}}</h4>
                                <ul class="flex orders_star">
                                  <li *ngIf="completed?.driverRating > 0">
                                    <i class="fa fa-star" aria-hidden="true"><span
                                        class="avg-rtng">{{completed?.driverRating}}</span></i>
                                  </li>
                                </ul>
                                <h4 class="blue_color">
                                  {{completed?.vehicleId?.vehicleModel}} - {{completed?.vehicleId?.licensePlateNumber}}
                                  <!-- <span>(white)</span> -->
                                </h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>Final Cost <span>$ {{completed?.booKingAmount}}</span></p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>Date/Time<span>{{completed?.bookingDate | date: 'd/MM/yy, h:mm a'}}</span></p>
                                  </div>
                                </div>
                              </div>
                              <button *ngIf="completed?.driverRating === 0" class="complete rate_now"
                                (click)="openRatingModal(completed)">RATE NOW</button>
                              <button class="complete">COMPLETED</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="completed?.driverId?.image" [src]="baseUrl+completed?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(completed,'RIDE')" class="view-booking-dtl">
                              <p>View Booking Details</p>
                            </div>
                            <!-- <div class="order_detail_right">
                              <div class="start_trip_text">
                                <div class="mm">
                                  <div class="blm">
                                    <div class="bb"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.pickUpAddress}}</p>
                                  </div>
                                </div>

                                <div class="mm">
                                  <div class="blm">
                                    <div class="blc"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.dropUpAddress}}</p>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onPrevPage('RIDE','totalPagesCompletedRide','currentPageCompletedRide', 1)">
                              <i></i>
                              <span>Previous</span>
                            </a>
                          </li>
                          <li *ngFor="let a of [].constructor(totalPagesCompletedRide);let i = index;"
                            class="{{currentPageCompletedRide === i+1 ? 'page-item active' : 'page-item' }}">
                            <a class="page-link"
                              *ngIf="currentPageCompletedRide < (i+1) ? ((i+1 - currentPageCompletedRide) < 3) : ((currentPageCompletedRide - (i+1)) < 3)"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i+1,'RIDE','totalPagesCompletedRide','currentPageCompletedRide', 1)">{{i+1}}</a>
                          </li>
                          <li class="{{nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onNextPage('RIDE','totalPagesCompletedRide','currentPageCompletedRide', 1)">
                              <i></i>
                              <span>Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCompletedRide.length === 0" class="del-main">
                      <h3>No data found</h3>
                    </div>
                  </mat-tab>
                  <mat-tab label="Canceled">
                    <div *ngIf="selectedTabIndex === 2 && bookingDataCanceledRide.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let calceled of bookingDataCanceledRide">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left" *ngIf="calceled.driverId">
                              <figure>
                                <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="calceled?.driverId?.image" [src]="baseUrl+calceled?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="" *ngIf="calceled.driverId">
                                <h4>{{calceled?.driverId?.firstName +' '+calceled?.driverId?.lastName}}</h4>
                                <ul class="flex orders_star">
                                  <!-- <li>
                                    <i class="fa fa-star" aria-hidden="true"><span
                                        class="avg-rtng">{{calceled?.driverId?.avgRating}}</span></i>
                                  </li> -->
                                </ul>
                                <h4 class="blue_color">
                                  {{calceled?.vehicleId?.vehicleModel}} - {{calceled?.vehicleId?.licensePlateNumber}}
                                  <!-- <span>(white)</span> -->
                                </h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>Final Cost <span>$ 0.0</span></p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>Date/Time<span>{{calceled?.bookingDate | date: 'd/MM/yy, h:mm a'}}</span></p>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="!calceled.driverId">
                                <h4>Driver not assigned.</h4>
                              </div>
                              <button class="cancel">CANCELED</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="calceled?.driverId?.image" [src]="baseUrl+calceled?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(calceled,'RIDE')" class="view-booking-dtl">
                              <p>View Booking Details</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onPrevPage('RIDE','totalPagesCanceledRide','currentPageCanceledRide', 2)">
                              <i></i>
                              <span>Previous</span>
                            </a>
                          </li>
                          <li *ngFor="let a of [].constructor(totalPagesCanceledRide);let i = index;"
                            class="{{currentPageCanceledRide === i+1 ? 'page-item active' : 'page-item' }}">
                            <a class="page-link"
                              *ngIf="currentPageCanceledRide < (i+1) ? ((i+1 - currentPageCanceledRide) < 3) : ((currentPageCanceledRide - (i+1)) < 3)"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i+1,'RIDE','totalPagesCanceledRide','currentPageCanceledRide', 2)">{{i+1}}</a>
                          </li>
                          <li class="{{nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onNextPage('RIDE','totalPagesCanceledRide','currentPageCanceledRide', 2)">
                              <i></i>
                              <span>Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCanceledRide.length === 0" class="del-main">
                      <h3>No data found</h3>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>

            </div>

            <div *ngIf="nextPopup === 2">
              <div class="orderdetail_content">
                <mat-tab-group (selectedTabChange)="onTabChange('DELIVERY',$event)">

                  <mat-tab label="Ongoing">
                    <div *ngIf="selectedTabIndex === 0 && bookingDataUpcomingDelivery.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let upcoming of bookingDataUpcomingDelivery">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left" *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED || 
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                            upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING">
                              <figure *ngIf="upcoming?.bookingStatus != constant?.BOOKING_STATUS?.PENDING">
                                <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl+upcoming?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right">
                              <!-- //abc -->
                              <div *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED || 
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                              upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING">
                                <h4>{{upcoming?.driverId?.firstName}} {{upcoming?.driverId?.lastName}}</h4>
                                <!-- <div class="driver_rating">
                                  <figure>
                                    <img src="assets/uimages/rate_icon.svg" alt="" />
                                  </figure>
                                  <p>{{upcoming?.driverId?.avgRating}}</p>
                                </div> -->
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>Final Cost <span>$ {{upcoming?.booKingAmount}}</span></p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>Date/Time<span>{{upcoming?.bookingDate | date: 'd/MM/yy, h:mm a'}}</span></p>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PENDING || upcoming?.bookingStatus === constant?.BOOKING_STATUS?.SCHEDULED
                              || upcoming?.bookingStatus === constant?.BOOKING_STATUS?.UPCOMING">
                                <h4>Driver not assigned.</h4>
                              </div>
                            </div>
                            <!-- <p class="complete" *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED">
                              ONGOING</p> -->
                            <!-- <p class="complete"
                              *ngIf="upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.STARTED && upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.PENDING">
                              {{constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus]}}</p> -->
                            <p class="complete-status">{{constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus]}}</p>
                          </div>
                          <div class="order_detail_conten flex" style="cursor: pointer;">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl+upcoming?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(upcoming,'DELIVERY')" class="view-booking-dtl">
                              <p>View Booking Details</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onPrevPage('DELIVERY','totalPagesUpcomingDelivery','currentPageUpcomingDelivery', 3)">
                              <i></i>
                              <span>Previous</span>
                            </a>
                          </li>
                          <li *ngFor="let a of [].constructor(totalPagesUpcomingDelivery);let i = index;"
                            class="{{currentPageUpcomingDelivery === i+1 ? 'page-item active' : 'page-item' }}">
                            <a class="page-link"
                              *ngIf="currentPageUpcomingDelivery < (i+1) ? ((i+1 - currentPageUpcomingDelivery) < 3) : ((currentPageUpcomingDelivery - (i+1)) < 3)"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i+1,'Delivery','totalPagesUpcomingDelivery','currentPageUpcomingDelivery', 3)">{{i+1}}</a>
                          </li>
                          <li class="{{nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onNextPage('DELIVERY','totalPagesUpcomingDelivery','currentPageUpcomingDelivery', 3)">
                              <i></i>
                              <span>Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataUpcomingDelivery.length === 0" class="del-main">
                      <h3>No data found</h3>
                    </div>
                  </mat-tab>

                  <mat-tab label="Completed">
                    <div *ngIf="selectedTabIndex === 1 && bookingDataCompletedDelivery.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let completed of bookingDataCompletedDelivery">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left">
                              <figure>
                                <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="completed?.driverId?.image" [src]="baseUrl+completed?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="">
                                <h4>{{completed?.driverId?.firstName +' '+completed?.driverId?.lastName}}</h4>
                                <ul class="flex orders_star">
                                  <li *ngIf="completed?.driverRating > 0">
                                    <i class="fa fa-star" aria-hidden="true"><span
                                        class="avg-rtng">{{completed?.driverRating}}</span></i>
                                  </li>
                                </ul>
                                <h4 class="blue_color">
                                  {{completed?.vehicleId?.vehicleModel}} - {{completed?.vehicleId?.licensePlateNumber}}
                                  <!-- <span>(white)</span> -->
                                </h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>Final Cost <span>$ {{completed?.booKingAmount}}</span></p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>Date/Time<span>{{completed?.bookingDate | date: 'd/MM/yy, h:mm a'}}</span></p>
                                  </div>
                                </div>
                              </div>
                              <button *ngIf="completed?.driverRating === 0" class="complete rate_now"
                                (click)="openRatingModal(completed)">RATE NOW</button>
                              <button class="complete">COMPLETED</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="completed?.driverId?.image" [src]="baseUrl+completed?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(completed,'DELIVERY')" class="view-booking-dtl">
                              <p>View Booking Details</p>
                            </div>
                            <!-- <div class="order_detail_right">
                              <div class="start_trip_text">
                                <div class="mm">
                                  <div class="blm">
                                    <div class="bb"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.pickUpAddress}}</p>
                                  </div>
                                </div>

                                <div class="mm">
                                  <div class="blm">
                                    <div class="blc"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.dropUpAddress}}</p>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onPrevPage('DELIVERY','totalPagesCompletedDelivery','currentPageCompletedDelivery', 1)">
                              <i></i>
                              <span>Previous</span>
                            </a>
                          </li>
                          <li *ngFor="let a of [].constructor(totalPagesCompletedDelivery);let i = index;"
                            class="{{currentPageCompletedDelivery === i+1 ? 'page-item active' : 'page-item' }}">
                            <a class="page-link"
                              *ngIf="currentPageCompletedDelivery < (i+1) ? ((i+1 - currentPageCompletedDelivery) < 3) : ((currentPageCompletedDelivery - (i+1)) < 3)"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i+1,'DELIVERY','totalPagesCompletedDelivery','currentPageCompletedDelivery', 1)">{{i+1}}</a>
                          </li>
                          <li class="{{nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onNextPage('DELIVERY','totalPagesCompletedDelivery','currentPageCompletedDelivery', 1)">
                              <i></i>
                              <span>Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCompletedDelivery.length === 0" class="del-main">
                      <h3>No data found</h3>
                    </div>
                  </mat-tab>
                  <mat-tab label="Canceled">
                    <div *ngIf="selectedTabIndex === 2 && bookingDataCanceledDelivery.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let calceled of bookingDataCanceledDelivery">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left" *ngIf="calceled.driverId">
                              <figure>
                                <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="calceled?.driverId?.image" [src]="baseUrl+calceled?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="" *ngIf="calceled.driverId">
                                <h4>{{calceled?.driverId?.firstName +' '+calceled?.driverId?.lastName}}</h4>
                                <ul class="flex orders_star">
                                  <!-- <li>
                                    <i class="fa fa-star" aria-hidden="true"><span
                                        class="avg-rtng">{{calceled?.driverId?.avgRating}}</span></i>
                                  </li> -->
                                </ul>
                                <h4 class="blue_color">
                                  {{calceled?.vehicleId?.vehicleModel}} - {{calceled?.vehicleId?.licensePlateNumber}}
                                  <!-- <span>(white)</span> -->
                                </h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>Final Cost <span>$ 0.0</span></p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>Date/Time<span>{{calceled?.bookingDate | date: 'd/MM/yy, h:mm a'}}</span></p>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="!calceled.driverId">
                                <h4>Driver not assigned.</h4>
                              </div>
                              <button class="cancel">CANCELED</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="calceled?.driverId?.image" [src]="baseUrl+calceled?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(calceled,'DELIVERY')" class="view-booking-dtl">
                              <p>View Booking Details</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onPrevPage('DELIVERY','totalPagesCanceledDelivery','currentPageCanceledDelivery', 2)">
                              <i></i>
                              <span>Previous</span>
                            </a>
                          </li>
                          <li *ngFor="let a of [].constructor(totalPagesCanceledDelivery);let i = index;"
                            class="{{currentPageCanceledDelivery === i+1 ? 'page-item active' : 'page-item' }}">
                            <a class="page-link"
                              *ngIf="currentPageCanceledDelivery < (i+1) ? ((i+1 - currentPageCanceledDelivery) < 3) : ((currentPageCanceledDelivery - (i+1)) < 3)"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i+1,'DELIVERY','totalPagesCanceledDelivery','currentPageCanceledDelivery', 2)">{{i+1}}</a>
                          </li>
                          <li class="{{nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a class="page-link" href="javascript:void(0)"
                              (click)="onNextPage('DELIVERY','totalPagesCanceledDelivery','currentPageCanceledDelivery', 2)">
                              <i></i>
                              <span>Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCanceledDelivery.length === 0" class="del-main">
                      <h3>No data found</h3>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>

            <div *ngIf="nextPopup === 3">
              <div class="profile_content">
                <div class="title_bar">
                  <h2>Payment Options</h2>
                </div>
                <!-----start add payment sec-->
                <!-- <div class="loyality_points_bx">
                  <figure>
                    <img src="../../assets/uimages/loylity_points.png" alt="" />
                  </figure>
                  <h4>500.00 <span>Wallet Balance</span></h4>
                </div> -->
                <div class="wallent_main">
                  <figure><img src="assets/images/pay.svg" /></figure>
                  <h3><span>Wallet</span>$15.50</h3>
                  <a href="###" class="add_money">Add Money</a>
                </div>
                <div class="crediti_card">
                  <figure>
                    <img src="assets/uimages/credit_card.png" alt="" />
                  </figure>
                </div>
                <!----start various type card-->
                <div class="credit_card_type">
                  <div class="credit_card_item">
                    <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="1">
                        <div class="card_detailcs">
                          <h3>XXXX XXXX XXXX 2567<span></span></h3>
                          <figure>
                            <img src="assets/uimages/visa_icon.png" alt="" />
                          </figure>
                        </div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="commen_btn_outer">
                  <button class="btn btn-primary" data-toggle="modal" data-target="#add_payment">
                    Add New Card
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="nextPopup === 7">
              <div class="payment_content">
                <div class="title_bar">
                  <h2>Wallet</h2>
                </div>
                <!-- <div class="wallent_main">
                  <figure><img src="../../assets/images/pay.svg" /></figure>
                  <h3><span>Wallet</span>$15.50</h3>
                </div> -->
                <!-- <div class="loyality_points_bx">
                    <figure>
                        <img src="../../assets/uimages/loylity_points.png" alt="" />
                    </figure>
                    <h4>500.00 <span>Wallet Balance</span></h4>
                </div> -->
                <div class="wallent_main">
                  <figure><img src="assets/images/pay.svg" /></figure>
                  <h3><span>Wallet</span>$15.50</h3>
                </div>
                <div class="promo">
                  <div class="title_bar">
                    <h2>Loyalty points</h2>
                  </div>
                  <div class="promo_main flex">
                    <div class="promo_left">
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="text" placeholder="Enter Loyalty points" name="name" />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="promo_right">
                      <div class="driverap_btn"><button>Apply</button></div>
                    </div>
                  </div>
                </div>
                <ul class="all_promo">
                  <h4>All Loyalty ponits</h4>
                  <li class="borderbtm">
                    <div class="all_promo_main flex">
                      <div class="all_promo_left">
                        <h4><b>It is a long established fact that </b></h4>
                        <div class="title_bar">
                          <p>
                            It is a long established fact that a reader will be distracted by the readable content of a
                            page when looking at its layout.
                          </p>
                        </div>
                      </div>
                      <div class="all_promo_right">
                        <div class="driverap_btn inactive">
                          <button>Copy Loyalty points</button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="borderbtm">
                    <div class="all_promo_main flex">
                      <div class="all_promo_left">
                        <h4><b>It is a long established fact that </b></h4>
                        <div class="title_bar">
                          <p>
                            It is a long established fact that a reader will be distracted by the readable content of a
                            page when looking at its layout.
                          </p>
                        </div>
                      </div>
                      <div class="all_promo_right">
                        <div class="driverap_btn inactive">
                          <button>Copy Promo Code</button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div *ngIf="nextPopup === 8">
              <div class="profile_content">
                <div class="profile title_bar">
                  <h2>
                    Change Password <span>Manage your profile details</span>
                  </h2>
                </div>

                <form [formGroup]="changePassForm">
                  <ul class="">
                    <li>
                      <label>Current password</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput formControlName="password" class="form-control" type="password"
                            placeholder="Current password" name="name" />
                          <mat-error
                            *ngIf="changePassSubmitted && changePassForm.controls['password'].hasError('required')">
                            Current password
                            is required.</mat-error>
                        </mat-form-field>
                      </div>
                    </li>
                    <li>
                      <label> New Password</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput formControlName="newPassword" class="form-control" type="password"
                            placeholder="New Password" name="name" />
                          <mat-error
                            *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('required')">
                            New password is
                            required.</mat-error>
                          <mat-error
                            *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('minlength')">
                            New password
                            length must be 6 characters.</mat-error>
                        </mat-form-field>
                      </div>
                    </li>
                    <li>
                      <label>Confirm New Password</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput formControlName="confirmPassword" class="form-control" type="password"
                            placeholder="Confirm New Password" name="name" />
                          <mat-error
                            *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('required')">
                            Confirm new
                            password is required.</mat-error>
                          <mat-error
                            *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('mustMatch')">
                            Password
                            and confirm password must match.</mat-error>
                        </mat-form-field>
                      </div>
                    </li>
                  </ul>
                  <div class="commen_btn_outer">
                    <button class="btn btn-primary" (click)="changePassword()">Save</button>
                  </div>
                </form>
              </div>
            </div>
            <!-----start loyality points-->
            <div *ngIf="nextPopup === 4">
              <div class="profile title_bar">
                <h2>Loyalty Points</h2>
              </div>
              <div class="grey_line payment_grey_line"></div>
              <!-- <div class="loyality_points_bx">
                <figure>
                    <img src="../../assets/uimages/loylity_points.png" alt="" />
                </figure>
                <h4>500.00 <span>Loyalty Points</span></h4>
            </div> -->
              <div class="wallent_main">
                <figure><img src="assets/images/pay.svg" /></figure>
                <h3><span>Loyalty Points</span>$15.50</h3>
              </div>
              <!----start loyity breakfast package-->
              <div class="loyality_points_offer">
                <div class="loyality_offer_item">
                  <div class="loyality_offer_img">
                    <figure>
                      <img src="assets/uimages/offer_img.png" alt="" />
                    </figure>
                  </div>
                  <div class="loyality_offer_disc">
                    <h3>Hot Breakfast<span>100 points</span></h3>
                    <p>Use between 8am and 11am.</p>
                    <div class="commen_btn_outer">
                      <button class="btn btn-primary" data-toggle="modal" data-target="#loyality_offer_popup">
                        Redeem
                      </button>
                    </div>
                  </div>
                </div>
                <div class="loyality_offer_item">
                  <div class="loyality_offer_img">
                    <figure>
                      <img src="assets/uimages/offer_img.png" alt="" />
                    </figure>
                  </div>
                  <div class="loyality_offer_disc">
                    <h3>Hot Breakfast<span>100 points</span></h3>
                    <p>Use between 8am and 11am.</p>
                    <div class="commen_btn_outer">
                      <button class="btn btn-primary" data-toggle="modal" data-target="#loyality_offer_popup">
                        Redeem
                      </button>
                    </div>
                  </div>
                </div>
                <div class="loyality_offer_item">
                  <div class="loyality_offer_img">
                    <figure>
                      <img src="assets/uimages/offer_img.png" alt="" />
                    </figure>
                  </div>
                  <div class="loyality_offer_disc">
                    <h3>Hot Breakfast<span>100 points</span></h3>
                    <p>Use between 8am and 11am.</p>
                    <div class="commen_btn_outer">
                      <button class="btn btn-primary" data-toggle="modal" data-target="#loyality_offer_popup">
                        Redeem
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!---end loyality ponits-->
            <!---start notification sec-->
            <div *ngIf="nextPopup === 5">
              <div class="profile_content">
                <h3 class="notification_title">Notification</h3>
                <div class="recent_notification_sec">
                  <span class="recent_notifi_title">Recent</span>
                  <div class="notification_item">
                    <figure>
                      <img src="assets/uimages/driver_icon.png" alt="" />
                    </figure>
                    <p>You Driver is on the Way</p>
                  </div>
                  <div class="notification_item">
                    <figure>
                      <img src="assets/uimages/driver_icon.png" alt="" />
                    </figure>
                    <p>You Driver is on the Way</p>
                  </div>
                </div>

                <div class="recent_notification_sec older_notification">
                  <span class="recent_notifi_title">Older Notification</span>
                  <div class="notification_item">
                    <figure>
                      <img src="assets/uimages/driver_icon.png" alt="" />
                    </figure>
                    <p>You Driver is on the Way</p>
                  </div>
                  <div class="notification_item">
                    <figure>
                      <img src="assets/uimages/driver_icon.png" alt="" />
                    </figure>
                    <p>You Driver is on the Way</p>
                  </div>
                </div>
              </div>
            </div>
            <!----end notification sec-->
            <!-----start manage address sec-->
            <div *ngIf="nextPopup === 6">
              <div class="profile_content">
                <h3 class="notification_title">Manage Addressses</h3>
                <!-- <div class="manage_address_item">
                  <figure class="address_mapn">
                    <img src="assets/uimages/address_map.png" alt="" />
                  </figure>
                  <div class="manage_address_text">
                    <h4>Jhon</h4>
                    <p>123445, house no,Bulding Name</p>
                    <p>Road Name,Area Colony,</p>
                    <p>City,state,landmark</p>
                    <p>phone No :- <span>123456789</span></p>
                  </div>
                  <figure class="manage_addres_edit">
                    <img src="assets/uimages/address_edit.png" alt="" />
                  </figure>
                </div> -->
                <div class="del-main pstn">
                  <h3>No data found</h3>
                </div>
                <div class="commen_btn_outer">
                  <button class="btn btn-primary" (click)="openModal()">
                    <!-- data-toggle="modal" data-target="#add_address" -->
                    Add Address
                  </button>
                </div>
              </div>
            </div>


            <div *ngIf="nextPopup === 9">
              <div class="profile_content">
                <div class="title_bar">
                  <h2>Favourite Drivers</h2>
                </div>
                <div *ngIf="favouriteDriverData.length > 0">
                  <div class="del-main" *ngFor="let data of favouriteDriverData">
                    <div class="flex-in2">
                      <div class="del-l">
                        <figure>
                          <img *ngIf="!data?.driverId?.image" src="assets/images/man_user.jpg">
                          <img *ngIf="data?.driverId?.image" [src]="baseUrl+data?.driverId?.image">
                        </figure>
                      </div>
                      <div class="del-r sec">
                        <h5>{{data?.driverId?.firstName}} {{data?.driverId?.lastName}}</h5>
                        <div class="flex-m">
                          <div class="rate-l">
                            <h6>Rating <span>{{data?.driverId?.avgRating}}/5</span></h6>
                          </div>
                          <div class="rev-r">
                            <h6>Review <span>{{data?.driverId?.reviewTotal}}</span></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="heart">
                      <!-- <i class="fa fa-heart-o redcolor" *ngIf="!data?.driverId?.isFav" aria-hidden="true" (click)="favouriteDriver(data?.driverId?._id,true)"></i> -->
                      <i class="fa fa-heart redcolor" aria-hidden="true"
                        (click)="favouriteDriver(data?.driverId?._id,false)"></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="favouriteDriverData.length === 0" class="del-main">
                  <h3>No data found</h3>
                </div>



              </div>
            </div>
            <!----end manages address sec-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ratingModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="title_bar">
          <h2 class="arrving_time">Rate Your
            {{constant?.SHOW_BOOKING_TYPE[bookingResponseData?.bookingType] | titlecase}}</h2>
        </div>
        <div class="request_ride_inner border_remove">
          <div class="driver_info_bx">

            <div class="rate-screen">
              <!-- <h6>Your Delivery</h6> -->
            </div>
            <div class="rl">
              <div class="del-main">
                <div class="del-l">
                  <figure>
                    <img *ngIf="!driverReviewData?.image" src="assets/images/man_user.jpg">
                    <img *ngIf="driverReviewData?.image" [src]="baseUrl+driverReviewData?.image">
                  </figure>
                </div>
                <div class="main-c">
                  <div class="del-r del-u">
                    <h5>{{driverReviewData?.firstName +' '+driverReviewData?.lastName}}</h5>
                  </div>
                  <div class="driver_rate_pop del-b">
                    <p>Rating<span>{{driverReviewData?.avgRating}}/5</span></p>
                    <p>Review<span>{{driverReviewData?.reviewTotal}}</span></p>
                  </div>
                </div>
              </div>
              <div class="heart">
                <i (click)="addFavouriteDriver()" *ngIf="!driverReviewData?.isFav" class="fa fa-heart-o redcolor"
                  aria-hidden="true"></i>
                <i (click)="removeFavouriteDriver()" *ngIf="driverReviewData?.isFav" class="fa fa-heart redcolor"
                  aria-hidden="true"></i>
              </div>
            </div>
            <div class="rating">
              <p class="title">Rate</p>
              <div class="star-rating">
                <button mat-icon-button *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
                  (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                  <mat-icon>
                    {{showIcon(i)}}
                  </mat-icon>
                </button>
              </div>
              <p class="rating-err" *ngIf="ratingSubmitted && rating === 0">Please give rating.
              </p>
            </div>
            <ul class="bages" *ngIf="rating > 2">
              <li [ngClass]="selectedDriverReview === i ? 'badge badge-secondary active' : 'badge badge-secondary'"
                *ngFor="let data of reviewData; let i = index" (click)="selectReview(i,data)">{{data?.name}}
              </li>
              <!-- <li [ngClass]="{'badge badge-secondary': data?.isActive === false,'badge badge-secondary active': data?.isActive === true}" *ngFor="let data of reviewData">{{data?.name}}</li> -->
            </ul>
            <div class="fgi sec" *ngIf="rating <= 2 && rating > 0">
              <textarea id="w3review" class="from-control" placeholder="Enter your feedback here..."
                [formControl]="review"></textarea>
            </div>
            <p class="rating-err" *ngIf="ratingSubmitted && selectedReviewData === '' && rating > 0">Please give
              feedback.</p>
          </div>
          <div class="tip-title">
            <p>Add a tip</p>
          </div>
          <div class="bb-hldr">
            <div [ngClass]="selectedTip === i ? 'bbxx active' : 'bbxx'" *ngFor="let item of dummy; let i = index"
              (click)="selectTip(i,item?.amount)">
              {{'$ ' + item?.amount}}</div>
          </div>
          <div class="custom-tip">
            <mat-form-field class="example-full-width">
              <input class="tip-input" type="number" [formControl]="customRate" matInput
                placeholder="Enter custom amount.">
            </mat-form-field>
          </div>
          <div class="feedback-links">
            <h5 class="fdd-links">Feedback Links</h5>
            <h6 class="dd">Driver</h6>
            <ul>
              <li>
                Was the driver on time?
                <div class="tp">
                  <mat-radio-group aria-label="Select an option" [formControl]="wasTheDriverOnTime">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                </div>
              </li>
              <li>
                Did the driver make your ride comfortabel?
                <div class="tp">
                  <mat-radio-group aria-label="Select an option" [formControl]="driverMakeYouComfortabel">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                </div>
              </li>
              <li>
                Was the ride safe?
                <div class="tp">
                  <mat-radio-group aria-label="Select an option" [formControl]="wasTheRideSafe">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                </div>
              </li>
              <li class="imp">
                What can we improve for next time?
                <div class="tp">
                  <mat-form-field class="example-full-width">

                    <textarea matInput [formControl]="comment" placeholder="Comment"></textarea>
                  </mat-form-field>
                </div>
              </li>
            </ul>
          </div>
          <div class="bk-hm">
            <button class="btn btn-primary first-btn" (click)="addRating(0)">Submit</button>
            <!-- <button class="btn btn-outline second-btn" (click)="closeratingModal()" >Skip</button> -->
          </div>
        </div>
      </div>
      <!-- <div class="commen_btn_outer">
        <button class="btn btn-primary">Save</button>
      </div> -->
    </div>
  </div>
</div>

<!-- ///verify email dialog -->
<div class="modal fade" id="verifyEmail" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="verify-email">
      <figure><img src="assets/images/verify_email_logo.png"></figure>
      <h3>Verifying your email</h3>
      <p>Please check your email account for <b>{{commonService?.emailForVerification}}</b> and click the "verify link
        inside".</p>
      <div class="btn_outer">
        <button class="btn btn-primary margin_top20">
          Next
        </button>
        <button class="blue-c">Resend</button>
      </div>
    </div>
  </div>
</div>

<!-- ///verify phone dialog -->
<div class="modal fade" id="verifyPhone" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="verify-email">
      <figure><img src="assets/images/verify_email_logo.png"></figure>
      <h3>Verifying your email</h3>
      <p>Please check your email account for <b>{{commonService?.emailForVerification}}</b> and click the "verify link
        inside".</p>
      <div class="btn_outer">
        <button class="btn btn-primary margin_top20">
          Next
        </button>
        <button class="blue-c">Resend</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="add_payment" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">Add a card</h3>
        <div class="card_card_form">
          <div class="add_card_input_itms">
            <input type="text" class="form-control" placeholder="card holder name" />
          </div>
          <div class="add_card_input_itms">
            <input type="text" class="form-control" placeholder="XXX XXX XXX " />
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>Month</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>year</option>
              <option>2015</option>
              <option>2016</option>
              <option>2017</option>
              <option>2018</option>
              <option>2019</option>
              <option>2020</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="CCV" />
          </div>
        </div>
      </div>
      <div class="commen_btn_outer">
        <button class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>

<!-----start loyality package detail-->
<div class="modal fade loyality_offer_popup" id="loyality_offer_popup" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3>Hot Breakfast</h3>
        <div class="loyality_offer_item">
          <div class="loyality_offer_img">
            <figure>
              <img src="assets/uimages/offer_img.png" alt="" />
            </figure>
          </div>
          <div class="loyality_offer_disc">
            <table>
              <tr>
                <td>Total Points</td>
                <td>300.00</td>
              </tr>
              <tr>
                <td>Redeem Points</td>
                <td>300.00</td>
              </tr>
              <tr>
                <td>Remaining Points</td>
                <td>300.00</td>
              </tr>
            </table>
            <div class="commen_btn_outer">
              <button class="btn btn-primary">Redeem</button>
            </div>

            <!-------condition base show this sec-->
            <div class="loyality_congratulations">
              <h4>
                Congratulations !!!
                <span>Your Redeem has been Successfull</span>
              </h4>

              <div class="promo_offer_bx">
                <figure>
                  <img src="assets/uimages/promo_code_icon.png" alt="" />
                </figure>
                <h4>#1256edt556rf</h4>
              </div>
              <p>
                Show the above Coupon code to the payment counter to redeem.
              </p>
            </div>
            <!-------end condition base show this sec-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!----start booking detail popup-->

<div class="modal right fade profile_side_popup" id="myModal2" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel2">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
        <h4 class="modal-title" id="myModalLabel2"></h4>
      </div>

      <div class="modal-body">

        <div class="request_ride_inner">
          <div class="title_bar">
            <h2>Booking Details</h2>
          </div>
          <div class="driver_info" *ngIf="bookingsData?.bookingData?.driverId">
            <div class="driver_info_img">
              <figure>
                <img *ngIf="!bookingsData?.bookingData?.driverId?.image" src="assets/images/user.png" alt="" />
                <img *ngIf="bookingsData?.bookingData?.driverId?.image"
                  [src]="baseUrl+bookingsData?.bookingData?.driverId?.image" alt="" />
              </figure>
            </div>
            <div class="driver_info_text">
              <h3>{{bookingsData?.bookingData?.driverId?.firstName}} {{bookingsData?.bookingData?.driverId?.lastName}}
              </h3>
              <!-- <figure>
                <img src="assets/uimages/star_rateing.png" alt="" />
              </figure> -->
              <span [hidden]="bookingsData?.bookingData?.driverRating === 0"><i class="fa fa-star"
                  aria-hidden="true"></i><span class="rtng">{{bookingsData?.bookingData?.driverRating}}</span></span>
              <p>{{bookingsData?.bookingData?.vehicleId?.vehicleModel}}:
                <span>{{bookingsData?.bookingData?.vehicleId?.licensePlateNumber}}</span></p>
            </div>
          </div>
          <div class="no-driver" *ngIf="!bookingsData?.bookingData?.driverId">
            <h4>Driver not assigned.</h4>
          </div>
          <!-- <div class="no-driver" *ngIf="selectedTabIndex !== 2 && 
          (bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.PENDING || 
          bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.CANCELED ||
          bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.UPCOMING ||
          bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.SCHEDULED
          )">
            <h4>Driver not assigned.</h4>
          </div> -->
          <div class="hdr-outr">
            <div class="trip_info">
              <p>Type of trip: {{bookingsData?.bookingData?.isSheduledBooking ? 'Later' : 'Now'}}</p>
              <p>{{bookingsData?.bookingData?.bookingDate | date: 'd/MM/yy, h:mm a'}}</p>
            </div>
            <div class="trck-now" *ngIf="selectedTabIndex === 0 
            && (bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED || 
            bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
            bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
            bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING ||
            bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
            (bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.PENDING && !bookingsData?.bookingData?.isSheduledBooking)
            )">
              <p (click)="goToStatusPageOfRide(bookingsData)">Track Now</p>
            </div>
          </div>
          <div class="start_trip_text">
            <div class="mm" *ngFor="let pick of bookingsData?.subBookingData">
              <div *ngIf="pick?.isPickUp">
                <div class="blm">
                  <div class="bb"></div>
                </div>
                <div class="mkl">
                  <p>{{pick?.address}}</p>
                </div>
              </div>

              <div class="stts">
                <p *ngIf="pick?.isPickUp && pick?.bookingStatus === constant.BOOKING_STATUS.PICKED">
                  {{constant.SHOW_STATUS_BOOKING[pick?.bookingStatus]}}</p>
              </div>

            </div>

            <div class="mm" *ngFor="let drop of bookingsData?.subBookingData">
              <div *ngIf="!drop?.isPickUp">
                <div class="blm">
                  <div class="blc"></div>
                </div>
                <div class="mkl">
                  <p>{{drop?.address}}</p>
                </div>
              </div>
              <div>
                <p class="stts" *!drop?.isPickUp && ngIf="drop?.bookingStatus === constant.BOOKING_STATUS.DROPED">
                  {{constant.SHOW_STATUS_BOOKING[drop?.bookingStatus]}}</p>
              </div>
            </div>
          </div>
          <div class="driver_name_info">
            <p>{{bookingsData?.bookingData?.userId?.firstName}} {{bookingsData?.bookingData?.userId?.lastName}}</p>
            <p>{{bookingsData?.bookingData?.userId?.countryCode}} {{bookingsData?.bookingData?.userId?.phoneNo}}</p>
          </div>
          <div class="total_pay">
            <h5>${{bookingsData?.bookingData?.booKingAmount}}<span>incl. Tax</span></h5>
          </div>
          <div class="commen_btn_outer review_driver">
            <button class="btn btn-primary" (click)="closeBookingStatusModal()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="add_address" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">Add Address</h3>
        <div class="card_card_form">
          <div class="add_card_input_itms">
            <input type="text" class="form-control" placeholder="Street name" />
          </div>
          <div class="add_card_input_itms">
            <input type="text" class="form-control" placeholder="Location " />
          </div>
          <div class="add_card_input_itms">
            <input type="text" class="form-control" placeholder="Postal / zip code " />
          </div>
        </div>
      </div>
      <div class="commen_btn_outer">
        <button class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->

<div id="verification" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
          <form >
              <div class="modal-body">
                  <h4>Let's verify your information</h4>
                  <p>Security and safety are very important for us.</p>
                  <div class="rb">
                      <mat-radio-group aria-label="Select an option">
                          <mat-radio-button value="phone" [checked]="true">{{verificationData}}</mat-radio-button>
                      </mat-radio-group>
                  </div>
                  <div class="flex signup_btn">
                      <button class="btn btn-primary" (click)="sendOtp()">
                          Continue
                      </button>
                  </div>
              </div>
          </form>

      </div>

  </div>
</div>

<!-----end manage address popup-->