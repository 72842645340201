import { LocationStrategy } from "@angular/common";
import { AfterViewInit, Component, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ParentService } from "src/app/services/parent/parent.service";
import { Constant } from '../../prelaunch/core/constant';
import { CommonService } from '../../prelaunch/services/common/common.service';
import { HttpsService } from '../../prelaunch/services/https/https.service';

@Component({
  selector: "app-riderotppage",
  templateUrl: "./riderotppage.component.html",
  styleUrls: ["./riderotppage.component.scss"],
})
export class RiderotppageComponent implements OnInit, AfterViewInit, AfterViewChecked {
  otp: string;
  number: string;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  code: any;
  otpInput: any = '';
  errorMsg: string = '';
  phoneNo: string;
  countryCode: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpsService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    public constant: Constant,
    private location: LocationStrategy,
    private parentService: ParentService
  ) {
    this.route.params.subscribe(res => {
      this.number = res.id;
      console.log(res);
    });
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngAfterViewInit() {
    this.getOtp();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getOtp() {
    if (localStorage.phoneNo !== undefined && localStorage.countryCode !== undefined) {
      this.number = localStorage.phoneNo;
      this.code = localStorage.countryCode;
    } else {
      this.commonService.getVerificationData().subscribe((res: any) => {
        this.number = res.phoneNo;
        this.code = res.countryCode;
        this.otp = res.otpCode;
        // this.ngOtpInput.setValue(this.otp)
      })
    }
  }

  sendOTP() {
    if (localStorage.phone !== undefined) {
      this.number = localStorage.getItem('phone');
    }
    this.http.httpPostWithHeaderUser('sendOtp', { phoneNo: this.number }).subscribe((res: any) => {
      this.otp = res.data.otpCode;
      this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      this.ngOtpInput.setValue("");
    });
  }

  sendOTPWithAuth() {
    if (localStorage.phone !== undefined) {
      this.phoneNo = localStorage.getItem('phoneNo');
    }
    if (localStorage.countryCode !== undefined) {
      this.countryCode = localStorage.getItem('countryCode');
    }
    this.http.httpPostWithHeaderUser('sendOtpWithAuth', { countryCode: this.countryCode, phoneNo: this.phoneNo }).subscribe((res: any) => {
      this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      this.otp = res.data.otpCode;
      this.ngOtpInput.setValue("");
    });
  }

  ngOnInit(): void {
    if (localStorage.phone !== undefined) {
      this.number = localStorage.getItem('phone');
    }
    if (localStorage.isThisUpdatedUser !== undefined) {
      this.commonService.isThisUpdatedUser = JSON.parse(localStorage.getItem('isThisUpdatedUser'));
    }
    if (localStorage.userUpdateForm !== undefined) {
      this.commonService.userUpdateForm = JSON.parse(localStorage.getItem('userUpdateForm'));
    }
    if (localStorage.userType !== undefined) {
      this.commonService.isThisDeliveryPartner = localStorage.getItem('userType') === 'DELIVERY_PARTNER' ? true : false;
    }
    // this.parentService.getPrelaunchStatus.subscribe((prelaunch: any) => {
    //   this.commonService.isThisPreUser = prelaunch;
    // });
    this.preventBackButton();
  }

  onOtpChange(otp) {
    this.otpInput = otp;
  }
  goTouhomepage() {
    this.router.navigate(["/goTouhomepage"]);
  }

  verifyOtp() {
    if (this.otpInput.length < 4) {
      return;
    }
    let data = {
      otpCode: this.otpInput,
    }
    this.http.httpPostWithHeaderUser('verifyOtp', data).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        if (this.commonService.isThisDeliveryPartner && !this.commonService.isThisUpdatedUser && res.data.isUserDeleted) {
          // this.commonService.OpenNotificationModal('success', 'top-end', 'Your account has been created successfully.');
          // this.router.navigate(["/riderequest"]);
          // localStorage.clear();
          this.commonService.getUser().then(data => {
            if (data) {
              this.commonService.accRecover();
            }
          });
        } else if (this.commonService.isThisDeliveryPartner && this.commonService.isThisUpdatedUser) {
          this.verifyUpdate();
        } else if (this.commonService.isThisPreUser) {
          this.commonService.OpenNotificationModal('success', 'top-end', 'Your account has been created successfully.');
          this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_DELIVERY);
          localStorage.clear();
        } else if (this.commonService.isThisUpdatedUser) {
          this.verifyUpdate();
        } else if (res.data.isAccountCompleted && !this.commonService.isThisUpdatedUser && res.data.isUserDeleted) {
          this.commonService.getUser().then(data => {
            if (data) {
              this.commonService.accRecover();
            }
          })
        } else if (res.data.isAccountCompleted && !res.data.isDeleted && !res.data.isUserDeleted) {
          this.router.navigate(["/riderequest"]);
        } else {
          this.router.navigate(["/riderdetail"]);
        }
        var detail = {
          isAddressInfo: false,
          isNewUser: true,
          isAccountCompleted: false
        }
        this.commonService.setRiderProfileAccessDetail(detail);
        // this.commonService.openCongratsModal();
      }
    })
  }

  verifyUpdate() {
    // var formData = new FormData();
    // var codeLength = this.commonService.userUpdateForm.phoneNo.dialCode.length;
    // formData.append('isPhoneVerified', "true");
    // formData.append('phoneNo', this.commonService.userUpdateForm.phoneNo.e164Number.slice(codeLength));
    // formData.append('countryCode', this.commonService.userUpdateForm.phoneNo.dialCode);
    // this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe((res1: any) => {
    //   if (res1.statusCode === 200) {
    //     this.commonService.getUser().then(response => {
    //       if (response) {
    //         this.commonService.isPhoneVerified = true;
    //         this.router.navigate(["/profilesetup"]);
    //       }
    //     })
    //   }
    // });
    this.commonService.updateUser().then((respond: boolean) => {
      if (respond) {
        this.commonService.isPhoneVerified = true;
        this.router.navigate(["/profilesetup"]);
        localStorage.removeItem('isThisUpdatedUser');
      }
    })
  }

  updateProfile() {
    var formData = new FormData();
    formData.append('isPhoneVerified', 'true');
    this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe((res: any) => {
      if (res.statusCode === 200) {
      }
    });
  }

  resend() {
    this.sendOTPWithAuth();
  }
}
