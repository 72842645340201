
<div class="login_wrap top" >
    <div class="signupinner">
      <div class="conta_iner">
          <div class="flex">
              <div class="haeder_left">
                  <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
                      <img src="assets/uimages/logo.png" />
                  </figure>
              </div>
              <div class="back_home">
                  <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                      Back To Home
                  </button>
              </div>
          </div>
      </div>
  </div>
    <div class="login_sec">
       <div class="conta_iner">
          <div class="login_outer_cls">
             <div class="otp_img">
                <figure>
                   <img src="assets/uimages/rider_otp.svg" alt="" />
                </figure>
             </div>
             <div class="login_content_sec">
                <h3 class="verify_number">Change your password!</h3>
                <form [formGroup]="changePassForm">
                   <ul class="">
                     <li>
                       <label> New Password</label>
                       <div class="form-group">
                         <mat-form-field [floatLabel]="'never'">
                           <input matInput formControlName="newPassword" class="form-control" type="password"
                             placeholder="New Password" name="name" />
                           <mat-error
                             *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('required')">
                             New password is
                             required.</mat-error>
                           <mat-error
                             *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('minlength')">
                             New password
                             length must be 6 characters.</mat-error>
                         </mat-form-field>
                       </div>
                     </li>
                     <li>
                       <label>Confirm New Password</label>
                       <div class="form-group">
                         <mat-form-field [floatLabel]="'never'">
                           <input matInput formControlName="confirmPassword" class="form-control" type="password"
                             placeholder="Confirm New Password" name="name" />
                           <mat-error
                             *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('required')">
                             Confirm new
                             password is required.</mat-error>
                           <mat-error
                             *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('mustMatch')">
                             Password
                             and confirm password must match.</mat-error>
                         </mat-form-field>
                       </div>
                     </li>
                   </ul>
                   <div class="commen_btn_outer">
                     <button class="btn btn-primary" (click)="changePassword()">Save</button>
                   </div>
                 </form>
                <!-- <div class="commen_btn_outer">
                   <button class="btn btn-primary" (click)="verifyOtp()">
                      Verify
                   </button>
                </div> -->
                <p class="resend_opt">
                </p>
             </div>
          </div>
       </div>
    </div>
 </div>