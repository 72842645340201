import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginFComponent implements OnInit, AfterViewInit {

  loginForm: FormGroup;
  public phoneNo: FormControl = new FormControl(null, Validators.required);
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private httpService: HttpsService,
    private commonService: CommonService,
    private http: HttpsService,
    public dialog: MatDialog
  ) {
    this.loginForm = this.fb.group({
      phoneNo: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  checkUserLoggedIn() {
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.getItem('userType') === 'driver') {
        let param = new HttpParams().set('userId', localStorage.userId);
        this.http.httpGetWithHeader('getDriverProfile', param).subscribe((res: any) => {
          if (res.data.isPhoneVerified === true) {
            if (res.data.isUploadDocument && res.data.isAccountVerified) {
              // $("#app-download").modal('show');
              this.httpService.httpPostWithHeader('logout', "").subscribe((res: any) => {
                if (res.statusCode == 200) {
                  localStorage.clear();
                  this.router.navigate(['udriver']);
                }
              })
            } else {
              this.router.navigate(["/profile"]);
            }
          }
        })
      }
    }
  }



  submitLogin() {
    if (this.loginForm.invalid) {
      return;
    }
    var param = {
      userName: this.loginForm.value.phoneNo.toString(),
      password: this.loginForm.value.password,
      deviceType: 'WEB',
      deviceToken: new Date()
    }
    this.httpService.httpPost('login', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('userId', res.data.id);
        localStorage.setItem('userType', 'driver');
        var details = {
          isFilledBankDetail: res.data.isFilledBankDetail,
          isDriverInfo: res.data.isDriverInfo,
          isVehicleInfo: res.data.isVehicleInfo,
          isRegularCompliance: res.data.isRegularCompliance,
          isNewUser: false
        }
        this.commonService.setUserProfileAccessDetail(details);
        if (res.data.isPhoneVerified === true) {
          if (res.data.isUploadDocument && res.data.isAccountCompleted) {
            // this.dialog.open(DownloadApplicationComponent);
            this.httpService.httpPostWithHeader('logout', "").subscribe((res1: any) => {
              if (res1.statusCode == 200) {
                localStorage.clear();
                this.commonService.goToDownloadSection.next(true);
                this.router.navigate(['udriver']);
              }
            })
          } else if(res.data.zoneId) {
            this.commonService.driverAlert();
          } else {
            this.router.navigate(["/profile"]);
          }
        }
      }
    })
  }

  forgotPassword() {
    if (this.phoneNo.valid) {
      var codeLength = this.phoneNo.value.dialCode.length;
      var param = {
        phoneNo: this.phoneNo.value.e164Number.slice(codeLength),
        countryCode: this.phoneNo.value.dialCode
      }
      this.httpService.httpPost('forgotPassword', param).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          this.commonService.setVerificationData({ countryCode: res.data.countryCode, phoneNo: res.data.phoneNo, otpCode: res.data.otpCode });
          this.router.navigate(["/verification"]);
        }
      });
    }
  }

  goTouhomepage() {
    this.router.navigate(["uhomepage"]);
  }
  goTosignup() {
    this.router.navigate(["/signup"]);
  }
  goToriderequest() {
    this.router.navigate(["/riderequest"]);
  }
  goToforgetpassword() {
    this.router.navigate(["/forgetpassword"]);
  }
  // goTorideremail(){
  //   this.router.navigate(['/rideremail'])
  // }
}
