import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { CommonService } from '../../services/common/common.service';
import { HttpsService } from '../../services/https/https.service';

@Component({
  selector: 'app-number-modal',
  templateUrl: './number-modal.component.html',
  styleUrls: ['./number-modal.component.scss']
})
export class NumberModalComponent implements OnInit {

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India];
  isSubmitted: boolean = false;
  
  numberForm: FormGroup;
  isPhoneExist: boolean = false;

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<NumberModalComponent>,
    private commonService: CommonService,
    private http: HttpsService) { }

  ngOnInit(): void {
    this.numberForm = this.fb.group({
      phoneNo: ["", Validators.required],
    });
    this.numberForm.get('phoneNo').valueChanges.subscribe((value: any) => {
      this.isPhoneExist = false;
    });
  }

  submit() {
    this.isSubmitted = true;
    if(this.numberForm.valid) {
      var codeLength = this.numberForm.value.phoneNo.dialCode.length;
      var phoneNo = this.numberForm.value.phoneNo.e164Number.slice(codeLength);
      this.http.httpPostUser('checkIfPhoneNumberExist', { phoneNo: this.numberForm.value.phoneNo.e164Number.slice(codeLength)}).subscribe((value: any) => {
        if(value.data.isValid) {
          this.isPhoneExist = false;
          var countryCode = this.numberForm.value.phoneNo.dialCode;
          this.commonService.socialLogiNumber$.next({phoneNo: phoneNo,countryCode: countryCode});
          this.numberForm.reset();
          this.isSubmitted = false;
          this.close();
        } else {
          this.isPhoneExist = true;
        }
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

}
