import { Component, OnInit, NgModule, NgZone, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { HttpsService } from '../services/https/https.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { CommonService } from '../services/common/common.service';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { SocketioService } from '../services/sockets/socketio.service';
import { Event } from '../core/models/event';
import { Action } from '../core/models/action';
import Swal from 'sweetalert2';
import { takeWhile, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { element } from 'protractor';
import { LocationStrategy, ViewportScroller } from '@angular/common';
import { fConstant } from '../core/constant';
import { HttpClient } from "@angular/common/http";
declare var google: any;
declare var $: any;

@Component({
  selector: "app-riderequest",
  templateUrl: "./riderequest.component.html",
  styleUrls: ["./riderequest.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class RiderequestFComponent implements OnInit, OnDestroy {
  selected = "label";
  lat: number = 51.678418;
  lng: number = 7.809007;
  public map: any = { lat: 51.678418, lng: 7.809007 };
  nextPopup = 0;
  user: any;
  bookingForm: FormGroup;
  pickup = {
    lat: 0,
    lng: 0
  }
  pickupAddress = "";
  drop = {
    lat: 0,
    lng: 0
  }
  current = {
    lat: 0,
    lng: 0
  }
  dropAddress = "";
  geoCoder: any;
  zoom: number = 12;
  // origin: any;
  // destination: any;
  showPickMarker: boolean = true;
  showDropMarker: boolean = false;
  public renderOptions = {
    suppressMarkers: true,
  }
  originMarkerIcon: string = 'assets/uimages/originmarker.png';
  destinationMarkerIcon: string = 'assets/uimages/destination.png'
  markerOptions: any;
  // markerOptions = {
  //   origin: {
  //     icon: this.originMarkerIcon,
  //     label: this.pickupAddress,
  //   },
  //   destination: {
  //     icon: this.destinationMarkerIcon,
  //     label: this.dropAddress,
  //   },
  // }
  vehicleCategoryData: any = [];
  distance: any;
  rideSubmited = false;
  origin;
  destination;
  selectedItem = 0;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  driverNoteForm: FormGroup;
  DriverNoteFormSubmitted = false;
  selectedVehicleData: any;
  baseUrl: string;
  requestForLaterForm: FormGroup;
  mindate = new Date();
  bookingFor: string = 'Now';
  isDateTimeSubmit: boolean = false;
  activeBtn: string = 'Now';
  cancelCounter = 0;
  timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  distanceInKM: any;
  duration: any;
  driverAddress: any;
  driverLatLng: { lat: any; lng: any; };
  assignedDriverData: any;
  bookingId: any;
  showDirections: boolean = false;
  requestData: any;
  isDropChanged: boolean = false;
  isPickupChanged: boolean = false;
  nearByDriversData: any = [];
  bookingTimeInH: any;
  ubericon: string = "assets/images/ic_car.svg";
  isTollRoute: boolean = false;
  notifier = new Subject();
  bookingResponseData: any;
  //ratings
  @Input('rating') public rating: number = 0;
  @Input('starCount') public starCount: number = 5;
  @Input('color') private color: string = 'accent';
  @Output() private ratingUpdated = new EventEmitter();
  reviewData = [
    { name: 'Excellent', isActive: false },
    { name: 'Very Good', isActive: false },
    { name: 'Safe Driving', isActive: false },
    { name: 'Good', isActive: false },
    { name: 'Enjoy Full Ride', isActive: false }
  ];
  dummy: any[] = [
    { amount: 2, isActive: false },
    { amount: 5, isActive: false },
    { amount: 8, isActive: false },
    { amount: 10, isActive: false },
  ]
  selectedTip: number;

  private snackBarDuration: number = 2000;
  public ratingArr = [];
  isDriverFavourite: boolean = false;
  isFeedBackSelected: boolean = false;
  feedBackQue = [
    { que: 'Was the driver on time?' },
    { que: 'Did the driver make your ride comfortabel?' },
    { que: 'Was the ride safe?' }
  ]
  selectedFeedbckQna: any = [];
  wasTheDriverOnTime: FormControl = new FormControl(null);
  driverMakeYouComfortabel: FormControl = new FormControl(null);
  wasTheRideSafe: FormControl = new FormControl(null);
  selectedDriverReview: number;
  customRate: FormControl = new FormControl(null);
  selectedTipAmount: any;
  comment: FormControl = new FormControl(null);
  ratingSubmitted: boolean = false;
  selectedReviewData: any = '';
  driverReviewData: any;
  isDriveingStart: boolean = false;
  sportCar = "assets/images/sport-car.svg";
  driverIcon: any;
  isContactDetailHide: boolean = true;
  isThisOnlyTrackingPage: boolean = false;
  monitorForm: FormGroup;
  monitorSubmit: boolean = false;
  review: FormControl = new FormControl('');
  // driverIcon: any;
  intervalSubscription;
  pickupFieldsCount: number = 1;
  dropupFieldsCount: number = 1;
  pickupfieldsArr: any = [{ name: 'pickup1', lat: 0, lng: 0, address: '', distance: 0 }];
  dropupfieldsArr: any = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0 }];
  pickupForm: FormGroup;
  dropupForm: FormGroup;
  pickupFormObj: {};
  dropupFormObj: {};
  waypoints = [];
  distancesForPath: any = [];
  calculated: boolean = true;
  calculateSubscription: any;
  directionStatus: any;
  driverMessage: string;
  distanceDuration: any = "1 mins";
  driverDistance: number;
  isDriverLatLngUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDriverLatLngUpdated = this.isDriverLatLngUpdated$.asObservable();
  currentCountry: any = '';
  display: string = 'none';

  constructor(private router: Router,
    private http: HttpsService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ngxService: NgxUiLoaderService,
    private socketService: SocketioService,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private location: LocationStrategy,
    private vps: ViewportScroller,
    public constant: fConstant,
    private https: HttpClient
  ) {
    this.baseUrl = environment.imageUrl;
  }

  ngOnInit(): void {
    this.socketService.initSocket();
    this.getCountry().then((stats: any) => {
      console.log('stats',stats)
      if(stats.status === "success") {
        this.createForm(stats.countryCode);
      } else {
        this.createForm('CA');
      }
    });
    if (this.commonService.bookingResponseData) {
      if (this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.STARTED
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ONGOING
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT
        || (this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PENDING && !this.commonService.bookingResponseData.isSheduledBooking)
      ) {
        this.goToBookingStatus(this.commonService.bookingResponseData);
      }
      this.timerStart.next(false);
    }
    this.markerOptions = {
      origin: {
        icon: this.originMarkerIcon
      },
      destination: {
        icon: this.destinationMarkerIcon
      },
    }
    this.calculateSubscription = setInterval(() => {
      this.calculated = false;
      console.log('ok');
    }, 60000);
    this.preventBackButton();
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }

    // this.bookingForm = this.fb.group({
    //   ridePickUpAddress: [null, Validators.required],
    //   rideDropAddress: [null, Validators.required]
    // });
    this.driverNoteForm = this.fb.group({
      phoneNo: [''],
      name: [''],
      notes: [''],
    });
    this.monitorForm = this.fb.group({
      name: ['', Validators.required],
      phoneNo: ['', Validators.required],
    })
    this.requestForLaterForm = this.fb.group({
      dateTime: [null, Validators.required]
    })
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      // this.initializeMapPosition();
    });
    this.getUser();
    this.getCurrentLocation();
    this.timerStart.pipe(takeUntil(this.notifier)).subscribe((val: boolean) => {
      if (val) {
        this.cancelCounter = 10;
        if (this.intervalSubscription) {
          clearInterval(this.intervalSubscription);
          this.cancelCounter = 10;
        }
        this.intervalSubscription = setInterval(() => {
          if (this.cancelCounter <= 0) {
            clearInterval(this.intervalSubscription);
          }
          this.cancelCounter--;
        }, 1000);
      } else {
        this.cancelCounter = 0;
      }
    });

    this.customRate.valueChanges.subscribe((value: any) => {
      this.selectedTip = 10000;
    });
    this.listenSocketEvent();
    this.review.valueChanges.subscribe((value: any) => {
      if (value) {
        this.selectedReviewData = value;
      } else {
        this.selectedReviewData = '';
      }
    });
  }

  getCountry() {
    return new Promise((resolve, reject) => {
      this.https.get('https://extreme-ip-lookup.com/json/').subscribe((location: any) => {
        console.log('location',location);
        this.currentCountry = location.countryCode;
        return resolve(location);
      });
    })
  }

  createForm(country) {
    this.pickupfieldsArr = [{ name: 'pickup1', lat: 0, lng: 0, address: '', distance: 0, country: country }];
    this.dropupfieldsArr = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0, country: country }];
    console.log(this.dropupfieldsArr);
    this.pickupFormObj = {};
    this.pickupfieldsArr.forEach(element => {
      let key = element.name;
      this.pickupFormObj[key] = new FormControl('', Validators.required);
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
    this.pickupForm.get('pickup1').valueChanges.subscribe(val => {
      if (val === "") {
        this.pickupfieldsArr[0].lat = 0;
        this.pickupfieldsArr[0].lng = 0;
        this.pickupfieldsArr[0].address = '';
        this.pickupfieldsArr[0].distance = 0;
      }
    });
    this.dropupFormObj = {};
    this.dropupfieldsArr.forEach(element => {
      let key = element.name;
      this.dropupFormObj[key] = new FormControl('', Validators.required);
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
    this.getCurrentLocation();
    if(this.commonService.origin && this.commonService.destination) {
      if (this.commonService.origin.lat !== 0 && this.commonService.destination.lat !== 0) {
        this.pickupForm.get('pickup1').patchValue(this.commonService.origin.address);
        this.dropupForm.get('dropup1').patchValue(this.commonService.destination.address);
        this.pickupfieldsArr[0].lat = this.commonService.origin.lat;
        this.pickupfieldsArr[0].lng = this.commonService.origin.lng;
        this.dropupfieldsArr[0].lat = this.commonService.destination.lat;
        this.dropupfieldsArr[0].lng = this.commonService.destination.lng;
        this.pickupfieldsArr[0].address = this.commonService.origin.address;
        this.dropupfieldsArr[0].address = this.commonService.destination.address;
        this.drawPath(this.commonService.origin, this.commonService.destination, this.originMarkerIcon);
      }
    }
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  getNearbyDrivers(position) {
    var param = {
      "latitude": position.lat,
      "longitude": position.lng,
      "pageNo": 1
    }
    this.http.httpPostWithHeaderUser('getNearbyDriversWithGeoFenceId', param).pipe(takeUntil(this.notifier)).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.nearByDriversData = res.data.drivers;
      }
    });
  }

  getUser() {
    this.http.httpGetWithHeaderUser('userProfile', '').pipe(takeUntil(this.notifier)).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.user = res.data;
        if (res.data.role === 'DELIVERY_PARTNER') {
          this.commonService.isThisDeliveryPartner = true;
          // this.router.navigate(['/riderdeliverybooking']);
        } else {
          this.commonService.isThisDeliveryPartner = false;
        }
      }
    });
  }

  initializeMapPosition() {
    this.commonService.getPosition().then(position => {
      this.pickup = position;
      // this.getPickupAddress(this.pickup);
    });
    // this.currentPosition = this.commonService.getLocalStorageItemParsed(Key.CURRENT_POSITION);
    // if (this.currentPosition) {
    //   this.pickup = this.currentPosition;
    //   this.emitNearByDrivers(this.currentPosition);
    // }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.pickupfieldsArr[0].lat = position.coords.latitude;
        this.pickupfieldsArr[0].lng = position.coords.longitude;
        this.pickup.lat = position.coords.latitude;
        this.pickup.lng = position.coords.longitude;
        this.current.lat = position.coords.latitude;
        this.current.lng = position.coords.longitude;
        this.zoom = 15;
        this.getPickupAddress({ lat: position.coords.latitude, lng: position.coords.longitude }, this.pickupfieldsArr[0],'');
        this.getNearbyDrivers({ lat: position.coords.latitude, lng: position.coords.longitude });
      }, err => {
        console.log(err)
      });
    }
  }

  onMapReady(map: any) {
    this.map = map;
    if (map) {
      map.setOptions({
        streetViewControl: false
      });
    }
  }

  markerDragEndOrigin(event: any, data) {
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data,'');
      // this.getNearbyDrivers(this.pickup);
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data,'');
      // this.getPickupAddress(this.pickup);
      // this.getNearbyDrivers(this.pickup);
    }

  }
  markerDragEndDestination(event: any, data) {
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getAddressDrop({ lat: data.lat, lng: data.lng }, data, '');
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      // this.drop.lat = this.dropupfieldsArr[]
      this.getAddressDrop({ lat: data.lat, lng: data.lng }, data, '');
    }
  }

  handlePickUpChnage(address, data) {
    this.isPickupChanged = true;
    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickupAddress = (address.formatted_address);
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      this.pickupfieldsArr.forEach(element => {
        if (element.name === data.name) {
          element.lat = lat;
          element.lng = lng;
        }
      });
      this.pickup.lat = this.pickupfieldsArr[0].lat;
      this.pickup.lng = this.pickupfieldsArr[0].lng;
      this.getPickupAddress(this.pickup,data,'');
      // this.getNearbyDrivers(this.pickup);
      // this.bookingForm.get('ridePickUpAddress').patchValue(this.pickupAddress);
      data.address = (address.formatted_address);
      this.pickupForm.get(data.name).patchValue(this.pickupAddress);
    }
  }

  handleDropChnage(address, data) {
    console.log(address);
    this.drop.lat = 0;
    this.drop.lng = 0;
    if (address.formatted_address) {
      this.dropAddress = (address.formatted_address);
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      this.dropupfieldsArr.forEach(element => {
        if (element.name === data.name) {
          element.lat = lat;
          element.lng = lng;
        }
      });
      var droplength = this.dropupfieldsArr.length;
      this.drop.lat = this.dropupfieldsArr[droplength - 1].lat;
      this.drop.lng = this.dropupfieldsArr[droplength - 1].lng;
      this.showDropMarker = true;
      // this.getDirection();
      // this.getDistanceMatrix(this.dropAddress, {lat: lat, lng: lng});
      // this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[droplength - 1].lat, lng: this.dropupfieldsArr[droplength - 1].lng }, data.name);
      this.getAddressDrop(this.drop, data,'');
    }
    if (address.types) {
      if (address.types.includes("airport")) {
        this.isTollRoute = true;
      } else {
        this.isTollRoute = false;
      }
    }
  }

  dropKeyup(drop, value) {
    if (value === "") {
      drop.lat = 0;
      drop.lng = 0;
      drop.address = '';
      drop.distance = 0;
    }
  }

  dropChange(drop,value) {
    if (drop.lat === 0 && drop.lng === 0) {
      this.dropupForm.get(drop.name).patchValue(null);
      this.rideSubmited = true;
    } else if(drop.address !== value) {
      this.dropupForm.get(drop.name).patchValue(null);
      this.rideSubmited = true;
    }
  }

  pickeyUp(pickup, value) {
    if (value === "") {
      pickup.lat = 0;
      pickup.lng = 0;
      pickup.address = '';
      pickup.distance = 0;
    }
  }

  pickupChange(data,value) {
    if (data.lat === 0 && data.lng === 0) {
      this.pickupForm.get(data.name).patchValue(null);
      this.rideSubmited = true;
    } else if(data.address !== value) {
      this.pickupForm.get(data.name).patchValue(null);
      this.rideSubmited = true;
    }
  }

  getDirection() {
    if ((this.pickup.lng !== null) && (this.pickup.lng !== null) && (this.drop.lat !== null) && (this.drop.lng !== null)) {
      this.origin = { lat: this.pickup.lat, lng: this.pickup.lng };
      this.destination = { lat: this.drop.lat, lng: this.drop.lng };
      this.showDirections = true;
      this.showPickMarker = false;
      this.showDropMarker = false;
      this.calculateDistance();
    }
    this.showPickMarker = false;
    this.showDropMarker = false;

  }

  calculateDistance() {
    const start = new google.maps.LatLng(this.pickupfieldsArr[0].lat, this.pickupfieldsArr[0].lng);
    const end = new google.maps.LatLng(this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng);
    this.distance = google.maps.geometry.spherical.computeDistanceBetween(start, end); // distance in Meter
    return this.distance.toFixed(0);
  }

  getFare(pricePerMiles) {
    var d = this.distance / 1.609; //convert KM into MILES
    var fare = d * pricePerMiles;
    return Math.round(fare);
  }

  getPickupAddress(latLng, data,formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log('exist');
            this.pickupForm.controls[data.name].patchValue(addresses.formatted_address);
            data.address = addresses.formatted_address;
            this.pickupAddress = addresses.formatted_address;
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log('not-exist');
          this.pickupForm.controls[data.name].patchValue(results[0].formatted_address);
          data.address = results[0].formatted_address;
          this.pickupAddress = results[0].formatted_address;
        }
        results[0].address_components.forEach(element => {
          if (element.types.includes("country")) {
            this.currentCountry = element.short_name;
          }
        })
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  getAddressDrop(latLng, data,formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      console.log(results, status);
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log('exist');
            this.dropupForm.controls[data.name].patchValue(addresses.formatted_address);
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log('not-exist');
          this.dropupForm.controls[data.name].patchValue(results[0].formatted_address);
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  bookRideForNow(type) {
    this.rideSubmited = true;
    this.checkFormFields();
    if (this.pickupForm.valid && this.dropupForm.valid) {
      this.pickupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      this.dropupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.originMarkerIcon);
      // this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_RIDE);
      this.bookingFor = type;
      var date = new Date();
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      var offset = date.getTimezoneOffset() / 60;
      this.bookingTimeInH = date.getHours();
      this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null)
      .then((value: any) => {
        if(value.duration) {
          this.getVehicle();
        }
      })
    }

    // this.getVehicle();
  }

  checkFormFields() {
    for (let i = 0; i < this.pickupfieldsArr.length; i++) {
      if (this.pickupfieldsArr[i].lat === 0 && this.pickupfieldsArr[i].lng === 0) {
        if (this.pickupfieldsArr.length === 1) {
          this.pickupfieldsArr[i].address = '';
          this.pickupForm.get(this.pickupfieldsArr[i].name).patchValue(null);
        } else {
          this.removePickupField(i);
        }
      }
    }
    for (let i = 0; i < this.dropupfieldsArr.length; i++) {
      if (this.dropupfieldsArr[i].lat === 0 && this.dropupfieldsArr[i].lng === 0) {
        if (this.dropupfieldsArr.length === 1) {
          this.dropupfieldsArr[i].address = '';
          this.dropupForm.get(this.dropupfieldsArr[i].name).patchValue(null);
        } else {
          this.removeDropupField(i);
        }
      }
    }
  }

  getVehicle() {
    this.rideSubmited = true;
    this.activeBtn = 'Now';
    this.getUser();
    if (this.pickupForm.valid && this.dropupForm.valid && this.directionStatus === 'OK') {
      this.originPathOptimization();
      this.destinationPathOptimization();
      this.ngxService.start();
      this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
      // this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null)
      // .then((value: any) => {
        // if(value.duration) {
          var param = {
            "pageNo": 1,
            "latitude": this.pickupfieldsArr[0].lat,
            "longitude": this.pickupfieldsArr[0].lng
          }
          this.http.httpPostWithHeaderUser('getVehicleCategoryInGeofence', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.ngxService.start();
              // this.vehicleCategoryData = res.data.vehicleCategoryData;
              // this.selectedVehicleData = res.data.vehicleCategoryData[0];
              this.driverNoteForm.get('name').patchValue(this.user.firstName);
              this.driverNoteForm.get('phoneNo').patchValue(this.user.phoneNo);
              this.continue(1);
              var arr = res.data.vehicleCategoryData;
              this.vehicleCategoryData = [];
              arr.forEach(element => {
                var totalFare: any;
                var d = this.distance / 1.609; //convert KM into MILES
                var priceA = d * element.pricePerMile;
                var pricePerMinute = element.pricePerHour / 60;
                let totalMinutesForRide = this.duration;
                var priceB = pricePerMinute * totalMinutesForRide;
                var basicFare = priceA * priceB + element.baseFareChangePercentage;
                var tax = (basicFare * 12) / 100;
                var booKingDriverAmount;
                if (this.bookingTimeInH > 9 && this.bookingTimeInH < 7) {
                  var specialCharge = (basicFare * 11) / 100;
                  totalFare = basicFare + tax + specialCharge;
                } else {
                  totalFare = basicFare + tax;
                }
                if (this.isTollRoute) {
                  totalFare = totalFare + 4;
                }
                booKingDriverAmount = (totalFare * 75) / 100;
                this.vehicleCategoryData.push($.extend({}, {
                  airpotFess: element.airpotFess,
                  booKingAmount: 90,
                  booKingDriverAmount: 20,
                  baseFare: element.baseFare,
                  createdAt: element.createdAt,
                  geofenceId: element.geofenceId,
                  id: element.id,
                  isBlocked: element.isBlocked,
                  isDeleted: element.isDeleted,
                  isDelivery: element.isDelivery,
                  isRide: element.isRide,
                  lateNightCharge: element.lateNightCharge,
                  packageSizeId: element.packageSizeId,
                  pricePerHour: element.pricePerHour,
                  pricePerMile: element.pricePerMile,
                  rideCapacity: element.rideCapacity,
                  updatedAt: element.updatedAt,
                  vehicleCategory: element.vehicleCategory,
                  vehicleImage: element.vehicleImage,
                  weatherEffectFees: element.weatherEffectFees,
                  zoneId: element.zoneId,
                  __v: element.__v,
                  _id: element._id,
                }))
              });
              this.selectedVehicleData = this.vehicleCategoryData[0];
              console.log(this.vehicleCategoryData);
              this.ngxService.stop();
              this.pickupfieldsArr.sort((a, b) => {
                return a.distance - b.distance;
              });
              this.dropupfieldsArr.sort((a, b) => {
                return a.distance - b.distance;
              });
              this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.originMarkerIcon);
            }
          });
    }
  }

  // calculateFare(baseFare,pricePerMile,
  //   pricePerHour,airpotFess,lateNightCharge,weatherEffectFees,
  //   distance,estimatedTime,isAirport){
  //     let total = 0;
  //     let miles = distance * 0.00062
  //     let distancePrice = pricePerMile * miles
  //     let minutes = estimatedTime/60
  //     let timePrice = minutes * pricePerHour
  //     total = baseFare + distancePrice + timePrice
  //     if (isAirport){
  //       total = total + airpotFess
  //     }
  //     let percentage = total * 0.12
  //     total = total + percentage
  //     let y = parseFloat(((100*total)/100).toFixed(2));
  //     return parseFloat(y)
  // }

  selectVehicle(vehicle, index) {
    this.selectedVehicleData = vehicle;
    this.selectedItem = index;
  }

  CreateRideBooking(poupType) {
    var date = moment(this.requestForLaterForm.value.dateTime).format('YYYY-MM-DD');
    var time = moment(this.requestForLaterForm.value.dateTime).format('HH:mm:ss');
    var dateTime = date + 'T' + time + 'Z';
    // return;
    this.ngxService.start();
    var d = new Date()
    var timeZone = d.getTimezoneOffset();
    if (Math.sign(timeZone) === 1) {
      timeZone = -Math.abs(timeZone);
    } else {
      timeZone = Math.abs(timeZone);
    }
    var param: any;
    var newParam: any;
    var latLngsArr = [];
    this.pickupfieldsArr.forEach(element => {
      latLngsArr.push({
        "address": element.address,
        "latitude": element.lat,
        "longitude": element.lng,
        "isPickUp": true
      });
    });
    this.dropupfieldsArr.forEach(element => {
      latLngsArr.push({
        "address": element.address,
        "latitude": element.lat,
        "longitude": element.lng,
        "isPickUp": false
      });
    });
    if (this.driverNoteForm.value.notes != null) {
      newParam = $.extend({}, {
        "vehicleCategoryId": this.selectedVehicleData.id,
        "totalDistance": this.calculateDistance(),
        "paymentMode": this.constant?.PAYMENT_MODE?.CASH ? this.constant.PAYMENT_MODE.CASH : undefined,
        "name": this.driverNoteForm.value.name,
        "phoneNo": this.driverNoteForm.value.phoneNo,
        "additionalInfo": this.driverNoteForm.value.notes != '' ? this.driverNoteForm.value.notes : undefined,
        "pickUpDropUp": latLngsArr,
        "timezone": timeZone,
        "bookingDate": this.bookingFor === 'Later' ? this.requestForLaterForm.value.dateTime : undefined,
        // "booKingAmount": this.selectedVehicleData.booKingAmount,
        // "booKingDriverAmount": this.selectedVehicleData.booKingDriverAmount.toFixed(2)
      });
    } else if (this.driverNoteForm.value.notes != '') {
      newParam = $.extend({}, {
        "vehicleCategoryId": this.selectedVehicleData.id,
        "totalDistance": this.calculateDistance(),
        "paymentMode": this.constant?.PAYMENT_MODE?.CASH ? this.constant.PAYMENT_MODE.CASH : undefined,
        "name": this.driverNoteForm.value.name,
        "phoneNo": this.driverNoteForm.value.phoneNo,
        "additionalInfo": this.driverNoteForm.value.notes != null ? this.driverNoteForm.value.notes : undefined,
        "pickUpDropUp": latLngsArr,
        "timezone": timeZone,
        "bookingDate": this.bookingFor === 'Later' ? this.requestForLaterForm.value.dateTime : undefined,
        // "booKingAmount": this.selectedVehicleData.booKingAmount,
        // "booKingDriverAmount": this.selectedVehicleData.booKingDriverAmount.toFixed(2)
      });
    }

    this.http.httpPostWithHeaderUser('createRideBooking', newParam).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.bookingId = res.data.bookingId;
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        if (this.bookingFor === 'Now') {
          this.continue(3);
          this.listenSocketEvent();
        } else {
          this.continue(0);
          this.listenSocketEvent();
        }
      }
    })
  }

  listenSocketEvent() {
    this.socketService.onEvent(Event.sendNotificationToUser).pipe(takeUntil(this.notifier)).subscribe((response: any) => {
      console.log(response);
      if (response.eventType != 32) {
        this.goToBookingStatus(response.data);
      }
    });
  }

  goToBookingStatus(response) {
    if (response.bookingType === this.constant.BOOKING_TYPE.RIDE) {
      if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING && !response.isSheduledBooking) {
        this.bookingId = response._id;
        this.nextPopup = 3;
        if (response.subBookingData[0].isPickUp && response.subBookingData[0].bookingStatus === this.constant.BOOKING_STATUS.PENDING) {
          this.commonService.OpenNotificationModal('success', 'top-end', 'Your driver has canceled delivery we are searching new driver for you');
          this.nextPopup = 3;
        }
        var statusArr = response.subBookingData.map(val => val.bookingStatus);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
        // this.duration = 0;
        this.commonService.bookingResponseData = response;

        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        // this.duration = '';
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.matrix({ lat: response.driverId.latitude, lng: response.driverId.longitude }, { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, null)
          .then((value: any) => {
            this.listenDriverLatLngEvent();
            this.timerStart.next(true);
            for (let i = 0; i < response.subBookingData.length; i++) {
              if (response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED
                && this.pickupfieldsArr.length > 1 && this.dropupfieldsArr.length === 1) {
                if (value.duration.toFixed() !== "0") {
                  this.driverMessage = 'Your Driver is ariving in ' + value.duration.toFixed() + ' min.';
                } else {
                  this.driverMessage = 'Your driver arrived to pick up rider in location ' + [i + 1] + '.';
                }
                this.duration = value.duration;
                this.continue(4);
                return;
              } else if (response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED
                && this.pickupfieldsArr.length === 1 && this.dropupfieldsArr.length > 1) {
                if (value.duration.toFixed() !== "0") {
                  this.driverMessage = 'Your driver is arriving ' + value.duration.toFixed() + ' min.';
                } else {
                  this.driverMessage = 'Your driver arrived to pick you all up.';
                }
                this.duration = value.duration;
                this.continue(4);
                return;
              } else if (response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED
                && this.pickupfieldsArr.length === 1 && this.dropupfieldsArr.length === 1) {
                if (value.duration.toFixed() !== "0") {
                  this.driverMessage = 'Your Driver is arriving in ' + value.duration.toFixed() + ' min.';
                } else {
                  this.driverMessage = 'Your driver arrived to pick you up.';
                }
                this.duration = value.duration;
                this.continue(4);
                return;
              }
            }
          });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT) {
        this.commonService.bookingResponseData = response;

        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        // this.duration = '';
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.matrix({ lat: response.driverId.latitude, lng: response.driverId.longitude }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null)
          .then((value: any) => {
            if (response.subBookingData[0].bookingStatus === this.constant.BOOKING_STATUS.PICKED) {
              this.driverMessage = 'You started your ride.';
            }
            if (response.subBookingData[1].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED || response.subBookingData[1].bookingStatus === this.constant.BOOKING_STATUS.DROPED) {
              this.driverMessage = 'You arrived at your destination.';
            }
          });
        this.continue(4);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
        this.commonService.bookingResponseData = response;

        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDriverLatLngEvent();
        this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
        this.continue(4);
        this.setDriverStatus(response);
        for (let i = 0; i < response.subBookingData.length; i++) {
          if (response.subBookingData.length > 2 && response.subBookingData[i].isPickUp) {

          }
        }
        this.isDriverLatLngUpdated.subscribe((value: boolean) => {
          if (value) {
            this.setDriverStatus(response);
          }
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.STARTED) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(0)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        // this.origin = { lat: response.pickUplatitude, lng: response.pickUplongitude };
        // this.destination = { lat: response.dropUplatitude, lng: response.dropUplongitude };
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDriverLatLngEvent();
        this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
        this.continue(6);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.COMPLETED) {
        this.commonService.bookingResponseData = response;
        this.bookingId = response._id;
        this.showDirections = false;
        this.showPickMarker = false;
        // this.pickup.lat = response.pickUplatitude;
        // this.pickup.lng = response.pickUplongitude;
        // this.drop.lat = response.dropUplatitude;
        // this.drop.lng = response.dropUplongitude;
        this.bookingResponseData = response;
        this.getDriverRatingsReview();
        this.ngxService.stop();
        this.continue(8);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
        this.commonService.bookingResponseData = response;
        if (response.isCanceledByDriver) {
          this.driverNotFoundAlert('Your delivery has been cancelled by driver.');
        } else if (response.isCanceledByCron) {
          this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.');
        }
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING) {
        this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.');
      }
    } else {
      this.commonService.bookingResponseData = response;
      this.router.navigate(['/riderdeliverybooking']);
    }
  }

  setDriverStatus(response) {
    for (let i = 0; i < response.subBookingData.length; i++) {
      if (this.pickupfieldsArr.length > 1 && this.dropupfieldsArr.length === 1) {
        if (response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED) {
          this.driverMessage = 'Your driver arrived to pick up rider in location ' + this.constant.ALPHAB_STATUS[i + 1] + '.';
        } else if (response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED && response.subBookingData.length != i + 2) {
          this.driverMessage = 'Your Driver is arriving in ' + this.distanceDuration + '.';
          // this.driverMessage = 'Driver will  arrive in ' + this.distanceDuration + ' at location ' + [i + 2] + '.';
        } else if (response.subBookingData[response.subBookingData.length - 2].isPickUp && response.subBookingData[response.subBookingData.length - 2].bookingStatus === this.constant.BOOKING_STATUS.PICKED) {
          this.driverMessage = 'Reach  your destination in ' + this.distanceDuration + '.';
          // this.driverMessage = 'Your ride has started.'
        } if (!response.subBookingData[response.subBookingData.length - 1].isPickUp
          && (response.subBookingData[response.subBookingData.length - 1].bookingStatus === this.constant.BOOKING_STATUS.DROPED
            || response.subBookingData[response.subBookingData.length - 1].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED)) {
          this.driverMessage = 'Arrived at your destination.';
        }
      } else if (this.pickupfieldsArr.length === 1 && this.dropupfieldsArr.length > 1) {
        if (i === 0 && response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED && response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED) {
          this.driverMessage = 'Your Driver is arriving in ' + this.duration + ' min.';
        } if (i === 0 && response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED && response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.PICKED) {
          this.driverMessage = 'Your driver arrived to pick you all up.';
        } if (i !== 1 && response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED
          && response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED && response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.DROPED) {
          this.driverMessage = 'Your rides have started.';
        } if (response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.PICKED
          && !response.subBookingData[i].isPickUp
          && (response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED)
          && i !== this.bookingResponseData.subBookingData.length - 1) {
          this.driverMessage = 'Arrived at drop-off location ' + this.constant.ALPHAB_STATUS[i] + '.';
        } if (response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED && i != response.subBookingData.length - 1) {
          this.driverMessage = 'Reach drop off location '+ this.constant.ALPHAB_STATUS[i + 1] + ' in ' + this.distanceDuration + '.';
        } if (i === this.bookingResponseData.subBookingData.length - 1 && !response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED) {
          this.driverMessage = 'Arrived at final destination.';
        }
      }
    }
  }

  listenDriverLatLngEvent() {
    this.socketService.onEvent(Event.updateDriverLatLongToUser).pipe(takeUntil(this.notifier)).subscribe((response: any) => {
      // console.log(response);
      if (response.status == 200 && this.bookingResponseData.bookingStatus !== this.constant.BOOKING_STATUS.COMPLETED) {
        this.isDriveingStart = true;
        this.showDirections = true;
        this.showPickMarker = false;
        this.distanceDuration = response.distanceDuration;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(response.bearing)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
          var driverLatLng = { lat: response.latitude, lng: response.longitude };
          this.destination = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
          this.origin = { lat: response.latitude, lng: response.longitude };
          this.waypoints = [];
          this.drawPath(driverLatLng, this.pickupfieldsArr[this.pickupfieldsArr.length - 1], this.iconObject(response.bearing))
          if (this.calculated === false) {
            this.calculated = true;
            this.matrix(driverLatLng, this.destination, null);
          }
        }
        if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
          // this.destination = { lat: this.drop.lat, lng: this.drop.lng };
          this.isDriverLatLngUpdated$.next(true);
          var driverLatLng = { lat: response.latitude, lng: response.longitude };
          this.drawPath(driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing))
          clearInterval(this.intervalSubscription);
        }
        if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT) {
          var driverLatLng = { lat: response.latitude, lng: response.longitude };
          this.drawPath(driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing))
          clearInterval(this.intervalSubscription);
        }
        // this.getDirection();
      } else {
        clearInterval(this.intervalSubscription);
      }
    });
  }

  iconObject(rotation: number) {
    return {
      path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
      scale: 0.7,
      strokeColor: "white",
      strokeWeight: 0.1,
      fillOpacity: 1,
      fillColor: "#404040",
      offset: "5%",
      rotation: rotation,
      flat: true,
      anchor: new google.maps.Point(10, 25)
    };
  }


  getDriverRatingsReview() {
    this.http.httpPostWithHeaderUser('getDriverRatingsReview', { driverId: this.bookingResponseData.driverId.id }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverReviewData = res.data.driverData;
      }
    });
  }

  driverNotFoundAlert(message) {
    Swal.fire({
      title: message,
      // text: "You won't be able to revert this!",
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.continue(0);
      }
    })
  }

  getDriverLocation(lat, lng) {
    this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 13;
          this.driverAddress = results[0].formatted_address;
          this.driverLatLng = { lat: lat, lng: lng };
          // this.getDistanceMatrix(this.driverAddress, this.driverLatLng);
          // this.calculateDistanceMatrix();
          // this.getDirection();
          // this.displayWaypoints = false;
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getDistanceMatrix(dropAddress, dropLatLng) {
    var origin1 = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
    var origin2 = this.pickupAddress;
    var destinationA = dropAddress;
    var destinationB = dropLatLng;
    var service = new google.maps.DistanceMatrixService;
    service.getDistanceMatrix({
      origins: [origin1, origin2],
      destinations: [destinationA, destinationB],
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false
    }, (res: any) => {
      this.distanceInKM = res.rows[0].elements[0].distance.text;
      this.duration = res.rows[0].elements[0].duration.text;
      this.ngxService.stop();
    });
  }
  calculateDistanceMatrix(pickup, drop, formField) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      this.directionStatus = status;
      if (status == google.maps.DirectionsStatus.OK) {
        this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
        this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
        this.driverDistance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
        var obj = { distance: this.distance, duration: this.duration };
      } else {
        // this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
        // if (formField !== null) {
        //   this.dropupForm.get(formField).patchValue('');
        // }
      }
      try {

      } catch (error) {
        console.log(error);
      }
    });
  }

  matrix(pickup, drop, formField) {
    return new Promise((resolve, reject) => {
      let directionsService = new google.maps.DirectionsService();
      var request = {
        origin: pickup,
        destination: drop,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      directionsService.route(request, (response, status) => {
        this.directionStatus = status;
        if (status == google.maps.DirectionsStatus.OK) {
          this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
          this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
          this.driverDistance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
          var obj = { distance: this.distance, duration: this.duration };
          return resolve(obj);
        } else {
          this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
          if (formField !== null) {
            this.dropupForm.get(formField).patchValue('');
          }
        }
        try {

        } catch (error) {
          console.log(error);
        }
      });
    })

  }

  continue(popupType) {
    this.ngxService.start();
    if (popupType === 0) {
      this.showDirections = false;
      this.showPickMarker = true;
      this.showDropMarker = false;
      this.isDriveingStart = false;
      this.rideSubmited = false;
      this.DriverNoteFormSubmitted = false;
      this.rideSubmited = false;
      this.isDateTimeSubmit = false;
      this.driverNoteForm.reset();
      // this.bookingForm.reset();
      this.requestForLaterForm.reset();
      this.pickupFieldsCount = 1;
      this.dropupFieldsCount = 1;
      this.pickupfieldsArr = [{ name: 'pickup1', lat: 0, lng: 0, address: '', distance: 0 }];
      this.dropupfieldsArr = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0 }];
      this.dropupForm.get('dropup1').patchValue(null);
      this.getCurrentLocation();
      this.nextPopup = popupType;
      this.ngxService.stop();
      this.drop.lat = 0;
      this.drop.lng = 0;
      this.rating = 0;
      this.selectedReviewData = '';
    }
    this.nextPopup = popupType;
    this.ngxService.stop();
  }

  bookRideForLater() {
    this.isDateTimeSubmit = true
    if (this.requestForLaterForm.valid) {
      var date = this.requestForLaterForm.value.dateTime;
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      var offset = date.getTimezoneOffset() / 60;
      this.bookingTimeInH = date.getHours();
      console.log(this.bookingTimeInH)
      // $('#exampleModall').modal('hide');
      this.display = 'none';
      this.bookingFor = 'Later';
      // this.getVehicle();
      this.bookRideForNow('Later');
    }
  }

  openDateTimeModal() {
    this.rideSubmited = true;
    this.activeBtn = 'Later';
    if (this.pickupForm.valid && this.dropupForm.valid) {
      // $('#exampleModall').modal('show');
      this.display = 'block';
    }
  }

  closeScheduleModal() {
    this.display = 'none';
  }

  cancelBooking(index) {
    if (this.bookingId) {
      this.http.httpPostWithHeaderUser('cancelBooking', { bookingId: this.bookingId }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.commonService.bookingResponseData = '';
          this.bookingResponseData = '';
          this.continue(0);
          this.DriverNoteFormSubmitted = false;
          this.rideSubmited = false;
          this.isDateTimeSubmit = false;
          this.driverNoteForm.reset();
          // this.bookingForm.reset();
          this.requestForLaterForm.reset();
          this.getCurrentLocation();
          this.drop.lat = 0;
          this.drop.lng = 0;
          this.showDirections = false;
          this.showPickMarker = true;
          this.showDropMarker = false;
        }
      });
    }
    console.error('Booking Id not found.');
  }

  goTouhomepage() {
    this.router.navigate(["riderequest"]);
  }
  goToprofilesetup() {
    this.commonService.isThisUpdatedUser = false;
    this.router.navigate(["/profilesetup"]);
  }
  goTorequestaccept() {
    this.router.navigate(["/requestaccept"]);
  }
  goToriderdeliverybooking() {
    this.router.navigate(["/riderdeliverybooking"]);
  }

  addFavouriteDriver() {
    this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: this.bookingResponseData.driverId.id, isFav: true }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.getDriverRatingsReview();
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      }
    });
  }

  removeFavouriteDriver() {
    this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: this.bookingResponseData.driverId.id, isFav: false }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.getDriverRatingsReview();
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      }
    });
  }

  //for rating
  onClick(rating: number) {
    // console.log(rating)
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    if (this.rating > 0 && this.rating < 3) {
      this.review.setValidators(Validators.required);
      this.review.updateValueAndValidity();
    } else {
      this.review.clearValidators();
      this.review.updateValueAndValidity();
    }
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  selectReview(index, data) {
    this.selectedDriverReview = index;
    this.selectedReviewData = data.isActive ? '' : data.name;
  }

  selectTip(i, amount) {
    this.selectedTip = i;
    this.selectedTipAmount = amount;
    if (this.customRate.value != null) {
      this.customRate.patchValue(null);
      this.selectedTip = i;
    }

  }

  addRating(index) {
    this.ratingSubmitted = true;
    if (this.rating > 0 && this.selectedReviewData != '') {
      this.selectedFeedbckQna = [];
      this.selectedFeedbckQna.push({ question: 'Was the driver on time?', answere: this.wasTheDriverOnTime.value });
      this.selectedFeedbckQna.push({ question: 'Was the ride safe?', answere: this.wasTheRideSafe.value });
      this.selectedFeedbckQna.push({ question: 'Did the driver make your ride comfortabel?', answere: this.driverMakeYouComfortabel.value });
      this.selectedFeedbckQna.forEach(element => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      this.selectedFeedbckQna.forEach(element => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      var selectedreview: any;
      this.reviewData.forEach(element => {
        if (element.isActive) {
          selectedreview = element.name
        }
      })
      var param = $.extend({}, {
        "bookingId": this.bookingId,
        "driverId": this.bookingResponseData.driverId.id,
        "driverRating": this.rating,
        "driverReview": this.selectedReviewData,
        "tip": this.customRate.value ? this.customRate.value : this.selectedTipAmount,
        "comment": this.comment.value ? this.comment.value : undefined,
        "feedBack": this.selectedFeedbckQna
      })
      this.http.httpPostWithHeaderUser('addRatings', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          this.continue(index);
        }
      });
    }
  }

  goToContactDetail() {
    this.isContactDetailHide = this.isContactDetailHide ? false : true;
    if (!this.isContactDetailHide) {
      this.vps.scrollToAnchor("driverInfo");
      // document.getElementById("driverInfo").scrollIntoView();
    }
  }

  monitorRide() {
    this.monitorSubmit = true;
    if (this.monitorForm.valid) {
      var codeLength = this.monitorForm.value.phoneNo.dialCode.length;
      var param = {
        "bookingId": this.bookingId,
        "moniterRide": {
          "name": this.monitorForm.value.name,
          "phoneNo": this.monitorForm.value.phoneNo.e164Number.slice(codeLength),
          "countryCode": this.monitorForm.value.phoneNo.dialCode
        }
      }
      this.http.httpPostWithHeaderUser('shareYourRide', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.monitorForm.reset();
          this.monitorSubmit = false;
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          $("#add_moniter").modal('hide');
        }
      });
    }
  }

  openMonitorModal(bookingId) {
    this.bookingId = bookingId;
    $("#add_moniter").modal('show');
  }

  addPickupField(pickup) {
    if (pickup.lat !== 0 && pickup.lng !== 0) {
      this.pickupFieldsCount++;
      if (!('pickup' + this.pickupFieldsCount in this.pickupFormObj)) {
        this.addFieldPick(pickup);
      } else {
        this.pickupFieldsCount++;
        this.addFieldPick(pickup);
      }
    } else {
      this.rideSubmited = true;
      pickup.address = '';
      this.pickupForm.get(pickup.name).patchValue(null);
    }

  }

  addFieldPick(pickup) {
    if (!('pickup' + this.pickupFieldsCount in this.pickupFormObj)) {
      this.pickupFormObj['pickup' + this.pickupFieldsCount] = new FormControl('', Validators.required);
      this.pickupForm = this.fb.group(this.pickupFormObj);
      this.pickupfieldsArr.push({ name: 'pickup' + this.pickupFieldsCount, lat: 0, lng: 0, address: '', distance: 0, country: this.currentCountry });
      console.log('check2', this.pickupfieldsArr);

    } else {
      this.addPickupField(pickup);
    }
  }

  removePickupField(index) {
    // this.pickupFieldsCount--;
    // console.log(this.pickupFieldsCount);
    // var size = Object.keys(this.pickupFormObj).length;
    // console.log('size',size);

    this.pickupfieldsArr.splice(index, 1);
    Object.keys(this.pickupFormObj).forEach(element => {
      let strArray = this.pickupfieldsArr.map(element => element.name)
      if (!(strArray.includes(element))) {
        delete this.pickupFormObj[element];
      }
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
    console.log('check1', this.pickupfieldsArr);

  }

  addDropupField(drop) {
    if (drop.lat !== 0 && drop.lng !== 0) {
      this.dropupFieldsCount++;
      if (!('dropup' + this.dropupFieldsCount in this.dropupFormObj)) {
        this.addFieldDrop(drop);
      } else {
        this.dropupFieldsCount++;
        this.addFieldDrop(drop);
      }
    } else {
      this.rideSubmited = true;
      drop.address = '';
      this.dropupForm.get(drop.name).patchValue(null);
    }
  }

  addFieldDrop(drop) {
    if (!('dropup' + this.dropupFieldsCount in this.dropupFormObj)) {
      this.dropupFormObj['dropup' + this.dropupFieldsCount] = new FormControl('', Validators.required);
      this.dropupForm = this.fb.group(this.dropupFormObj);
      this.dropupfieldsArr.push({ name: 'dropup' + this.dropupFieldsCount, lat: 0, lng: 0, address: '', distance: 0, country: this.currentCountry });
    } else {
      this.addDropupField(drop);
    }
  }

  removeDropupField(index) {
    // this.dropupFieldsCount--;
    // this.dropupfieldsArr.splice(index, 1);

    this.dropupfieldsArr.splice(index, 1);
    Object.keys(this.dropupFormObj).forEach(element => {
      let strArray = this.dropupfieldsArr.map(element => element.name)
      if (!(strArray.includes(element))) {
        delete this.dropupFormObj[element];
      }
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
  }

  drawPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
            stopover: false
          });
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        } else {
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon
        },
        destination: {
          icon: this.destinationMarkerIcon
        },
        waypoints: wayPontsMarkerArr
      }
    } else if (this.dropupfieldsArr.length > 1) {
      for (let i = 0; i < this.dropupfieldsArr.length; i++) {
        if (i !== this.dropupfieldsArr.length - 1) {
          this.waypoints.push({
            location: { lat: this.dropupfieldsArr[i].lat, lng: this.dropupfieldsArr[i].lng },
            stopover: false
          });
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        } else {
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon
        },
        destination: {
          icon: this.destinationMarkerIcon
        },
        waypoints: wayPontsMarkerArr
      }
    }
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    this.destination = { lat: destination.lat, lng: destination.lng };
  }

  originPathOptimization() {
    this.pickupfieldsArr.forEach(element => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.current, destination, element);
    });
  }

  destinationPathOptimization() {
    this.dropupfieldsArr.forEach(element => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.pickup, destination, element);
    });
  }

  calculateDistanceForPathOpt(pickup, drop, data) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        data.distance = response?.routes[0]?.legs[0]?.distance.value;
      }
    });
  }

  goToHome() {
    this.commonService.origin = { lat: 0, lng: 0, address: '' };
    this.commonService.destination = { lat: 0, lng: 0, address: '' };
    this.router.navigate(["/uhomepage"]);
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    clearInterval(this.intervalSubscription);
    clearInterval(this.calculateSubscription);
  }
}
