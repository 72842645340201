<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="conta_iner">
    <div class="nav_inner">
      <div class="logo">
        <a routerLink="/uhomepage">
          <figure>
            <img src="assets/uimages/logo.png" alt="" />
          </figure>
        </a>
      </div>
     <div  class="togglm">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li [ngClass]="{'nav_active dd-link shutter half': navItem?.isActiveClass === true}" *ngFor="let navItem of navLinkArr">
            <a routerLink="/{{navItem?.url}}" [ngClass]="{'active': navItem?.isActiveClass === true}" routerLinkActive="active">{{navItem?.name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</nav>