import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { HttpParams } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constant } from '../core/constant';
import { ParentService } from "src/app/services/parent/parent.service";

@Component({
  selector: "app-uhomepage",
  templateUrl: "./uhomepage.component.html",
  styleUrls: ["./uhomepage.component.scss"],
})
export class UhomepageComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("whyDiv") divView: ElementRef;
  notifier = new Subject();
  currentYear: number;
  goToWhySection: boolean;
  constructor(private router: Router,
    private http: HttpsService,
    public commonService: CommonService,
    private vps: ViewportScroller,
    public constant: Constant,
    private parentService: ParentService) { }

  ngOnInit(): void {
    var d = new Date();
    this.currentYear = d.getFullYear();
    this.commonService.getDownloadSection.pipe(takeUntil(this.notifier)).subscribe((value: any) => {
      if(value) {
        var element = document.getElementById('our_driver');
        var headerOffset = 250;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    });
    
  }
  ngAfterViewInit() {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   if(this.parentService.isThisWhySectionScroll) {
    //     console.log('ko');
    //     this.divView.nativeElement.scrollIntoView();
        
    //     this.parentService.isThisWhySectionScroll = false;
    //   }
    // });
    var ele = document.getElementById("whyU2go");
    localStorage.whyU2go !== undefined && ele.click();localStorage.removeItem('whyU2go');
    
    // this.commonService.getScrollId().subscribe((res: boolean) => {
    //   if (res) {
    //     console.log(ele)
    //     this.parentService.isThisWhySectionScroll = res;
    //     this.goToWhySection = res;
    //     // this.vps.scrollToAnchor(divId);
    //     // this.vps.scrollToAnchor(divId);
    //     // document.getElementById("why_id").scrollIntoView();
    //     // window.document.getElementById(divId).scrollIntoView(true);
    //     // console.log('divId',divId);
    //     this.divView.nativeElement.scrollIntoView();
    //     var offset = this.divView.nativeElement.scrollTop;
    //     console.log('offset',this.divView.nativeElement);
    //     // var offset = document.getElementById("why_id").offsetTop;
    //     // window.scrollTo(0, offset);
    //     this.commonService.setScrollOnNavigation(false);
    //   }
    // })
  }

  goTologin() {
    this.router.navigate(["/login"]);
    // if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
    //   if (localStorage.getItem('userType') === 'driver') {
    //     let param = new HttpParams().set('userId', localStorage.userId);
    //     this.http.httpGetWithHeader('getDriverProfile', param).subscribe((res: any) => {
    //       if (res.data.driverData.isPhoneVerified === true) {
    //         if (!res.data.driverData.isUploadDocument) {
    //           this.router.navigate(["/profile"]);
    //         } else {
    //           this.router.navigate(["/login"]);
    //         }
    //       }
    //     })
    //   } else {
    //     this.router.navigate(["/login"]);
    //   }
    // } else {
    //   this.router.navigate(["/login"]);
    // }
  }
  goTosignup() {
    this.router.navigate(["/signup"]);
  }
  goToriderlogin() {
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.getItem('userType') === 'rider') {
        this.http.httpGetWithHeaderUser('userProfile', "").subscribe((res: any) => {
          if (res.data.isEamilVerified === true || res.data.isPhoneVerified === true) {
            if (res.data.isAccountCompleted) {
              this.router.navigate(["/riderequest"]);
            } else {
              var detail = {
                isAddressInfo: true,
                isNewUser: false,
                isAccountCompleted: false
              }
              this.commonService.setRiderProfileAccessDetail(detail);
              this.router.navigate(["/riderdetail"]);
            }
          } else {
            this.http.httpPostWithHeaderUser('sendOtp', { phoneNo: res.data.phoneNo }).subscribe((item: any) => {
              this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
              this.router.navigate(["/riderotppage"]);
            });
          }
        })
      } else {
        this.router.navigate(["/riderlogin"]);
      }
    } else {
      this.router.navigate(["/riderlogin"]);
    }
  }

  goToBP() {
    localStorage.setItem('bpSection','true');
    this.router.navigateByUrl("/ubusinesspartners");
  }

  goToridersignup() {
    this.router.navigate(["/ridersignup"]);
  }
  goToriderdeliverybooking() {
    this.router.navigate(["/riderdeliverybooking"]);
  }

  goToWhySecton() {
    document.getElementById('why_id').scrollIntoView();
  }

  goToFbPage() {
    window.open('https://www.facebook.com/apptunixs', '_blank');
  }

  goToDeliverySignup() {
    this.commonService.isThisDeliveryPartner = true;
    this.router.navigate(['delivery-partner-signup']);
  }
  goToDeliveryLogin() {
    this.commonService.isThisDeliveryPartner = true;
    this.router.navigate(['delivery-partner-login']);
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

}
