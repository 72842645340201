import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-ridercongratulations",
  templateUrl: "./ridercongratulations.component.html",
  styleUrls: ["./ridercongratulations.component.scss"],
})
export class RidercongratulationsFComponent implements OnInit, OnDestroy {
  id: NodeJS.Timeout;
  
  constructor(private router: Router,
    public dialogRef: MatDialogRef<RidercongratulationsFComponent>) {}

  ngOnInit(): void {
    // this.id = setTimeout(() => {
    //   this.dialogRef.close();
    // }, 2000);
  }

  goTouhomepage() {
    this.dialogRef.close();
    // this.router.navigate(["/riderequest"]);
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }
}
