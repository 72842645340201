import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
interface modalData {
  style: string;
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class ParentService {
  isPreLaunch: Subject<boolean> = new Subject<boolean>();
  isFunctional: Subject<boolean> = new Subject<boolean>();
  isTokenUsed: Subject<boolean> = new Subject<boolean>();
  getPrelaunchStatus = this.isPreLaunch.asObservable();
  getFunctionalStatus = this.isFunctional.asObservable();
  getTokenStatus = this.isTokenUsed.asObservable();
  isThisWhySectionScroll: boolean = false;
  // openNotificationModal = 'none';
  // data: string = '';
  // modalData =  {
  //   style: 'none',
  //   data: ''
  // }
  // openNotificationModal$: BehaviorSubject<modalData> = new BehaviorSubject<modalData>({style: 'none',data: ''});
  // openNotificationModal = this.openNotificationModal$.asObservable();

  constructor(private http: HttpClient) { }

  getGeofenceStatus() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          console.log('location', latitude, longitude);
          if (latitude !== 0 && longitude !== 0) {
            this.http.post(environment.baseUrl + 'user/checkPrelaunchLocation', { latitude: latitude, longitude: longitude }).subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.isPreLaunch = res.data.geofenceData.isPreLaunch;
                return resolve(res.data.geofenceData.isPreLaunch);
              }
            });
          }
        });
      }
    });
  }
}
