import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Event } from '../../core/models/event';
import  { Action } from '../../core/models/action'

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket: any;

  constructor() { }

  initSocket() {
    // this.socket = socketIo(environment.socketUrl);
    this.socket = socketIo.connect(environment.socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax : 3000,
      reconnectionAttempts: Infinity
    });
    this.socket.on('connect', (res: any) => {
      console.log('debug connect', res);
      if (localStorage.length > 0) {
        const userId = localStorage.getItem('userId');
        if (userId != undefined || userId != null) {
          const data = {
            userId: userId
          }
          this.emitAction(Action.onlineUser, data);
        }
      }
    });
    
    this.socket.on('disconnect', (message: any) => {
      console.log('debug disconnect', message);
    });
  }

  emitAction(action : Action, payload: any): void {
    this.socket.emit(action, payload);
  }

  public onEvent(event: Event): Observable<any> {
    console.log(event);
    
    return new Observable<Event>(observer => {
      this.socket.on(event, (data) => observer.next(data));
    });
  }
}
