import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../services/common/common.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit, AfterViewInit {
  title: string;
  message: string;
  id: NodeJS.Timeout;
  constructor(public dialogRef: MatDialogRef<NotificationModalComponent>,
    private ngZone: NgZone,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngxService: NgxUiLoaderService ) { }

  ngOnInit(): void {
    this.ngxService.start();
    console.log('----------->',this.data);
    // this.id = setTimeout(() => {
    //   this.dialogRef.close();
    // }, 5000);
  }

  ngAfterViewInit() {
    this.ngxService.stop();
  }

  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  ngOnDestroy() {
    this.close();
    // if (this.id) {
    //   clearInterval(this.id);
    // }
  }
}
