<div class="main">
    <i class="fa fa-times close" (click)="close()" aria-hidden="true"></i>
    <div class="succes-msg">
        <i *ngIf="commonService?.notificationType === 'success'" class="fa fa-check " aria-hidden="true"></i>
        <i *ngIf="commonService?.notificationType === 'error'" class="fa fa-times" aria-hidden="true"></i>
        <i *ngIf="commonService?.notificationType === 'warning'" class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <!-- <h2>Successful</h2> -->
        <h2>{{commonService?.notificationText}}</h2>
        <!-- <p>jbvfb fb ib fb ifbifbivbfvf fi if ifif vif</p> -->
    </div>
</div>


