
<router-outlet></router-outlet>
<ngx-ui-loader></ngx-ui-loader>
<!-- <div id="verification" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display, 'opacity': 1}">
    <div class="modal-dialog">
        <div class="modal-content">
            <form >
                <div class="modal-body">
                    <div class="main">
                        <i class="fa fa-times close" (click)="close()"  aria-hidden="true"></i>
                        <div class="succes-msg">
                            <h2>{{data}}</h2>
                        </div>
                    </div>
                </div>
            </form>
  
        </div>
  
    </div>
  </div> -->