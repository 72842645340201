<!------start homapge page-->
<div class="businesspartners_page">
    <app-unavbar></app-unavbar>
    <div class="header_padding_class">
        <section class="riders_first_sec businesspartners_first_sec">
            <div class="conta_iner">
                <div class="riders_first_main">
                    <div class="rider_firstsec_content">
                        <div class="main_headings">
                            <h2>Partner with us and run your own business</h2>
                            <h3>
                                Apptunix™ Enterprises Inc. is a leading, innovative and growth oriented company who is offering Business Partner (BP) opportunities in selected markets.
                            </h3>
                        </div>
                        <div class="main_headings">
                            <button (click)="scrollToFormSection()">Become a Business Partner</button>
                        </div>
                    </div>
                    <div class="rider_firstsec_img">
                        <figure>
                            <img src="assets/uimages/business_partners_firstsec.png" alt="riders" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <!-----start delivery partners secound sec-->
        <section class="all_sec riders_first_sec businesspartners_secound">
            <div class="conta_iner">
                <div class="riders_first_main second">
                    <div class="rider_firstsec_content">
                        <div class="main_headings">
                            <h2>Our state-of-the-art,</h2>
                            <p>
                                reliable and secure technology enables our BPs to profitably grow their businesses without the large capital costs associated with know-how, technology, processes and infrastructure investments.
                            </p>
                        </div>
                    </div>
                    <div class="rider_firstsec_img">
                        <figure>
                            <img src="assets/uimages/business_ourart.png" alt="riders" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <!-----cta sec-->
        <section class="u2go_opportunities">
            <div class="conta_iner">
                <div class="u2go_opportunities_main">
                    <div class="u2go_opportunities_content">
                        <div class="main_headings">
                            <h2>If You Are Interested In Business Partner Opportunities</h2>
                            <h3>
                                please send us a quick email indicating your market of interest (e.g. City, Province/State, Country) and your stage in the process (e.g. investigating opportunities, investigating Apptunix™, ready to make a commitment with the next 3 months).
                                <span>Please contact George Demarchi at gdemarchi@Apptunix.ca</span>
                            </h3>
                        </div>
                    </div>
                    <div class="u2go_opportunities_img">
                        <figure>
                            <img src="assets/uimages/oppurinty.png" alt="" />
                        </figure>
                    </div>
                </div>
            </div>
           
        </section>
        <a href="/ubusinesspartners#partner" id="bp"></a>
        <section id="partner">
            <div class="login">
                <div class="conta_iner">
                    <div class="login_main">
                        <div class="login_form center">
                            <h4>Please contact us 
                                <!-- <span>Become a Business Partner</span> -->
                            </h4>
        
                            <form class="form_control_form" [formGroup]="partnerForm">
                                <div class="form-group">
                                    <mat-form-field [floatLabel]="'never'">
                                        <label class="algn-lft">First name</label>
                                        <input class="form-control" maxlength="25" (change)="onChangeFirst($event.target.value)" (keypress)="onKeyPress($event)" formControlName="firstName" matInput type="text"
                                            placeholder="First name" />
                                    </mat-form-field>
                                    <mat-error *ngIf="submitted && partnerForm.controls['firstName'].hasError('required')">First
                                        name is required.</mat-error>
                                        <mat-error *ngIf="submitted && validateFirstArr.includes(true) && !partnerForm.controls['firstName'].hasError('required')">
                                            Please enter valid first name.</mat-error>
                                </div>
                                <div class="form-group">
                                    <mat-form-field [floatLabel]="'never'">
                                        <label class="algn-lft">Last name</label>
                                        <input class="form-control" maxlength="25" (change)="onChangeSecond($event.target.value)" (keypress)="onKeyPress($event)" formControlName="lastName" matInput type="text"
                                            placeholder="Last name" />
                                    </mat-form-field>
                                    <mat-error *ngIf="submitted && partnerForm.controls['lastName'].hasError('required')">Last
                                        name is required.</mat-error>
                                        <mat-error *ngIf="submitted && validateSecondArr.includes(true) && !partnerForm.controls['lastName'].hasError('required')">
                                            Please enter valid last name.</mat-error>
                                </div>
                                <div class="form-group">
                                    <mat-form-field [floatLabel]="'never'">
                                        <label class="algn-lft">Email address</label>
                                        <input class="form-control" type="email" matInput placeholder="Email address" formControlName="email" />
                                    </mat-form-field>
                                    <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('required')">Email is
                                        required.</mat-error>
                                    <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('pattern')">Enter valid
                                        email.</mat-error>
                                </div>
                                <div class="form-group">
                                    <!-- <mat-form-field [floatLabel]="'never'"> -->
                                        <label class="algn-lft">Phone number</label>
                                    <ngx-intl-tel-input [inputId]="'adsasd'" matInput [cssClass]="'custom'"
                                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                                        [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                                    </ngx-intl-tel-input>
                                    <!-- </mat-form-field> -->
                                    <mat-error *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('required')">Phone
                                        number is required.</mat-error>
                                    <mat-error
                                        *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('validatePhoneNumber')"> 
                                       Enter valid number.</mat-error>
                                </div>
                                <div class="form-group">
                                    <label class="algn-lft">Comment</label>
                                    <div class="fgi sec">
                                        <textarea id="w3review" class="from-control" placeholder="Enter your comment here"
                                            formControlName="comment"></textarea>
                                        <mat-error
                                            *ngIf="submitted && partnerForm.controls['comment'].hasError('required')">
                                            Comment is required.</mat-error>
                                    </div>
                                </div>
        
                                <div class="flex submit_btn">
                                    <button class="btn btn-primary" (click)="submit()">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-ufooter></app-ufooter>
    </div>
</div>