<div class="login_wrap signup">
    <app-unavbar id="navbar"></app-unavbar>
    <!-- <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div> -->

    <div class="login" *ngIf="nextPopup == 0">
        <div class="conta_iner">
            <div class="login_main">
                <div class="login_form driver-type">
                    <div class="login_img_sec">
                        <figure class="login_img">
                            <img src="assets/uimages/signup-icon.svg" />
                        </figure>
                        <div class="type-text">
                            <h4>Select your driver type</h4>
                        </div>
                        
                        <!-- <p>Create an account</p> -->
                    </div>
                    
                    <button (click)="goToSignupStep('RIDE')" class="btn btn-primary margin_top20">
                        Rides
                    </button>
                    <button (click)="goToSignupStep('DELIVERY')" class="btn btn-primary margin_top20">
                        Deliveries
                    </button>
                    <button (click)="goToSignupStep('BOTH')" class="btn btn-primary margin_top20">
                        Rides and Deliveries
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="login" *ngIf="nextPopup == 1">
        <div class="conta_iner">
            <div class="login_main">
                <div class="login_form">
                    <div class="login_img_sec">
                        <!-- <figure class="login_img">
                            <img src="assets/uimages/signup-icon.svg" />
                        </figure> -->
                        <h4>Apptunix Drivers Pre-Registration Form</h4>
                        <p>Dear friend, thank you for your interest in being a driver with us. We hope you will be amazed at the opportunities Apptunix offers. Please fill up this pre-registration form and send it to us as soon as possible. We will send you a special notification and an invitation for you to complete your application as soon as we start operations in your city. Thank you and let's make a lot of money together! </p>
                    </div>

                    <form class="form_control_form Pre-Registration" [formGroup]="signupForm">
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>First name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeFirst($event.target.value)" (keypress)="onKeyPress($event)" formControlName="firstName" matInput type="text"
                                    placeholder="First name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['firstName'].hasError('required')">First name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateFirstArr.includes(true)">
                                Please enter valid first name.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>Last name</label>
                                <input class="form-control" maxlength="50" (change)="onChangeSecond($event.target.value)" (keypress)="onKeyPress($event)" formControlName="lastName" matInput type="text"
                                    placeholder="Last name" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['lastName'].hasError('required')">Last
                                name is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && validateSecondArr.includes(true)">
                                Please enter valid last
                                name.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Street no. and name.</label>
                                <input class="form-control" formControlName="street" matInput type="text"
                                    placeholder="Street no. and name." />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['street'].hasError('required')">Street no. and name are required.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Additional details (unit #, name of business, etc)</label>
                                <input class="form-control" formControlName="localAddress" matInput type="text"
                                    placeholder="Additional details (unit #, name of business, etc)" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">City, state/province and country.</label>
                                <input class="form-control" matInput placeholder="Search for your city, state/province and country" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete #placesRef="ngx-places"
                                            (keyup)="onKeyUp($event.target.value)" (change)="onAddChanged($event.target.value)"
                                            (onAddressChange)="handleAddressChnage($event)"
                                            formControlName="address" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['address'].hasError('required')">City, state/province and country are required.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Zip code</label>
                                <input class="form-control" formControlName="zipCode" matInput type="text"
                                    placeholder="Zip code" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['zipCode'].hasError('required')">Zip code
                                is required.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">Email address</label>
                                <input class="form-control" formControlName="email" matInput type="email"
                                    placeholder="Email address" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('required')">Email is
                                required.</mat-error>
                            <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('pattern')">Please enter valid
                                email.</mat-error>
                        </div>
                        <div class="form-group number">
                            <!-- <mat-form-field [floatLabel]="'never'"> -->
                                <label class="algn-lft">Phone number</label>
                            <ngx-intl-tel-input [inputId]="'adsasd'" matInput [cssClass]="'custom'"
                                [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                                [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                            </ngx-intl-tel-input>
                            <!-- </mat-form-field> -->
                            <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('required')">Phone
                                number is required.</mat-error>
                                <mat-error
                                *ngIf="submitted && signupForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                                Please enter valid number.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label class="algn-lft">DL number</label>
                                <input class="form-control" formControlName="dlNumber" matInput type="text"
                                    placeholder="DL number" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['dlNumber'].hasError('required')">DL number
                                is required.</mat-error>
                        </div>
                        <!-- <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <input class="form-control" formControlName="password" matInput type="password"
                                    placeholder="Password" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('required')">
                                Password is required.</mat-error>
                            <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <input class="form-control" formControlName="confirmPassword" matInput type="password"
                                    placeholder="Confirm Password" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('required')">
                                Confirm password is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('mustMatch')">
                                Password and confirm password must match.</mat-error>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div> -->
                        <div class="form-group check pre-check">
                            <mat-checkbox formControlName="term" class="example-margin">By clicking this box you agree to share this information with Apptunix and receive notifications and announcements over email or SMS.</mat-checkbox>
                            <mat-error
                            *ngIf="submitted && signupForm.controls['term'].hasError('required')">
                            Checkbox is required.</mat-error>
                        </div>
                        <div class="flex submit_btn">
                            <button class="btn btn-primary" (click)="goToVerificationPopup()">
                                Submit
                            </button>
                        </div>
                    </form>
                    <!-- <p class="dont_account">
                        Do you have an account? <a (click)="goTologin()">Login</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="login_images"></div> -->
</div>
<div id="verification" class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <form [formGroup]="verificationTypeForm">
                <div class="modal-body">
                    <h4>Let's verify your information</h4>
                    <p>Security and safety are very important for us.</p>
                    <div class="rb">
                        <mat-radio-group aria-label="Select an option" formControlName="verifyBy">
                            <mat-radio-button value="email">{{email}}</mat-radio-button>
                            <mat-radio-button value="phone" [checked]="true">{{phoneNo}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="flex signup_btn">
                        <button class="btn btn-primary" (click)="goToriderotppage()">
                            Continue
                        </button>
                    </div>
                </div>
            </form>

        </div>

    </div>
</div>