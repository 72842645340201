import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiList } from './api-list';
import { apiListUser } from './api-list';

@Injectable({
  providedIn: 'root'
})
export class HttpsService {
  baseUrl:string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private apiList: apiList,
    private apiListUser: apiListUser
    ) { }

  httpPost(url:string, params) {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }

  httpPostWithHeader(url:string, params: any) {
    return this.http.post(this.baseUrl + this.apiList[url] , params, this.header());
  }

  httpGetWithHeader(url:string, params) {
    return this.http.get(this.baseUrl + this.apiList[url] +"?"+params, this.header());
  }

  httpGet(url:string) {
    return this.http.get(this.baseUrl+ this.apiList[url]);
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append('image', fileData, fileData.name);
    const headers = new HttpHeaders({
      "mimeType": "multipart/form-data",
      authorization: localStorage.accessToken
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiList['uploadFile'], formData, headersObj);
  }

  userFileUpload(fileData: any) {
    var formData = new FormData();
    formData.append('image', fileData, fileData.name);
    const headers = new HttpHeaders({
      "mimeType": "multipart/form-data",
      authorization: localStorage.accessToken
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiListUser['uploadFile'], formData, headersObj);
  }

  userFileUploadWithoutAuth(fileData: any) {
    var formData = new FormData();
    formData.append('image', fileData, fileData.name);
    const headers = new HttpHeaders({
      "mimeType": "multipart/form-data"
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiListUser['uploadFileWithOutAuth'], formData, headersObj);
  }

  header() {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      const headers = new HttpHeaders({
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
        // "mimeType": "multipart/form-data",
        authorization: localStorage.accessToken
      });
      const option = {
        headers
      };
      return option;
    }
  }

  multipartHeader() {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      const headers = new HttpHeaders({
        'cache-control': 'no-cache',
        // 'Content-Type': 'application/json',
        "mimeType": "multipart/form-data",
        authorization: localStorage.accessToken
      });
      const option = {
        headers
      };
      return option;
    }
  }

  multipartHeaderWithoutAuth() {
    const headers = new HttpHeaders({
      'cache-control': 'no-cache',
      // 'Content-Type': 'application/json',
      "mimeType": "multipart/form-data",
    });
    const option = {
      headers
    };
    return option;
  }

  // user

    
  httpPostUser(url:string, params) {
    return this.http.post(this.baseUrl + this.apiListUser[url], params);
  }

  httpPostWithHeaderUser(url:string, params: any) {
    return this.http.post(this.baseUrl + this.apiListUser[url] , params, this.header());
  }

  httpGetWithHeaderUser(url:string, params) {
    return this.http.get(this.baseUrl + this.apiListUser[url] +"?"+params, this.header());
  }

  httpGetUser(url:string) {
    return this.http.get(this.baseUrl+ this.apiListUser[url]);
  }

  httpPostWithFormDataHeader(url:string, params: any) {
    return this.http.post(this.baseUrl + this.apiListUser[url] , params, this.multipartHeader());
  }
  httpPostWithFormDataWithoutAuth(url:string, params: any) {
    return this.http.post(this.baseUrl + this.apiListUser[url] , params, this.multipartHeaderWithoutAuth());
  }

}