<div class="welcome_wrap">
    <div class="conta_iner">
        <div class="welcome">
            <div class="login_form">
                <figure><img src="assets/images/welcome.svg"></figure>
                <h3>Welcome</h3>
                <div class="title_bar">
                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                </div>
                <div class=""> <button class="driverap_btn" (click)="goToriderequest()">Lets start</button></div>
            </div>
        </div>
    </div>
</div>