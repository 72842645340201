// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://demo.appdukaan.com:4015/api/v1/',
  imageUrl: 'https://appgrowthcompany.com:4015',
  socketUrl: 'https://appgrowthcompany.com:4015',
  webUrl: 'https://appgrowthcompany.com/u2go_web/#'
  // baseUrl: 'https://apiu2gosafe.u2go.ca/api/v1/',
  // imageUrl: 'https://u2go.s3.ca-central-1.amazonaws.com',
  // socketUrl: 'https://apiu2gosafe.u2go.ca',
  // webUrl: 'https://u2go.ca'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
