
 
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div class="download-popuptitle">
                    <h4>Download Your Driver Application</h4>
                    <p>lipsum as it is sometimes known, is dummy text used in laying out print</p>
                </div>
              <div class="driverapp-download-link">
                  <div class="driverapp-item">
                      <figure>
                          <img src="assets/uimages/google_store.png" alt="">
                      </figure>
                  </div>
                  <div class="driverapp-item">
                    <figure> <img src="assets/uimages/app_store.png" alt=""></figure>
                  </div>
              </div>
  
            </div>
       