import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import *  as  countryCodeData from '../core/countryCode.json';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { takeUntil, take } from 'rxjs/operators';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit, AfterViewInit {
  nextPopup: any = 0;
  phoneNo = new FormControl("", Validators.required);
  selectedCountryCode: string;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  signupForm: FormGroup
  submitted: boolean = false;
  isPhoneValid: boolean = true;


  constructor(private router: Router,
    private httpService: HttpsService,
    private commponService: CommonService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      phoneNo: [null, Validators.required]
    })
  }

  ngAfterViewInit() {
  }

  getErrorMessage() {
    if (this.phoneNo.hasError("required")) {
      return "Plz Enter Mobile Number!";
    }
    return this.phoneNo.hasError("required") ? "Plz Enter Mobile Number!" : "";
  }




  continue(popupType) {
    this.nextPopup = popupType;
  }

  goTologin() {
    this.router.navigate(["login"]);
  }

  goToverification() {
    this.submitted = true
    if (this.signupForm.invalid) {
      return;
    }
    var codeLength = this.signupForm.value.phoneNo.dialCode.length;
    var param = {
      phoneNo: this.signupForm.value.phoneNo.e164Number.slice(codeLength),
      countryCode: this.signupForm.value.phoneNo.dialCode
    }
    this.httpService.httpPost('checkIfPhoneNumberExist', { phoneNo: this.signupForm.value.phoneNo.e164Number.slice(codeLength) }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isPhoneValid = res.data.isValid;
        if(this.isPhoneValid) {
          this.httpService.httpPost('sendOtp',param).subscribe((res: any) => {
            if(res.statusCode == 200) {
              localStorage.clear();
              this.commponService.OpenNotificationModal('success','top-end',res.message);
              this.commponService.setVerificationData({countryCode: res.data.countryCode, phoneNo: res.data.phoneNo,otpCode: res.data.otpCode});
              this.router.navigate(["/verification"]);
            }
          });
        }
      }
    })
  }

  goToSignupStep(type) {
    this.commponService.driverType = type;
    this.nextPopup = 1;
  }

  goTouhomepage() {
    this.router.navigate(["/uhomepage"]);
  }
}
