<footer class="all_sec footer">
    <div class="conta_iner">
        <div class="footer_main">
            <div class="footer_links">
                <div class="footer_logo_bx">
                    <div class="footer_logo">
                        <figure>
                            <img src="assets/uimages/logo.png" routerLink="/uhomepage" alt="" />
                        </figure>
                        <p>You can find us in Social Media too</p>
                    </div>
                    <ul class="social_icons">
                        <li>
                            <a (click)="goToFbPage()" style="cursor: pointer;">
                                <img src="assets/uimages/facebook.png" alt="" />
                            </a>
                        </li>
                        <li>
                            <a (click)="goToInstaPage()" style="cursor: pointer;">
                                <img src="assets/uimages/instagram.png" alt="" />
                            </a>
                        </li>
                        <li>
                            <a (click)="goToLinkedInPage()" style="cursor: pointer;">
                                <img src="assets/uimages/linkedin.png" alt="" />
                            </a>
                        </li>
                        <li>
                            <a (click)="goToTwitterPage()" style="cursor: pointer;">
                                <img src="assets/uimages/twitter.png" alt="" />
                            </a>
                        </li>
                    </ul>
                    <div class="accept">
                        <h3>We Accept</h3>
                        <img src="assets/uimages/accept.png" alt="" />
                    </div>
                </div>
                <div class="footer_quicklinks_bx">
                    <h3>Quick Links</h3>
                    <div class="quick_links_list">
                        <ul>
                            <!-- <li><a routerLink="/">Home</a></li> -->
                            <li *ngIf="router.url !== '/uhomepage'"><a id="whyId" (click)="scrollUp('why_id')" style="cursor: pointer;">Why Apptunix™ </a></li>
                            <li *ngIf="router.url === '/uhomepage'"><a id="whyId" href="#why_id" style="cursor: pointer;">Why Apptunix™ </a></li>
                            <li><a routerLink="/udriver" style="cursor: pointer;">Drivers </a></li>
                            <li><a routerLink="/urider" style="cursor: pointer;">Riders</a></li>
                            <li><a routerLink="/udeliverypartners" style="cursor: pointer;">Delivery Partners</a></li>
                            <li><a (click)="goToCmsPages('/cms/FAQs')" style="cursor: pointer;">FAQs </a></li>
                        </ul>
                        <ul>
                            <li><a routerLink="/customer-services" style="cursor: pointer;">Customer Service </a></li>
                            <li class="gray"><span style="cursor: not-allowed;font-size: 1.6rem;line-height: 4.0rem;">Track your ride or delivery </span></li>
                            <li><a (click)="goToCmsPages('/cms/privacy-policy')" style="cursor: pointer;">Privacy Policy </a></li>
                            <li><a (click)="goToCmsPages('/cms/contact-support')" style="cursor: pointer;">Contact Support </a></li>
                            <li><a (click)="goToCmsPages('/cms/legal')" style="cursor: pointer;">Legal </a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-----footer bottom-->
        </div>
    </div>
    <div class="footer_bottom">
        <p>© {{currentYear}} All Rights Reserved by Apptunix™ <span>SSL Certification</span></p>
    </div>
</footer>