<div class="login_wrap top">
    <!-- <div class="back_home">
        <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
    </div> -->
    <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" style="cursor: pointer;" routerLink="/uhomepage">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" style="cursor: pointer;" routerLink="/uhomepage">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="login">
        <div class="conta_iner">
            <div class="login_main">
                <div class="policy_form center policy">
                    <div class="login_img_sec">
                        <!-- <figure class="login_img">
                            <img src="assets/uimages/login.svg" />
                        </figure> -->
                        <h4>Terms of services</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
                    </div>

                   
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="login_images"></div> -->
</div>