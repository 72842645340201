


<div class="login_wrap top">
   <div class="signupinner">
      <div class="conta_iner">
          <div class="flex">
              <div class="haeder_left">
                  <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
                      <img src="assets/uimages/logo.png" />
                  </figure>
              </div>
              <div class="back_home">
                  <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                      Back To Home
                  </button>
              </div>
          </div>
      </div>
  </div>
   <div class="login_sec">
       <div class="conta_iner">
         <div class="verify-email">
            <figure><img src="assets/images/verify_email_logo.png"></figure>
            <h3>Verifying your email</h3>
            <p>Please check your email account for <b>{{commonService?.emailForVerification}}</b> and click the "verify link
               inside".</p>
            <div class="btn_outer">
               <button class="btn btn-primary margin_top20" (click)="goToNextPage()">
                  Next
               </button>
               <button class="blue-c" style="cursor: pointer;" (click)="sendOTP()">Resend</button>
            </div>
         </div>
       </div>
   </div>
</div>
