import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ForgetpasswordFComponent } from './modules/functional/forgetpassword/forgetpassword.component';
import { LoginFComponent } from './modules/functional/login/login.component';
import { ProfileFComponent } from './modules/functional/profile/profile.component';
import { ProfilesetupFComponent } from './modules/functional/profilesetup/profilesetup.component';
import { RequestacceptFComponent } from './modules/functional/requestaccept/requestaccept.component';
import { RidercongratulationsFComponent } from './modules/functional/ridercongratulations/ridercongratulations.component';
import { RiderdeliverybookingFComponent } from './modules/functional/riderdeliverybooking/riderdeliverybooking.component';
import { RiderdetailFComponent } from './modules/functional/riderdetail/riderdetail.component';
import { RideremailFComponent } from './modules/functional/rideremail/rideremail.component';
import { RiderequestFComponent } from './modules/functional/riderequest/riderequest.component';
import { RiderloginFComponent } from './modules/functional/riderlogin/riderlogin.component';
import { RiderotppageFComponent } from './modules/functional/riderotppage/riderotppage.component';
import { RidersignupFComponent } from './modules/functional/ridersignup/ridersignup.component';
import { SignupFComponent } from './modules/functional/signup/signup.component';
import { TrackingFComponent } from './modules/functional/tracking/tracking.component';
import { UbusinesspartnersFComponent } from './modules/functional/ubusinesspartners/ubusinesspartners.component';
import { UdeliverypartnersFComponent } from './modules/functional/udeliverypartners/udeliverypartners.component';
import { UdriverFComponent } from './modules/functional/udriver/udriver.component';
import { UhomepageFComponent } from './modules/functional/uhomepage/uhomepage.component';
import { UnavbarFComponent } from './modules/functional/unavbar/unavbar.component';
import { UriderFComponent } from './modules/functional/urider/urider.component';
import { VechicleFComponent } from './modules/functional/vechicle/vechicle.component';
import { VerificationFComponent } from './modules/functional/verification/verification.component';
import { VerifyemailFComponent } from './modules/functional/verifyemail/verifyemail.component';
import { WelcomeFComponent } from './modules/functional/welcome/welcome.component';
import { ParentService } from './services/parent/parent.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'wingman';
  latitude: number;
  longitude: number;
  funtionalRoute: ({ path: string; component: any; redirectTo?: undefined; pathMatch?: undefined; } | { path: string; redirectTo: string; pathMatch: string; component?: undefined; })[];
  display = 'none';
  data = '';
  constructor(private router: Router,
    private http: HttpClient,
    public parentService: ParentService) {
    // this.setFunctionalRoute();
  }

  ngOnInit() {
    // this.getCurrentLocation();
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   if (localStorage.whyU2go === undefined) {
    //     console.log('app,ok')
    //     window.scrollTo(0, 0)
    //   }
    // });
    // this.parentService.openNotificationModal.subscribe((res: any) => {
    //   this.display = res.style;
    //   this.data = res.data;
    //   // document.body.click();
    // });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log('location', this.latitude, this.longitude);
        if (this.latitude !== 0 && this.longitude !== 0) {
          this.httpPostUser('user/checkPrelaunchLocation', { latitude: this.latitude, longitude: this.longitude }).subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.parentService.isPreLaunch = res.data.geofenceData.isPreLaunch;
              if (!res.data.geofenceData.isPreLaunch) {
                this.router.resetConfig(this.funtionalRoute);
              }
            }
          });
        }
      });
    }
  }

  httpPostUser(url: string, params) {
    return this.http.post(environment.baseUrl + url, params);
  }

  setFunctionalRoute() {
    this.funtionalRoute = [
      {
        path: "homepage",
        component: UhomepageFComponent,
        // canActivate: [AuthGuard]
      },
      {
        path: "unavbar",
        component: UnavbarFComponent,
      },
      {
        path: "login",
        component: LoginFComponent
      },
      {
        path: "signup",
        component: SignupFComponent,
      },
      {
        path: "verification",
        component: VerificationFComponent,
      },

      {
        path: "profile",
        component: ProfileFComponent,
      },
      {
        path: "vechicle",
        component: VechicleFComponent,
      },
      {
        path: "welcome",
        component: WelcomeFComponent,
      },
      {
        path: "riderequest",
        component: RiderequestFComponent,
      },
      {
        path: "tracking/:bookingId",
        component: TrackingFComponent,
      },
      {
        path: "profilesetup",
        component: ProfilesetupFComponent,
      },
      {
        path: "forgetpassword",
        component: ForgetpasswordFComponent,
      },

      {
        path: "udriver",
        component: UdriverFComponent,
      },
      {
        path: "urider",
        component: UriderFComponent,
        // canActivate: [AuthGuard]
      },
      {
        path: "udeliverypartners",
        component: UdeliverypartnersFComponent,
      },
      {
        path: "ubusinesspartners",
        component: UbusinesspartnersFComponent,
      },
      {
        path: "riderlogin",
        component: RiderloginFComponent,
        // canActivate: [AuthGuard]
      },
      {
        path: "ridersignup",
        component: RidersignupFComponent,
      },
      {
        path: "delivery-partner-signup",
        component: RidersignupFComponent,
      },
      {
        path: "requestaccept",
        component: RequestacceptFComponent,
      },
      {
        path: "riderdetail",
        component: RiderdetailFComponent,
      },
      {
        path: "ridercongratulations",
        component: RidercongratulationsFComponent,
      },
      {
        path: "riderotppage",
        component: RiderotppageFComponent,
      },
      {
        path: "riderdeliverybooking",
        component: RiderdeliverybookingFComponent,
      },
      {
        path: "rideremail",
        component: RideremailFComponent,
      },
      {
        path: "verifyemail",
        component: VerifyemailFComponent,
      },
      {
        path: '**',
        redirectTo: 'homepage',
        pathMatch: 'full'
      }
    ];
  }

  close() {
    this.display = 'none';
  }
}
