import { Component, OnInit, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: "app-urider",
  templateUrl: "./urider.component.html",
  styleUrls: ["./urider.component.scss"],
})
export class UriderFComponent implements OnInit {

  bookingForm: FormGroup;
  pickup = {
    lat: 0,
    lng: 0
  }
  drop = {
    lat: 0,
    lng: 0
  }
  pickupAddress: any;
  geoCoder: any;
  zoom: number;
  dropAddress: any;
  origin: { lat: number; lng: number; };
  destination: { lat: number; lng: number; };
  distance: number;
  rideSubmited: boolean = false;
  slides = [
    {content: "Multi pick-up option (Friends or families waiting in different locations)",main: "Multi pick-up", img: "assets/images/multiple-pickp.png"},
    {content: "Favorite Driver  (All your dispatches go to him/her first)",main: "Favorite Driver", img: "assets/images/favourite-driver.png"},
    {content: "No Driver GPS Off (Drives are not able to select a different route unless you ask them to)",main: "No Driver GPS Off", img: "assets/images/gps-off.png"},
    {content: "Multi drop-off option (Friends or families left in different locations)",main: "Multi drop-off option", img: "assets/images/multiple-drop.png"},
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 999,
    // arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpsService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.bookingForm = this.fb.group({
      ridePickUpAddress: [''],
      rideDropAddress: ['']
    });
    this.getCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  onKeyUp(value) {
    if (value === "") {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.bookingForm.get('ridePickUpAddress').patchValue(null);
    }
  }

  pickupChange(value) {
    if (this.pickup.lat === 0 && this.pickup.lng === 0) {
      this.bookingForm.get('ridePickUpAddress').patchValue(null);
      this.rideSubmited = true;
    } else if(this.pickupAddress !== value) {
      this.bookingForm.get('ridePickUpAddress').patchValue(null);
      this.rideSubmited = true;
    }
  }
  onKeyUpDrop(value) {
    if (value === "") {
      this.drop.lat = 0;
      this.drop.lng = 0;
      this.bookingForm.controls['rideDropAddress'].patchValue(null);
    }
  }

  dropupChange(value) {
    if (this.drop.lat === 0 && this.drop.lng === 0) {
      this.bookingForm.controls['rideDropAddress'].patchValue(null);
      this.rideSubmited = true;
    } else if(this.dropAddress !== value) {
      this.bookingForm.controls['rideDropAddress'].patchValue(null);
      this.rideSubmited = true;
    }
  }
  
  handlePickUpChnage(address) {
    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickupAddress = (address.formatted_address);
      this.bookingForm.get('ridePickUpAddress').patchValue(this.pickupAddress);
      this.pickup.lat = (address.geometry.location.lat())
      this.pickup.lng = (address.geometry.location.lng())
      this.getPickupAddress(this.pickup, address.formatted_address);
    }
  }

  handleDropChnage(address) {
    console.log(address);
    this.drop.lat = 0;
    this.drop.lng = 0;
    if (address.formatted_address) {
      this.dropAddress = (address.formatted_address);
      this.drop.lat = (address.geometry.location.lat());
      this.drop.lng = (address.geometry.location.lng());
      this.getAddressDrop(this.drop, address.formatted_address);
    }
    this.getDirection();
  }

  getPickupAddress(latLng, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log('exist');
            this.bookingForm.controls['ridePickUpAddress'].patchValue(addresses.formatted_address);
            this.pickupAddress = addresses.formatted_address;
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log('not-exist');
          this.bookingForm.controls['ridePickUpAddress'].patchValue(results[0].formatted_address);
          this.pickupAddress = results[0].formatted_address;
        }
        this.commonService.origin = {lat: latLng.lat, lng: latLng.lng, address: this.pickupAddress};
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  getAddressDrop(latLng, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      console.log(results);
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log('exist');
            this.bookingForm.controls['rideDropAddress'].patchValue(addresses.formatted_address);
            this.dropAddress = addresses.formatted_address;
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log('not-exist');
          this.bookingForm.controls['rideDropAddress'].patchValue(results[0].formatted_address);
          this.dropAddress = results[0].formatted_address;
        }
        this.commonService.destination = {lat: latLng.lat, lng: latLng.lng, address: this.dropAddress};
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDirection() {
    if ((this.pickup.lng !== null) && (this.pickup.lng !== null) && (this.drop.lat !== null) && (this.drop.lng !== null)) {
      this.origin = { lat: this.pickup.lat, lng: this.pickup.lng };
      this.destination = { lat: this.drop.lat, lng: this.drop.lng };
      this.calculateDistance();
    }
  }

  calculateDistance() {
    const start = new google.maps.LatLng(this.pickup.lat, this.pickup.lng);
    const end = new google.maps.LatLng(this.drop.lat, this.drop.lng);
    this.distance = google.maps.geometry.spherical.computeDistanceBetween(start, end) / 1000; // distance in KM
    return this.distance;
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.pickup.lat = position.coords.latitude;
        this.pickup.lng = position.coords.longitude;
        this.zoom = 15;
        this.getPickupAddress(this.pickup,'');
      });
    }
  }

  goToriderequest() {
    this.rideSubmited = true
    if(this.bookingForm.valid) {
      this.goToriderlogin();
      // this.router.navigate(["/riderlogin"]);
    }
  }
  goToriderlogin() {
    // this.router.navigate(["/riderlogin"]);
    this.commonService.isThisDeliveryPartner = false;
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.getItem('userType') === 'RIDER') {
        this.http.httpGetWithHeaderUser('userProfile', "").subscribe((res: any) => {
          if (res.data.isEamilVerified === true || res.data.isPhoneVerified === true) {
            if (res.data.isAccountCompleted) {
              this.router.navigate(["/riderequest"]);
            } else {
              this.router.navigate(["/riderlogin"]);
              // var detail = {
              //   isAddressInfo: true,
              //   isNewUser: false,
              //   isAccountCompleted: false
              // }
              // this.commonService.setRiderProfileAccessDetail(detail);
              // this.router.navigate(["/riderdetail"]);
            }
          } else {
            // this.http.httpPostWithHeaderUser('sendOtp', { phoneNo: res.data.phoneNo }).subscribe((item: any) => {
            //   this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
            //   this.router.navigate(["/riderotppage"]);
            // });
            this.router.navigate(["/riderlogin"]);
          }
        })
      } else {
        this.router.navigate(["/riderlogin"]);
      }
    } else {
      this.router.navigate(["/riderlogin"]);
    }
  }
  
  goToridersignup() {
    this.router.navigate(["/ridersignup"]);
  }
}
