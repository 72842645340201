import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalFComponent implements OnInit, OnDestroy {
  title: string;
  message: string;
  id: NodeJS.Timeout;
  constructor(public dialogRef: MatDialogRef<NotificationModalFComponent>,
    private ngZone: NgZone,
    public commonService: CommonService) { }

  ngOnInit(): void {
    // this.id = setTimeout(() => {
    //   this.dialogRef.close();
    // }, 5000);
  }

  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  ngOnDestroy() {
    // if (this.id) {
    //   clearInterval(this.id);
    // }
  }
}
