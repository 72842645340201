import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-f-unavbar",
  templateUrl: "./unavbar.component.html",
  styleUrls: ["./unavbar.component.scss"],
})
export class UnavbarFComponent implements OnInit {

  navLinkArr = [
    { name: 'Home', url: 'uhomepage', isActiveClass: false },
    { name: 'Drivers', url: 'udriver', isActiveClass: false },
    { name: 'Riders', url: 'urider', isActiveClass: false },
    { name: 'Delivery Partners', url: 'udeliverypartners', isActiveClass: false },
    { name: 'Business Partners', url: 'ubusinesspartners', isActiveClass: false },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.activeClass();
  }

  activeClass() {
    this.navLinkArr.forEach((data: any) => {
      if(this.router.url.search(data.url) === 1) {
        data.isActiveClass = true;
      }
    });
  }

  
}
