import { Component, OnInit, ViewChild, ElementRef, NgZone, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from '../services/common/common.service';
import { HttpsService } from '../services/https/https.service';
import { MatStepper } from '@angular/material/stepper';
import { MapsAPILoader } from '@agm/core';
import { MustMatch } from '../core/must-match.validator';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { count } from 'console';
declare var $: any;
declare var google: any;
interface Ready {
  value: string;
  viewValue: string;
}
interface driver {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileFComponent implements OnInit, AfterViewInit, AfterViewChecked {
  isLinear: boolean = false;
  personalFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  bankFormGroup: FormGroup;
  vehicleFormGroup: FormGroup;
  drivingFormGroup: FormGroup;
  regulatoryFormGroup: FormGroup;
  documentFormGroup: FormGroup;

  status: boolean = false;

  pick: Ready[] = [
    { value: "5 Days", viewValue: "lorem" },
    { value: "1 Days", viewValue: "lorem" },
  ];
  pick2: driver[] = [
    { value: "DELIVERY", viewValue: "Delivery Driver " },
    { value: "RIDE", viewValue: "Ride Driver" },
    { value: "BOTH", viewValue: "Both " },
  ];
  pick4: driver[] = [
    { value: "male", viewValue: "Male" },
    { value: "female", viewValue: "Female" },
    { value: "others", viewValue: "Others" },
  ];

  modalYear = [];
  addDriverForm: FormGroup;
  fileDataUrl: any;
  allFileUploadUrl = {
    proImage: '',
    voidCheque: '',
    dlImage: '',
    dlImageFront: '',
    dlImageBack: '',
    criminalBg: '',
    drivingVoidCheque: '',
    insuranceImage: '',
    registrationImage: '',
    inspectionImage: '',
    abuseRegisterCheck: '',
    licensePlateImage: '',
    vehicleImage: ''
  };
  @ViewChild('stepper', { static: false }) public stepper: MatStepper;
  imageSrc: string;
  public searchControl: FormControl;
  @ViewChild("search") public searchElementRef: ElementRef;
  @ViewChild("stateSearch") public stateElementRef: ElementRef;
  submitted: boolean = false;
  phoneNo: any;
  countryCode: any;
  location: any;
  public onStepsChange: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public onStepBack: BehaviorSubject<boolean> = new BehaviorSubject(null);
  stateLocation: string;
  vehicleYears: any[];
  requiredDocumentsData: any = [];
  documentsImages: any = {};
  pickup = {
    lat: 0,
    lng: 0
  }
  drop = {
    lat: 0,
    lng: 0
  }
  pickupAddress: any;
  geoCoder: any;
  zoom: number;
  dropAddress: any;
  origin: { lat: number; lng: number; };
  destination: { lat: number; lng: number; };
  distance: number;
  minDate;
  isVehicleSubmit: boolean = false;
  getVehicleCategoryData: any = [];
  editable: boolean = true;
  inValidEmail: boolean = false;
  driverLatLng: any = { lat: 0, lng: 0 };
  regularityFormSubmitted: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private httpService: HttpsService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 31);
    this.minDate = tomorrow;
    console.log(this.minDate,'minDate');
  }

  ngOnInit(): void {
    this.createForm();
    this.commonService.getVerificationData().subscribe((res: any) => {
      if (res) {
        this.countryCode = res.countryCode;
        this.phoneNo = res.phoneNo;
        this.personalFormGroup.get('phoneNumber').patchValue(res.countryCode + ' ' + res.phoneNo);
      }
    });
    this.onStepsChange.subscribe((res: any) => {
      if (res) {
        this.cdr.detectChanges();
        this.stepper.selectedIndex++;
        this.isLinear = true;
      }
    });
    this.onStepBack.subscribe((res: any) => {
      if (res) {
        this.cdr.detectChanges();
        this.stepper.selectedIndex--;
        this.isLinear = true;
      }
    });
    this.getVehicleYear();
    // this.getCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.userType === 'driver') {
        this.getDriverDetails();
        this.httpService.httpGetWithHeader('getDriverProfile', "").subscribe((res: any) => {
          if (res.statusCode === 200) {
            if (res.data.driverData.zoneId && res.data.driverData.geofenceId) {
              this.getVehicleCatData(res.data.driverData.zoneId, res.data.driverData.geofenceId);
            }
            //  else {
            //   this.commonService.driverAlert();
            // }
          }
        })
      }
    }

  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address)
      this.addressFormGroup.get('searchControl').patchValue(address.formatted_address);
      var lat = (address.geometry.location.lat())
      var lng = (address.geometry.location.lng())
      this.driverLatLng.lat = lat;
      this.driverLatLng.lng = lng;
      this.getAddress(lat, lng);
    }
  }

  handleStateChnage(address) {
    if (address.formatted_address) {
      this.drivingFormGroup.get('dlState').patchValue(address.formatted_address);
      let lat = (address.geometry.location.lat());
      let lng = (address.geometry.location.lng());
      console.log(address.formatted_address, lat, lng)
      var arr = address.formatted_address.split(',');
      var country = arr[arr.length - 1];
      this.drivingFormGroup.get('dlCountry').patchValue(country);
      this.getCountry(lat, lng);
    }
  }

  getCountry(lat, lng) {
    this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        console.log(results);
        if (results[0]) {
          this.pickupAddress = results[0].formatted_address;

          // this.addressFormGroup.get('zipCode').patchValue(geocode);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  getAddress(lat, lng) {
    this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.pickupAddress = results[0].formatted_address;
          var geocode = results[0].address_components[results[0].address_components.length - 1].long_name;
          this.addressFormGroup.get('zipCode').patchValue(geocode);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  getVehicleYear() {
    let date = new Date().getFullYear();
    let edate = date - 10;
    this.vehicleYears = [];
    for (let i = edate; i <= date; i++) {
      this.vehicleYears.push(i);
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.userType === 'driver') {
        this.httpService.httpGetWithHeader('getDriverProfile', "").subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.editable = true;
            this.isLinear = false;
            this.stepper.selectedIndex = 4;
            if (!res.data.driverData.firstName && !res.data.driverData.lastName) {
              this.stepper.selectedIndex = 0;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isFilledBankDetail) {
              this.stepper.selectedIndex = 2;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isDriverInfo) {
              this.stepper.selectedIndex = 3;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isVehicleInfo && !res.data.driverData.isVehicleAbout && res.data.driverData.zoneId) {
              this.stepper.selectedIndex = 4;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.zoneId) {
              this.commonService.driverAlert();
              return;
            } else if (!res.data.driverData.isRegularCompliance) {
              this.stepper.selectedIndex = 5;
              this.isLinear = true;
              this.editable = false;
            } else if (res.data.driverData.isNewUser) {
              this.stepper.selectedIndex = 0;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isUploadDocument || !res.data.driverData.isAccountCompleted) {
              this.stepper.selectedIndex = 6;
              this.isLinear = true;
              this.editable = false;
            }
          }
        })
      }
    } else {
      this.editable = false;
      this.isLinear = true;
      if (localStorage.getItem('verifiedPhone') != undefined) {
        var verifiedPhoneDetail = JSON.parse(localStorage.getItem('verifiedPhone'));
        this.commonService.driverType = verifiedPhoneDetail.driverType;
        this.phoneNo = verifiedPhoneDetail.phoneNo;
        this.countryCode = verifiedPhoneDetail.countryCode;
        this.personalFormGroup.get('phoneNumber').patchValue(verifiedPhoneDetail.countryCode + ' ' + verifiedPhoneDetail.phoneNo);
      } else {
        this.router.navigate(['/signup']);
      }
    }
    // this.commonService.getUserProfileAccessDetail().subscribe((res: any) => {
    //   if (res) {
    //     this.isLinear = false;
    //     if (res.isNewUser) {
    //       this.stepper.selectedIndex = 0;
    //       this.isLinear = true
    //       return;
    //     } else if (!res.isFilledBankDetail) {
    //       this.stepper.selectedIndex = 2;
    //       this.isLinear = true
    //     } else if (!res.isDriverInfo) {
    //       this.stepper.selectedIndex = 3;
    //       this.isLinear = true
    //     } else if (!res.isVehicleInfo) {
    //       this.stepper.selectedIndex = 4;
    //       this.isLinear = true
    //     } else if (!res.isRegularCompliance) {
    //       this.stepper.selectedIndex = 5;
    //       this.isLinear = true
    //     } else if (res.isNewUser) {
    //       this.stepper.selectedIndex = 0;
    //       this.isLinear = true
    //     } else if(!res.isUploadDocument) {
    //       this.stepper.selectedIndex = 6;
    //       this.isLinear = true
    //     }
    //   }
    // });

  }

  goToPersonalTab() {
    this.editable = true;
    this.isLinear = false;
    // this.stepper.selectedIndex = 0;
    this.onStepBack.next(true);
  }

  goToBack() {
    this.router.navigate(['udriver']);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  createForm() {
    this.personalFormGroup = this._formBuilder.group({
      firstName: new FormControl(null, [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]),
      lastName: new FormControl(null, [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]),
      'email': new FormControl(null, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      'phoneNumber': new FormControl(null, Validators.required),
      'dlNumber': new FormControl(null, Validators.required),
    });
    this.bankFormGroup = this._formBuilder.group({
      'accNumber': new FormControl(null, Validators.required),
      'transit': new FormControl(null, Validators.required),
      'bankName': new FormControl(null, Validators.required),
      'branchAdd': new FormControl(null, Validators.required),
      'voidCheque': new FormControl(null, Validators.required),
    });
    this.vehicleFormGroup = this._formBuilder.group({
      'vehicleMake': new FormControl(null, Validators.required),
      'vehicleModel': new FormControl(null, Validators.required),
      'vehicleYear': new FormControl(null, Validators.required),
      'insuranceExpiry': new FormControl(null, Validators.required),
      'insuranceImage': new FormControl(null, Validators.required),
      'registrationImage': new FormControl(null, Validators.required),
      'inspectionImage': new FormControl(null, Validators.required),
      'inspectionExpiry': new FormControl(null, Validators.required),
      'licensePlateNo': new FormControl(null, Validators.required),
      'licensePlateImage': new FormControl(null, Validators.required),
      'policyNumber': new FormControl(null, Validators.required),
      'insuranceName': new FormControl(null, Validators.required),
      'driveCoverage': new FormControl(null, Validators.required),
      'isAcWorking': new FormControl(null, Validators.required),
      'isHeatingWorking': new FormControl(null, Validators.required),
      'isAnyDents': new FormControl(null, Validators.required),
      'isInteriorClean': new FormControl(null, Validators.required),
      'vehicleImage': new FormControl(null, Validators.required),
    });

    this.drivingFormGroup = this._formBuilder.group({
      'dlNumber': new FormControl(null, Validators.required),
      'dlExpiry': new FormControl(null, Validators.required),
      'dlState': new FormControl(null, Validators.required),
      'dlCountry': new FormControl(null, Validators.required),
      'dlImage': new FormControl(null, Validators.required),
    });
    this.regulatoryFormGroup = this._formBuilder.group({
      'criminalBg': new FormControl(null, Validators.required),
      'criminalBgExpiry': new FormControl(null, Validators.required),
      'TandC': new FormControl(null, Validators.requiredTrue),
      'childAbuseExpiry': new FormControl(null, Validators.required),
      'abuseRegisterCheck': new FormControl(null, Validators.required),
    });
    this.addressFormGroup = this._formBuilder.group({
      'searchControl': new FormControl(null, Validators.required),
      'zipCode': new FormControl(null, Validators.required),
      'password': new FormControl(null, [Validators.required, Validators.minLength(6)]),
      'confirmPass': new FormControl(null, [Validators.required, Validators.minLength(6)]),
      'streetName': new FormControl(null, Validators.required),
      'TandC': new FormControl(null, Validators.requiredTrue),
    }, {
      validator: MustMatch('password', 'confirmPass')
    });
    this.documentFormGroup = this._formBuilder.group({
    });

  }

  onProfileUpload(fileInput: any, formField) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = (event) => {
        this.imageSrc = reader.result as string;
      }
    }
    const fileData = <File>fileInput.target.files[0];
    const fileDataName = fileData.name;
    this.allFileUploadUrl[formField] = { fileData: fileData, fileName: fileDataName };
  }

  onFileChange(fileInput: any, formField) {
    const fileData = <File>fileInput.target.files[0];
    this.httpService.fileUpload(fileData).subscribe(res => {
      this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
      this.allFileUploadUrl[formField] = this.fileDataUrl;
    });
  }
  
  goToStepTwo() {
    if (this.personalFormGroup.valid) {
      this.httpService.httpPost('checkIfEmailExist', { email: this.personalFormGroup.get('email').value }).subscribe((res: any) => {
        if (res.statusCode == 200) {
          if (res.data.isValid) {
            this.stepper.selectedIndex++;
          } else {
            this.inValidEmail = true;
          }
        }
      })
    }
  }

  register(index: number, formName: string, action: string) {
    this.submitted = true
    if (!this[formName].invalid) {
      // if(localStorage.length > 0) {
      //   if(localStorage.accessToken != null || localStorage.accessToken != undefined) {
      //     if(localStorage.userType === 'driver') {
      //       let param = new HttpParams().set('userId', localStorage.userId);
      //       this.httpService.httpGetWithHeader('getDriverProfile', param).subscribe((res: any) => {
      //         if (res.data.email) {
      //           this.editUpdate = true;
      //           this.stepper.selectedIndex++;
      //           return;
      //         }
      //       });
      //     }
      //   }
      // }
      var formdata = new FormData();
      formdata.append('firstName', this.personalFormGroup.value.firstName);
      formdata.append('lastName', this.personalFormGroup.value.lastName);
      formdata.append('email', this.personalFormGroup.value.email);
      formdata.append('countryCode', this.countryCode);
      formdata.append('phoneNo', this.phoneNo);
      formdata.append('address', this.location);
      formdata.append('zipCode', this.addressFormGroup.value.zipCode);
      formdata.append('streetName', this.addressFormGroup.value.streetName);
      formdata.append('deviceType', 'WEB');
      formdata.append('deviceToken', new Date().toString());
      formdata.append('password', this.addressFormGroup.value.confirmPass);
      formdata.append('driverType', this.commonService.driverType);
      formdata.append('latitude', this.driverLatLng.lat);
      formdata.append('longitude', this.driverLatLng.lng);
      if(this.allFileUploadUrl.proImage !== '') {
        formdata.append('image', this.allFileUploadUrl.proImage);
      }        
      formdata.append('dlNumber', this.personalFormGroup.value.dlNumber);
      this.httpService.httpPost('register', formdata).subscribe((res: any) => {
        if (res.statusCode == 200) {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('userId', res.data.id);
          localStorage.setItem('userType', 'driver');
          this.stepper.selectedIndex++;
        }
      });
    }


  }

  bankFormSubmit() {
    if (this.bankFormGroup.invalid) {
      return;
    }
    var param = {
      'accountNumber': this.bankFormGroup.value.accNumber.toString(),
      'transit': this.bankFormGroup.value.transit,
      'bankName': this.bankFormGroup.value.bankName,
      'bankAddress': this.bankFormGroup.value.branchAdd,
      'checkImage': this.allFileUploadUrl.voidCheque !== "" ? this.allFileUploadUrl.voidCheque : this.fileDataUrl,
      'isFilledBankDetail': true
    }
    console.log('checkImage',param);  
    this.httpService.httpPostWithHeader('updateProfile', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isLinear = false;
        this.onStepsChange.next(true);
        var details = {
          isFilledBankDetail: true,
          isDriverInfo: false,
          isVehicleInfo: false,
          isVehicleAbout: false,
          isRegularCompliance: false,
          isNewUser: false
        }
        this.commonService.setUserProfileAccessDetail(details);
        this.drivingFormGroup.get('dlNumber').patchValue(this.personalFormGroup.value.dlNumber);
      }
    });
  }

  getDlExpiryDate(event: any) {
    this.drivingFormGroup.get('dlExpiry').patchValue(event.value);
  }

  getInsuranceExpiryDate(event: any) {
    this.vehicleFormGroup.get('insuranceExpiry').patchValue(event.value);
  }

  getInspectionExpiryDate(event: any) {
    this.vehicleFormGroup.get('inspectionExpiry').patchValue(event.value);
  }

  drivingFormSubmit() {
    if (this.drivingFormGroup.invalid) {
      return;
    }
    var param = {
      'dlNumber': this.drivingFormGroup.value.dlNumber,
      'dlExpiry': this.drivingFormGroup.value.dlExpiry,
      'dlCountry': this.drivingFormGroup.value.dlCountry,
      'dlState': this.drivingFormGroup.value.dlState,
      'dlImageFront': this.allFileUploadUrl.dlImage !== "" ? this.allFileUploadUrl.dlImage : this.fileDataUrl,
      'isDriverInfo': true
    }
    if(param.dlImageFront === "") {
      param.dlImageFront = this.allFileUploadUrl.dlImage;
    }
    console.log('image issue',param);
    // return;
    this.httpService.httpPostWithHeader('updateProfile', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isLinear = false;

        if (res.data.zoneId && res.data.geofenceId) {
          this.getVehicleCatData(res.data.zoneId, res.data.geofenceId);
          this.onStepsChange.next(true);
          var details = {
            isFilledBankDetail: true,
            isDriverInfo: true,
            isVehicleInfo: false,
            isVehicleAbout: false,
            isRegularCompliance: false,
            isNewUser: false
          }
          this.commonService.setUserProfileAccessDetail(details);
        } else {
          this.commonService.driverAlert();
        }
      }
    });
  }



  getVehicleCatData(zoneId, geofenceId) {
    if (zoneId && geofenceId) {
      var param2 = {
        zoneId: zoneId,
        geofenceId: geofenceId
      }
      this.httpService.httpPostWithHeader('getVehicleCategory', param2).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.getVehicleCategoryData = res.data.vehicleCategoryData;
        }
      });
    }
  }

  vehicleFormSubmit() {
    this.isVehicleSubmit = true;
    if (this.vehicleFormGroup.invalid) {
      return;
    }
    var param = {
      'vehicleMake': this.vehicleFormGroup.value.vehicleMake,
      'vehicleModel': this.vehicleFormGroup.value.vehicleModel,
      'vehicleImage': this.allFileUploadUrl.vehicleImage,
      'vehicleYear': this.vehicleFormGroup.value.vehicleYear.toString(),
      'licensePlate': this.allFileUploadUrl.licensePlateImage,
      'licensePlateNumber': this.vehicleFormGroup.value.licensePlateNo,
      'isAcWorking': this.vehicleFormGroup.value.isAcWorking,
      'isheatingWorking': this.vehicleFormGroup.value.isHeatingWorking,
      'isDentRust': this.vehicleFormGroup.value.isAnyDents,
      'isCleaning': this.vehicleFormGroup.value.isInteriorClean,
      'registrationCopyImage': this.allFileUploadUrl.registrationImage,
      'vehicleInspectionImage': this.allFileUploadUrl.inspectionImage,
      'inspectioneExpiryDate': this.vehicleFormGroup.value.inspectionExpiry,
      'insurancePolicyNumber': this.vehicleFormGroup.value.policyNumber,
      'insuranceName': this.vehicleFormGroup.value.insuranceName,
      'insuranceExpiryDate': this.vehicleFormGroup.value.insuranceExpiry,
      'shareDriverCoverage': this.vehicleFormGroup.value.driveCoverage,
      'insuranceImage': this.allFileUploadUrl.insuranceImage,
      'isVehicleInfo': true,
      'isVehicleAbout': true
    }
    this.httpService.httpPostWithHeader('updateProfile', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isLinear = false;
        this.onStepsChange.next(true);
        var details = {
          isFilledBankDetail: true,
          isDriverInfo: true,
          isVehicleInfo: true,
          isVehicleAbout: true,
          isRegularCompliance: false,
          isNewUser: false
        }
        this.commonService.setUserProfileAccessDetail(details);
      }
    });
  }

  regularityFormSubmit() {
    this.regularityFormSubmitted = true;
    if (this.regulatoryFormGroup.invalid) {
      return;
    }
    var param = {
      'yourBackgroundImage': this.allFileUploadUrl.criminalBg,
      'yourBackgroundCertificateExpiryDate': this.regulatoryFormGroup.value.criminalBgExpiry,
      'childAbuseCertificateImage': this.allFileUploadUrl.abuseRegisterCheck,
      'childAbuseCertificateExpiryDate': this.regulatoryFormGroup.value.childAbuseExpiry,
      'isTermAndCondition': this.regulatoryFormGroup.value.TandC,
      'isRegularCompliance': true
    }
    this.httpService.httpPostWithHeader('updateProfile', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        var details = {
          isFilledBankDetail: true,
          isDriverInfo: true,
          isVehicleInfo: true,
          isVehicleAbout: true,
          isRegularCompliance: true,
          isNewUser: false
        }
        this.commonService.setUserProfileAccessDetail(details);
        this.isLinear = false;
        this.onStepsChange.next(true);
        this.getDriverDetails();
      }
    });
  }

  getDriverDetails() {
    if (localStorage.userId === undefined || localStorage.userId === null) {
      return;
    }
    let param = new HttpParams().set('userId', localStorage.userId);
    this.httpService.httpGetWithHeader('getDriverProfile', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        var details = {
          isFilledBankDetail: res.data.driverData.isFilledBankDetail,
          isDriverInfo: res.data.driverData.isDriverInfo,
          isVehicleInfo: res.data.driverData.isVehicleInfo,
          isVehicleAbout: res.data.driverData.isVehicleAbout,
          isRegularCompliance: res.data.driverData.isRegularCompliance,
          isNewUser: false
        }
        this.commonService.setUserProfileAccessDetail(details);
        if (res.data.driverData.zoneId && res.data.driverData.geofenceId) {
          var params = {
            "zoneId": res.data.driverData.zoneId,
            "geofenceId": res.data.driverData.geofenceId,
            "pageNo": 1
          }
          this.httpService.httpPostWithHeader('getDefaultDriverDocument', params).subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.requiredDocumentsData = res.data.documentData;
              const group: any = {};
              this.requiredDocumentsData.forEach(data => {
                if (data.isMandate) {
                  if (data.isFrontSideImage) {
                    group[data.documentName + 'FrontSide'] = new FormControl(null, Validators.required);
                  }
                  if (data.isBackSideImage) {
                    group[data.documentName + 'BackSide'] = new FormControl(null, Validators.required);
                  }
                  if (data.isExpirydate) {
                    group[data.documentName + 'Expiry'] = new FormControl(null, Validators.required);
                  }
                } else {
                  if (data.isFrontSideImage) {
                    group[data.documentName + 'FrontSide'] = new FormControl(null);
                  }
                  if (data.isBackSideImage) {
                    group[data.documentName + 'BackSide'] = new FormControl(null);
                  }
                  if (data.isExpirydate) {
                    group[data.documentName + 'Expiry'] = new FormControl(null);
                  }
                }
                this.documentsImages[data.documentName + 'FrontSide'] = '';
                this.documentsImages[data.documentName + 'BackSide'] = '';
                this.documentsImages[data.documentName + 'id'] = data._id;
              });
              this.documentFormGroup = new FormGroup(group);
            }
          })
        }
      }
    });
  }

  getExpiryDateOfDocuments(event: any, formField: any) {
    this.documentFormGroup.get(formField).patchValue(event.value);
  }

  onDocumentUpload(fileInput: any, formField) {
    const fileData = <File>fileInput.target.files[0];
    this.httpService.fileUpload(fileData).subscribe(res => {
      this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
      this.documentsImages[formField] = this.fileDataUrl;
    });
  }

  documentFormSubmit() {
    if (this.documentFormGroup.invalid) {
      return;
    }
    var documents = [];
    this.requiredDocumentsData.forEach((element: any) => {
      var documentObj = $.extend({}, {
        documentId: element._id,
        documentFrontImage: element.isFrontSideImage === true ? this.documentsImages[element.documentName + 'FrontSide'] : undefined,
        documentBackImage: element.isBackSideImage === true ? this.documentsImages[element.documentName + 'BackSide'] : undefined,
        documentExpirydate: element.isExpirydate === true ? this.documentFormGroup.get(element.documentName + 'Expiry').value : undefined
      });
      documents.push(documentObj);
    });
    var param = {
      'documents': documents,
      'isUploadDocument': true
    }
    this.httpService.httpPostWithHeader('addDriverDocument', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        // this.commonService.openCongratsModal();
        this.markAsCompleteProfile();
        this.commonService.OpenNotificationModal('success','top-end','your account created successfully.')
        // this.router.navigate(["/uhomepage"]);
        // this.router.navigate(["/ridercongratulations"]);
      }
    });
  }

  markAsCompleteProfile() {
    var param = {
      isAccountCompleted: true
    }
    this.httpService.httpPostWithHeader('updateProfile', param).subscribe((res: any) => {
      if (res.statusCode === 200) {
        // this.commonService.openCongratsModal();
        this.router.navigate(["/udriver"]);
        this.commonService.goToDownloadSection.next(true);
      }
    })
  }

  goTovechicle() {
    this.router.navigate(["vechicle"]);
  }
  goTologin() {
    this.router.navigate(["login"]);
  }

  goTouhomepage() {
    this.router.navigate(["uhomepage"]);
  }
}
