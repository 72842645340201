<div class="riderequest">
    <div class="map">
        <agm-map [latitude]="pickup.lat" [longitude]="pickup.lng" [zoom]="zoom" (mapReady)="onMapReady($event)">
            <div *ngIf="!isDriveingStart">
                <agm-overlay *ngFor="let driver of nearByDriversData" [latitude]="driver?.latitude"
                    [longitude]="driver?.longitude"
                    [bounds]="{x:{latitude:-0.003,longitude:-0.0052},y:{latitude:0.003,longitude:0.0052}}">
                    <div>
                        <img style="cursor: pointer;width: 50px; height: 50px;" [src]="ubericon">
                    </div>
                </agm-overlay>
            </div>
            <div *ngFor="let pick of pickupfieldsArr">
                <agm-marker *ngIf="showPickMarker && pick?.lat !== 0 && pick?.lng !== 0" [iconUrl]="originMarkerIcon"
                    [latitude]="pick?.lat" [longitude]="pick?.lng" [markerDraggable]="true"
                    (dragEnd)="markerDragEndOrigin($event,pick)"></agm-marker>
            </div>
            <div *ngFor="let drop of dropupfieldsArr">
                <agm-marker *ngIf="showDropMarker && drop?.lat !== 0 && drop?.lng !== 0"
                    [iconUrl]="destinationMarkerIcon" [latitude]="drop?.lat" [longitude]="drop?.lng"
                    [markerDraggable]="true" (dragEnd)="markerDragEndDestination($event,drop)"></agm-marker>
            </div>
            <!-- <div *ngIf="bookingResponseData?.bookingStatus === 'ARRIVED'">
                <agm-marker [iconUrl]="driverIcon" [latitude]="pickup.lat" [longitude]="pickup.lng"
                    [markerDraggable]="false"></agm-marker>
            </div> -->
            <agm-direction *ngIf="showDirections" [origin]="origin" [destination]="destination"
                [renderOptions]="renderOptions" [markerOptions]="markerOptions" [waypoints]="waypoints">
            </agm-direction>
        </agm-map>
    </div>
    <div class="login_map_toper flex" (click)="goToprofilesetup()">
        <div class="login_map_toper_left">
            <figure>
                <img class="proImage" *ngIf="!user?.image" src="assets/images/user.png" />
                <img class="proImage" *ngIf="user?.image" [src]="baseUrl+user?.image" />
            </figure>
        </div>
        <div class="login_map_toper_right">
            <h4>{{user?.firstName ? user?.firstName : '' + " " + user?.lastName ? user?.lastName : ''}}</h4>
        </div>
        <div class="fa_arrow"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
    </div>
    <div class="ride_request_main" *ngIf="!isThisOnlyTrackingPage">
        <div class="ride_request_right">
            <div class="ride_request_right2">
                <figure class="ride_request_logo" (click)="continue(0)">
                    <img src="assets/uimages/logo.png" />
                </figure>
            </div>
            <div class="ride_request_right2 fbtn">
                <figure class="ride_request_logo" style="cursor: pointer;" (click)="goToHome()">
                    <img src="assets/uimages/logo.png" />
                </figure>
                <div class="back_home hmbtn">
                    <button class="driverap_btn" style="cursor: pointer;" (click)="goToHome()">
                        Back To Home
                    </button>
                </div>
            </div>
            <div class="request_ride_inner" *ngIf="nextPopup == 0">
                <div class="select_ride_cate">
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="ride" style="margin-right: 15px" class="choose_ride_radio">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="183" height="183" viewBox="0 0 183 183">
                                <defs>
                                    <filter id="Rectangle_3731" x="0" y="0" width="183" height="183"
                                        filterUnits="userSpaceOnUse">
                                        <feOffset dy="2" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="10" result="blur" />
                                        <feFlood flood-opacity="0.078" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                    </filter>
                                </defs>
                                <g id="Group_59548" data-name="Group 59548" transform="translate(-20 -466)">
                                    <g transform="matrix(1, 0, 0, 1, 20, 466)" filter="url(#Rectangle_3731)">
                                        <rect id="Rectangle_3731-2" data-name="Rectangle 3731" width="123" height="123"
                                            rx="10" transform="translate(30 28)" fill="#fff" />
                                    </g>
                                    <text id="Book_a_Ride" data-name="Book a Ride" transform="translate(62 515)"
                                        fill="#0171bd" font-size="13" font-family="SFUIText-Bold, SF UI Text"
                                        font-weight="700">
                                        <tspan x="11.825" y="12">Book a Ride</tspan>
                                    </text>
                                    <g id="Taxi-Car-Cab-Vehicle-Transport" transform="translate(87 553.896)">
                                        <path id="Path_13432" data-name="Path 13432"
                                            d="M10.99,35a2.99,2.99,0,1,0,2.99,2.99A2.99,2.99,0,0,0,10.99,35Zm0,4.486a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,10.99,39.486Z"
                                            transform="translate(-1.515 -7.068)" fill="#0171bd" />
                                        <path id="Path_13433" data-name="Path 13433"
                                            d="M50.99,35a2.99,2.99,0,1,0,2.99,2.99A2.99,2.99,0,0,0,50.99,35Zm0,4.486a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,50.99,39.486Z"
                                            transform="translate(-11.611 -7.068)" fill="#0171bd" />
                                        <path id="Path_13434" data-name="Path 13434" d="M23,40H36.456v1.5H23Z"
                                            transform="translate(-5.301 -8.33)" fill="#0171bd" />
                                        <path id="Path_13435" data-name="Path 13435" d="M23,36H36.456v1.5H23Z"
                                            transform="translate(-5.301 -7.32)" fill="#0171bd" />
                                        <path id="Path_13436" data-name="Path 13436" d="M29,21h4.486v1.5H29Z"
                                            transform="translate(-6.815 -3.534)" fill="#0171bd" />
                                        <path id="Path_13437" data-name="Path 13437"
                                            d="M46.855,24.194V22.7a2.249,2.249,0,0,0-2.243-2.243h-2.96L40.1,15.029l-.464-1.847a2.243,2.243,0,0,0-2.175-1.7h-6.31V8.5a1.5,1.5,0,0,0-1.5-1.5H19.194a1.5,1.5,0,0,0-1.5,1.5v2.99H11.39a2.243,2.243,0,0,0-2.175,1.7l-.456,1.839L7.2,20.456H4.243A2.249,2.249,0,0,0,2,22.7v1.5a2.237,2.237,0,0,0,1.054,1.891A3.72,3.72,0,0,0,2,28.68v8.971a2.232,2.232,0,0,0,.748,1.66v2.826A2.249,2.249,0,0,0,4.99,44.379h5.981a2.249,2.249,0,0,0,2.243-2.243V39.894H35.641v2.243a2.249,2.249,0,0,0,2.243,2.243h5.981a2.249,2.249,0,0,0,2.243-2.243V39.311a2.232,2.232,0,0,0,.748-1.66V28.68A3.72,3.72,0,0,0,45.8,26.086a2.237,2.237,0,0,0,1.054-1.892ZM19.194,8.5H29.661v2.99H19.194Zm-8.53,5.054a.748.748,0,0,1,.725-.568H37.465a.748.748,0,0,1,.725.568l.232.927H10.433Zm-.628,2.422H38.818l2.557,8.971h-21.5a4.48,4.48,0,0,0-8.836,0H7.48Zm8.306,8.971H12.571a2.978,2.978,0,0,1,5.771,0ZM3.5,22.7a.748.748,0,0,1,.748-.748H6.777l-.852,2.99H4.243a.748.748,0,0,1-.748-.748Zm8.223,19.437a.748.748,0,0,1-.748.748H4.99a.748.748,0,0,1-.748-.748V39.894h7.476Zm32.894,0a.748.748,0,0,1-.748.748H37.884a.748.748,0,0,1-.748-.748V39.894h7.476Zm.748-4.485a.748.748,0,0,1-.748.748H4.243a.748.748,0,0,1-.748-.748V36.9H45.36Zm0-2.243H3.5V28.68a2.249,2.249,0,0,1,2.243-2.243H43.117A2.249,2.249,0,0,1,45.36,28.68Zm0-11.214a.748.748,0,0,1-.748.748H42.93l-.852-2.99h2.534a.748.748,0,0,1,.748.748Z"
                                            transform="translate(0 0)" fill="#0171bd" />
                                    </g>
                                </g>
                            </svg>
                        </mat-radio-button>
                        <mat-radio-button value="delivery" class="choose_ride_radio"
                            (click)="goToriderdeliverybooking()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="183" height="183" viewBox="0 0 183 183">
                                <defs>
                                    <filter id="Rectangle_4193" x="0" y="0" width="183" height="183"
                                        filterUnits="userSpaceOnUse">
                                        <feOffset dy="2" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="10" result="blur" />
                                        <feFlood flood-opacity="0.078" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                    </filter>
                                </defs>
                                <g id="Group_59547" data-name="Group 59547" transform="translate(-173 -466)">
                                    <g transform="matrix(1, 0, 0, 1, 173, 466)" filter="url(#Rectangle_4193)">
                                        <rect id="Rectangle_4193-2" data-name="Rectangle 4193" width="123" height="123"
                                            rx="10" transform="translate(30 28)" fill="#fff" />
                                    </g>
                                    <text id="Book_a_Delivery" data-name="Book a Delivery"
                                        transform="translate(211 514)" fill="#0171bd" font-size="13"
                                        font-family="SFUIText-Bold, SF UI Text" font-weight="700">
                                        <tspan x="2.992" y="12">Book a Delivery</tspan>
                                    </text>
                                    <g id="logistics" transform="translate(238 552)">
                                        <path id="Path_13438" data-name="Path 13438"
                                            d="M49.434,49a2.434,2.434,0,1,0,2.434,2.434A2.437,2.437,0,0,0,49.434,49Zm0,3.245a.811.811,0,1,1,.811-.811A.812.812,0,0,1,49.434,52.245Z"
                                            transform="translate(-8.681 -8.303)" fill="#0171bd" />
                                        <path id="Path_13439" data-name="Path 13439"
                                            d="M17.434,49a2.434,2.434,0,1,0,2.434,2.434A2.437,2.437,0,0,0,17.434,49Zm0,3.245a.811.811,0,1,1,.811-.811A.812.812,0,0,1,17.434,52.245Z"
                                            transform="translate(-2.642 -8.303)" fill="#0171bd" />
                                        <path id="Path_13440" data-name="Path 13440"
                                            d="M49.058,30.15l-1.082-.541-1.1-6.615a4.044,4.044,0,0,0-4-3.39H34.263a4.038,4.038,0,0,0-3.224,1.623H28.584V13.113H23.716V5H12.358v8.113H7.49v8.113H2.623v8.113A1.624,1.624,0,0,0,1,30.961v1.623a1.624,1.624,0,0,0,1.623,1.623v7.3a2.437,2.437,0,0,0,2.434,2.434H9.178a5.671,5.671,0,0,0,11.228,0H35.139a5.671,5.671,0,0,0,11.228,0h2.5A2.437,2.437,0,0,0,51.3,41.508v-7.73A4.035,4.035,0,0,0,49.058,30.15Zm.62,7.612-1.623-.811V35.829h1.623ZM17.226,22.848h1.623v1.623H17.226Zm3.245,3.245V22.848h1.623v6.49H13.981v-6.49H15.6v3.245Zm9.735,3.245h-6.49v-6.49h1.623v3.245h4.868Zm0-5.679v.811H26.961V22.848h3.327a4.053,4.053,0,0,0-.082.811Zm-3.245-2.434H18.848v-6.49h1.623v3.245h4.868V14.735h1.623Zm-4.868-6.49h1.623v1.623H22.094ZM17.226,6.623h1.623V8.245H17.226Zm-3.245,0H15.6V9.868h4.868V6.623h1.623v6.49H13.981Zm-1.623,8.113h1.623v1.623H12.358Zm-3.245,0h1.623v3.245H15.6V14.735h1.623v6.49H9.113ZM7.49,22.848H9.113v1.623H7.49Zm-3.245,0H5.868v3.245h4.868V22.848h1.623v6.49H4.245ZM2.623,30.961H30.206v1.623H2.623Zm1.623,6.49H7.262l-.406,1.623H4.245Zm0,4.056V40.7H8.124l1.217-4.868h-5.1V34.206H30.206v8.113h-9.8a5.671,5.671,0,0,0-11.228,0H5.056A.812.812,0,0,1,4.245,41.508Zm10.547,5.679a4.056,4.056,0,1,1,4.056-4.056A4.061,4.061,0,0,1,14.792,47.187Zm25.15-17.848h3.245v1.623H41.565A1.624,1.624,0,0,1,39.942,29.339Zm0-1.623a1.624,1.624,0,0,0-1.623,1.623,3.212,3.212,0,0,0,.451,1.623h-3.7v-6.49h7.339l.608,3.245Zm-4.868,4.868h8.113v5.423a5.658,5.658,0,0,0-8.048,4.312h-.065Zm5.679,14.6a4.056,4.056,0,1,1,4.056-4.056A4.061,4.061,0,0,1,40.753,47.187Zm8.113-4.868h-2.5a5.66,5.66,0,0,0-1.558-3.155V28.527l-.8-4.353a1.623,1.623,0,0,0-1.595-1.326h-7.34a1.624,1.624,0,0,0-1.623,1.623V42.319H31.829V23.66a2.437,2.437,0,0,1,2.434-2.434h8.609a2.426,2.426,0,0,1,2.4,2.034l1.239,7.431,1.821.91a2.422,2.422,0,0,1,1.346,2.177v.428H46.432v3.747l3.245,1.623v1.932A.812.812,0,0,1,48.866,42.319Z"
                                            transform="translate(0 0)" fill="#0171bd" />
                                    </g>
                                </g>
                            </svg>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <!-- <form class="choose_pickup_input" [formGroup]="bookingForm"> -->
                <div class="title_bar">
                    <h2>Request a Ride now</h2>
                </div>
                <form [formGroup]="pickupForm">
                    <div class="form-group choose_pickup_outer">
                        <div class="form_grp_request_left">

                        </div>
                        <div class="form_grp_request_right" *ngIf="pickupfieldsArr.length > 0">
                            <mat-label class="ride_request">Choose your pick up</mat-label>
                            <div class="multiple_add_outer" *ngFor="let pickup of pickupfieldsArr; let i = index">
                                <mat-form-field class="example-full-width">
                                    <div [ngClass]="i === pickupfieldsArr.length-1 ? 'multiple-add last' :'multiple-add' ">
                                        <div class="blue_circle"></div>
                                        <input matInput placeholder="Search for pick up location" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete [options]="{
                                                types: [],
                                                componentRestrictions: { country: [pickup.country] }
                                              }" #placesRef="ngx-places"(change)="pickupChange(pickup,$event.target.value)"
                                              (kayup)="pickeyUp(pickup,$event.target.value)"
                                            (onAddressChange)="handlePickUpChnage($event,pickup)"
                                            formControlName="{{pickup?.name}}" />
                                        <div *ngIf="dropupfieldsArr.length === 1">
                                            <button class="rotate" *ngIf="i !== pickupfieldsArr?.length - 1 || i > 2"
                                                (click)="removePickupField(i)">+</button>
                                            <button [disabled]="pickupForm.controls[pickup?.name].hasError('required')"
                                                class="plusBtn" *ngIf="i === pickupfieldsArr?.length - 1 && i<= 2"
                                                (click)="addPickupField(pickup)">+</button>
                                        </div>
                                    </div>
                                    <!-- <p style="color: red;"
                                        *ngIf="rideSubmited === true && pickupForm.controls[pickup?.name].hasError('required')">
                                        Pick up address is required.</p> -->
                                    <!-- <p style="color: red;"
                                    *ngIf="rideSubmited === true && !isPickupChanged && !bookingForm.controls['ridePickUpAddress'].hasError('required')">
                                    Please enter valid source.</p> -->
                                </mat-form-field>
                            </div>
                            <p style="color: red;"
                            *ngIf="rideSubmited === true && pickupForm.status === 'INVALID'">
                            Pick up address is required.</p>
                        </div>
                    </div>
                </form>
                <form [formGroup]="dropupForm">
                    <div class="form-group choose_pickup_outer">
                        <div class="form_grp_request_left">

                        </div>
                        <div class="form_grp_request_right sec" *ngIf="dropupfieldsArr.length > 0">
                            <mat-label class="ride_request">Choose your drop off </mat-label>
                            <div class="multiple_add_outer" *ngFor="let drop of dropupfieldsArr; let i = index">
                                <mat-form-field class="example-full-width">
                                    <!-- <mat-label>Search for drop off location </mat-label> -->
                                    <div [ngClass]="i === dropupfieldsArr.length-1 ? 'multiple-add last' :'multiple-add' ">
                                        <div class="red_circle"></div>
                                        <input matInput placeholder="Search for drop off location" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete [options]="{
                                                types: [],
                                                componentRestrictions: { country: [drop.country] }
                                              }" #placesRef="ngx-places" (change)="dropChange(drop,$event.target.value)"
                                              (keyup)="dropKeyup(drop,$event.target.value)" (onAddressChange)="handleDropChnage($event,drop)"
                                            formControlName="{{drop?.name}}" />
                                        <div *ngIf="pickupfieldsArr.length === 1">
                                            <button class="rotate" *ngIf="i !== dropupfieldsArr?.length - 1 || i > 2"
                                                (click)="removeDropupField(i)">+</button>
                                            <button [disabled]="dropupForm.controls[drop?.name].hasError('required')"
                                                class="plusBtn" *ngIf="i === dropupfieldsArr?.length - 1 && i <= 2"
                                                (click)="addDropupField(drop)">+</button>
                                        </div>
                                    </div>
                                    <!-- <p style="color: red;"
                                        *ngIf="rideSubmited === true && dropupForm.controls[drop?.name].hasError('required')">
                                        Drop off address is required.</p> -->
                                </mat-form-field>
                            </div>
                            <p style="color: red;"
                            *ngIf="rideSubmited === true && dropupForm.status === 'INVALID'">
                            Drop off address is required.</p>
                            <!-- <p style="color: red;"
                                *ngIf="rideSubmited === true && !isDropChanged && !bookingForm.controls['rideDropAddress'].hasError('required')">
                                Please enter valid destination.</p> -->
                        </div>
                    </div>
                </form>
                <!-- </form> -->
                <div class="btn_divde margin_top25">
                    <div class="btn_outer">
                        <button
                            [ngClass]="{'btn-active': activeBtn === 'Now','btn': true, 'btn-outline': activeBtn != 'Now'}"
                            (click)="bookRideForNow('Now')">
                            Start now
                        </button>
                    </div>
                    <div class="btn_outer">
                        <!-- data-toggle="modal" data-target="#exampleModall" -->
                        <button
                            [ngClass]="{'btn-active': activeBtn === 'Later','btn': true, 'btn-outline': activeBtn != 'Later'}"
                            (click)="openDateTimeModal()">
                            Schedule a ride
                        </button>
                    </div>


                </div>
            </div>
            <div class="request_ride_inner part2" *ngIf="nextPopup == 1">
                <div class="car_using_main">
                    <div class="car_using_left">
                        <h4>Suggested vehicle <span class="suggted-car">(options subject to availability)</span></h4>
                        <ul class="car_using_left_inner">
                            <li [ngClass]="{'active_car_bx': selectedItem === i}"
                                *ngFor="let category of vehicleCategoryData; let i = index"
                                (click)='selectVehicle(category,i);'>
                                <div class="yellow_bg">
                                    <figure>
                                        <img [src]="baseUrl+category?.vehicleImage" />
                                    </figure>
                                </div>
                                <h5>{{category?.vehicleCategory}}</h5>
                                <p>US $<span>{{category?.booKingAmount}}</span></p>
                                <!-- <p>US $<span>{{getFare(category?.pricePerMile)}}.00</span></p> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="note_driver">
                    <h3>Note to driver</h3>
                    <span>Let your driver know more about your request.</span>
                    <div class="note_driver_name">
                        <form [formGroup]="driverNoteForm">
                            <mat-form-field class="example-full-width">
                                <input type="text" matInput formControlName="phoneNo" class="form-control"
                                    placeholder="Enter Your Phone Number" />
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <input type="text" matInput formControlName="name" class="form-control"
                                    placeholder="Enter Your Name" />
                            </mat-form-field>
                            <textarea placeholder="Note" maxlength="100" formControlName="notes" id="" cols="30"
                                rows="10"></textarea>
                        </form>
                    </div>
                </div>
                <div class="add_promo_sec">
                    <div class="visa_card">
                        <figure (click)="continue('2')">
                            <img src="assets/uimages/visa_card.png" alt="" />
                        </figure>
                    </div>
                    <div class="add_promo">
                        <a href="##" data-toggle="modal" data-target="#add_promo"><span><img
                                    src="assets/uimages/promo_icon.png" alt="" /></span>Loyality Points</a>
                    </div>
                </div>
                <div class="btn_divde margin_custom">
                    <div class="btn_outer inactive">
                        <button class="btn btn-primary" (click)="continue(0)">
                            Back
                        </button>
                    </div>
                    <div class="btn_outer">
                        <button class="btn btn-primary" (click)="CreateRideBooking(3)">
                            Request Booking
                        </button>
                    </div>
                </div>
            </div>

            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 2">
                <div class="title_bar">
                    <h2>Payment</h2>
                </div>
                <ul class="payment_last">
                    <li class="borderbtm">
                        <div class="list_inner">
                            <mat-checkbox>Pay via Apptunix Wallet</mat-checkbox>
                        </div>
                    </li>
                    <li class="borderbtm">
                        <div class="list_inner">
                            <mat-checkbox>Pay With Paypal</mat-checkbox>
                        </div>
                    </li>
                    <li>
                        <div class="list_inner">
                            <mat-checkbox>Pay with Credit/Debit Card</mat-checkbox>
                        </div>
                    </li>
                    <li class="half">
                        <div data-toggle="modal" data-target="#add_payment">
                            <a>Add new card</a>
                        </div>
                    </li>
                    <li class="borderbtm">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1">
                                <div class="card_detailcs">
                                    <h3>•••• •••• •••• 4561<span>Mastercard - 06/23</span></h3>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </li>
                    <li>
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1">
                                <div class="card_detailcs">
                                    <h3>•••• •••• •••• 4561<span>Visacard - 06/23</span></h3>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </li>
                </ul>

                <div class="btn_outer">
                    <button class="btn btn-primary" (click)="continue('1')">Next</button>
                </div>
            </div>
            <!-----start rider accept -->
            <div class="request_ride_inner border_remove paymernt sec" *ngIf="nextPopup == 3">
                <div class="driver_info_bx sec">
                    <div class="spinners"><i class="fa fa-spinner fa-spin"></i></div>
                    <p class="arrving_time">We are processing your booking request..</p>
                    <p class="arrving_time">A notification will be received soon.</p>

                    <div class="ride_can_btn">
                        <button class="ride_cancle_btn" (click)="cancelBooking(0)">Cancel</button>
                    </div>
                </div>

                <!-- <div class="flex reqst driverap_btn">
                        <button (click)="continue('1')">Next</button>
                    </div> -->

                <!-----end rider accept-->
            </div>
            <!-----start call me reach me screen-->
            <!-----start rider accept -->
            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 4">
                <div class="driver_info_bx" constant.BOOKING_STATUS.ACCEPTED>
                    <!-- <div *ngFor="let data of bookingResponseData.subBookingData; let i = index"> -->
                        <!-- <div *ngIf="pickupfieldsArr.length > 1 && dropupfieldsArr.length === 1">
                            <p class="arrving_time"
                                *ngIf="i === 0 && data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.ACCEPTED && data.bookingStatus !== constant.BOOKING_STATUS.ARRIVED_AT_PICKED
                                && data.bookingStatus !== constant.BOOKING_STATUS.ARRIVED_AT_DROPED">
                                driver will arrive in {{duration.toFixed()}} minutes at location {{i + 1}} .
                            </p>
                            <p class="arrving_time"
                                *ngIf="data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.ARRIVED_AT_PICKED && data.bookingStatus !== constant.BOOKING_STATUS.PICKED">
                                driver has arrived at location {{i+1}} .
                            </p>
                            <p class="arrving_time"
                                *ngIf="data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.PICKED && bookingResponseData.subBookingData.length != i + 2">
                                driver will arrive in {{distanceDuration}} at location {{i + 2 }} .
                            </p>
                            <p class="arrving_time"
                                *ngIf="!data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.ARRIVED_AT_DROPED && data.bookingStatus !== constant.BOOKING_STATUS.DROPED && data.bookingStatus !== constant.BOOKING_STATUS.PICKED">
                                Your ride has started.
                            </p>
                            <p class="arrving_time"
                                *ngIf="!data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.DROPED">
                                you have arrived at your destination.
                            </p>
                        </div> -->
                        <!-- <div *ngIf="pickupfieldsArr.length === 1 && dropupfieldsArr.length > 1">
                            <p class="arrving_time"
                                *ngIf="i === 0 && data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.ACCEPTED && data.bookingStatus !== constant.BOOKING_STATUS.ARRIVED_AT_PICKED">
                                driver will arrive in {{duration}} minutes.
                            </p>
                            <p class="arrving_time"
                                *ngIf="i === 0 && data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.ARRIVED_AT_PICKED && data.bookingStatus !== constant.BOOKING_STATUS.PICKED">
                                driver is at pickup location.
                            </p>
                            <p class="arrving_time"
                                *ngIf="i !== 1 && data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.PICKED
                                && data.bookingStatus !== constant.BOOKING_STATUS.ARRIVED_AT_DROPED && data.bookingStatus !== constant.BOOKING_STATUS.DROPED">
                                Your ride has started.
                            </p>
                            <p class="arrving_time"
                                *ngIf="data.bookingStatus !== constant.BOOKING_STATUS.PICKED 
                                && !data.isPickUp
                                && (data.bookingStatus === constant.BOOKING_STATUS.ARRIVED_AT_DROPED || data.bookingStatus === constant.BOOKING_STATUS.DROPED) 
                                && i !== bookingResponseData.subBookingData.length - 1">
                                You arrived at your location {{i}}.
                            </p>
                            <p class="arrving_time"
                                *ngIf="i === bookingResponseData.subBookingData.length - 1 && !data.isPickUp && data.bookingStatus === constant.BOOKING_STATUS.DROPED">
                                you have arrived at your final destination.
                            </p>
                        </div> -->
                    <!-- </div> -->
                    <p class="arrving_time" >{{driverMessage}}</p>
                    <!-- <p class="arrving_time" *ngIf="duration <= 1">Your driver has arrived at location 1.</p> -->

                    <div class="about_driver">
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
                            <img src="assets/images/user.png" alt=" " />
                        </figure>
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
                            <img [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distance?.toFixed(2)}} km</span>

                        </div>
                        <div class="car_img">
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleModel}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                <div *ngIf="pick?.isPickUp">
                                    <div class="blm">
                                        <div class="bb"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{pick?.address}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                                <div *ngIf="!drop?.isPickUp">
                                    <div class="blm">
                                        <div class="blc"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{drop?.address}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <div class="note_call" *ngIf="bookingResponseData?.note != null">
                        <p>Note : {{bookingResponseData?.note}}.</p>
                    </div>
                    <div class="trip_payment">
                        <h4>Payment</h4>
                        <div class="visa_card">
                            <figure (click)="continue('2')">
                                <img src="assets/uimages/visa_card.png " alt=" " />
                            </figure>
                        </div>
                    </div>
                    <!----start cancel call btn-->
                    <div class="ride_can_btn">
                        <button class="ride_cancle_btn" (click)="cancelBooking(0)">Cancel<div class="duration"
                                *ngIf="cancelCounter > 0">{{cancelCounter}}</div></button>
                        <button class="call_btn" (click)="goToContactDetail()">Call</button>
                    </div>
                    <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
                        <div class="add_honda_left">
                            <div class="file">
                                <figure class="driver-img">
                                    <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image"
                                        src="assets/images/user.png">
                                    <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image"
                                        [src]="baseUrl+bookingResponseData?.driverId?.image">
                                </figure>
                                <h5><span>{{bookingResponseData?.driverId?.countryCode}}
                                        {{bookingResponseData?.driverId?.phoneNo}}</span>
                                    {{bookingResponseData?.driverId?.firstName}}
                                    {{bookingResponseData?.driverId?.lastName}}
                                    <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                                </h5>
                            </div>
                        </div>
                        <div class="add_vehicle_right flex">
                            <div class="add_vehicle_right_icon">
                                <figure>
                                    <img src="assets/images/phone-call.svg">
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="flex reqst driverap_btn ">
                        <button (click)="continue( '1') ">Next</button>
                    </div> -->

                <!-----end rider accept-->
            </div>
            <!-----End  call me reach me screen-->



            <!-----start driver reach -->
            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 5">
                <div class="driver_info_bx">
                    <p class="arrving_time">Your driver has arrived.</p>
                    <div class="about_driver">
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
                            <img src="assets/images/user.png" alt=" " />
                        </figure>
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
                            <img [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distance?.toFixed(2)}} km</span>
                        </div>
                        <div class="car_img">
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleModel}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                <div *ngIf="pick?.isPickUp">
                                    <div class="blm">
                                        <div class="bb"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{pick?.address}}</p>
                                    </div>

                                </div>
                                <div class="stts"
                                    *ngIf="pick?.isPickUp && pick?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                    <p>{{constant.SHOW_STATUS_BOOKING[pick?.bookingStatus]}}</p>
                                </div>
                            </div>

                            <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                                <div *ngIf="!drop?.isPickUp">
                                    <div class="blm">
                                        <div class="blc"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{drop?.address}}</p>
                                    </div>
                                </div>
                                <div class="stts"
                                    *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                    <p>{{constant.SHOW_STATUS_BOOKING[drop?.bookingStatus]}}</p>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <div class="note_call" *ngIf="bookingResponseData?.note != null">
                        <p>Note : {{bookingResponseData?.note}}.</p>
                    </div>
                    <div class="trip_payment">
                        <h4>Payment</h4>
                        <div class="visa_card">
                            <figure (click)="continue('2')">
                                <img src="assets/uimages/visa_card.png " alt=" " />
                            </figure>
                        </div>
                    </div>
                    <!----start cancel call btn-->
                    <div class="ride_can_btn">
                        <button class="call_btn" (click)="goToContactDetail()">Call</button>
                    </div>
                    <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
                        <div class="add_honda_left">
                            <div class="file">
                                <div class="file-left">
                                    <figure class="driver-img">
                                        <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image"
                                            src="assets/images/user.png">
                                        <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image"
                                            [src]="baseUrl+bookingResponseData?.driverId?.image">
                                    </figure>
                                </div>
                                <div class="file-right">
                                    <h5><span>{{bookingResponseData?.driverId?.countryCode}}
                                            {{bookingResponseData?.driverId?.phoneNo}}</span>
                                        {{bookingResponseData?.driverId?.firstName}}
                                        {{bookingResponseData?.driverId?.lastName}}
                                        <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="add_vehicle_right flex">
                            <div class="add_vehicle_right_icon">
                                <figure>
                                    <img class="clr-bl" src="assets/images/phone-call.svg">
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="flex reqst driverap_btn ">
                        <button (click)="continue( '1') ">Next</button>
                    </div> -->

                <!-----end rider accept-->
            </div>
            <!-----End  call me driver  me screen-->

            <!----start next 2 screen ride start now-->
            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 6">
                <div class="driver_info_bx">
                    <p class="arrving_time">Your ride has started.</p>
                    <div class="about_driver">
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
                            <img src="assets/images/user.png" alt=" " />
                        </figure>
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
                            <img [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distance?.toFixed(2)}} km</span>

                        </div>
                        <div class="car_img">
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleModel}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                <div *ngIf="pick?.isPickUp">
                                    <div class="blm">
                                        <div class="bb"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{pick?.address}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                                <div *ngIf="!drop?.isPickUp">
                                    <div class="blm">
                                        <div class="blc"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{drop?.address}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <div class="moniter_your_ride">
                        <p (click)="openMonitorModal(bookingResponseData?.id)">
                            Monitor your ride
                            <i class="fa fa-angle-right"></i>
                        </p>
                    </div>
                    <div class="ride_cost_sec">
                        <div class="cost_item">
                            <p>Cost <span>US ${{bookingResponseData?.booKingAmount}}</span></p>
                        </div>
                        <div class="cost_item">
                            <p>Duration <span>{{duration.toFixed(0)}} mins.</span></p>
                        </div>
                    </div>
                </div>
                <!-----end rider accept-->
            </div>


            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 7">
                <div class="driver_info_bx">
                    <p class="arrving_time">You will reach your destination in next 5 minutes.</p>
                    <div class="about_driver">
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
                            <img src="assets/images/user.png" alt=" " />
                        </figure>
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
                            <img [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">Jhon</p>

                            <span><i class="fa fa-star" aria-hidden="true"></i>4.8</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>2:30 min</span>

                        </div>
                        <div class="car_img">
                            <figure>
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <p>Toyota: <span>8CF4829</span></p>
                        </div>
                    </div>
                    <h4 class="trip_title">Trip</h4>
                    <Div class="start_trip">

                        <div class="start_trip_text">
                            <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                <div *ngIf="pick?.isPickUp">
                                    <div class="blm">
                                        <div class="bb"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{pick?.address}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                                <div *ngIf="!drop?.isPickUp">
                                    <div class="blm">
                                        <div class="blc"></div>
                                    </div>
                                    <div class="mkl">
                                        <p>{{drop?.address}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Div>
                    <div class="moniter_your_ride">
                        <p (click)="openMonitorModal(bookingResponseData?.id)">
                            Monitor your ride
                            <i class="fa fa-angle-right"></i>
                        </p>
                    </div>
                    <div class="ride_cost_sec">
                        <div class="cost_item">
                            <p>Cost <span>US ${{bookingResponseData?.booKingAmount}}</span></p>
                        </div>
                        <div class="cost_item">
                            <p>Duration <span>{{duration.toFixed(0)}} mins.</span></p>
                        </div>
                    </div>
                </div>
                <!-----end rider accept-->
            </div>
            <!----end next 2 screen ride start now-->

            <!-----you ride started page-->

            <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 8">
                <div class="driver_info_bx">
                    <p class="arrving_time">Ride is completed.</p>
                    <div class="about_driver">
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
                            <img src="assets/images/user.png" alt=" " />
                        </figure>
                        <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
                            <img [src]="baseUrl+bookingResponseData?.driverId?.image" alt=" " />
                        </figure>
                        <div class="driver_name_sec">
                            <p class="driver_name">{{bookingResponseData?.driverId?.firstName}}</p>
                            <span><i class="fa fa-star"
                                    aria-hidden="true"></i>{{bookingResponseData?.driverId?.avgRating}}</span>
                            <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{distance?.toFixed(2)}} km</span>


                        </div>
                        <div class="car_img">
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure>
                            <p>{{bookingResponseData?.vehicleId?.vehicleModel}}:
                                <span>{{bookingResponseData?.vehicleId?.licensePlateNumber}}</span></p>
                        </div>

                    </div>
                    <div>
                        <div class="trip">
                            <p>Type of trip: {{bookingResponseData?.isSheduledBooking ? 'Later' : 'Now'}}</p>
                            <p>{{bookingResponseData?.bookingDate | date: 'MMM d, y, h:mm a'}}</p>
                        </div>
                        <Div class="start_trip">

                            <div class="start_trip_text">
                                <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                    <div *ngIf="pick?.isPickUp">
                                        <div class="blm">
                                            <div class="bb"></div>
                                        </div>
                                        <div class="mkl">
                                            <p>{{pick?.address}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                                    <div *ngIf="!drop?.isPickUp">
                                        <div class="blm">
                                            <div class="blc"></div>
                                        </div>
                                        <div class="mkl">
                                            <p>{{drop?.address}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Div>
                        <div class="details">
                            <p>{{bookingResponseData?.name}}</p>
                            <hr>
                            <p>{{bookingResponseData?.phoneNo}}</p>
                            <hr>
                            <p *ngIf="bookingResponseData?.note">{{bookingResponseData?.note}}</p>
                            <hr *ngIf="bookingResponseData?.note">
                        </div>
                        <div class="amount">
                            <div class="price">${{bookingResponseData?.booKingAmount}}</div>
                            <p class="tax">incl. Tax</p>
                        </div>
                    </div>
                    <div class="ride_can_btn rvw">
                        <button class="call_btn btn btn-primary" (click)="continue(14)">Review Driver</button>
                    </div>
                </div>
                <!-----end rider accept-->
            </div>

            <div class="request_ride_inner border_remove" *ngIf="nextPopup == 14">
                <div class="driver_info_bx">
                    <p class="arrving_time">Rate Your Ride.</p>
                    <div class="rate-screen">
                    </div>
                    <div class="rl">
                        <div class="del-main">
                            <div class="del-l">
                                <figure>
                                    <img *ngIf="!driverReviewData?.image" src="assets/images/man_user.jpg">
                                    <img *ngIf="driverReviewData?.image" [src]="baseUrl+driverReviewData?.image">
                                </figure>
                            </div>
                            <div class="main-c">
                                <div class="del-r del-u">
                                    <h5>{{driverReviewData?.firstName +' '+driverReviewData?.lastName}}</h5>
                                </div>
                                <div class="driver_rate_pop del-b">
                                    <p>Rating<span>{{driverReviewData?.avgRating}}/5</span></p>
                                    <p>Review<span>{{driverReviewData?.reviewTotal}}</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="heart">
                            <i (click)="addFavouriteDriver()" *ngIf="!driverReviewData?.isFav"
                                class="fa fa-heart-o redcolor" aria-hidden="true"></i>
                            <i (click)="removeFavouriteDriver()" *ngIf="driverReviewData?.isFav"
                                class="fa fa-heart redcolor" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="rating">
                        <p class="title">Rate</p>
                        <div class="star-rating">
                            <button mat-icon-button *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
                                (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
                                <mat-icon>
                                    {{showIcon(i)}}
                                </mat-icon>
                            </button>
                        </div>
                        <p class="rating-err" *ngIf="ratingSubmitted && rating === 0">Please give rating.
                        </p>
                    </div>
                    <ul class="bages" *ngIf="rating > 2">
                        <li [ngClass]="selectedDriverReview === i ? 'badge badge-secondary active' : 'badge badge-secondary'"
                            *ngFor="let data of reviewData; let i = index" (click)="selectReview(i,data)">{{data?.name}}
                        </li>
                        <!-- <li [ngClass]="{'badge badge-secondary': data?.isActive === false,'badge badge-secondary active': data?.isActive === true}" *ngFor="let data of reviewData">{{data?.name}}</li> -->
                    </ul>
                    <div class="fgi sec" *ngIf="rating <= 2 && rating > 0">
                        <textarea id="w3review" class="from-control" placeholder="Enter your feedback here..."
                            [formControl]="review"></textarea>
                    </div>
                    <p class="rating-err" *ngIf="ratingSubmitted && selectedReviewData === '' && rating > 0">Please give
                        feedback.</p>
                </div>
                <div class="tip-title">
                    <p>Add a tip</p>
                </div>
                <div class="bb-hldr">
                    <div [ngClass]="selectedTip === i ? 'bbxx active' : 'bbxx'"
                        *ngFor="let item of dummy; let i = index" (click)="selectTip(i,item?.amount)">
                        {{'$ ' + item?.amount}}</div>
                </div>
                <div class="custom-tip">
                    <mat-form-field class="example-full-width">
                        <input class="tip-input" type="number" [formControl]="customRate" matInput
                            placeholder="Enter custom amount.">
                    </mat-form-field>
                </div>
                <div class="feedback-links">
                    <h5 class="fdd-links">Feedback Links</h5>
                    <h6 class="dd">Driver</h6>
                    <ul>
                        <li>
                            Was the driver on time?
                            <div class="tp">
                                <mat-radio-group aria-label="Select an option" [formControl]="wasTheDriverOnTime">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </li>
                        <li>
                            Did the driver make your ride comfortabel?
                            <div class="tp">
                                <mat-radio-group aria-label="Select an option" [formControl]="driverMakeYouComfortabel">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </li>
                        <li>
                            Was the ride safe?
                            <div class="tp">
                                <mat-radio-group aria-label="Select an option" [formControl]="wasTheRideSafe">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </li>
                        <li class="imp">
                            What can we improve for next time?
                            <div class="tp">
                                <mat-form-field class="example-full-width">

                                    <textarea matInput [formControl]="comment" placeholder="Comment"></textarea>
                                </mat-form-field>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="bk-hm">
                    <button class="btn btn-primary first-btn" (click)="addRating(0)">Submit</button>
                    <button class="btn btn-outline second-btn" (click)="continue(0)">Skip</button>
                </div>
            </div>

            <!--  <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 10" (click)="continue('0')">
                <div class="driver_info_bx">
                    <p class="arrving_time">Ride is completed</p>
                 
                        <div class="driver_info">
                            <div class="driver_info_img">
                              <figure>
                                <img src="assets/uimages/driver_img.png" alt="" />
                              </figure>
                            </div>
                            <div class="driver_info_text">
                              <h3>Evan Guzman</h3>
                              <figure>
                                <img src="assets/uimages/star_rateing.png" alt="" />
                              </figure>
                              <p>Toyota Corolla:<span>8CF4829</span></p>
                            </div>
                          </div>
                          <div class="trip_info">
                            <p>Type of trip: Now</p>
                            <p>July 24, 2020, 9:58 AM</p>
                          </div>
                          <div class="trip_pickup_info">
                            <div class="trip_info_img">
                              <figure>
                                <img src="assets/uimages/blue_yellow_circle.png" alt="" />
                              </figure>
                            </div>
                            <div class="trip_detail">
                              <h5><label>Pick-Up</label>199 Brown Estate Apt. 866</h5>
                              <h5><label>Destination</label>199 Brown Estate Apt. 866</h5>
                            </div>
                          </div>
                          <div class="driver_name_info">
                            <p>sandeep singh</p>
                            <p>+40-0767217315</p>
                          </div>
                        
                          <div class="booking-complete">
                              <h4>Booking Completed</h4>
                          </div>
                  
                </div>
              
            </div>-->



            <!-- Modal -->
            <div class="modal fade" id="exampleModal " tabindex="-1"  role="dialog"
                aria-labelledby="exampleModalLabel " aria-hidden="true ">
                <div class="modal-dialog" role="document ">
                    <div class="modal-content">
                        <div class=" ">
                            <button type="button " class="close" data-dismiss="modal " aria-label="Close ">
                                <span aria-hidden="true ">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="add_vehicle">
                                <h4>Add vehicle</h4>
                                <div class="vehicle_form">
                                    <div class="form-group">
                                        <mat-form-field [floatLabel]="'never'">
                                            <input matInput type="text " placeholder="Vehicle make " name="name " />
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field [floatLabel]="'never'">
                                            <input matInput type="text " placeholder="Vehicle Model " name="name " />
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field>
                                            <mat-label>Vehicle type</mat-label>
                                            <mat-select [(value)]="selected">
                                                <mat-option value="option1 ">SUV</mat-option>
                                                <mat-option value="option2 ">SUV</mat-option>
                                                <mat-option value="option3 ">SUV</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group upload_form_group">
                                        <figure class="ipload_img_vehicle">
                                            <svg xmlns="http://www.w3.org/2000/svg " width="114.195 " height="97.656 "
                                                viewBox="0 0 114.195 97.656 ">
                                                <g id="surface1 " transform="translate(0) ">
                                                    <path id="Path_24238 " data-name="Path 24238 "
                                                        d="M110.78,160.375H3.416a3.39,3.39,0,0,0-2.583,1.164,3.25,3.25,0,0,0-.8,2.7l7.069,50.644a8.6,8.6,0,0,0,8.431,7.381H98.8a8.581,8.581,0,0,0,8.432-7.409l6.926-50.645a3.343,3.343,0,0,0-.795-2.7A3.461,3.461,0,0,0,110.78,160.375ZM75.267,191.744a2.266,2.266,0,0,1-1.533.6,2.418,2.418,0,0,1-1.675-.71l-12.69-13.655v32.448a2.271,2.271,0,0,1-4.543,0V177.976L42.137,191.6a2.269,2.269,0,1,1-3.321-3.094l16.635-17.857a2.272,2.272,0,0,1,1.675-.709,2.318,2.318,0,0,1,1.675.709l16.635,17.857A2.372,2.372,0,0,1,75.267,191.744Zm0,0 "
                                                        transform="translate(0 -124.605) " fill="#ccc " />
                                                    <path id="Path_24239 " data-name="Path 24239 "
                                                        d="M39.057,31.228h96.152V24.045a8.379,8.379,0,0,0-8.432-8.432H91.263A13.123,13.123,0,0,1,80.078,9.2L77.126,4.173A8.569,8.569,0,0,0,69.83,0H46.58a8.468,8.468,0,0,0-6.161,2.7,8.563,8.563,0,0,0-2.357,6.33Zm0,0 "
                                                        transform="translate(-29.566) " fill="#ccc " />
                                                </g>
                                            </svg>
                                        </figure>
                                        <h5>Upload insurance document</h5>
                                        <input type="file " id="file-input " class="upload_file" />
                                    </div>
                                </div>
                                <div class="driverap_btn">
                                    <button>Add Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- request_modale -->

            <div class="modal fade" id="exampleModall" tabindex="-1" [ngStyle]="{'display':display,'opacity':1}" role="dialog "
                aria-labelledby="exampleModalLabel " aria-hidden="true ">
                <div class="modal-dialog" role="document ">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button " class="close" (click)="closeScheduleModal()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true ">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body second">
                            <div class="add_vehicle">
                                <h4>Pick a day and time to book a vehicle</h4>
                                <div class="vehicle_form">
                                    <form [formGroup]="requestForLaterForm">
                                        <!-- <div class="form-group">
                                            <mat-form-field>
                                                <mat-label>Choose a date</mat-label>
                                                <input matInput formControlName="date" [matDatepicker]="picker" />
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>

                                                <mat-datepicker #picker> </mat-datepicker>
                                                <div class="alm_modale clender">
                                                    <figure class="alarm_modale">
                                                        <img src="../../assets/images/alarm_modale.svg " />
                                                    </figure>
                                                </div>
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group">
                                            <div class="default-time-example">
                                                <input aria-label="default time" formControlName="time"
                                                    [ngxTimepicker]="defaultValue" [value]="'05:11 pm'" readonly />
                                                <ngx-material-timepicker #defaultValue></ngx-material-timepicker>
                                            </div>
                                            <div class="alm_modale">
                                                <figure class="alarm_modale">
                                                    <img src="../../assets/images/timer_modale.svg " />
                                                </figure>
                                            </div>
                                        </div> -->
                                        <div class="form-group">
                                            <input [owlDateTimeTrigger]="dt" formControlName="dateTime"
                                                placeholder="Select Date and Time" [min]="mindate" [owlDateTime]="dt">
                                            <owl-date-time #dt></owl-date-time>
                                            <mat-error
                                                *ngIf="isDateTimeSubmit && requestForLaterForm.controls['dateTime'].hasError('required')">
                                                Please select Date and Time</mat-error>
                                        </div>
                                    </form>
                                </div>
                                <div class="driverap_btn">
                                    <button (click)="bookRideForLater()">Request For Later</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-----add payment card modal-->
            <!-- Modal -->
            <div class="modal fade" id="add_payment " role="dialog ">
                <div class="modal-dialog">
                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button " class="close" data-dismiss="modal" aria-label="Close ">
                                +
                            </button>
                        </div>
                        <div class="modal-body">
                            <h3 class="add_card_title">Add a card</h3>
                            <div class="card_card_form">
                                <div class="add_card_input_itms">
                                    <input type="text " placeholder="card holder name " />
                                </div>
                                <div class="add_card_input_itms">
                                    <input type="text " placeholder="XXX XXX XXX " />
                                </div>
                                <div class="add_card_input_itms">
                                    <select>
                                        <option>Month</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                    </select>
                                </div>
                                <div class="add_card_input_itms">
                                    <select>
                                        <option>year</option>
                                        <option>2015</option>
                                        <option>2016</option>
                                        <option>2017</option>
                                        <option>2018</option>
                                        <option>2019</option>
                                        <option>2020</option>
                                    </select>
                                </div>
                                <div class="add_card_input_itms">
                                    <input type="text " placeholder="CCV " />
                                </div>
                            </div>
                        </div>
                        <button class="add_card">Save</button>
                    </div>
                </div>
            </div>

            <!----start promo card popup-->
        </div>
    </div>
    <div *ngIf="isThisOnlyTrackingPage">
        <h3>hello</h3>
    </div>
</div>

<!-- Modal -->
<div class="modal fade promo_page" id="add_promo" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true ">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h3 class="add_card_title">Add Promo code</h3>
                <div class="enter_promo_code">
                    <input class="from-control" type="text " placeholder="Enter Your Promotion Code " />
                    <div class="commen_btn_outer">
                        <button class="btn btn-primary">Apply</button>
                    </div>
                </div>
                <!----start promo code offer-->
                <div class="promo_offer_main">
                    <div class="promo_offer_bx">
                        <figure>
                            <img src="assets/uimages/promo_code_icon.png " alt=" " />
                        </figure>
                        <h4>
                            10% of base rate on your next rental
                            <span>Valid to 10/10/2020</span>
                        </h4>

                        <mat-checkbox></mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!----end promo card popup-->
<!-- Modal -->
<div class="modal fade promo_page" id="add_moniter" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button " class="close" data-dismiss="modal" aria-label="Close">
                    <span><i class="fa fa-times" aria-hidden="true"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="monitorForm">
                    <h3 class="add_card_title">Monitor your ride</h3>
                    <p class="moniter_para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et dolore magna aliqua. Ut enim.
                    </p>
                    <div class="monitor_outer">
                        <input type="text" class="from-control moniter_input " formControlName="name"
                            placeholder="Name" />
                        <mat-error class="monitor_err sec"
                            *ngIf="monitorSubmit && monitorForm.controls['name'].hasError('required')">
                            Name is required.
                        </mat-error>
                        <ngx-intl-tel-input [inputId]="'adsasd'" [cssClass]="'custom'"
                            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                            [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                            [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                        </ngx-intl-tel-input>
                        <mat-error class="monitor_err"
                            *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('required')">Phone
                            number is required.</mat-error>
                        <mat-error class="monitor_err"
                            *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                            Enter valid number.</mat-error>
                    </div>
                    <div class="share_btn">
                        <button (click)="monitorRide()">share</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!----end promo card popup-->